const STORAGE_ID = 'iam.usersettings';
const IMAGE_MAX_LENGTH = 30;
interface UserSettingType{
    symbol:{
        color?:"warning" | "primary" | "danger";
        avatar?:number;
    }
    theme_mode?: string;
    create_time?: number;
}
const defaultValues:UserSettingType = (()=>{
    return {
        symbol:{}
    }
})();

/**
 * 사용자 설정정보를 관리합니다.
 */
const UserSettings = class{
    /**
     * 테마를 관리합니다.
     */
    public static theme = class{
        /**
         * 테마 모드값을 반환합니다.
         * @returns 
         */
        public static getThemeMode = ():string =>{
            const info = UserSettings.storage.get();
            return info?.theme_mode || "light";
        }

        public static setThemeMode = (mode?:string)=>{
            const info = UserSettings.storage.get() || defaultValues;
            info.theme_mode = mode;
            UserSettings.storage.set(info);
        }
    }


    /**
     * 심볼의 컬러값을 저장합니다.
     */
    public static symbol = class{
        public static setColor = (color? : "warning" | "primary" | "danger")=>{
            const info = UserSettings.storage.get() || defaultValues;
            info.symbol.color = color;
            UserSettings.storage.set(info);
        }

        /**
         * 심볼의 컬러를 값을 반환합니다.
         * 기존 컬러가 없는 경우, 랜덤으로 색을 선정합니다.
         * @returns 
         */
        public static getColor = ():"warning" | "primary" | "danger" | undefined=>{
            const info = UserSettings.storage.get();
            const color = info?.symbol.color || ["warning", "primary", "danger"].randomSelect() as ("warning"| "primary"| "danger");
            if(info?.symbol.color !== color){ this.setColor(color); }
            return color;
        }

        public static setAvatar = (index?:number)=>{
            const info = UserSettings.storage.get() || defaultValues;
            info.symbol.avatar = index;
            UserSettings.storage.set(info);
        }

        /**
         * 심볼의 이미지를 바꿉니다.
         * @returns 
         */
        public static resetAvatar = (index?:number):number=>{
            const info = UserSettings.storage.get();
            const avatar = index || Math.ceil( Math.random()*IMAGE_MAX_LENGTH);
            if(info?.symbol.avatar !== avatar){ this.setAvatar(avatar); }
            return avatar;
        }

        /**
         * 심볼의 이미지를 바꿉니다.
         * @returns 
         */
        public static nextAvatar = ():number=>{
            const info = UserSettings.storage.get();
            let index = info?.symbol.avatar || 0; 
            if(++index > IMAGE_MAX_LENGTH){ index=1; }
            if(info?.symbol.avatar !== index){ this.setAvatar(index); }
            return index;
        }

        /**
         * 심볼의 이미지 번호를 반환합니다.
         * @returns 
         */
        public static getAvatar = ():number| undefined=>{
            const info = UserSettings.storage.get();
            const avatar = info?.symbol.avatar !==undefined ? info?.symbol.avatar : (Math.ceil( Math.random()*IMAGE_MAX_LENGTH));
            if(info?.symbol.avatar !== avatar){ this.setAvatar(avatar); }
            return avatar;
        }
    }

    public static storage = class {
        /**
         * 로그인정보를 스토리지에 저장합니다.
         * @param token 및 사용자정보
         */
        public static set(data:UserSettingType){
            data.create_time = new Date().getTime();
            localStorage.setItem(STORAGE_ID, JSON.stringify(data));
        }

        /**
         * 로컬스토리지로부터 세션정보를 로딩하여 반환합니다.
         * 세션시간이 만료한 경우, undefined인 반환됩니다.
         * @returns 세션시간 만료한 경우 or 데이터가 없는 경우 undefined 반환합니다.
         */
        public static get():UserSettingType|undefined{
            const data: string | null = localStorage.getItem(STORAGE_ID);
            if (!data) { return undefined; }
            try {
                const info:UserSettingType|undefined  = data ? JSON.parse(data) : undefined;
                if (!info) { return undefined; }
                return info;
            } catch (error) {
                console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
            }
        }

        /**
         * 로컬스토리지를 비웁니다.
         */
        public static clear(){
            localStorage.removeItem(STORAGE_ID);
        }
    }
}

export default UserSettings;