import clsx from "clsx";
import React, {CSSProperties, useEffect, useState} from "react";


export interface DragAndDropListProps<T=string>{
    className?: string;
    style?: CSSProperties;
    options: T[];

    /**
     * 순서가 변경된 경우 정렬된 배열로 반환합니다.
     * @param items 
     * @returns 
     */
    onChange?: (items:T[])=>void;

    /**
     * 아이템 컨텐츠를 구성합니다.
     * @param item 
     * @returns 
     */
    formatter: (item:T)=>string|React.ReactNode;
}

interface ItemType<T>{
    id: number;
    item : T;
    checked: boolean;
}

interface DragSelectItemType{
    index: number;
    current: HTMLDivElement;
}


/** DragList Custom 버전 - 드래그 효과 없음, Popup상태에서 UI 틀어짐 없음 */
const DragAndDropList = <T extends unknown>(props:DragAndDropListProps<T>) => {
    const [useItems, setUseItems] = useState<ItemType<T>[]>([]);
    const [dragItem, setDragItem] = useState<DragSelectItemType|null>(null);
    const [dragOverItem, setDragOverItem] = useState<DragSelectItemType|null>(null);
    // const [mouseItem, setMouseItem] = useState<React.MouseEvent|null>(null);


    useEffect(()=>{ 
        const items = props.options.map((v, index)=>({id:index, item:v, checked:useItems[index]?.checked || false}));  //기존 데이터의 선택정보 유지
        setUseItems(items);
    // eslint-disable-next-line
    },[props.options]);

    // a little function to help us with reordering the result
    const handleSort = () => {
        const src:number = dragItem?.index || 0;
        const target:number = dragOverItem?.index || 0;
        const start:number = src > target ? target+1 : target;  //underline에 따라 무조건 아래 추가되도록 함
        const [removed] = useItems.splice(dragItem?.index || 0, 1);
        useItems.splice(start || 0, 0, removed);

        setUseItems([...useItems]);
        props.onChange && props.onChange(useItems.map((v)=>v.item));
        setDragItem(null);
        setDragOverItem(null);
    };

    const onDragStart = (e: React.DragEvent<HTMLDivElement>, index:number)=>{
        setDragItem({index, current:e.currentTarget});
    }

    const onDragEnter = (e: React.DragEvent<HTMLDivElement>, index:number)=>{
        setDragOverItem({index, current:e.currentTarget});
        // const height = e.currentTarget.offsetHeight;
        // e.currentTarget.style.transform = index!==dragItem.current ? `translate(0px, ${index > (dragItem.current || 0) ? `-${height}px` : `${height}px`})` : '';
    }

    const onMouseMove = (e: React.MouseEvent<HTMLDivElement>, index:number)=>{
        // setMouseItem(e);
        // setDragOverItem({index, current:e.currentTarget});
        // const height = e.currentTarget.offsetHeight;
        // e.currentTarget.style.transform = index!==dragItem.current ? `translate(0px, ${index > (dragItem.current || 0) ? `-${height}px` : `${height}px`})` : '';
    }

    const onDragEnd = (e: React.DragEvent<HTMLDivElement>, index:number)=>{
        handleSort();
    }

    return <div className={clsx("list-container", props.className)} style={props.style}>
        {useItems.map((v, index) =>{
            let translateY:number|undefined = undefined;
            // if(dragItem && dragOverItem){
            //     if( index > dragOverItem.index && index < dragItem.index){
            //         translateY = dragItem.current.offsetHeight;
            //     }else if( index < dragOverItem.index && index > dragItem.index){
            //         translateY = dragItem.current.offsetHeight * -1;
            //     }
            // }
            
            return <div key={index} className='list-item'
                draggable
                onDragStart={(e)=>onDragStart(e,index)}
                onDragEnter={(e)=>onDragEnter(e,index)}
                onMouseMove={(e)=>onMouseMove(e,index)}
                onDragEnd={(e)=>onDragEnd(e,index)}
                onDragOver={(e)=>e.preventDefault()}
                style={{transform:`translate(0,${translateY}px)`, ...(index===dragOverItem?.index ? {borderBottom:'1px solid var(--bs-gray-500)'} : {})}}
            >
                {props.formatter(v.item)}
            </div>
        })}
    </div>
};

// Put the thing into the DOM!
export default DragAndDropList;
