import clsx from "clsx";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { OptionType } from "../../../../modules/types/Types";
import { DashBoardStatReportDataType, DashBoardStatTableClientResType, DatshBoardStatTableType } from "../../../common/services/models/DashboardTypes";
import { BookmarkResType } from "../../../common/services/models/admin/BookmarkTypes";
import Badge from "../../../modules/badge/Badge";
import Ellipsis from "../../../modules/layer/Ellipsis";
import CustomBaseCard from "../../modules/card/CustomBaseCard";
import MediaTypeIcon, { getMediaTypeColor } from "../../modules/symbol/MediaTypeIcon";
import CircleChart, { CircleChartItemType } from "./CircleChart";
// import DeviceTypeButton from "./DeviceTypeButton";
import DeviceTypeButton from '../../components/buttons/DeviceTypeButton';
import MediaTypeButton from "./MediaTypeButton";


export enum SelectTypeKey{
    none = 'none',
    media = 'media',
    client='client'
}

interface ReportCardProps{
    info: BookmarkResType;
    value?:DatshBoardStatTableType;
    loading?:boolean;
    bookmarkRemove?:(id:number)=>void;
}

/** 불필요한 연산을 줄이기 위해 빈값을 지정 */
const defaultTableData = ():DashBoardStatReportDataType=>({
    key: '',
    impressionCount: 0,
    clickCount: 0,
    cost: 0,
    purchaseCount: 0,
    purchaseValue: 0,
});

/** 디바이스 선택에 따라 값을 SUM하여 반환합니다.  */
const getStatData = (stats?:DashBoardStatReportDataType[], deviceTypes?:string[]):DashBoardStatReportDataType=>{
    // 디바이스 선택 P,M --> stat 키타입 변환 PC, MOBILE
    // const keys:string[] = [{value:"P", key:"PC"}, {value:"M", key:"MOBILE"}].filter(v=>!deviceTypes || deviceTypes.includes(v.value)).map(v=>v.key);
    if(!stats){ return defaultTableData(); }

    return stats.filter(v=>!deviceTypes || deviceTypes.length===0 || deviceTypes.includes(v.key))
        .reduce((prev:DashBoardStatReportDataType, v):DashBoardStatReportDataType=>({
            key: prev.key,
            impressionCount: prev.impressionCount + v.impressionCount,
            clickCount: prev.clickCount + v.clickCount,
            cost: prev.cost + v.cost,
            purchaseCount: prev.purchaseCount + v.purchaseCount,
            purchaseValue: prev.purchaseValue + v.purchaseValue,
        }), defaultTableData());
}

/** 선택한 clients의 Sum을 반환합니다. */
const getClientsData = (clients:DashBoardStatTableClientResType[], deviceTypes?:string[]):{week1:DashBoardStatReportDataType, week2:DashBoardStatReportDataType}=>({
    week1 : clients.reduce((prev, v)=>{
        const tmp = getStatData(v.week1, deviceTypes);
        return {
            key: prev.key,
            impressionCount: prev.impressionCount + tmp.impressionCount,
            clickCount: prev.clickCount + tmp.clickCount,
            cost: prev.cost + tmp.cost,
            purchaseCount: prev.purchaseCount + tmp.purchaseCount,
            purchaseValue: prev.purchaseValue + tmp.purchaseValue,
        }
    }, defaultTableData()),
    week2 : clients.reduce((prev, v)=>{
        const tmp = getStatData(v.week2, deviceTypes);
        return {
            key: prev.key,
            impressionCount: prev.impressionCount + tmp.impressionCount,
            clickCount: prev.clickCount + tmp.clickCount,
            cost: prev.cost + tmp.cost,
            purchaseCount: prev.purchaseCount + tmp.purchaseCount,
            purchaseValue: prev.purchaseValue + tmp.purchaseValue,
        }
    }, defaultTableData()),
});


const ReportCard:React.FC<ReportCardProps> = (props)=>{
    const [useData, setUseData] = useState<DatshBoardStatTableType|undefined>();
    const [deviceTypes, setDeviceTypes] = useState<string[]>([]);
    const [selectValue, setSelectValue] = useState<string|undefined>();
    const [selectMediaType, setSelectMediaType] = useState<string|undefined>();
    const [selectClientId, setSelectClientId] = useState<string|undefined>();
    const [selectType, setSelectType] = useState<string>(SelectTypeKey.none);
    const [chartData, setChartData] = useState<CircleChartItemType[]>([]);
    const [tableData, setTableData] = useState<{week1:DashBoardStatReportDataType, week2:DashBoardStatReportDataType}>({week1:defaultTableData(), week2:defaultTableData()});
    const [selectIndex, setSelectIndex] = useState<number|undefined>();

    useEffect(()=>{
        setUseData(props.value);
        showAllType(props.value);
    // eslint-disable-next-line
    }, [props.value]);

    /** 전체 표시 */
    const showAllType = (data?:DatshBoardStatTableType)=>{
        data = data || useData;
        setChartData(
            ["NAVER", "KAKAO", "GOOGLE"].map((v):CircleChartItemType=>({
                id: v,
                label: v,
                type: SelectTypeKey.media,
                value: data?.clients.filter((v1)=>v1.mediaType===v).reduce((prev,v)=>prev + getStatData(v.week1, deviceTypes).cost, 0) || 0,
                color: getMediaTypeColor(v),
            }))
            .sort((a,b)=>a.value > b.value ? -1 : 1)
            .filter((v)=>(data?.clients.filter((v1)=>v1.mediaType===v.id).length || 0)>0)
        );

        const clientData = getClientsData(data?.clients || [], deviceTypes);
        setTableData(clientData);
    }

    /** 특정 매체 표시 */
    const showMediaType = (media:string)=>{
        //보라님이 요청 매체별 색상표
        // const map = new Map<string, string[]>(Object.entries({
        //     NAVER : ["#f3e9d2", "#c6dabf", "#88d498", "#1a936f", "#114b5f", "#052f5f", "#1b2a41",],
        //     KAKAO : ["#ffc15e", "#f7b05b", "#f7934c", "#cc5803", "#8f250c",],
        //     GOOGLE : ["#cfdee7", "#92b4f4", "#5e7ce2", "#4472ca", "#0a369d",],
        // }));

        setChartData(
            useData?.clients.filter((v)=>v.mediaType===media)
            .map((v)=>({
                ...v,
                value: getStatData(v.week1, deviceTypes),
            }))
            .sort((a,b)=>a.value.cost > b.value.cost ? -1 : 1)
            .map((v, index):CircleChartItemType=>{
                const colors:string[] = ["#FFBE0B","#FB5607","#FF006E","#8338EC","#3A86FF","#4CB050","#9E9E9E"];
                return {
                    id : v.clientId.toString(),
                    label: v.clientName,
                    type: SelectTypeKey.client,
                    value: v.value.cost,
                    color: colors?.[index] || "" ,
                }
            }) || []
        );
        
        const clientData = getClientsData(useData?.clients.filter((v)=>v.mediaType===media) || [], deviceTypes);
        setTableData(clientData);
    }

    /** 특정 자산 표시 */
    const showClientId = (id:string, media?:string)=>{
        const client:DashBoardStatTableClientResType|undefined = useData?.clients.find((v)=>v.mediaType===media && v.clientId.toString()===id);
        const week1:DashBoardStatReportDataType = getStatData(client?.week1, deviceTypes);
        const week2:DashBoardStatReportDataType = getStatData(client?.week2, deviceTypes);
        setTableData({week1, week2});
    }

    const handleDeviceTypeChange = (value:string, item?:OptionType) => {
        setDeviceTypes(value.split(','));    //필터 변경시 자동 재검색
    }

    const MediaTypeChange = (value:string, type?:string)=>{
        // 자산을 선택한 경우, 상위 매체 선택처리 필요
        if(type===SelectTypeKey.client){
            const client = useData?.clients.find((v)=>v.clientId.toString()===value);
            client && legendClick(client.mediaType, SelectTypeKey.media);
        }
        legendClick(value, type || SelectTypeKey.none);
    }

    const legendClick = (id:string, type?:string)=>{
        setSelectValue(id);
        /** 매체선택 */
        switch(type){
            case SelectTypeKey.media : {
                setSelectType(SelectTypeKey.media);
                setSelectMediaType(id);
                setSelectClientId(undefined);
                showMediaType(id);
                break;
            }
            
            /** 자산선턱 */
            case SelectTypeKey.client : {
                setSelectType(SelectTypeKey.client);
                setSelectClientId(id);
                showClientId(id, selectMediaType);
                break;
            }
            
            /** 모두표시 */
            default :
                setSelectType(SelectTypeKey.none);
                setSelectMediaType(undefined);
                setSelectClientId(undefined);
                showAllType();
        }
    }

    /** Device 옵션 선택에 따라 새로고침 처리 */
    useEffect(()=>{
        if(useData){
            legendClick((selectType===SelectTypeKey.media ? selectMediaType : selectClientId) || '', selectType);
        }
    // eslint-disable-next-line
    },[deviceTypes]);
    const loading:boolean =  props.loading ? true : false; //props.loading && !props.value ? true : false;

    return <CustomBaseCard className="mb-5">
        <div className="row mb-3 align-items-center">
            <div className="fw-bolder col-8">
                <i className="bi bi-grip-vertical fs-4 me-2" />
                <i className="bi bi-star-fill text-warning fs-4 cursor-pointer me-2" onClick={()=>{props.bookmarkRemove && props.bookmarkRemove(props.info.magId)}} />
                {props.info.magName}
                <div className='d-inline ms-10'>
                    {props.info.magUseYn==='Y' ? <span className='text-success'>● 사용중</span> : <span className='text-danger'>● 사용 안 함</span>}
                </div>
            </div>
            <div className="col-4 justify-content-end d-flex">
                <MediaTypeButton className="me-1" onChange={MediaTypeChange} data={useData} value={selectValue || ""} />
                <DeviceTypeButton onChange={handleDeviceTypeChange} />
            </div>
        </div>
        {loading && <div className="text-center h-200px pt-20"><Spinner className='mt-6' animation="border" variant={"secondary"} role='status' /></div>}
        {!loading && <div className='row'>
            <div className="col-xxl-4 d-flex align-items-center">
                <div className="w-250px">
                    <CircleChart data={chartData} title='총 비용'
                        selection={(v:number)=>{setSelectIndex(v); legendClick(chartData[v].id.toString(), chartData[v].type)}}
                        selectionIndex={selectIndex}
                    /> 
                </div>
                <div>
                    {selectMediaType && <div
                        className="fw-bolder p-2 ps-1 fs-5 me-2 cursor-pointer bg-hover-light rounded w-200px" 
                        onClick={()=>{legendClick('')}}
                        style={{color:getMediaTypeColor(selectMediaType)}}
                    >
                        <MediaTypeIcon code={selectMediaType} label={selectMediaType} viewLabel={true}/>
                        <i className="bi bi-arrow-left fs-3" style={{float:'right', lineHeight:'140%'}} />
                    </div>}
                    <div className="align-items-center scroll p-0" style={{maxHeight:'170px'}}>
                        {chartData.map((v,index)=><MediaLegendItem
                            key={v.id}
                            type={v.label}
                            color={v.color}
                            value={(v.value/(chartData.reduce((prev,v2)=>prev+(v2.value || 0),0) || 1)*100)}
                            onClick={()=>{setSelectIndex(index); legendClick(v.id, v.type);}}
                            checked={v.id===selectClientId}
                        />)}
                    </div>
                </div>
            </div>
            <div className="col-xxl-8 row m-0">
                <CellCountView title="노출수" target={tableData.week1.impressionCount} base={tableData.week2?.impressionCount} />
                <CellCountView title="클릭수" target={tableData.week1.clickCount} base={tableData.week2.clickCount} />
                <CellCountView title="클릭률" target={tableData.week1.clickCount/tableData.week1.impressionCount} base={tableData.week2.clickCount/tableData.week2.impressionCount} round={2} showPercent={true}/>
                <CellCountView title="비용" target={tableData.week1.cost} base={tableData.week2.cost} />
                <CellCountView title="구매전환수" target={tableData.week1.purchaseCount} base={tableData.week2.purchaseCount} />
                <CellCountView title="전환율" target={tableData.week1.purchaseCount/tableData.week1.clickCount} base={tableData.week2.purchaseCount/tableData.week2.clickCount} round={2} showPercent={true}/>
                <CellCountView title="구매전환매출" target={tableData.week1.purchaseValue} base={tableData.week2.purchaseValue} />
                <CellCountView title="ROAS" target={tableData.week1.purchaseValue/tableData.week1.cost} base={tableData.week2.purchaseValue/tableData.week2.cost} showPercent={true} />
            </div>
        </div>}
    </CustomBaseCard>
}
export default ReportCard;

/** 변동율을 배찌로 표시합니다. */
const BadgeDiffPer:React.FC<{base:number, target:number}> = (props)=>{
    const term:number = props.target - props.base;
    const arrow:string = term === 0 ? '' : term < 0 ? '↓' :'↑';
    const color:string = term === 0 ? 'light' : term < 0 ? 'light-danger' :'light-success';

    return <Badge size="xs" className="fs-8 ps-1 pe-1" bg={color} displayInline={false} opacity="0.2" style={{borderRadius:'1em', minWidth:'60px', width:'inherit'}}>
        {arrow} {(Math.abs(term)/(props.base || 1)*100).toFixed(1)+'%'}
    </Badge>
}

/** 변동값 표시합니다. */
const ValueDiff:React.FC<{value:number, unit?:string}> = (props)=>{
    const arrow:string = props.value > 0 ? '+' : '';
    const color:string = props.value === 0 ? 'text-dark' : props.value < 0 ? 'text-danger' :'text-success';
    return <div className={color}>({arrow}{props.value.addComma()}{props.unit === '%' ? '%p' : props.unit})</div>
}

const CellCountView:React.FC<{title?:React.ReactNode|string, base?:number, target?:number, showPercent?:boolean, round?:number}> = (props)=>{
    const unit:string|undefined = props.showPercent===true ? "%" : undefined;
    const time:number = props.showPercent ? 100 : 1;
    const target:number = props.target || 0;
    const base:number = props.base || 0;

    return <div className="col-lg-6 col-xl-4 col-xxl-3 m-0 p-0">
        <div className="border border-1 border-gray-300 rounded p-5 m-0 ms-3 mb-3">
            <div className="mb-5">
                {props.title}
                <div style={{float:'right'}}>
                    <BadgeDiffPer target={target} base={base} />
                </div>
            </div>
            <div className="d-flex text-right align-items-baseline">
                <div className="fs-3 fw-bolder me-3">{(target*time).round(props.round || 0).addComma()}{unit}</div>
                <ValueDiff value={((target - base)*time).round(props.round || 0)} unit={unit} />
            </div>
            <div className="text-gray-400">비교기간 {(base*time).round(props.round || 0).addComma()}{unit}</div>
        </div>
    </div>
};


const MediaLegendItem:React.FC<{value?:number, title?:string, type?:string, color?:string, checked?:boolean, onClick?:()=>void}> = (props)=>{
    return <>
        <div className={clsx("d-flex w-200px fw-bolder bg-hover-light rounded p-2 ps-1", props.checked ? 'bg-gray-300' :'')} onClick={props.onClick}>
            <div className="d-flex col-8">
                <div className={clsx('mt-1 me-2')}
                    style={{
                        width:'14px', height:'14px', minWidth:'14px', minHeight:'14px',
                        borderRadius:'4px', 
                        backgroundColor:props.color || getMediaTypeColor(props.type?.toLocaleUpperCase()),
                    }}></div>
                <Ellipsis className={clsx(props.checked ? "text-gray-900" : "text-gray-600")} style={{width:'100px'}}>{props.title || props.type}</Ellipsis>
            </div>
            <div className="col-4 text-right">{props.value?.round(2).addComma()}%</div>
        </div>
    </>
}


