import { NaverServer } from '../../axios';
import Utils from '../../utils/Utils';
import { AdPostCopyType } from '../models/naver/AdTypes';
import NaverHelperComponent from './NaverHelperComponent';

/** 네이버 소재 Helper */
export default class AdHelper extends NaverHelperComponent{
    static path="/media/ads"; //기본 Path
    static stat='/performance/ads';   //실적데이터
    static performances='/performances/ads';   //실적데이터
    static downloadPath='/api/naversa/ad';

    public static copy(body:AdPostCopyType){
        const valid = Utils.apiRequestValidation({}); if(valid){ return valid; }
        return NaverServer.post<AdPostCopyType>(`/media/copy-ads`, body, {headers:{useAccount:true}});
    }
}
