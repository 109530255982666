import React, { useEffect, useState } from 'react';
import { Container, Pagination } from "react-bootstrap";
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import clsx from 'clsx';
import { OptionType } from '../../../../modules/types/Types';
import AdPloSelect from '../../components/select/AdPloSelect';
// import "./pagination/_pagination.scss";

export interface PagePropsType {
    page: number;
    total: number;
    pageSize: number;
    pageSizeOptions?: OptionType[];
    onChange?: (page:number, size:number) => void;
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
    hidePageOption?:boolean;
}


const pageSizeOption:OptionType[] = [
    {label: '30', value: "30"},
    {label: '50', value: "50"},
    {label: '70', value: "70"},
    {label: '100', value: "100"},
    // {label: '200', value: 200},
    // {label: '300', value: 300},
    // {label: '500', value: 500},
    // {label: '700', value: 700},
    // {label: '1000', value: 1000},
];

export const pageSizeOptionLarge:OptionType[] = [
    ...pageSizeOption,
    {label: '200', value: "200"},
    {label: '300', value: "300"},
    {label: '500', value: "500"},
    {label: '700', value: "700"},
    {label: '1000', value: "1000"},
];

/**
 * 
 * @param props
 *  page: number;
 *  total: number;
 *  pageSize: number;
 *  onChangePage?: (page: number) => void;
 *  onChangeSize?: (size: number) => void;
 * 
 * @returns React.FC<PagePropsType>
 */
const PaginationBootstrap: React.FC<PagePropsType> = (props:PagePropsType) => {
    const [usePage, setUsePage] = useState<number>(1);
    const [usePageSize, setUsePageSize] = useState<number>(30);
    const totalPages = Math.ceil(props.total / usePageSize);
    const handlChangePage = (page:number) => {
        if(page < 1 || page > totalPages) return false;
        props.onChange && props.onChange(page, usePageSize);
        setUsePage(page);
    }

    const handlChangePageSize = (value:string) => {
        const pageSize = Number(value);
        props.onChange && props.onChange(1,pageSize);
        setUsePageSize(pageSize);
    }

    useEffect(()=>{setUsePage(props.page);},[props.page]);
    useEffect(()=>{setUsePageSize(props.pageSize)},[props.pageSize]);

    // page 시작과 끝 계산
    const pageBtnLength = 10;
    const pMin = (Math.floor((usePage-1)/pageBtnLength)*10)+1;
    const pMax = Math.min(pMin+pageBtnLength, totalPages);
    const pLength = Math.min(pageBtnLength, pMax-pMin+1);

    // data 구간
    const data_start = (usePage-1) * usePageSize;
    const data_end = Math.min(props.total, usePage * usePageSize);
    const data_range = data_end===0 ? 0 : `${data_start+1}~${data_end}`;

    const minWidth = ((pMin+pLength).toString().length * 0.65 + 2.5) * pLength + 4 * 3;   //자릿수에 따라 동적으로 결정

    return (
        <Container className={clsx("d-flex m-0 p-0", props.className)} style={{maxWidth:"inherit"}}>
            <Container className='d-flex justify-content-start align-items-center m-0 p-0' style={{maxWidth:"inherit", minWidth:'20em'}}>
                {props.hidePageOption!==true && <AdPloSelect
                    onChange={handlChangePageSize}
                    style={{minWidth: '4em', maxWidth: '7em',  verticalAlign: 'top'}}
                    value={usePageSize}
                    options={props.pageSizeOptions || pageSizeOption}
                />}
                <span className='ps-3 pt-1'>총 {props.total}개 중 {data_range}번째</span>
            </Container>
            {totalPages>0 && (
                <Pagination className="pagination justify-content-end text-right" style={{minWidth:`${minWidth}rem`}}>
                    <Pagination.First disabled={pMin <= 1} onClick={() => handlChangePage(1)} />
                    <Pagination.Prev disabled={pMin <= 1} onClick={() => handlChangePage(pMin - 1)} />
                    
                    {Array.from({length: pLength},(v,i)=>pMin+i).map((v,index)=>{
                        return (<Pagination.Item key={index} active={usePage === v} onClick={() => handlChangePage(v)} style={{zIndex:0}}>{v}</Pagination.Item>);
                    })}

                    <Pagination.Next disabled={totalPages < (pMin+pageBtnLength)} onClick={() => handlChangePage(pMin + 10)} />
                    <Pagination.Last disabled={totalPages < (pMin+pageBtnLength)} onClick={() => handlChangePage(totalPages)} />
                </Pagination>
            )}
        </Container>
    );
};
export default PaginationBootstrap;
