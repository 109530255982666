import md5 from 'md5';
import { OptionType } from '../../../../modules/types/Types';
import { RequestFilterType, RequestOrderType } from '../../../common/services/models/RequestTypes';
import NaverCode from '../../../modules/code/NaverCode';
import { CompareSelectOption, EqInExSelectOptions, MatchBitOptions } from '../../../modules/code/Options';
import { CheckedListType, FilterType, FilterViewType, HeaderColumn } from './TableType';

export default class Utils{
    public static getId(row:any, key?:string):string{
        const dataMap = new Map(Object.entries(row));
        return key ? dataMap.get(key) as string : "";
    }

    public static checklist = class{

        /**
         * 선택된 데이터를 생성합니다.
         * @param data : 데이터
         * @param checkIds : 선택된 목록
         * @param checkedId : ID 키
         * @returns 
         */
        public static filter = <T extends unknown>(data:T[], checkIds:CheckedListType<T>[], checkedId:string, searchId?:string, checked?:boolean):CheckedListType<T>[] =>{
            return data?.filter((v)=>{
                    const id = Utils.getId(v, checkedId);
                    return ( 
                        (checked && searchId===id)  //선택한 경우
                        || (
                            (checked===false && searchId!==id)  //선택해제의 경우, 제외
                            && checkIds?.find((v2)=>v2.id===id) ? true : false      //기존 선택값
                        )
                    );
                })
                .map((v:T):CheckedListType<T>=>{
                    const id = Utils.getId(v, checkedId);
                    return {id:id, data:v,}
                }) || [];
        }
        /**
         * 체크목록에서 반환형 데이터 배열로 반환합니다.
         * @param data 체크목록
         * @returns 
         */
        public static getRows = <T extends unknown>(data:CheckedListType<T>[]) =>{
            return data.map((v)=>v.data);
        }
    }


    public static column = class{ 
        // header 기본설정(숨김) 초기정보 세팅
        public static getDetault(columns:HeaderColumn[]) {
            return columns.reduce((prev, item) => {
                return {...prev, [item.key || item.accessor]: (item.isHide !== undefined && item.isHide) ? false : true};
            },[]);
        }

        public static getLength(columns:HeaderColumn[]):number{
            const datas = columns.filter((item) => item.colGroup === undefined);
            return datas.length;
        }

        /**
         * 선택한 컬럼만 반환합니다. 그룹핑 span값도 계산합니다.
         * @param columns : 전체 컬럼
         * @param checkedColumns : 선택한 컬럼 목록
         * @returns 
         */
        public static filter(columns:HeaderColumn[], checkedColumns:any ){
            //컬럼 표시여부 확인
            const columnMap = new Map(Object.entries(checkedColumns));
            const isView = (item:HeaderColumn):boolean=>{
                const isSet = columnMap.size > 0;
                const columnConfig = columnMap.get(item.key || item.accessor);                  //컬럼 설정 정보 검색
                return  item.isFixed || (isSet ? columnConfig : !item.isHide) ? true : false;

            }

            //비활성화된 컬럼 정리 및 colspan정리
            return columns
                .filter((v) => isView(v))   //표시될
                .map((v,index,data)=>{
                if(v.group){ v.group.colspan = data.filter((v2)=>v2.group?.key===v.group?.key).length; }   //colspan 초기화 및 해당 키를 같는 컬럼수 계산
                return v;
            });
        }

    }

    /** 필터 - 표시용 데이터 구성 */
    public static filterViewData(filter?:RequestFilterType[], columns?:HeaderColumn[]):FilterViewType[]|undefined{
        return filter?.map((v):FilterViewType=>{
            const column = columns?.find((v2)=>(v2.key || v2.accessor)===v.key);
            const values = v.value ? v.value.split("\n") : v.values;
            const options:OptionType[] = [...(column?.filter?.options || []), ...NaverCode.getOptions(NaverCode.data.type.campaign)].filter((v2)=>values?.includes(v2.value));
            const value:string[] = options.map((v2)=>v2.label).unique();
            return {
                key: column?.header,
                operation: [...EqInExSelectOptions, ...CompareSelectOption].find((v2)=>v2.value === v.operation)?.label,
                value: value.length > 0 ? value : values,
            }
        });
    }
    

    public static getTotalPage(total:number, pageSize:number) {
        let totalPage: number = 0;
        if(total === 0) {
            return 0;
        } else {
            totalPage = Math.floor(total / pageSize);
            if((total / pageSize) > totalPage) {
                totalPage++;
            }
        }
        return totalPage;
    }

    public static storage = class{
        public static get(columns:HeaderColumn[]){
            const key = md5(columns.map((v)=>v.accessor).join("|"));
            const history = localStorage.getItem(`columns-${key}`);
            return history!==null ? JSON.parse(history) : Utils.column.getDetault(columns);
        }
        
         public static set(columns:HeaderColumn[], data:HeaderColumn[]){
            const key = md5(columns.map((v)=>v.accessor).join("|"));
            localStorage.setItem(`columns-${key}`, JSON.stringify(data));
        }
    }

    /**
     * Filter 구조체 배열로 변경합니다.
     * @param key filter 키
     * @param operation 
     * @param value, undefined 원소는 자동 배제됩니다.
     * @returns 
     */
    public static makeToFilter(key:string, operation:string, value:string|(string|undefined)[]):RequestFilterType[] {
        return [{key:key, operation:operation, value:(Array.isArray(value) ? value : [value]).filter((v)=>v!==undefined).join("\n")}];  //undefined 제거하고 개행으로 묶음
    }

    /**
     * 필터와 선택된 ID를 받아 최종 사용될 필터로 반환합니다. 선택된 ID가 우선하며 선택 목록이 없는 경우, 필터를 반환합니다.
     * IAM에서는 수정, 삭제 등에서 조회조건을 사용하지 않습니다. 
     * @param checkIds : checked id
     * @param key : key
     * @param filters : filter list
     * @returns : filter list
     */
    public static finalFilters(filters:RequestFilterType[], checkIds:string[], key:string):RequestFilterType[]{
        return checkIds.length > 0
            ? this.makeToFilter(key, 'In', checkIds)
            : filters;
    }

    /**
     * 데이터 정렬 처리용 함수
     * @param a row A
     * @param b row B
     * @param order 정렬 정보
     * @param columns 테이블 컬럼정보
     * @returns 
     */
    public static sortCompare<T=any>(a:T, b:T, orders?:RequestOrderType[], columns?:HeaderColumn[]):number{
        let result:number = 0;
        orders?.forEach((v)=>{
            if(result!==0){ return; }   // 0 아니면 이후 비교 안함 
            if(!v.direction){ return ;} // 정렬기준값 없음

            const [mapA, mapB] = [new Map(Object.entries(a || {})), new Map(Object.entries(b || {}))];
            let [valueA, valueB]:[valueA:any, valueB:any] = [mapA.get(v.key), mapB.get(v.key)];
            const column:HeaderColumn|undefined = columns?.find(v2=>(v2.key || v2.accessor)===v.key);    //리포트 컬럼의 경우, 결과값 산출하기 위함

            // 0.정렬 비교 직접 설정
            if(column?.sort?.compare){
                result = column.sort.compare(valueA, valueB, v);
                return;
            }
            
            // 1. 정렬 전처리 - 코드형의 경우, 화면 표시가 아닌 선택 항목리스트에 맞추어 변환합니다.(복합 컬럼으로 표시 결정하는 경우)
            if(column?.sort?.formatter){
                [valueA, valueB] = [
                    column.sort.formatter(valueA, a, undefined, v),
                    column.sort.formatter(valueB, b, undefined, v),
                ];
            }
            else if(column?.filter?.formatter){  //정렬용 없으면 필터용 사용
                [valueA, valueB] = [
                    column.filter.formatter(valueA, a),
                    column.filter.formatter(valueB, b),
                ];
            }

            // 2. 선택 항목 리스트형의 경우, 옵션 값으로 부터 출력값을 찾습니다.(목록 선택형)
            if(column?.filter?.type === FilterType.mSelect){
                [valueA, valueB] = [
                    column?.filter?.options?.find((v)=>v.value===valueA)?.label || valueA,
                    column?.filter?.options?.find((v)=>v.value===valueB)?.label || valueB,
                ];
            }

            // 3. 숫자형 컬럼의 경우 - 전처리가 없는 경우, 포맷형 사용
            else if(!column?.sort?.formatter && column?.filter?.type === FilterType.number){
                [valueA, valueB] = [
                    valueA?.toString().toNumber() || 0,
                    valueB?.toString().toNumber() || 0,
                ];
            }

            // null 빈값처리
            if(
                column?.filter?.type === FilterType.text ||
                column?.filter?.type === FilterType.date ||
                column?.filter?.type === FilterType.list ||
                column?.filter?.type === FilterType.select ||
                column?.filter?.type === FilterType.mSelect
            ){
                [valueA, valueB] = [
                    valueA!==null ? valueA : '',
                    valueB!==null ? valueB : '',
                ];
            }
            // null 빈값처리
            else if(
                column?.filter?.type === FilterType.number 
            ){
                [valueA, valueB] = [
                    valueA!==null ? valueA : 0,
                    valueB!==null ? valueB : 0,
                ];
            }
            
            result = valueA===valueB ? 0 : ((valueA < valueB ? -1 : 1) * (v.direction==='ASC' ? 1 : -1));
        });
        return result;
    }

   /**
     * 데이터 필터 처리용 함수
     * @param row 데이터
     * @param filter 필터 정보
     * @param columns 테이블 컬럼정보
     * @returns 
     */
   public static filterCompare<T=any>(row:T, filter?:RequestFilterType, columns?:HeaderColumn[]):boolean {
    if(!filter || !filter.key || !(filter.value || filter.values)){ return true; }
    const column:HeaderColumn|undefined = columns?.find(v=>(v.key || v.accessor)===filter.key);    //리포트 컬럼의 경우, 결과값 산출하기 위함
    if(!column){ return true; }
    if((filter?.value?.length || 0)===0 && (filter.values?.length || 0)===0){ return true; }        //설정값이 없는 경우, 제외
    
    const map = new Map(Object.entries(row || {}));
    let value:any = map.get(column?.accessor);

    switch(column.filter?.type){
        // 선택형
        case FilterType.mSelect :
        case FilterType.list :
        case FilterType.text :
        case FilterType.select :
        case FilterType.arraySelect :
            return MatchBitOptions.find((v)=>v.value===filter.operation)
                ? FilterCompare.bit(value, row, 0, filter, column)          // bit형
                : FilterCompare.string(value, row, 0, filter, column);      // string형

        // 날짜형
        case FilterType.date :
            return FilterCompare.date(value, row, 0, filter, column);

        // 숫자형
        case FilterType.number :
            return FilterCompare.number(value, row, 0, filter, column);
    }
    return true;
}
}

const toLocaleUpperCase = (value:any):any =>{
    return typeof(value)==='string' ? value.toLocaleUpperCase() : value;
}



/** 필터용 비교 처리자 */
class FilterCompare{
    /**
     *  기본 포맷 처리
     * @param value
     * @param row 
     * @param index 
     * @param filter : 컬럼 필터 조건
     * @param column : 해당 컬럼 정보
     * @returns 
     */
    private static formatter<T=any>(value:any, row?:T, index?:number, column?:HeaderColumn):any{
        if(!column || !column?.filter){ return value; }    //필터대상 아님

        //필터 포맷 지정 됨
        if(column?.filter?.formatter){ return column.filter.formatter(value, row, index); };

        //목록 선택형 - 코드값 사용하기에 그대로 반환
        if([FilterType.mSelect, FilterType.arraySelect, FilterType.select].includes(column.filter.type)){
            //Boolean, Number 무조건 string 처리
            if(['boolean', 'number'].includes(typeof(value))){ return value.toString(); }   //우선순위 높음
            return value;
         }

        //그 외 기본 포맷 적용
        if(column.formatter){ return column.formatter(value, row, index); }

        return value;
    }

    /**
     * 텍스트형
     * @param value 컬럼값
     * @param row 데이터
     * @param index 데이터 index
     * @param filter 필터 정보
     * @param column 테이블 컬럼정보
     * @returns 
     */
    public static string<T=any>(value:any, row:T, index:number, filter:RequestFilterType, column:HeaderColumn):boolean {
        value = this.formatter<T>(value, row, index, column);
        const values:string[] = filter.value?.trim().split("\n").map(v=>v.toLocaleUpperCase()) || [];   //대소문자 구분하지 않도록 처리 - 목록형처리
        // console.log({filter, value, values, result: values.includes(toLocaleUpperCase(value))});
        
        const str:string = value?.toString().toLocaleUpperCase() || '';
        switch(filter?.operation){
            case "In" : return values.includes(toLocaleUpperCase(value));        // 일치
            case "Like" :
            case "LikeIn" : return values.find((v)=>str.indexOf(v.toLocaleUpperCase())!==-1) ? true : false;    // 포함
            case "NotIn" : return !values.includes(toLocaleUpperCase(value));        // 제외

            // [] : [] 비교
            case 'INTERSECT' : {
                 const tmp:string[] = (value as string[]).map((v)=>v.toLocaleUpperCase());
                return values.find((v)=>tmp.includes(v)) ? true : false;
            }  // 배열포함

            // [] : [] 비교 - 동일원소
            case 'ArrayEq' : {
                const tmp:string[] = (value as string[]).map((v)=>v.toLocaleUpperCase());
                return values.equal(tmp) ? true : false;
            }  // 배열포함

            // [] : [][] - 선택항목에 값 배열이 포함되는지 판단
            case 'Subset' : {
                const valuesMat:string[][] = values.map((v)=>v.split(","));     //','로 구분된 키 묶음 사용하는 경우 PC,MOBILE | PC | MOBILE
                const tmp:string[] = (value as string[]).map((v)=>v.toLocaleUpperCase());
                return valuesMat.find((v)=>tmp.equal(v)) ? true : false;
            }  // 배열포함
        }

        return true;
    }

    /**
     * 날짜형
     * @param value 컬럼값
     * @param row 데이터
     * @param index 데이터 index
     * @param filter 필터 정보
     * @param column 테이블 컬럼정보
     * @returns 
     */
    public static date<T=any>(value:any, row:T, index:number, filter:RequestFilterType, column:HeaderColumn):boolean {
        value = this.formatter<T>(value, row, index, column);
        const date1:string = new Date(value).format('yyyy-MM-dd');
        const date2:string = new Date(filter.value || '').format('yyyy-MM-dd');
        return date1===date2;
    }
    
    /**
     * 숫자형
     * @param value 컬럼값
     * @param row 데이터
     * @param index 데이터 index
     * @param filter 필터 정보
     * @param column 테이블 컬럼정보
     * @returns 
     */
    public static number<T=any>(value:any, row:T, index:number, filter:RequestFilterType, column:HeaderColumn):boolean {
        value = this.formatter<T>(value, row, index, column);
        const num1:number = value?.toString().toNumber() || 0;
        const num2:number = filter.value?.toNumber() || 0;
        // console.log({filter, num1, num2});
        switch(filter?.operation){
            case 'GT' : return num1 > num2;         // '>(보다 큼)'
            case 'LT' : return num1 < num2;         // '<(보다 작음)'
            case 'GTE' : return num1 >= num2;       // '>=(크거나 같음)'
            case 'LTE' : return num1 <= num2;       // '<=(작거나 같음)'
            case 'EQ' : return num1 === num2;       // '=(같음)'
        }
        return true;
    }

    /**
     * Bit형
     * @param value 컬럼값
     * @param row 데이터
     * @param index 데이터 index
     * @param filter 필터 정보
     * @param column 테이블 컬럼정보
     * @returns 
     */
    public static bit<T=any>(value:any, row:T, index:number, filter:RequestFilterType, column:HeaderColumn):boolean {
        value = this.formatter<T>(value, row, index, column);
        const num:number = value?.toString().toNumber() || 0;
        
        let bit:number = 0;
        if([...MatchBitOptions, ].some((v1)=>v1.value===filter?.operation)){   // 비트연산 처리
            filter.values = filter?.value?.trim().split("\n") || [];
            bit = filter.values.reduce((pre:number, v:string)=>pre+Math.pow(2,v.toNumber()),0);
        }

        switch(filter?.operation){
            // [] : [] Bit 연산자
            case 'MatchAnyBit' : return (num & bit) > 0;        // 포함 - 선택자가 하나라도 포함된 경우
            case 'MatchAllBits' : return (num & bit) === bit;   // 일치 - 선택자 모두 포함한 경우
            case 'NotMatchAnyBit' : return (num & bit) === 0;   // 제외
        }

        return true;
    }
}

