import React, {FC} from 'react'
import { ThemeControll } from '../../../../app/common/conf/ThemeConfig';

const ExplorerToggleDrawer: FC = () =>{
  const isShow = ThemeControll.isStatShow();
  return <>{isShow &&
    <button
      id='kt_engage_explorer_toggle'
      className='engage-explorer-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0'
      title={`Check out ${process.env.REACT_APP_THEME_NAME} more explorer`}
      data-bs-toggle='tooltip'
      data-bs-placement='left'
      data-bs-dismiss='click'
      data-bs-trigger='hover'
    >
      <span id='kt_engage_explorer_label'>Explorer</span>
    </button>
  }</>
}

export {ExplorerToggleDrawer}
