import clsx from "clsx";
import { useEffect, useState } from "react";
import { AdminService } from "../../../common/services";
import { BizMoneyResType } from "../../../common/services/models/BizMoneyTypes";
import { EstimateAccountGroupType, EstimateUserResType } from "../../../common/services/models/admin/EstimateTypes";
import { useUserContext } from "../../../contexts/UserContext";
import AdploButton from "../buttons/AdploButton";
import { bizMoneyDataMerge } from "./BizMoneyData";
import { bizMoneyDataSync } from "./BizMoneyDataSync";
import { BizMoneyAccountGroupType } from "./BizMoneyLibs";


interface BizMoneyRefreshButonProps{
    onClick?:()=>void,
    onLoaded:(data:BizMoneyResType[])=>void,
    onFilnaly?:()=>void, 
    data:EstimateAccountGroupType[],
    loading:boolean,
}


interface BizMoneyControlLayerProps{
    /** 데이터 */
    state: [BizMoneyAccountGroupType[], React.Dispatch<React.SetStateAction<BizMoneyAccountGroupType[]>>];

    /** 기본 로딩중 상태 관리 */
    loadingStatus: [boolean, React.Dispatch<React.SetStateAction<boolean>>];

    /** Polling 상태 관리 */
    syncStatus: [boolean, React.Dispatch<React.SetStateAction<boolean>>];

    /** 동기화 버튼 클릭시 */
    onClick?: ()=>void;

    /** 사용자 목록 사용여부 */
    useUserList?:boolean;
}
export const BizMoneyControlLayer:React.FC<BizMoneyControlLayerProps> = (props)=>{
    const userContext = useUserContext();
    const [, setUseData] = props.state;
    const [useResult, setUseResult] = useState<BizMoneyResType[]>([]);
    const [magData, setMagData] = useState<EstimateAccountGroupType[]>([]);
    const [userData, setUserData] = useState<EstimateUserResType[]>([]);
    const [loading, setLoading] = props.loadingStatus;  //두개(Data, User)의 상태를 별도로 관리하여 반영한다. 간섭방지
    const [sync, setSync] = props.syncStatus;
    const [nowTime, setNowTime] = useState<number>(0);

    // 광고주 및 자산 정보
    useEffect(()=>{
        setMagData(userContext.userSelect.accountState?.list || []);
    },[userContext.userSelect.accountState?.list]);

    // 광고주-사용자 정보 로딩
    useEffect(()=>{
        if(props.useUserList){
            setLoading(true);
            AdminService.getAccountUsers()
            .then((res)=>{ setUserData(res || []); })
            .catch((e)=>{ 
                userContext.alertMessage.add({variant:"danger", title:"광고주 사용자 권한 데이터 조회 오류", body:<>광고주 사용자 권한 데이터 조회 중 오류가 발생했습니다.<br />{AdminService.ErrorMessage(e)}</>});
                return [] as BizMoneyResType[];
            })
            .finally(()=>{ setLoading(false); });
        }
    // eslint-disable-next-line
    }, []);

    // 비즈머니 데이터 로딩 - 시작시 무조건 동기화 시작하기에 불필요
    // useEffect(()=>{
    //     setStatusData(true);
    //     setLoading(true && statusUser);
    //     bizMoneyDataLoad(magData)
    //         .then((res)=>{ setUseResult(res); })
    //         .catch((e)=>{ 
    //             userContext.alertMessage.add({variant:"danger", title:"비즈머니 현황 데이터 조회 오류", body:<>비즈머니 현황 데이터 조회 중 오류가 발생했습니다.<br />{AdminService.ErrorMessage(e)}</>});
    //             return [] as BizMoneyResType[];
    //         })
    //         .finally(()=>{setStatusData(false); setLoading(false && statusUser); });
    // // eslint-disable-next-line
    // },[magData]);

    // 데이터 취합
    useEffect(()=>{
        const tmp = bizMoneyDataMerge(magData, userData, useResult, loading, sync, nowTime);
        setUseData(tmp);
    // eslint-disable-next-line
    }, [magData, userData, useResult, loading, sync, nowTime]);
    
    return <BizMoneyRefreshButon
        data={magData}
        onLoaded={setUseResult} 
        onClick={()=>{setSync(true); setNowTime(new Date().getTime()); }}
        onFilnaly={()=>{setSync(false)}}
        loading={loading}
    />;
}


/** 비즈머니 갱신 버튼 - 데이터로딩, 새로고침 등 모두 처리 */
const BizMoneyRefreshButon:React.FC<BizMoneyRefreshButonProps> = (props)=>{
    const [sync, setSync] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [naver, setNaver] = useState<BizMoneyResType[]>([]);
    const [kakao, setKakao] = useState<BizMoneyResType[]>([]);

    useEffect(()=>{
        props.onLoaded([...naver, ...kakao]); 
    // eslint-disable-next-line
    },[naver, kakao]);

    //최초 동기화 시도하도록 함
    // eslint-disable-next-line
    useEffect(()=>{
        if(props.data.length > 0){
            onClick();
        }
    // eslint-disable-next-line
    },[props.data]);

    const onClick = async ()=>{
        props.onClick && props.onClick();
        setDisabled(true);
        window.setTimeout(()=>{setDisabled(false)}, 5000);  //5초간 버튼 잠금
        
        setSync(true);
        await bizMoneyDataSync(props.data, setNaver, setKakao);
        setSync(false);
    }
    const status:boolean = props.loading || disabled || sync;
    return <AdploButton data-type="icon" className="ms-1" onClick={onClick} disabled={status}>
        <div style={{
            display: 'inline-block', 
            animation: status ? '1.35s linear infinite spinner-border' : '',
        }}>
            <i className={clsx("bi bi-arrow-clockwise m-0 p-0", {'text-gray-300':status})} />
        </div>
    </AdploButton>
}
