import Config from "./Config";

const AuthConfig =(() => {
    return {
        /**
         * 세션만료 시간(초)
         */
        expireTime: Config.session.expire_time, 
    }
})();

export default AuthConfig;
