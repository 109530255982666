
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { UserContextModel } from "../../contexts/UserContext";
import UserSelect from "../helpers/UserSelect";
import AuthService from "../services/AuthService";
// import { MenuItemType } from "../../../context/menuType";
// import ApiInfo from '../../../components/api/info';
// import Auth from '../../../components/api/auth';
// import { UserContextInfo } from "../../../context/UserContext";

export interface ApiRequestValidationType{
    useAccount?:boolean;     //광고주 선택정보 사용여부
    useAccessToken?:boolean;
    userContext?:UserContextModel;      
}

export default class Utils {


    //API통신에 사용되는 파라미터정보(광고주정보, 필정보, 기타 조회정보) method에 따라 조정
    // public static paramsConvert(config:AxiosRequestConfig){
    //     //선택 광고주정보만 첨부 - partitionadd
    //     if(
    //         (config.params?.addAdvertiser || config.data?.params?.addAdvertiser) 
    //         || (config.params?.addAdvertiserClientId || config.data?.params?.addAdvertiserClientId)
    //     ){
    //         const advertiserInfo = ApiInfo.getSelectAdvertiser();
    //         // console.log("advertiserInfo : " , advertiserInfo);
    //         if(advertiserInfo==null){
    //             alert("광고주 계정을 선택하지 않았습니다!");
    //             throw Object.assign(
    //                 new Error('광고주 계정을 선택하지 않았습니다!'),
    //                 { code: 500 }
    //              );
    //         }
    //         ///////////// Parameter로 고정하여 보내기 //////////////
    //         //자동으로 선택 광고주계정정보 추가 -- 파라미터로 고정
    //         const qry = new URLSearchParams(
    //             (config.params?.addAdvertiser || config.data?.params?.addAdvertiser) 
    //             ?{
    //                 advertiserId : advertiserInfo.id,
    //             }
    //             :{
    //                 advertiserId : advertiserInfo.id,
    //                 // mediaCode : advertiserInfo.mediaCode,
    //                 mediaClientId : advertiserInfo.mediaClientId
    //             }
    //         ).toString();
    //         config.url += `${config.url?.indexOf("?")!==-1 ? "&" : "?"}${qry}`;
    //     }
    //     delete config.params?.addAdvertiser;  //불필요한 값 제거
    //     delete config.data?.params?.addAdvertiser;  //불필요한 값 제거
    //     delete config.params?.addAdvertiserClientId;  //불필요한 값 제거
    //     delete config.data?.params?.addAdvertiserClientId;  //불필요한 값 제거

    //     // console.log(config.params?.addAdvertiserClientId, JSON.stringify( config));

    //     //다양한 필터위치
    //     if(config.params?.filters){ config.params.filters = this.filterValidation(config.params.filters); }
    //     if(config.data?.searchFilter?.filters){ config.data.searchFilter.filters = this.filterValidation(config.data.searchFilter.filters); }
    //     if(config.params?.searchFilter?.filters){ config.params.searchFilter.filters = this.filterValidation(config.params.searchFilter.filters); }

    //     if(config.params?.filters ){
    //         switch(config.method?.toUpperCase()){
    //             case "GET":
    //                 const filters = Utils.filterConvert(config.params.filters);
    //                 const sorts = Utils.sortConvert(config.params?.sorts);
    //                 config.params = {...config.params, ...filters, ...sorts};
    //                 delete config.params.filters;
    //                 delete config.params.sorts;
    //                 break;
                
    //             default : 
    //                 config.params.searchFilter.filters = {...config.params.filters};
    //                 config.params.sorting.sorts = {...config.params.sorts};
    //                 delete config.params.filters;
    //                 delete config.params.sorts;
    //                 config.data = {...config.params};
    //                 delete config.params;
    //         }
    //     }
    //     return config;
    // }





    public static dateFormatChange(value:string, strFormat?:string) {
        const form = strFormat || 'yyyy-MM-dd HH:mm:ss';
        if(!value || value===""){ return ""; }
        const date = new Date(value);
        return format(date, form);
    }


    public static patternReplace(value:string, pattern:RegExp, replaceValue:string) {
        return value.replace(pattern, replaceValue);
    }

    // public static makeCheckIdsToFilters(values:string[], key:string, operation:string) {
    //     let filters:FilterResType[] = [];
    //     filters.push({key:key, operation:operation, value:values.join("\n")});
    //     return filters;
    // }
    
    /**
     * 필터와 선택된 ID를 받아 최종 사용될 필터로 반환합니다. 선택된 ID가 우선하며 선택 목록이 없는 경우, 필터를 반환합니다.
     * @param filters : filter list
     * @param checkIds : checked id
     * @param key : key
     * @returns : filter list
     */
    // public static finalFilters(filters:FilterResType[], checkIds:string[], key:string):FilterResType[]{
    //     return checkIds.length > 0
    //         ? this.makeCheckIdsToFilters(checkIds, key, 'In')
    //         : filters;
    // }


    // Api정보 호출에 필요한 정보 확인용
    public static apiRequestValidation({useAccount=true,useAccessToken=true,userContext}:ApiRequestValidationType):Promise<AxiosResponse<any, any>>|undefined{
        if(useAccessToken===true){
            const info = AuthService.storage.get();

            if(!info?.userInfo.access_token){
                // if(userContext){
                //     userContext.modalMessage.add({
                //         title:"접근오류", 
                //         body:"접속 인증정보가 만료되었거나 없습니다! 'SignIn' 후 재시도하여 주세요!", 
                //         ok:{click:()=>{AuthService.signOut();}}
                //     });
                // }else{
                //     alert("접속 인증정보가 만료되었거나 없습니다! 'SignIn' 후 재시도하여 주세요!");
                // }
                // return new Promise<AxiosResponse<any, any>>(()=>{throw new Error("접속 인증정보가 만료되었거나 없습니다! 'SignIn' 후 재시도하여 주세요!"); });
                return;
            }
        }
        if(useAccount===true){
            const account = UserSelect.account.get();
            if( !account?.accountGroup?.magId || !account.accountClient?.mediaType || !account.accountClient.name ){
                // if(userContext){ userContext.toastMessage.add({bg:"danger", title:"오류", body:"광고주 계정선택이 필요합니다! 광고주 선택 후 재시도하여 주세요!"}); }
                // else{alert("광고주 계정선택이 필요합니다! 광고주 선택 후 재시도하여 주세요!");}
                // return false;
                return new Promise<AxiosResponse<any, any>>(()=>{throw new Error("광고주 계정선택이 필요합니다! 광고주 선택 후 재시도하여 주세요!"); });
            }
        }
        return;    
    }
}