import {KakaoServer} from '../../axios';
import Utils from '../../utils/Utils';
import { DatshBoardStatQryType } from '../models/DashboardTypes';


/** 카카오 비즈머니 Helper */
class CashHelper{
    static path='/cash';         //기본 Path
    
    public static get(clientIds:number[]){
        return Utils.apiRequestValidation({useAccount:false}) || KakaoServer.get(this.path,{params:{clientIds}});
    }
    public static put(clientIds:number[], renewAfter?:number){
        return Utils.apiRequestValidation({useAccount:false}) || KakaoServer.put('/media/sync/cash', clientIds.map((v)=>v.toString()), {params:{renewAfter}});
    }

}


/** 카카오 대시보드용 */
export default class DashboardHelper{
    static path="/dashboard"; //기본 Path
    /** 네이버 대시보드 작업이력 정보 */
    public static getStatus(code:string|string[]){
        return Utils.apiRequestValidation({useAccount:false}) || KakaoServer.get(`${this.path}/status`,{params:{status:code}});
    }

    /** 네이버 대시보드 통계정보 */
    public static getStat(params:DatshBoardStatQryType){
        return Utils.apiRequestValidation({useAccount:false}) || KakaoServer.get(`${this.path}/stat`,{params});
    }
    /** 비즈머니 */
    public static cash = CashHelper;
}

