import { KakaoConfig } from "../../../../common/conf/KakaoConfig";
import { KakaoService } from "../../../../common/services";
import KakaoHelperComponent from "../../../../common/services/kakao/KakaoHelperComponent";
import { StatReportResType, StatReportResultType } from "../../../../common/services/models/kakao/StatReportType";
import { LabelResType } from "../../../../common/services/models/LabelTypes";
import { StatReportResultArrayType } from "../../../../common/services/models/naver/StatReportType";
import { RequestFilterType, RequestSearchType } from "../../../../common/services/models/RequestTypes";
import { MediaResResultType, MediaResponseListDataType } from "../../../../common/services/models/ResponseTypes";
import { StatDataExtends } from "../../modules/KakaoOxfordColumns";

/** 카카오 마스터 데이터를 모두 호출하여 반환합니다. */
export const masterDataLoad = async <T extends any>(path:string, filters?:RequestFilterType[]):Promise<T[]> => {
    const result:T[] = [];
    let total:number = 1000;
    
    for(let i=0; i<100 && result.length<total; i++){
        // setPageNumber(pageNum);
        const requestData:RequestSearchType = {
            filters: filters || [], 
            orders: [],
            page: i,
            pageSize: 10000,
        };
    
        const tmp:MediaResponseListDataType<T>|undefined = await KakaoHelperComponent.getList<MediaResResultType<T>>(requestData, path)
            .then(((res) => {
                if(res.status===200){
                    const data:T[] = res.data.result.items;
                    return {
                        total : res.data.result.totalItems,
                        data : data,
                    };
                }
                return undefined;
            }))
            .catch((e)=>{ throw new Error(e); });
        if(!tmp){ throw new Error("데이터를 로딩하지 못하였습니다."); }
        total = tmp.total;
        result.push(...tmp.data);
    }
    return result;
}

/** 리포트데이터를 로딩합니다. */
export const statDataLoad = async (path:string, ids:string[]):Promise<StatReportResType[]> => {
    const chunk:number = 300;
    const promise:Promise<StatReportResType[]>[] = [];
    for(let i=0; i<ids.length; i+=chunk){
        const id:string[] = ids.slice(i, i+chunk);
        promise.push( KakaoHelperComponent.getStat<StatReportResultType<StatReportResType>>(id, undefined, undefined, path)
            .then(((res) => {
                if(res.status===200){
                    return res.data.result.total || [];
                }
                return [];
            })).catch(()=>[])
        );
    }

    const data:StatReportResType[][] = await Promise.all(promise)
    .catch((e)=>{ throw new Error(e); });

    const result:StatReportResType[]=[];
    for(let i=0; i<data.length; i+=1){
        result.push(...data[i].map(v=>StatDataExtends(v)));
    }
    return result;
}

export interface DataLoadMasterStatType<T=any>{
    master: T[];
    report: StatReportResType[];
    data: T[];
}

/**
 * 
 * @param path 마스터 호출 path
 * @param stat 리포트 호출 path
 * @param getId 마스터 리포트 조인 키
 * @param filters 마스터 검색 필터 조건
 * @returns 
 */
export const dataLoadMasterStat = async <T extends object>(
    path:string,
    stat:string,
    getId:((v:T)=>string),
    filters:RequestFilterType[]
):Promise<DataLoadMasterStatType<T>>=>{
    const master = await masterDataLoad<T>(path, filters).then((data)=>data).catch((e)=>{ throw new Error(e); })
    const report = master.length>0 ? await KakaoHelperComponent.postPerformances<StatReportResultArrayType<StatReportResType>[]>(master.map((v)=>getId(v)), stat)
        .then((res) => res.data.result.map(v=>v.performance) || [])
        .catch((e)=>{ throw new Error(e); }) : [];

    const tmp:T[] = master.map((v:T)=>{
        const id:string = getId(v);
        const tmp = report.find((v2:StatReportResType)=>id===v2.key);
        return {...v, ...(tmp ? StatDataExtends(tmp) : {})};
    });
    return {master, report, data:tmp};
}



/** 레이블 목록 로딩 */
export const labelLoad = async ():Promise<LabelResType[]>=>{
    return KakaoService.label.getList({}).then((res)=>{
        const data:LabelResType[] = res.data.result?.map((v:LabelResType)=>({
            ...v,
            labelId:toNumber(v.labelId),
            clientId:toNumber(v.clientId),
            createdBy:toNumber(v.createdBy),
            modifiedBy:toNumber(v.modifiedBy),
        })) || 0;
        return data?.sort((a,b)=>a.labelId<b.labelId ? -1 : 1) || [];
    });
}
const toNumber = (value:number|string):number|undefined=>value?.toString().toNumber();

/** 레이블 목록 로딩 */
export const labelSimpleLoad = async ():Promise<LabelResType[]>=>{
    return KakaoService.label.getSimpleList().then((res)=>{
        const data:LabelResType[] = res.data.result?.map((v:LabelResType)=>({
            ...v,
            labelId:toNumber(v.labelId),
            clientId:toNumber(v.clientId),
            createdBy:toNumber(v.createdBy),
            modifiedBy:toNumber(v.modifiedBy),
        })) || 0;
        return data?.sort((a,b)=>a.labelId<b.labelId ? -1 : 1) || [];
    });
}

//데이터 호출텀 계산용
let _lockLoadTm:number|null = null;
export const getLockState = async <T extends object>(path:string, getId:((v:T)=>string), data?:T[], lock?:boolean):Promise<string[]|undefined|null>=>{
    const chunk:number = 300;
    const result:string[] = [];

    if(!data?.length){ return; }
    if(
        _lockLoadTm 
        && (new Date().getTime()-(_lockLoadTm || 0)) < KakaoConfig.lockStateInterval * 1000 //롱타임
        && lock!==true //강제사항
    ){ return; }
    // return data?.filter((v)=>Math.random()>0.5).map(v=>v.campaignId);

    _lockLoadTm = new Date().getTime();
    const ids:string[] = data?.map((v)=>getId(v)) || [];
    for(let i=0; i<ids.length; i+=chunk){
        const id:string[] = ids.slice(i, i+chunk);
        // 리포트 데이터 로딩
        const tmp:string[] = await KakaoHelperComponent.getLockState(id || [], path)
        .then(((res) => {
            if(res.status===200){
                return res.data?.result?.ids || [];
            }
            return [];
        })).catch(()=>[]);
        result.push(...tmp);
    }
    return result;
}