import { ExceptionKeywordListType } from "../../app/pages/kakao/keywordAd/adgroup/Types";

const Yap = (value:any)=>{
    return new YapCore(value);
}

class YapCore{
    private value:any = null;
    constructor(value:any){
        this.value = value;
    }
    /**
     * 최소 입력문자 개수를 체크합니다.
     * @param length 최소 입력문자 수
     * @param msg 예외사항 메세지 '{VALUE}' 문자열을 사용하는 경우 해당 키워드를 치환합니다.
     * @returns 
     */
    minLength(length:number, msg?:string){
        if(!this.value || this.value===null){ throw new Error(msg || `최소 ${length.addComma()}자까지 입력하여야 합니다!`); }
        const list = Array.isArray(this.value) ? this.value : [this.value];
        list.forEach((v)=>{
            if( v.toString().length < length){
                throw new Error(msg?.replaceAll('{VALUE}', v) || `최소 ${length.addComma()}자까지 입력하여야 합니다!`);
            }
        });
        return this;
    }
    /**
     * 최대 입력문자 수에 대해서 체크합니다.
     * @param length 최대 입력문자 수
     * @param msg 예외사항 메세지 '{VALUE}' 문자열을 사용하는 경우 해당 키워드를 치환합니다.
     * @returns 
     */
    maxLength(length:number, msg?:string){
        if(!this.value || this.value===null){ throw new Error(msg || `최대 ${length.addComma()}자까지 입력하여야 합니다!`); }
        const list = Array.isArray(this.value) ? this.value : [this.value];
        list.forEach((v)=>{
            if( v.toString().length > length){
                throw new Error(msg?.replaceAll('{VALUE}', v) || `최대 ${length.addComma()}자까지 입력 가능합니다!`);
            }
        });
        return this;
    }

    /**
     * 배열의 최소 크기를 체크합니다.
     * @param size 최소 크기
     * @param msg 예외사항 메세지
     * @returns 
     */
    minSize(size:number, msg?:string){
        if(!this.value || this.value===null || !Array.isArray(this.value) || this.value.length < size){ 
            throw new Error(msg || `최소 ${size.addComma()}개이상 입력하여야 합니다!`);
        }
        return this;
    }

    /**
     * 배열의 최대 크기를 체크합니다.
     * @param size 최대 크기
     * @param msg 예외사항 메세지
     * @returns 
     */
    maxSize(size:number, msg?:string){
        if(!this.value || this.value===null || !Array.isArray(this.value) || this.value.length > size){ 
            throw new Error(msg || `최대 ${size.addComma()}개까지 입력 가능합니다!`);
        }
        return this;
    }

    /**
     * 빈 입력값의 경우를 체크합니다.
     * @param msg 예외사항 메세지
     * @returns 
     */
    required(msg?:string){
        try{
            if(this.value===undefined || this.value===null || this.value.toString().trim().isEmpty()){ throw new Error('required : undefined or null!'); }
            if(Array.isArray(this.value) && this.value.length===0){ throw new Error('required : Array - undefined or null!'); }
            switch(typeof(this.value)){
                case 'number' : break;
                case 'object' : if(!this.value){ throw new Error('required : object undefined or null!'); } break;
                case 'string' : if(!this.value || this.value.length === 0){ throw new Error('required : string!'); } break;
            }
        }catch(e){
            throw new Error(msg || `필수 입력사항입니다!`);
        }
        return this;
    }

    /**
     * 파일여부를 확인합니다.
     * @param msg 예외사항 메세지
     * @returns 
     */
    isFile(msg?:string){
        const file:File|null|undefined = this.value;
        if(!file || file===null){ throw new Error(msg || `필수 입력사항입니다!`); }
        if(!file.type || !file.name || !file.size){ throw new Error(msg || `파일이 아닙니다!`); }
        return this;
    }

    /**
     * 파일 확장자를 확인합니다.
     * @param ext 파일 확장자 목록
     * @param msg 예외사항 메세지
     * @returns 
     */
    isFileExt(ext:string[]|string, msg?:string){
        const file:File|null|undefined = this.value;
        const fileName:string = file?.name.toLowerCase() || '';
        const fileExtension:string = fileName.split('.').pop() || '';
        ext = (Array.isArray(ext) ? ext : [ext]).map((v)=>v.toLowerCase());

        if(!ext.includes(fileExtension)){ throw new Error(msg || `허용하지 않는 확장자입니다.(${ext.join(",")})`); }
        return this;
    }

    /**
     * 파일의 허용 용량을 제한합니다.
     * @param num 허용용량
     * @param msg 
     * @returns 
     */
    maxFileSize(num:number, msg?:string){
        const file:File|null|undefined = this.value;
        this.isFile();
        if( (file?.size || 0) > num){ throw new Error(msg || `제한 용량(${num.addComma()})을 초과하였습니다!`); }
        return this;
    }

    /**
     * 허용 파일형식을 체크합니다.
     * @param type application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
     * @param msg 
     * @returns 
     */
    fileAccept(type:string[], msg?:string){
        const file:File|null|undefined = this.value;
        this.isFile();
        if( !file?.type || !type.includes(file.type) ){ throw new Error(msg || `허용(${type.join(',')})하지 않는 파일형식입니다!`); }
        return this;
    }

    /**
     * 최소 입력 숫자를 체크합니다.
     * @param num 최소 입력값
     * @param msg 예외사항 메세지
     * @returns 
     */
    min(num:number, msg?:string){
        const value = !this.value || this.value===null ? 0 : this.value;
        const tmp = typeof(value) !== 'number' ? value.toString().toNumber() : this.value;
        if(tmp < num){
            throw new Error(msg || `${num.addComma}이상 입력하여야 합니다!`)
        }
        return this;
    }
    /**
     * 최대 입력값을 체크합니다.
     * @param num 최대 입력값
     * @param msg 예외사항 메세지
     * @returns 
     */
    max(num:number, msg?:string){
        const value = !this.value || this.value===null ? 0 : this.value;
        const tmp = typeof(value) !== 'number' ? value.toString().toNumber() : this.value;
        if(tmp > num){
            throw new Error(msg || `${num.addComma()}까지 입력 가능합니다!`);
        }
        return this;
    }

    /**
     * url 체크 (http(s)://www. 시작)
     * @param msg 예외사항 메세지
     * @returns 
     */
    url(msg?:string){
        const urlExp = /(http(s)?:\/\/)(www\.)?([/a-z0-9-%#?&=\w])+(\.[a-z0-9]{2,4}(\?[/a-z0-9-%#?&=\w]+)*)*/gi;
        if(!urlExp.test(this.value)) {
            throw new Error(msg || `올바른 URL 형식이 아닙니다.`);
        }
        return this;
    }

    /**
     * value 값 정규식 체크
     * @regex 정규식
     * @param msg 예외사항 메세지 '{VALUE}' 문자열을 사용하는 경우 해당 키워드를 치환합니다.
     * @param test undefined|true : 허용 문자열 체크, false : 제외 문자열 포함여부 체크
     * @returns 
     */
    regex(regex:RegExp, msg?:string, test?:boolean){
        const txt = Array.isArray(this.value) ? this.value : [this.value];
        txt.forEach((v)=>{
            if(regex.test(v) !== (test===undefined ? true : test)) {
                throw new Error(msg?.replaceAll('{VALUE}', v) || `허용하지 않는 문자열을 포함하고 있습니다.`);
            }
        });
        return this;
    }

    /**
     * 0자릿수 체크
     * @param num 체크할 자릿수 n>=0, 0보다 작은 경우, Fail 처리됨
     * @param msg 오류 메세지
     */
    zeroDigit(digit:number, msg?:string){
        if(digit<0){ throw new Error(`잘 못된 자릿수입니다. digit값을 0보다 같거나 큰 값을 입력하세요`); }
        const pow = (10).pow(digit);
        if( (this.value % pow) !== 0 ) {
            throw new Error(msg || `올바른 입력 단위가 아닙니다. ${pow}단위로 입력해 주세요.`);
        }
    }

    /**
     * step 값 단위로 입력되었는지 체크합니다.
     * @param step 
     * @param msg 
     */
    step(step:number, msg?:string){
        if( (this.value % step) !== 0 ) {
            throw new Error(msg || `올바른 입력 단위가 아닙니다. ${step}단위로 입력해 주세요.`);
        }
    }

    /**
     * 필수 포함단어를 체크합니다.
     * @param word 찾을 단어
     * @param min 존재할 최소 개수
     * @param msg 에어 메세지
     */
    wordMinLength(word:string, min:number, msg?:string){
        if( (this.value.split(word).length-1) < min) {
            throw new Error(msg || `"${word}" 단어가 최소 ${min}개를 입력되어야 합니다.`);
        }
    }

    /**
     * 필수 포함단어를 체크합니다.
     * @param word 찾을 단어
     * @param max 포함할 수 있는 최대 개수
     * @param msg 에어 메세지
     */
    wordMaxLength(word:string, max:number, msg?:string){
        if( (this.value.split(word).length-1) > max) {
            throw new Error(msg || `"${word}" 단어를 최대 ${max}개까지 입력 가능합니다.`);
        }
    }

    /**
     * 배열 값으로부터 값이 중복된 것이 있는지 체크합니다.
     * @param msg '{VALUE}' 문자열을 사용하는 경우 해당 키워드를 치환합니다.
     * @returns 
     */
    isDuplicate(msg?:string) {
        if(!this.value || !Array.isArray(this.value)){ return this; } //값이 없거나 배열이 아닌 경우 Pass
        const list:string[] = this.value;
        list.forEach((v, index) => {
            if( index !== list.lastIndexOf(v) ){
                throw new Error(msg?.replaceAll('{VALUE}', v) || `중복된 키워드가 존재합니다. 중복 키워드 : [${v}]`);
            }
        });
        return this;
    }

    /**
     * 제외키워드 중복 체크
     * @param exceptionList 1개 선택시 제외키워드 목록 
     * @param msg 에러 메시지
     */
    exceptionKeywordDupl(exceptionList:ExceptionKeywordListType[], msg?:string) {
        let duplChk:boolean = false;
        const arr:string[] = this.value.splitNoneBlank("\n");
        let dulpKeyword: string = '';
        exceptionList.forEach((item) => {
            if(arr.includes(item.exceptionKeywordName)) {
                duplChk = true;
                dulpKeyword = item.exceptionKeywordName;
                return false;
            }
        })
        const chkArr = arr.filter((val, index) => {if(arr.indexOf(val) !== index){dulpKeyword=arr[index]};return arr.indexOf(val) === index;})
        if(duplChk || arr.length !== chkArr.length) {
            throw new Error(msg || `중복된 키워드가 존재합니다. 중복 키워드 : [${dulpKeyword}]`);
        }
        return this;
    }

    /**
     * Textarea 라인별 최소 글자 체크
     * @param length 최소 글자수
     * @param msg 에러 메시지
     * @returns 
     */
    textareaLineMinLength(length:number, msg?:string) {
        const arr:string[] = this.value.splitNoneBlank("\n");
        arr.forEach((val:string) => {
            if(val.length < length) {
                throw new Error(msg || `최소 ${length}자까지 입력하여야 합니다!`);
            }
        })
        return this;
    }

    /**
     * Textarea 라인별 최대 글자 체크
     * @param length 최대 글자수
     * @param msg 에러 메시지
     * @returns 
     */
    textareaLineMaxLength(length:number, msg?:string) {
        const arr:string[] = this.value.splitNoneBlank("\n");
        arr.forEach((val:string) => {
            if(val.length > length) {
                throw new Error(msg || `최대 ${length}자까지 입력하여야 합니다!`);
            }
        })
        return this;
    }

    /**
     * Textarea 내 중복 항목(Line) 체크
     * @param msg 에로 메시지
     * @returns 
     */
    textareaDupl(msg?:string) {
        const arr:string[] = this.value.splitNoneBlank("\n");
        arr.forEach((val:string) => {
            const chk:string[] = arr.filter((item) => item === val);
            if(chk.length > 1) {
                let message:string = `중복 입력된 항목이 존재합니다. 중복 항목: (${val})`;
                if(msg) {
                    message = msg + ` 중복키워드 : (${val})`;
                }
                throw new Error(message);
            }
        })
        return this;
    }

    limit(limit:number, regCnt?:number, msg?:string){
        const tmp = typeof(this.value) !== 'number' ? this.value.toString().toNumber() : this.value;
        const regCount:number = regCnt ? regCnt:0;
        if(tmp > limit - regCount){
            throw new Error(msg || `등록가능 수량(${(limit-regCount).addComma})을 초과 했습니다.`)
        }
        return this;
    }
}

export default Yap;