/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { useUserContext } from '../../../contexts/UserContext';
import CustomCard from '../../modules/card/CustomCard';
import ExplorerList from './ExplorerList';
import KakaoCode from '../../../modules/code/KakaoCode';
import ExplorerSearchBar from '../../explorer/ExplorerSearchBar';
import { KakaoService } from '../../../common/services';
import { Button, Container } from 'react-bootstrap';
import Code from '../../../modules/code/Code';
import { StatReportInfoType } from '../../../common/services/models/kakao/StatReportType';
import { ExplorerTitle } from './StatInfoData';

export function Explorer() {
    const userContext = useUserContext();
    const [level, setLevel] = useState<string>('');
    const media = 'kakao';//[media, setMedia] = useState<string>('kakao');
    const [search, setSearch] = useState<string>('');
    const [from, setFrom] = useState<number>(0);
    const size = 10;//[size, setSize] = useState<number>(10);
    const [result, setResult] = useState<StatReportInfoType[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const [selectData,setSelectData] = useState<StatReportInfoType|undefined>();

    useEffect(()=>{
        const data = userContext.userSelect.data?.explorer;
        if(data?.media === Code.base.mediaType.KAKAO.value){
            onSearch(data?.level || '', data?.search || '', 0);
        }else{
            onSearch('', '', 0);
        }
    }, [userContext.userSelect.data?.explorer]);

    const onSearch=(level:string, search:string, from:number) => {
        // userContext.explorer.set({level, search});
        setSearch(search);
        setLevel(level);
        setFrom(from);
        setShowDetail(false);
        if(from===0){
            setResult([]);
            setTotal(0);
            setLoaded(false);
        }
        if(!search || !level){ return; }
        
        userContext.modalLoading.show();
        KakaoService.explorer<StatReportInfoType>(level, search, from, size)
        .then((res)=>{
            const data = res.data?.result;
            if(from > 0){
                const searchData:StatReportInfoType[] = data?.search || [];
                searchData.forEach((v)=>result.push(v));
                setResult(result);
            }else{
                setResult(data?.search || []);
                setTotal(data?.totalCount || 0);
            }
        })
        .catch((e)=>{ userContext?.alertMessage.add({
            variant:'danger', 
            title:'Explorer 검색 오류',
            body: <>
                Explorer검색에 실패하였습니다.({search})<br/>
                {KakaoService.ErrorMessage(e)}
            </>,
        }); })
        .finally(()=>{
            userContext.modalLoading.hide();
            setLoaded(true);
        });
    }

    const moreSearch = ()=>{
        onSearch(level, search, from+size);
    }

    return (<>
        <ExplorerSearchBar className='mb-5' level={level} search={search} onSearch={onSearch} options={KakaoCode.getOptions(KakaoCode.data.options.ExplorerLevel)}/>

        {loaded && <>
            {!total && <CustomCard className='text-center mb-6'>검색결과가 없습니다. </CustomCard>}
            {total>0 && <CustomCard className='mb-6 fw-bold'>
                <Container className='d-flex'>
                    <Container className='ps-0 pt-1 pb-2 fs-4'>
                        {!showDetail && <>{KakaoCode.getLabel(KakaoCode.data.options.ExplorerLevel, level||'')} 검색결과 <span className='text-danger'>{total.addComma()}</span> 건</>}
                        {showDetail && <ExplorerTitle level={level} data={selectData} />}
                    </Container>
                    
                    {showDetail && <Container>
                        <Button variant="light" className='fw-normal' style={{float:'right'}} size="sm" onClick={()=>{setShowDetail(false)}}>검색결과로 이동</Button>
                    </Container>}
                </Container>
            </CustomCard>}
            {total > 0 && <ExplorerList data={result} level={level} search={search} media={media} more={total > from+size ? moreSearch : undefined} 
            detailState={[showDetail, setShowDetail]} onChange={(data?:StatReportInfoType)=>setSelectData(data)} />}
        </>}
    </>)
}
