

/** 카카오 리포트 데이터 결과 타입 */
export interface StatReportResultType<T=StatReportResType> {
    info?:StatReportInfoType;
    total?: T[];
    daily?:T[];
    performances?:T[];
}

export interface StatReportInfoType{
    customerId?: number,
    businessChannelId?: string,
    businessChannelName?: string,
    businessChannelRspvUrl?: string,
    businessChannelPcUrl?: string,
    businessChannelMoUrl?: string,
    campaignId?: string,
    campaignName?: string,
    adgroupId?: string,
    adgroupName?: string,
    adId?: string,
    adName?: string,
    adLinkId?: string,
    keywordId?: string,
    keyword?: string,
    title?: string,
    description?: string,
    assetTypeValues?:string,
}



export interface StatReportResType{
    key:string;
    date?:string;
    impressionCount: number;
    clickCount: number;
    adCost: number;
    adRank: number;
    rankImpression: number;
    cvAppInstall1D: number;
    cvAppInstall7D: number;
    cvParticipation1D: number;
    cvParticipation7D: number;
    cvPurchase1D: number;
    cvPurchase7D: number;
    cvPurchasePrice1D: number;
    cvPurchasePrice7D: number;
    cvRegistration1D: number;
    cvRegistration7D: number;
    cvSignup1D: number;
    cvSignup7D: number;
    cvViewCart1D: number;
    cvViewCart7D: number;

    averageAdRank?: number;
    clickThroughRate?: number;
    costPerClick?: number;
    conversionRate?: number;
    costPerAction?: number;
    costPerSubscription?: number;
    returnOnAdSpend?: number;
}

export enum StatReportResKeys{
    key                 = "key",
    impressionCount     = "impressionCount",
    clickCount          = "clickCount",
    adCost              = "adCost",
    adRank              = "adRank",
    cvAppInstall1D      = "cvAppInstall1D",
    cvAppInstall7D      = "cvAppInstall7D",
    cvParticipation1D   = "cvParticipation1D",
    cvParticipation7D   = "cvParticipation7D",
    cvPurchase1D        = "cvPurchase1D",
    cvPurchase7D        = "cvPurchase7D",
    cvPurchasePrice1D   = "cvPurchasePrice1D",
    cvPurchasePrice7D   = "cvPurchasePrice7D",
    cvRegistration1D    = "cvRegistration1D",
    cvRegistration7D    = "cvRegistration7D",
    cvSignup1D          = "cvSignup1D",
    cvSignup7D          = "cvSignup7D",
    cvViewCart1D        = "cvViewCart1D",
    cvViewCart7D        = "cvViewCart7D",

    averageAdRank       = "averageAdRank",
    clickThroughRate    = "clickThroughRate",
    costPerClick        = "costPerClick",
    conversionRate      = "conversionRate",
    costPerAction       = "costPerAction",
    costPerSubscription = "costPerSubscription",
    returnOnAdSpend     = "returnOnAdSpend",
}
