
export default class Utils {
   
    //  '.' 구분된 키를 처리하는 모듈
    public static DotNotation = class{
        // '.'으로 구분된 키를 Object 구조로 변환하고 value을 입력하여 반환한다.
        // "aa.bb.cc", "1234" --> return {aa:{bb:{cc:"1234"}}}
        public static toObject(key:string, value:any):Object
        {
            return JSON.parse( key.split(".").reverse().reduce((pre,val)=>{ return `{"${val}":${pre}}`; }, JSON.stringify(value)));
        }

        /**
         * Object형에서 key에 해당하는 값을 반환합니다.
         * @param key '.'로 depth를 구분하는 키문자열
         * @param obj 데이터
         * @returns 
         */
        public static getValue(key:string, obj:Object):any{
            return key?.split(".").reduce((pre:any, val:string)=>{ return pre && pre instanceof Object ? new Map(Object.entries(pre)).get(val) : undefined; },obj);
        }
    }

    public static objectIsEmpty(value?:object){
        if(value===undefined){ return true; }
        for (var key in value) {
            if (value.hasOwnProperty(key))
                return false;
        }
        return true;
    };

    /**
     * Object에서 해당 키에 값을 반환합니다.
     * @param data :object
     * @param key : string
     * @returns 
     */
    public static objectFind(data:object, key?:string):undefined | any{
        if(!key){ return undefined; }
        const value = Object.entries(data).find(([k, v])=>k===key);
        return value && value[1];
    }


    /**
     * 파일 확장자를 반환합니다.
     * @param name 파일명 
     * @returns 확장자
     */
    public static getFileExtension(name:string):string|undefined{ return name.toLowerCase().split('.').pop();}

}