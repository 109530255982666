import React, {useEffect, useState} from "react";
import { DragDropContext, Droppable, Draggable, DropResult} from "react-beautiful-dnd";
import ListGroup from 'react-bootstrap/ListGroup';
import Checkbox from "../form/Checkbox";
import { v4 as uuid } from 'uuid';
import clsx from "clsx";
// import "./dragdropstyle.scss";

export interface DragDropGroupProps<T=string>{
    options: T[];
    /**
     * 아이콘 드래그만 사용할 경우
     */
    isDragIcon?: boolean;
    /**
     * 항목 선택 기능
     */
    isCheckBox?: boolean;
    /**
     * 순서가 변경된 경우 정렬된 배열로 반환합니다.
     * @param items 
     * @returns 
     */
    onChange?: (items:T[])=>void;
    /**
     * 체크박스가 활성화 되었을 때, 선택된 목록을 반환합니다.
     * @param items :선택항목 리스트
     * @returns 
     */
    onChecked?: (items:T[])=>void;
    /**
     * 아이템 컨텐츠를 구성합니다.
     * @param item 
     * @returns 
     */
    formatter: (item:T)=>string|React.ReactNode;

    /** 드래그 아이콘 표시여부 */
    hideIcon?:boolean;
}

interface ItemType<T>{
    item : T;
    checked: boolean;
}

// Normally you would want to split things out into separate components.
// But in this example everything is just done in one place for simplicity
const DragList = <T extends unknown>(props:DragDropGroupProps<T>) => {
    const uid = uuid();
    const [useItems, setUseItems] = useState<ItemType<T>[]>([]);

    useEffect(()=>{ 
        const items = props.options.map((v, index)=>{return {item:v, checked:useItems[index]?.checked || false}});  //기존 데이터의 선택정보 유지
        setUseItems(items);
    // eslint-disable-next-line
    },[props.options]);

    // a little function to help us with reordering the result
    const sort = (items:ItemType<T>[], startIndex:number, endIndex:number) => {
        if(startIndex===endIndex){ return items; }
        const result = Array.from(items);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    //순서정렬
    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;
        const { source, destination } = result;
        if(source.index === destination.index){ return; }
        setUseItems((prev)=>{
            const items = sort(prev, source.index, destination.index);
            props.onChange && props.onChange(items.map((v)=>v.item));
            return items;
        });
    }

    //선택여부
    const onChecked = (index:number, checked:boolean) => {
        if(!props.isCheckBox){ return; }
        setUseItems((prev)=>{
            prev[index].checked = checked;
            props.onChecked && props.onChecked(prev.filter((v)=>!props.isCheckBox ||  v.checked).map((v)=>v.item));
            return [...prev];
        });
    }
    return (<>
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={`droppable`+uid} >
                {(provided, snapshot) => (
                    <ListGroup {...provided.droppableProps} className={clsx('border-0', snapshot.isDraggingOver?"droppable":"")} ref={provided.innerRef}>
                        {useItems.map((v, index) => (
                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                {(provided, snapshot) => {
                                    const iconDragHandleProps = props.isDragIcon ? {...provided.dragHandleProps}:{};
                                    const itemDragHandleProps = props.isDragIcon ? {}:{...provided.dragHandleProps};
                                    return (
                                        <ListGroup.Item
                                            className={clsx(snapshot.isDragging?"dragging":"", 'p-0 bg-transparent border-0')}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...itemDragHandleProps}
                                        >
                                            {props.isCheckBox && <Checkbox key={index} 
                                                onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{onChecked(index, e.target.checked)}} 
                                                value={index} checked={v.checked} size={'sm'}>{props.formatter(v.item)}</Checkbox>}
                                            {!props.isCheckBox && <div className="d-inline">{props.formatter(v.item)}</div>}
                                            {!props.hideIcon && <i className="bi bi-list pt-1 fs-3 mt-1" {...iconDragHandleProps} style={{float:"right"}} />}
                                        </ListGroup.Item>
                                    );
                                }}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </ListGroup>
                )}
            </Droppable>
        </DragDropContext>
    </>);
};

// Put the thing into the DOM!
export default DragList;
