import clsx from 'clsx';
import { CSSProperties, useEffect, useState } from 'react';
import { Dropdown } from "react-bootstrap";
import { OptionType } from '../../../../modules/types/Types';
import ThemeConfig from '../../../common/conf/ThemeConfig';
import { ButtonDropDown } from './ButtonDropDown';

export interface DropDownOptionType extends OptionType<string, string|React.ReactNode>{
    href?:string;
    target?:string;
    children?:DropDownOptionType[];
}

export interface DropDownListProps {
    title?: string|React.ReactNode;
    value?: string;
    size?: 'sm'|'lg';
    onChange?: (value:string)=>void;
    options:DropDownOptionType[];
    className?:string;
    style?:CSSProperties;
    disabled?:boolean;
    variant?: string;

    /** 메뉴 닫힘 체크용 */
    status?:boolean;
    isLocked?: boolean;
}

/**
 * 드롭다운 버튼
 * @param props 
 * @returns 
 */
const DropDownList:React.FC<DropDownListProps> = (props)=>{
    const btnCfg = ThemeConfig.Button.Sub;
    const [show, setShow] = useState<boolean>(true);
    const [current, setCurrent] = useState<string>("");

    return <ButtonDropDown
        className={clsx('d-inline', props.className)}
        title={props.title}
        disabled={props.disabled}
        style={props.style}
        menu={{
            className:'p-4',
        }}
        onHide={()=>{setShow(false); setCurrent("");}}
        onShow={()=>setShow(true)}
        useCaret={true}
        isLocked={props.isLocked}
    >
        {props.options.map((v,index)=>
            (v.children?.length || 0) > 0 
            ? <DropDownChildren key={index} {...props} title={v.label} options={v.children || []} disabled={v.disabled} status={show && current===v.value} 
                onChangeCurrent={()=>setCurrent(v.value)}/>
            : <Dropdown.Item key={index} target={v.target} href={v.href} onClick={()=>props.onChange && props.onChange(v.value)} 
                onMouseOver={()=>setCurrent(v.value)} disabled={v.disabled} >{v.label}</Dropdown.Item>
        )}
    </ButtonDropDown>
}

const DropDownChildren:React.FC<DropDownListProps & {onChangeCurrent:()=>void}> = (props)=>{
    const [show, setShow] = useState<boolean>(false);
    const [current, setCurrent] = useState<string>("");
    useEffect(()=>{setShow(props.status || false);}, [props.status]);
    const onSelect = ()=>{
        setShow(true);
        props.onChangeCurrent();
    }

    return <div className='d-flex'>
        <div className={clsx('dropdown-item cursor-pointer', props.disabled ? 'disabled' :'')} style={{minWidth:'max-content'}} onClick={onSelect} onMouseOver={onSelect} >
            {props.title}
            <i className="bi bi-chevron-right ms-3" style={{float:'right', marginTop:"0.17em"}}/>
        </div>
        <div style={{position:'relative'}}>
            {show && <div className="p-4 dropdown-menu show" style={{position: 'absolute', transform: 'translate(0px, 2px)'}}>
                {props.options.map((v,index)=>
                    (v.children?.length || 0) > 0 
                    ? <DropDownChildren key={index} {...props} title={v.label} options={v.children || []} status={show && current===v.value}
                        onChangeCurrent={()=>setCurrent(v.value)}/>
                    : <Dropdown.Item key={index} target={v.target} href={v.href} onClick={()=>props.onChange && props.onChange(v.value)} 
                        onMouseOver={()=>setCurrent(v.value)} disabled={v.disabled}>{v.label}</Dropdown.Item>
                )}
            </div>}
        </div>
    </div>
}

export default DropDownList;