import KakaoHelperComponent from './KakaoHelperComponent';

/** 카카오 광고그룹 */
export default class AdGroupHelper extends KakaoHelperComponent{
    static path="/media/ad-groups"; //기본 Path
    static onOff="/media/ad-groups/on-off"; //onOff modify Path
    static simple="/media/simple-ad-groups"; //simple(combo) Path
    static stat='/performance/ad-groups';    //실적데이터
    static performances='/performances/ad-groups';    //실적데이터
    static downloadPath='/api/kakaokeywordad/xlsxdnld/adgroups';
}
