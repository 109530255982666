import clsx from "clsx";

export interface SymbolProps {
    name:string;
    initials?:string;
    state?:"warning" | "primary" | "danger";
    size?:"sm" | "md" | "lg" | "xl" | "xxl";
    type?: "circle" | "square";
    src?:string;
    style?: React.CSSProperties;
    onDoubleClick?:(e:React.MouseEvent<HTMLDivElement>)=>void;
}

/**
 * 심볼마크를 생성합니다.
 * @param props 
 *    name : 이름
 *    initials : 이미지가 없는 경우, 표시된 이니셜(기본값 : 이름의 첫글자)
 *    state : 배경 색상
 *    size : 크기
 *    type : 형태
 *    src : 이미지 URL, 이미지가 없는 경우 이니셜이 표시됩니다.
 * @returns 
 */
const Symbol = (props:SymbolProps) => {
    const initials = props.initials || props.name.substring(0,1);
    const state = props.state || ["warning", "primary", "danger"].randomSelect();    //warning, primary, danger
    const type = props.type ? `symbol-${props.type}` : ''; 
    let size = 'symbol-35px';

    switch(props.size){
        case "md" : size="symbol-50px fs-2"; break;
        case "lg" : size="symbol-75px fs-2x"; break;
        case "xl" : size="symbol-100px fs-3x"; break;
        case "xxl" : size="symbol-200px fs-5x"; break;
    }

    return (
        <div
            className={clsx(
                'symbol',
                size,
                type,
            )}
            data-bs-toggle='tooltip'
            title={props.name}
            style={props.style}
            onDoubleClick={props.onDoubleClick}
        >
            {props.src && <img src={props.src} alt={props.name} />}
            {!props.src &&
                <span
                    className={clsx(
                        'symbol-label fw-bold',
                        'bg-' + state,
                        'text-inverse-' + state,
                    )}
                >
                    {initials}
                </span>
            }
        </div>
    );
}

export default Symbol;

