import clsx from 'clsx';
import { omit } from 'lodash';
import { HTMLAttributes } from 'react';

const TextButton:React.FC<HTMLAttributes<HTMLSpanElement> & {disabled?:boolean}> = (props)=>{
    return <span
        {...omit(props,"disabled")}
        onClick={props.disabled ? undefined : props.onClick}
        className={clsx(
            "rounded h-40px p-2 text-center",
            {
                'text-hover-primary':!props.disabled, 
                'bg-hover-light':!props.disabled,
                'text-gray-300':props.disabled,
            },
            props.className
        )}
    ></span>
}

export default TextButton;
