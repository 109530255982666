
import clsx from "clsx";
import { omit } from 'lodash';
import React, { CSSProperties, useEffect, useState } from "react";
import { FormControl, FormControlProps } from "react-bootstrap";
import { v4 as uuid } from 'uuid';
export interface CheckboxProps extends Omit<FormControlProps, 'id' | 'type'>{
    checked?:boolean;
    isViewer?: boolean;
    onChange?:(e:React.ChangeEvent<HTMLInputElement>)=>void;
    labelClassName?: string;
    labelStyle?: CSSProperties;
}
/**
 * 체크박스
 * @param props 
 * @returns 
 */
const Checkbox = (props:CheckboxProps)=>{
    const uid = uuid();
    const [useChecked, setUseChecked] = useState<boolean|undefined>(false);
    useEffect(()=>{
        setUseChecked(props.checked)
    }, [props.checked]);
    const handleOnChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
        if(props.readOnly || props.disabled) return;
        setUseChecked(e.target.checked);
        props.onChange && props.onChange(e);
    }
    let size="";
    switch(props.size){
        case "sm" : size="form-check-sm"; break;
        case "lg" : size="form-check-lg"; break;
    }
    const props_inp = omit(props, ['children', 'style', 'labelClassName', 'labelStyle']);
    return (<div key={uid}
        className={clsx('form-check form-check-custom form-check-solid', size, props.className)}
        style={{display:'inline-flex', padding:0, ...props.style }}>
        <FormControl className={`form-check-input`} type="checkbox" id={'chk-'+uid} size={props.size}
            style={{minHeight:"auto", padding:0, cursor:"pointer", display:props.isViewer===true ? "none" : ""}} {...props_inp}
            onChange={handleOnChange} checked={useChecked} />
        <label className={clsx("me-5 pt-1 ps-2 cursor-pointer", props.className)} htmlFor={'chk-'+uid} style={props.labelStyle}>{props.children}</label>
    </div>);
}

export default Checkbox;