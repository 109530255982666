import URLParse from 'url-parse';
import SsoConfig from '../conf/SsoConfig';
import SsoHelper from './helpers/SsoHelper';
import { SsoTokenResType, SsoUserinfoResType } from './models/SsoModels';
import AuthConfig from '../conf/AuthConfig';

const STORAGE_ID='iam.auth';

export interface AuthType {
    token?: SsoTokenResType;
    userInfo: SsoUserinfoResType;
    signed: boolean;
    authorized: boolean;
    access_time: number;
    create_time: number;
}

let _lastRefreshTokenCheckTime:number|undefined = undefined;    //마지막 토큰 리프레시 시도 시간

export default class AuthService {
    /**
     * Sso 인증서버에 로그인 요청합니다.
     */
    public static async signIn(login:boolean=false):Promise<boolean|undefined>{
        const url = URLParse(SsoConfig.redirectUri);
        if (login || document.location.pathname === url.pathname) {
            // 리다이렉션 페이지인 경우
            const search = document.location.search;
            const code = new URLSearchParams(search).get('code') || undefined;
            if (!login && code) {
                return await this.getAccessInfo(code);
            }else{
                SsoHelper.authorize();   //통합인증 로그인
            }
        }
        return;
    }

    public static signOut(){
        const info = this.storage.get();
        this.storage.clear();
        info?.userInfo && SsoHelper.logout(info.userInfo);
    }

    /**
     * SSO 인증코드를 이용하여 AccessToken을 발급받습니다.
     * @param code SSO 인증코드
     * @returns 정상처리여부
     */
    public static async getAccessInfo(code: string): Promise<boolean> {
        const token = await SsoHelper.getTokenFromCode(code);     // access token 획득
        const userInfo = await SsoHelper.getUserInfo(token.access_token); //접속정보 획득
        
        //인증 검증
        if( !token?.access_token || !userInfo?.access_token ){ return false; }
        token.exp = token.expires_in*1000 + new Date().getTime();
        this.storage.set({
            token,
            userInfo,
            signed: true,
            authorized: true,
            access_time: new Date().getTime(),
            create_time: new Date().getTime(),
        });
        return true;
    }

    /**
     * 사용자정보를 갱신합니다.
     * @returns 
     */
    public static async userInfoRefresh()
    {
        const data = this.storage.get();
        if(!data?.token?.access_token){ return; }
        const userInfo = await SsoHelper.getUserInfo(data.token.access_token); //접속정보 획득
        if(!userInfo){ return; }
        data.userInfo = userInfo;
        this.storage.set(data);
    }

    /**
     * Access Time을 갱신합니다.
     */
    public static accessTimeRefresh():void
    {
        const data = this.storage.get();
        if( !data?.access_time ){ return; }
        data.access_time = new Date().getTime();
        this.storage.set(data);
    }

    /**
     * Token 만료시간을 체크하여 만료전 갱신합니다.
     */
    public static tokenRefreshCheck():void
    {
        const data = this.storage.get();
        if( !data?.token?.exp || !data?.token?.access_token){ return; }
        if( (data.token.exp - 120*1000) > new Date().getTime() ){ return; }
        if(_lastRefreshTokenCheckTime && (new Date().getTime() - _lastRefreshTokenCheckTime) < 30*1000){ return; }
        _lastRefreshTokenCheckTime = new Date().getTime();
        this.tokenRefresh();    //토큰 갱신진행
    }

    /**
     * 토큰을 갱신합니다.
     */
    public static async tokenRefresh(){
        const data = AuthService.storage.get();
        if(!data){ return; }
        const token = data.token ? await SsoHelper.tokenRefresh(data.token) : null;
        if(token !== null){
            token.exp = new Date().getTime() + token.expires_in * 1000;
            data.token = token;
            AuthService.storage.set(data);
        }
    }

    /**
     * 토큰유효여부를 확인합니다. 유효하지 않은 경우, 사용자 로그인정보를 제거합니다.
     * @returns 
     */
    public static async tokenCheck()
    {
        const info = this.storage.get();
        const active = await SsoHelper.introspect(info?.token?.access_token);
        if(!active){
            this.storage.clear();
        }
    }

    /**
     * 로그인 세션정보를 관리합니다.
     */
    public static storage = class {
        /**
         * 로그인정보를 스토리지에 저장합니다.
         * @param token 및 사용자정보
         */
        public static set(data:AuthType){
            data.create_time = new Date().getTime();
            localStorage.setItem(STORAGE_ID, JSON.stringify(data));
        }

        /**
         * 로컬스토리지로부터 세션정보를 로딩하여 반환합니다.
         * 세션시간이 만료한 경우, undefined인 반환됩니다.
         * @returns 세션시간 만료한 경우 or 데이터가 없는 경우 undefined 반환합니다.
         */
        public static get():AuthType|undefined{
            const data: string | null = localStorage.getItem(STORAGE_ID);
            if (!data) { return undefined; }
            try {
                const info:AuthType|undefined  = data ? JSON.parse(data) : undefined;
                if (!info) { return undefined; }
                if( !this.expireCheck(info) ){ return undefined; }  //만료

                //임시 비밀번호 사용자 - 비밀번호 변경 페이지로 이동
                // if(info.userInfo.tempPwYn === 'Y'){
                //     document.location.href = 'https://portaldev.dbase.co.kr/modifypassword';
                //     return undefined;
                // }
                
                return info;
            } catch (error) {
                console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
            }
        }

        /**
         * 로컬스토리지를 비웁니다.
         */
        public static clear(){
            localStorage.removeItem(STORAGE_ID);
        }

        /**
         * data 만료시간을 체크합니다.
         * @param data 
         * @returns 
         */
        public static expireCheck(data?:AuthType):boolean{
            if( !data?.create_time || !data.token){ return false; }
            const now = new Date().getTime();
            return now < (data.access_time + AuthConfig.expireTime * 1000)        //Front기반 Expire
                && now < (data.create_time + data?.token?.expires_in * 1000);     //Token자체 Expire
        }
    }
}
