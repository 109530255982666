import clsx from 'clsx';
import React, { CSSProperties, HTMLAttributes } from 'react';
import IconAll from '../../../images/all.png';
import IconKakao from '../../../images/kakao.png';
import IconNaver from '../../../images/naver.png';
import IconGoogle from '../../../images/u22.png';

export interface MediaTypeIconProps extends HTMLAttributes<HTMLImageElement>{
    type? : string;
    code? : string;
    viewLabel?: boolean;
    label? :string | React.ReactNode;
    iconStyle?:CSSProperties;
    nowrap?: boolean;
}

export const MediaTypeIcon = (props:MediaTypeIconProps)=>{
    const type = props.type || props.code || 'A01M1001';
    let src = undefined;
    let label:string='';

    const styles:CSSProperties = {
        width: '1em',
        height: '1em',
        padding: '0',
        borderRadius: '1em',
    }
    switch(type.toUpperCase()){
        case 'ALL' : case 'A01M1000' : label='모든 매체'; src=IconAll; break;
        case 'NAVER' : case 'A01M1001' : label='네이버'; src=IconNaver; break;
        case 'KAKAO' : case 'A01M1002' : label='카카오'; src=IconKakao ; break;
        case 'GOOGLE': case 'A01M1003' : label='구글';  src=IconGoogle; break;
    }
    
    return (<div className={clsx({"text-nowrap":props.nowrap!==false}, props.className)} style={{display:'inline-block', ...props.style}}>
        {src 
            ? <img
                ref={(node)=>{
                    node?.style.setProperty('margin-top', '-0.1em', 'important');
                }}
                style={{...styles, ...props.iconStyle}}
                className='me-2' src={src} alt={label}
            />
            : <div
                ref={(node)=>{
                    node?.style.setProperty('margin-bottom', '-0.1em', 'important');
                }}
                className='align-items-center text-center m-0 p-0 me-2'
                style={{display:'inline-block', backgroundColor:'var(--bs-gray-700)', overflow:'hidden',...styles, ...props.iconStyle, }}
            >
                <div className='fw-bolder text-gray-100' style={{fontSize:'0.8em'}}>{props.code}</div>
                {props.label}
            </div>
        }
        {props.viewLabel && (props.label || label)}
    </div>);
}

/** 매체타이별 색상값 반환 */
export const getMediaTypeColor = (type?:string):string|undefined =>{
    switch(type){
        case "NAVER" : case 'A01M1001' : return "#03C75A";
        case "KAKAO" : case 'A01M1002' : return "#FFCD01";
        case "GOOGLE" : case 'A01M1003' : return "#3C64FF";
    }
    return undefined;
}



export default MediaTypeIcon;
