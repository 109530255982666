import AuthService from '../../../common/services/AuthService';
import {AuthModel} from './_models';

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getAuth = (): AuthModel | undefined => {
    const info = AuthService.storage.get();
    return {
        api_token:info?.token?.access_token || '',
        refreshToken: info?.token?.refresh_token,
    }
}
// const getAuth_old = (): AuthModel | undefined => {
//     if (!localStorage) {
//         return
//     }

//     const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
//     if (!lsValue) {
//         return
//     }

//     try {
//         const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
//         if (auth) {
//             // You can easily check auth_token expiration also
//             return auth
//         }
//     } catch (error) {
//         console.error('AUTH LOCAL STORAGE PARSE ERROR', error);
//     }
// }

const setAuth = (auth: AuthModel) => {
    AuthService.storage.set({
        token:{
            access_token: auth.api_token,
            refresh_token: auth.refreshToken || '',
            token_type: '',
            expires_in: 3600,
            scope: '',
        },
        userInfo: {
            user_signature: '사용자',
            last_login_success: true,
            last_login_time: '2023-03-14 15:40:12',
            user_name: 'admin@tbase.co.kr',
            accountType: 'user',
            last_login_ip_addr: '123.123.34.234',
            authorities: ['관리자'],
            authentication_id: 'A7A7ECE5ABB41B37456C4F7C0B815C2B',
            user_id: 11,
            name: 'admin@tbase.co.kr',
            ip_addr: '123.123.34.234',
        },
        signed: true,        //로그인상태
        authorized: true,    //권한여부
        access_time: new Date().getTime(),
        create_time: new Date().getTime(),
    });
}

// const setAuth_old = (auth: AuthModel) => {
//     if (!localStorage) {
//         return;
//     }

//     try {
//         const lsValue = JSON.stringify(auth);
//         localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
//     } catch (error) {
//         console.error('AUTH LOCAL STORAGE SAVE ERROR', error);
//     }
// }
const removeAuth = () => {
    AuthService.storage.clear();
}

export function setupAxios(axios: any) {
    axios.defaults.headers.Accept = 'application/json';
    axios.interceptors.request.use(
        (config: {headers: {Authorization: string}}) => {
            const auth = getAuth();
            if (auth && auth.api_token) {
                config.headers.Authorization = `Bearer ${auth.api_token}`;
            }

            return config;
        },
        (err: any) => Promise.reject(err)
    )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
