// import React from 'react';
import { AdminServer } from "../axios/";
import Utils from '../utils/Utils';
import { AdminRequestType, AdminResType } from "./models/admin/AdminModels";
import { BookmarkPutType, BookmarkResType } from "./models/admin/BookmarkTypes";
import { EstimateAccountGroupType, EstimateMagType, EstimateResType, EstimateUserResType } from "./models/admin/EstimateTypes";
import { RequestFilterType } from "./models/RequestTypes";
import { MediaResponseType } from "./models/ResponseTypes";
import { Service } from './Service';

interface ServicePostProps<S=any>{
    body:S;
}

class ServiceComponent{
    static path="";

    //표준 목록조회
    public static getList<T=any>(params:AdminRequestType, mediaType?:string){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.get<MediaResponseType<T>>(this.getPath(mediaType),{params:params,});
    }

    //표준 상세조회
    public static get<T=any>(id:string, mediaType?:string){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.get<MediaResponseType<T>>(`${this.getPath(mediaType)}/${id}`, );
    }

    //표준 수정
    public static put<T=any>(props:ServicePostProps<T>, mediaType?:string){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.put(this.getPath(mediaType),{...props, });
    }

    //표준 삭제
    public static delete(filters:RequestFilterType, mediaType?:string){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.delete(this.getPath(mediaType),{data:{searchFilter:filters}, });
    }

    //표준 추가
    public static post<T=any>(props:ServicePostProps<T>, mediaType?:string){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.post(this.getPath(mediaType),{...props, });
    }

    static getPath(mediaType?:string):string{
        return mediaType ? `/media-accounts/${mediaType}${this.path}` : this.path;
    }
}

/**
 * Account 표준형형 
 */
class Account extends ServiceComponent{
    static path="/connected";
    
    /**
     * 광고주와 연결된 자산 사용 동기화
     */
    public static sync<T=any>( id:string, mediaType?:string){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.put<T>(`${this.getPath(mediaType)}/${id}/sync`,);
    }

    /**
     * 연결 안된 자산 전체 조회
     * @param userContext 
     * @returns 
     */
    public static unconnected<T=any>(mediaType?:string){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.get<T>(`/media-accounts/${mediaType}/unconnected`,);
    }

    /**
     * 광고주와 연결된 매체 자산 히스토리
     * @param id 자산 ID
     * @param userContext 
     * @returns 
     */
    public static history<T=any>(id:string, params:AdminRequestType, mediaType?:string){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.get<T>(`${this.getPath(mediaType)}/${id}/history`, {params:params,});
    }
    
    /**
     * 전체 매체에 대한 자산중 사용중인 데이터
     * @param userContext 
     * @returns 
     */
    public static all<T=any>(mediaType?:string){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.get<T>(`${this.getPath(mediaType)}/all`,);
    }

    /**
     * 자산 연결
     * @param props : {magId:string, id:string}
     * @param mediaType 
     * @param userContext 
     * @returns 
     */
    public static post<T=any>(props:T, mediaType?:string){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.post(this.getPath(mediaType), props);
    }

    /**
     * 자산 수정
     * @param props : {magId:string, useYn:string}
     * @param id 자산 ID
     * @param mediaType 
     * @param userContext 
     * @returns 
     */
    public static putAccount<T=any>(props:T, id:string, mediaType:string){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.put(`${this.getPath(mediaType)}/${id}`, props);
    }

    /**
     * adplo광고주와 네이버/카카오 자산 연결 다건 수정
     * @param props {ids(mediaAccountId): string[], magId:string}
     * @param mediaType 
     * @param userContext 
     * @returns 
     */
    public static putAccountMag<T=any>(props:T, mediaType:string){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.put(this.getPath(mediaType), props);
    }

    /**
     * 사용여부 설정
     * @param props {ids(mediaAccountId): string[], useYn:string}
     * @param mediaType 
     * @param userContext 
     * @returns 
     */
    public static putOnOff<T=any>(props:T, mediaType:string){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.put(`${this.getPath(mediaType)}/onoff`, props);
    }
}

class Code extends ServiceComponent{
    static path="/common/code";
}

class User extends ServiceComponent {
    static path="/user";

    //표준 목록조회
    public static getAll<T=any>(){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.get<MediaResponseType<T>>(`${this.path}/all`);
    }
}

class AccountGroup extends ServiceComponent {
    static path="/media-account-group/mag";

    /**
     * Mag 사용 회원
     * @param id MagId
     * @param userContext 
     * @returns 
     */
    public static users<T=any>(id:string){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.get<T>(`${this.path}/users?magId=${id}`);
    }

    /**
     * Mag 전체조회
     * @param userContext 
     * @returns 
     */
    public static all<T=any>(){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.get<T>(`${this.path}/all`,);
    }

    /**
     * 광고주 사용여부 수정
     * @param props {magId, useYn}[]
     * @param userContext 
     * @returns 
     */
    public static putUseYn<T=any>(props:T){
        return Utils.apiRequestValidation({useAccount:false}) ||AdminServer.put(`${this.path}/useYn`, props);
    }

    /**
     * 광고주 명 중복 체크
     * @param magName 광고주 명
     * @param userContext 
     * @returns 
     */
    public static isDuplicate<T=any>(magName:string){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.get<MediaResponseType<T>>(`${this.path}/duplicate`,{params:{magName:magName},});
    }

    public static postMagName<T=any>(props:T){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.post(`${this.path}`, props);
    }

    public static putMag<T=any>(id:string, props:T){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.put(`${this.path}/${id}`, props);
    }
}

class RoleGroup extends ServiceComponent {
    static magPath = '/media-account-group/roleGroup';
    static userPath = '/user/roleGroup';

    /**
     * Mag 에 회원 일괄 등록
     * @param props 
     * @param userContext 
     * @returns 
     */
    public static putBulkUser<T=any>(props:T){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.put(`${this.magPath}/bulk-user-allocation`, props);
    }

    /**
     * 
     * @param props Mag에 회원 등록
     * @param userContext 
     * @returns 
     */
    public static putUser<S=any>(props:S){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.put(`${this.magPath}/user-allocation`, props);
    }


    /**
     * 회원에 Mag 일괄 등록
     * @param props 
     * @param userContext 
     * @returns 
     */
    public static putBulkMag<T=any>(props:T){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.put(`${this.userPath}/bulk-mag-allocation`, props);
    }

    /**
     * 회원에 Mag 등록
     * @param props 
     * @param userContext 
     * @returns 
     */
    public static putMag<T=any>(props:T){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.put(`${this.userPath}/mag-allocation`, props);
    }
}

/** 즐겨찾기 관리 */
class Bookmark extends ServiceComponent {
    static path="/user/mag/bookmark";

    /** 즐겨찾기 목록 조회 */
    public static getList(){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.get<MediaResponseType<BookmarkResType>>(this.path);
    }

    /** 즐겨찾기 목록 수정 */
    public static put<T=BookmarkPutType>(body:T, mediaType?:string){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.put(this.path,body);
    }
}

class Notification extends ServiceComponent {
    static path="/noti";

    /** 사용자 알림 설정 정보 조회 */
    public static get<T=any>(){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.get<T>(`${this.path}/user`);
    }

    /** 사용자 알림 설정 수정 */
    public static put<T=any>(props:T){
        return Utils.apiRequestValidation({useAccount:false}) ||AdminServer.put(`${this.path}/user`, props);
    }

    /** 사용자 알림 설정 초기 등록 */
    public static init<T=any>(id:number){
        return Utils.apiRequestValidation({useAccount:false}) || AdminServer.post(`${this.path}/default/userId/${id}`);
    }
}


export default class AdminService extends Service{
    /** 광고주 목록 반환 */
    public static getMyAccountGroups():Promise<EstimateAccountGroupType[]>{
        return AdminServer.get<AdminResType<EstimateResType>>(`/user/mag/estimate`,{})
            .then((v)=>this.convertAccountGroup(v.data?.result));
    }

    /** 광고주 - 사용자 목록 반환 */
    public static getAccountUsers():Promise<EstimateUserResType[]>{
        return AdminServer.get<AdminResType<EstimateUserResType>>(`/user/mag/estimate/user`,{})
            .then((res)=>res.data?.result || []);
    }

    /** 광고주 - 전체목록 반환 - Notification에서 사용됨 */
    public static getAccountAll():Promise<EstimateMagType[]>{
        return AdminServer.get<AdminResType<EstimateMagType>>(`/media-account-group/mag/all-names`,{})
            .then((res)=>res.data?.result || []);
    }

    static convertAccountGroup(data?:EstimateResType[]):EstimateAccountGroupType[]{
        const result:EstimateAccountGroupType[] = [];
           
        data = data?.sort((a,b)=>`${a.magName}|${a.name}` < `${b.magName}|${b.name}` ? -1 : 1)  //광고주 정렬
        let tmpAG:EstimateAccountGroupType|undefined = undefined;
        data?.forEach((v)=>{
            if( !tmpAG || tmpAG.magId!==v.magId ){
                tmpAG = {...v, clients:[]};
                result.push(tmpAG);
            }
            tmpAG.clients.push({...v});
        });
        return result;
    }

    /** 공통코드 관련 처리 */
    public static code = Code;

    /** 자산목록 관련 처리 */
    public static account = Account;

    /** 회원관리 */
    public static user = User;

    /** 광고주관리 */
    public static accountGroup = AccountGroup;

    /** 관리 권한 */
    public static roleGroup = RoleGroup;

    /** 즐겨찾기 관리 */
    public static bookmark = Bookmark;

    /** 알림 설정 */
    public static notification = Notification;
}




