import KakaoHelperComponent from './KakaoHelperComponent';

/** 카카오 비즈채널 */
export default class BizChannelHelper extends KakaoHelperComponent{
    static path="/media/biz-channels"; //기본 Path
    static simple="/media/simple-biz-channels"; //simple(combo) Path
    static stat='/performance/biz-channels';    //실적데이터
    static performances='/performances/biz-channels';    //실적데이터
    static downloadPath='/api/kakaokeywordad/xlsxdnld/bizchannels';
}

