import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActiveNew, KTSVG, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import styles from '../../../../../app/pages/modules/modify/modify.module.scss';

type Props = {
    to: string;
    title: string|React.ReactNode;
    icon?: string;
    fontIcon?: string;
    hasBullet?: boolean;
    badge?:string;
    className?:string;
}

const SidebarTreeItem: FC<Props & WithChildren> = (props) => {
    const {pathname} = useLocation()
    const isActive = checkIsActiveNew(pathname, props.to)
    const {config} = useLayout()
    const {app} = config

    return <div className='menu-item'>
        <Link className={clsx('menu-link without-sub', {active: isActive}, props.className)} to={props.badge ? '#' : props.to}>
            {props.hasBullet && (
                <span className='menu-bullet'>
                    <span className='bullet bullet-dot'></span>
                </span>
            )}
            {(props.icon || props.fontIcon) && (
                <span className='menu-icon'>
                    {' '}
                    {props.icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
                        <KTSVG path={props.icon} className='svg-icon-2' />
                    )}
                    {props.fontIcon && ( //app?.sidebar?.default?.menu?.iconType === 'font' && (
                        <i className={clsx('bi fs-3', props.fontIcon)}></i>
                    )}
                    </span>
                )}
                <span className='menu-title'>{props.title}</span>
                
                {props.badge && <span className={`badge badge-light ${styles.badge} ${styles.badge_light}`}>{props.badge}</span>}
        </Link>
        {props.children}
    </div>
}

export {SidebarTreeItem}
