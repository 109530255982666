import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NaverDbidService } from "../../../common/services";
import { TaskDashboardResType } from "../../../common/services/models/d-bid/TaskTypes";
import { useUserContext } from "../../../contexts/UserContext";
import MediaTypeIcon from "../../modules/symbol/MediaTypeIcon";
import styles from '../style.module.scss';

const DBidList:React.FC = ()=>{
    const userContext = useUserContext();
    const [useData, setUseData] = useState<TaskDashboardResType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const dataLoad = ()=>{
        setLoading(true);
        NaverDbidService.Task.dashboard().then((res)=>{
            if(res.status===200){
                const tmp:TaskDashboardResType[] = res.data.result;
                setUseData(tmp.sort((a,b)=>a.completeDateTime>b.completeDateTime ? -1 : 1));    //최적화 완료일시 내림차 정렬
            }
        })
        .catch((e)=>{ 
            userContext.alertMessage.add({
                variant:"danger",
                title:"D-Bid 운영현황 데이터 조회 오류",
                body:<>"D-Bid 운영현황 데이터 데이터 조회 중 오류가 발생했습니다.<br />{NaverDbidService.ErrorMessage(e)}</>
            });
        })
        .finally(()=>{ setLoading(false); });
    }

    // eslint-disable-next-line
    useEffect(()=>{dataLoad(); },[]);

    return <div className="scroll h-200px">
        <table className={styles.table}>
            <thead>
                <tr className="bg-body">
                    <th className='text-left'>광고주</th>
                    <th className='text-left'>자산</th>
                    <th className='text-left'>작업명</th>
                    <th>최적화 완료일</th>
                    <th>보기</th>
                </tr>
            </thead>
            <tbody>
                {loading && <tr><td className="text-center h-150px" colSpan={6}><Spinner animation="border" variant={"secondary"} role='status' /></td></tr>}
                {!loading && !useData?.length && <tr><td className="text-center h-150px" colSpan={6}>운영중인 D-Bid 작업이 없습니다.</td></tr>}
                {!loading && useData?.map((v,index)=><tr key={index} className="text-center">
                    <td className='text-left'>{v.magName}</td>
                    <td className='text-left'><MediaTypeIcon code={"NAVER"} label={v.clientName} viewLabel={true} /></td>
                    <td className="text-left">{v.taskName}</td>
                    <td>{v.completeDateTime.formatDateToString('yyyy.MM.dd')}</td>
                    <td>
                        <Link
                            to={'/pages/naver/d-bid/task'}
                            onClick={()=>{userContext.userSelect.accountState?.select(v.magId, "NAVER", v.clientId.toString())}}
                            className="text-hover-primary bg-hover-light rounded h-40px p-2 text-center"
                        >
                            <i className="bi bi-arrow-right-square" />
                        </Link>
                    </td>
                </tr>)}
            </tbody>
        </table>
    </div>
}
export default DBidList;