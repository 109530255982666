import { useState } from "react";
import { AdGroupSimpleResType } from "../services/models/naver/AdGroupTypes";
import { CampaignSimpleResType } from "../services/models/naver/CampaignTypes";
import { NaverService } from "../services";
import { MediaResResultType, TreeResDataType } from "../services/models/ResponseTypes";
import NaverCode from "../../modules/code/NaverCode";

export type NaverTreeResType = TreeResDataType<CampaignSimpleResType,AdGroupSimpleResType>;

export interface NaverTreeStateType{
    set: (data:NaverTreeDataType)=>void;
    load: ()=> Promise<NaverTreeDataType>;
    data: NaverTreeDataType;
}

/** 네이버 전용 - 파워링크, 쇼핑검색 데이터 */
export interface NaverTreeDataType{
    powerlink:TreeResDataType<CampaignSimpleResType,AdGroupSimpleResType>[];
    shopping:TreeResDataType<CampaignSimpleResType,AdGroupSimpleResType>[];
}

const NaverTreeStorage = class {
    public state = ():NaverTreeStateType=>{
        const [data, setData] = useState<NaverTreeDataType>({powerlink:[], shopping:[]});
        const load = async ()=>{ return this.load().then((data)=>{setData(data); return data;}); }
        const set = (data:NaverTreeDataType)=>{ setData(data); }
        return {set,load, data};
    }

    /** 데이터 로딩 */
    private async load():Promise<NaverTreeDataType>{
        const campaign:CampaignSimpleResType[] = await NaverService.campaign.getSimpleList<MediaResResultType<CampaignSimpleResType>>({})
        .then(((res) => {
            const data:CampaignSimpleResType[] = res.data.result.items || [];
            return data.sort((a,b)=>a.campaignName < b.campaignName ? -1 : 1 );
        }));
    
        const adgroup:AdGroupSimpleResType[] = await NaverService.adgroup.getSimpleList<MediaResResultType<AdGroupSimpleResType>>({})
        .then(((res) => {
            const data:AdGroupSimpleResType[] = res.data.result.items || [];
            return data.sort((a,b)=>a.adGroupName < b.adGroupName ? -1 : 1 );
        }));

        const powerlink:TreeResDataType<CampaignSimpleResType,AdGroupSimpleResType>[] = campaign
            .filter((v)=>v.campaignType===NaverCode.data.type.campaign.WEB_SITE.value)
            .map((v)=>({
                data:v,
                children: adgroup.filter((v2)=>v.campaignId===v2.campaignId),
            }));

        const shopping:TreeResDataType<CampaignSimpleResType,AdGroupSimpleResType>[] = campaign
            .filter((v)=>v.campaignType===NaverCode.data.type.campaign.SHOPPING.value)
            .map((v)=>({
                data:v,
                children: adgroup.filter((v2)=>v.campaignId===v2.campaignId),
            }));
        return {powerlink, shopping};
    }
}

export default NaverTreeStorage;