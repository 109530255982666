import React, { CSSProperties, useEffect, useState } from 'react';
import { OptionType } from '../../../../modules/types/Types';
import UserSelect from '../../../common/helpers/UserSelect';
import Code from '../../../modules/code/Code';
import { DropDownRadio } from '../../modules/dropdown';

export interface DeviceTypeButtonProps{
    onChange?:(value:string, item?:OptionType)=>void;
    className?:string;
    style?:CSSProperties;
}

const DeviceTypeButton:React.FC<DeviceTypeButtonProps> = (props) => {
    const [value, setValue]= useState<string>('');
    const onChange = (value:string, item?:OptionType)=>{
        setValue(value);
        UserSelect.deviceType.set(value.split(','));
        props.onChange && props.onChange(value, item);
    }
    useEffect(()=>{
        const types:string[] = UserSelect.deviceType.get() || ['P','M'];
        setValue(types.join(','));
        //props.onChange && props.onChange(types.join(',')); - state stack 오류발생
    },[]);

    return (<DropDownRadio name='diveceType' 
        className={props.className}
        style={props.style}
        menu={{title:'디바이스 구분', className:'w-150px p-2'}}
        value={value}
        options={Code.getOptions(Code.base.deviceTypeButton)}
        onChange={onChange}
        formatter={(v)=>`디바이스 : ${v.label.includes("합산") ? "합산" : v.label}`}
        radio={{className:'my-2'}}
    />);
}


export default DeviceTypeButton;