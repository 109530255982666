import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { FormSelect, InputGroup } from "react-bootstrap";
import { OptionType } from "../../../modules/types/Types";
import UserSelect from "../../common/helpers/UserSelect";
import AdploButton from "../components/buttons/AdploButton";
import AdPloInput from "../components/input/AdPloInput";
import MediaTypeIcon from "../modules/symbol/MediaTypeIcon";

export interface ExplorerSearchBarProps{
    level?:string;
    search?:string;
    onSearch:(level:string, search:string, from:number)=>void;
    className?:string;
    options:OptionType[];
}

const ExplorerSearchBar:React.FC<ExplorerSearchBarProps> = (props)=>{
    const [level, setLevel] = useState<string>('');
    const [search, setSearch] = useState<string>('');
    const userSelect = UserSelect.account.get();

    useEffect(()=>{
        setLevel(props.level || props.options?.[0].value || '');
        setSearch(props.search || '');
    // eslint-disable-next-line
    }, [props.level, props.search]);

    const onSearch = ()=>{
        props.onSearch(level, search, 0);
    }

    return (<div className={clsx('d-flex flex-stack', props.className)}>
        <MediaTypeIcon className="me-1" code={userSelect?.accountClient?.mediaType} viewLabel={false}
            iconStyle={{width:'2em', height:'2em', borderRadius:'2em', margin:0}} />
        <FormSelect value={level} className='form-control p-2 ps-3' autoComplete='off' onChange={(e)=>setLevel(e.target.value)} style={{width:'130px', height:'35px'}}>
            {props.options.map((v, index)=><option key={index} value={v.value}>{v.label}</option>) }
        </FormSelect>
        <InputGroup className='ms-2'>
            <AdPloInput
                maxLength={128}
                value={search} 
                onKeyDown={(e)=>{ if(e.key==='Enter')onSearch(); }}
                onChange={(e)=>setSearch(e.target.value)}
            />
            <AdploButton variant='' className='me-1 bg-secondary' onClick={() =>{onSearch();}}><i className='bi bi-search'/></AdploButton>
        </InputGroup>
    </div>);
}


export default ExplorerSearchBar;