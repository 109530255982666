import axios, { AxiosInstance, AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';
import qs from 'qs';
import AuthService from '../services/AuthService';
import UserSelect from '../helpers/UserSelect';
import { EqInExSelectOptions, InetrSectSelectOptions, LowerEqInExSelectOptions, LowerInetrSectSelectOptions, MatchBitOptions } from '../../modules/code/Options';
import { RequestFilterType, RequestOrderType } from '../services/models/RequestTypes';
import Config from '../conf/Config';

/* 기본정보 통신용 API - iBase(naver)-backend */
interface AxiosProps{
    baseURL?:string;
    useAuthorization?:boolean;
    useMediaCode?:boolean;      //매체코드 사용여부 - 기본 : true
}
export default class Axios {

    public static create({baseURL='', useAuthorization=true}:AxiosProps):AxiosInstance{
        const server = axios.create({
            baseURL: baseURL,
            headers: {'X-Requested-With': 'XMLHttpRequest'},
            withCredentials : true,
        });

        server.interceptors.request.use((config) => {
            config.headers = this.useAccessToken(useAuthorization, config.headers);
            // config = {headers:{}, url:'', ...config};
        
            //선택 광고주정보 첨부 & Method에 따라 데이터 첨부 변경
            config = this.useClientId(config);
            config = this.useAccount(config);
            config = this.useFilter(config);    //필터사용여부에 따라 필터 첨부
            config = this.useOrder(config);

            // if( ['get', 'post', 'put', 'delete'].includes(config.method || '') && !config.paramsSerializer ){
                 //SSEM 사용할 경우 파라미터 추가
                if(Config.app.SSEM.enable){
                    const userInfo = AuthService.storage.get()?.userInfo;
                    config.params = {...config.params, ssemSession: userInfo?.user_name}; 
                }
                config.paramsSerializer = {serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat', encodeValuesOnly: true })};
            // }

            return config;
        }, (error) => {
            return Promise.reject(error);
        });
        
        server.interceptors.response.use((response) => {
            if (response.status === 302 && response.headers.location) { return response;}
            return response;
        }, (error) => {
            if (error.response?.status && error.response?.status === 401) {
                //// 토큰 체크 - refresh
                //// 무한루프 방지 필요
                return error.response;
            }
            return Promise.reject(error);
        });
        return server;
    }

    //액세스 토큰 탑재여부
    static useAccessToken(use:boolean, headers:AxiosRequestHeaders):AxiosRequestHeaders{
        const info = AuthService.storage.get();
        if(use && !headers?.Authorization){
            headers.Authorization = `Bearer ${info?.token?.access_token}`;
        }
        if(headers.Authorization === false){ delete headers.Authorization; } //미사용 헤더 제거
        return headers;
    }

    //광고주 정보 첨부여부 처리
    static useAccount(config:InternalAxiosRequestConfig<any>):InternalAxiosRequestConfig<any>
    {
        if(config.headers?.useAccount){   //account 정보 사용
            const info = UserSelect.account.get();
            if(!info?.accountGroup?.magId || !info?.accountClient?.clientId){
                throw Object.assign(new Error('광고주 계정을 선택하지 않았습니다!'), { code: 500 });
            }

            //// 실광고주 수정 차단 ////
            // if(config.method!=='get' && ['1483591','302768', '1502614'].includes(info.accountClient.clientId)){
            //     throw new Error("[쿠쿠홈시스 주식회사, 매일유업] 계정에 대한 Method는 'GET'만 허용합니다!");
            // }
            //Path에 계정정보 추가
            config.url = `/account-group-id/${info.accountGroup.magId}/client-id/${info.accountClient.clientId}${config.url}`;
        }
        delete config.headers?.useAccount;  //불필요한 값 제거
        return config;
    }

    //광고주 정보 첨부여부 처리
    static useClientId(config:InternalAxiosRequestConfig<any>):InternalAxiosRequestConfig<any>
    {
        if(config.headers?.useClientId){   //account 정보 사용
            const info = UserSelect.account.get();
            if(!info?.accountGroup?.magId || !info?.accountClient?.clientId){
                throw Object.assign(new Error('광고주 계정을 선택하지 않았습니다!'), { code: 500 });
            }

            //// 실광고주 수정 차단 ////
            // if(config.method!=='get' && ['1483591','302768', '1502614'].includes(info.accountClient.clientId)){
            //     throw new Error("[쿠쿠홈시스 주식회사, 매일유업] 계정에 대한 Method는 'GET'만 허용합니다!");
            // }
            //Path에 계정정보 추가
            config.url = `/client-id/${info.accountClient.clientId}${config.url}`;
        }
        delete config.headers?.useClientId;  //불필요한 값 제거
        return config;
    }

    //필터정보 정리
    static useFilter(config:InternalAxiosRequestConfig<any>):InternalAxiosRequestConfig<any>{
        if(!config.params?.filters){ return config; }
        config.params.filters = Axios.filterConvert(config.params.filters);
        // .filter((v:RequestFilterType)=>v.value?.length || v.values?.length).map((v:RequestFilterType)=>{
        //     //배열형 값 변형
        //     const tmp:RequestFilterType = {...v};
        //     if([...EqInExSelectOptions, ...InetrSectSelectOptions].some((v1)=>v1.value===tmp.operation)){   // In, RegExp, NotIn, LikeIn
        //         if(tmp.operation === 'RegExp' && tmp.values?.length){       // RegExp
        //             tmp.value = tmp.values.join("|");
        //             delete tmp.values;
        //         }else if(!tmp.values?.length){
        //             tmp.values = tmp.value?.trim().split("\n");
        //             delete tmp.value;
        //         // }else{
        //         //     tmp.value = tmp.values?.join("\n").trim();
        //         }
        //     }
        //     return this.encode(tmp);
        // });
        return config;
    }

    /**
     * filter 구조에서 value와 values를 구분하여 정리합니다.
     * @param filters 
     * @param extension : true인 경우 value와 values 모두 활성화, 그 외는 불필요항목 제거 
     * @returns 
     */
    static filterConvert(filters?:RequestFilterType[], extension?:boolean):string[]|undefined{
        return filters?.filter((v:RequestFilterType)=>v.value?.length || v.values?.length)
        .map((v:RequestFilterType)=>{
            //배열형 값 변형
            const tmp:RequestFilterType = {...v};
            if([...EqInExSelectOptions, ...InetrSectSelectOptions, ...LowerEqInExSelectOptions, ...LowerInetrSectSelectOptions]
                .some((v1)=>v1.value===tmp.operation)
            ){   // In, RegExp, NotIn, LikeIn
                if(tmp.operation === 'RegExp' && tmp.values?.length){       // RegExp
                    tmp.value = tmp.values.join("|");
                    extension && delete tmp.values;
                }else if(!tmp.values?.length){
                    tmp.values = tmp.value?.trim().split("\n");
                    extension && delete tmp.value;
                }
            }
            if([...MatchBitOptions, ]
                .some((v1)=>v1.value===tmp.operation)
            ){   // 비트연산 처리
                tmp.values = tmp.value?.trim().split("\n") || [];
                tmp.value = tmp.values.reduce((pre:number, v:string)=>pre+Math.pow(2,v.toNumber()),0).toString();
                !extension && delete tmp.values;
            }

            return this.encode(tmp);
        });
    }


    //필터정보 정리
    static useOrder(config:InternalAxiosRequestConfig<any>):InternalAxiosRequestConfig<any>{
        //다양한 필터위치
        if(!config.params?.orders){ return config; }
        const orders:RequestOrderType[] = Array.isArray(config.params.orders) ? config.params.orders : [config.params.orders];
        config.params.orders = orders.filter((v)=>v.key && v.direction).map((v:RequestOrderType)=>this.encode(v));
        return config;
    }

    static encode(data:any){
        return encodeURIComponent(JSON.stringify(data));
    }
}
