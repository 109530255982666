import CustomCard from "../../modules/card/CustomCard";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";

const ReportNone:React.FC = ()=><CustomCard className="text-center fs-3 fw-bolder text-gray-400 p-10">
    <div>즐겨찾기(★)에서 성과를 확인할 광고주를 등록해 주세요.</div>
    <img src={toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')} alt="" className="mw-100 h-200px h-sm-325px"/>   
</CustomCard>

export default ReportNone;



