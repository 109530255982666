/* eslint-disable jsx-a11y/anchor-is-valid */
import ApexCharts, { ApexOptions } from 'apexcharts';
import { format } from "date-fns";
import { CSSProperties, useEffect, useRef } from 'react';
import { getCSS } from '../../../../_metronic/assets/ts/_utils';
import { ChartConfigType } from '../../modules/charts/Types';


export interface LineDoubleChartsProps{
    chart?: ChartConfigType;
    slave?: ChartConfigType;
    target?: ChartConfigType;
    className?:string;
    style?:CSSProperties;
}

const LineDoubleCharts:React.FC<LineDoubleChartsProps> = (props) => {
    const chartRef = useRef<HTMLDivElement | null>(null);
    const refreshChart = () => {
        if (!chartRef.current) { return; }
        const height = parseInt(getCSS(chartRef.current, 'height'));
        const chart = new ApexCharts(chartRef.current, getChartOptions(height, props));
        if (chart) { chart.render(); }
        return chart;
    }

    useEffect(() => {
        const chart = refreshChart();
        return () => { chart && chart.destroy(); }
    // eslint-disable-next-line
    }, [chartRef, props.chart, props.slave]);

    return <div ref={chartRef} className={props.className} style={props.style} />
}

export default LineDoubleCharts;

const _tickCount=5;      //기준값
const getChartOptions = (height: number, props:LineDoubleChartsProps): ApexOptions => {
    const labelColor = 'var(--bs-gray-500)';
    const borderColor = 'var(--bs-gray-400)';
    const baseColor = props.chart?.color || 'var(--bs-primary)';// getCSSVariableValue('--bs-primary')
    const secondaryColor = props.slave?.color || 'var(--bs-info)';// getCSSVariableValue('--bs-info')
    // const dataCount = Math.max(props.barChart.data.length, props.lineChart.data.length);
    // console.log(dataCount, dataCount.gcd(5));
    // console.log(dataCount, dataCount.gcd(6));
    // console.log(dataCount, dataCount.gcd(7));
    // console.log(dataCount, dataCount.gcd(8));
    // console.log(dataCount, dataCount.gcd(9));
    // const tickAmount = Math.min( Math.max(props.barChart.data.length, props.lineChart.data.length), 10);
    const tmp:number[] = props.chart?.data?.filter((v)=>v[1]!==null).map(v=>v[1] as number) || [0,100];
    const data:number[] = tmp.length>0 ? tmp : [0,100];
    const min:number = Math.min(...data);
    const max:number = Math.max(...data);
    const step:number = Math.ceil((max-min) / (_tickCount+1) / 10) * 10 || 10;    //최소 10\

    return {
        series: [
            { name: props.chart?.title, data: props.chart?.data || [], },
            { name: props.slave?.title, data: props.slave?.data || [], }, //bar, line, area, column, mixed, timeline
            { name: props.target?.title, data: props.target?.data || [], },
        ],
        chart: {
            // fontFamily: 'inherit',
            stacked: false,
            height: height || 350,
            toolbar: { show: false, offsetX:0, offsetY:0, },
            zoom: { enabled: true },
            background: "transparent",
            type:'line',
        },
        legend: { show: true, position: 'top', offsetY: 8, labels:{colors:"var(--bs-text)"}},//범례
        dataLabels: {
            enabled: false,
            enabledOnSeries: undefined,
            formatter: (v:string|number|number[], opts):string=>`${v} 회`,
            textAnchor: 'middle',
            distributed: false,
            offsetX: 0,
            offsetY: 0,
            style: {
                // fontSize: '1em',
                // fontFamily: 'Helvetica, Arial, sans-serif',
                // fontWeight: 'bold',
                // colors: undefined
            },
            background: {
                enabled: true,
                foreColor: '#fff',
                padding: 4,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#fff',
                opacity: 0.9,
                dropShadow: {
                    enabled: false,
                    top: 1,
                    left: 1,
                    blur: 1,
                    color: '#000',
                    opacity: 0.45
                }
            },
            dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                color: '#000',
                opacity: 0.45
            }
        },
        stroke: { 
            curve: 'straight',
            lineCap: undefined,
            show: true,
            width: 3,
            colors: [secondaryColor, baseColor, '#ff6600'],
        },
        markers: {
            size: 0,
            colors: baseColor,
            strokeColors: baseColor,
            strokeWidth: 3,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: "circle",
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: { size: 4, sizeOffset: 2 }
        },
        xaxis: {
            axisBorder: { show: false, },
            axisTicks: {
                show: true,
                // borderType?: string
                // color?: string
                // height?: number
                // offsetX?: number
                // offsetY?: number
            },
            type: 'datetime',
            // min: startDate.getTime(),
            // max: addDays(endDate,-1).getTime(),
            // tickAmount: differenceInDays(endDate, startDate) > 10 ? 10 : 'dataPoints', //좌표 표시갯수
            // tickAmount: 'dataPoints',//tickAmount >= 10 ? tickAmount : 'dataPoints',   //tick 제한을 하면 날짜 포인트가 틀어짐
            labels: {
                /**
                * Allows users to apply a custom formatter function to x-axis labels.
                *
                * @param { String } value - The default value generated
                * @param { Number } timestamp - In a datetime series, this is the raw timestamp 
                * @param { object } contains dateFormatter for datetime x-axis
                */
                // formatter:(value:string, timestamp:number)=>format(timestamp, "MM/dd"),  //실제 타임하고 안맞음
                format: 'HH:mm',
                style: { colors: labelColor, fontSize:'0.9em' },
                datetimeUTC: false,
            },
            tooltip: { enabled: false, }
        },
        yaxis: [
            {
                labels: { style: { colors: secondaryColor, fontSize: '0.9em', }, offsetX:-15 },
                title:{
                    text: `${props.chart?.title} ${props.chart?.unit ? `(${props.chart?.unit})` : ''}`,
                    offsetX:-10,
                    style:{ color: secondaryColor, fontSize: '1em' },
                },
                stepSize: step,
                // tickAmount: 5, //좌표 표시갯수
            },
            {
                labels: { style: { colors: baseColor, fontSize: '0.9em',}, offsetX:10 },
                opposite:true,
                title:{
                    text: `${props.slave?.title} ${props.slave?.unit ? `(${props.slave?.unit})` : ''}`,
                    offsetX:15,
                    style:{ color: baseColor, fontSize: '1em' }
                },
                tickAmount: 5,
                reversed: true,
                min:1,
                max:15,
            },
            {
                show: false,
                reversed: true,
                min:1,
                max:15,
            }
        ],
        states: {
            normal: { filter: { type: 'lighten',  value: 0.03,} }, //'lighten', 'darken', 'none'
            hover: { filter: { type: 'lighten', value: 0.15, } },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: { type: 'darken', value: 0.5, }
            },
        },
        tooltip: {
            style: { fontSize: '0.9em', },
            x: { formatter: (v)=>format(v, "yyyy-MM-dd HH:mm"), },
            y: [
                { formatter: (v)=>`${v} ${props.chart?.unit ? `(${props.chart?.unit})` : ''}`, },
                { formatter: (v)=>`${v} ${props.slave?.unit ? `(${props.slave?.unit})` : ''}`, }
            ],
            followCursor:true
        },
        colors:[secondaryColor, baseColor, '#ff6600'],
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: { lines: { show: true, }, },
            padding: { top: 0, right:15, bottom: 0, left: -10, },
        },
    }
}
