import { FilterOptionType } from '../../modules/table/TableType';
// import styles from './formater.module.scss';
import {
    AdExtensionResType, AdExtensionType_CALCULATION, AdExtensionType_CATALOG_BRAND_MESSAGE, AdExtensionType_CATALOG_DETAIL,
    AdExtensionType_CATALOG_IMAGE, AdExtensionType_CATALOG_MOVIE, AdExtensionType_CATALOG_RELEASE_DATE, AdExtensionType_DESCRIPTION,
    AdExtensionType_DESCRIPTION_EXTRA, AdExtensionType_HEADLINE, AdExtensionType_IMAGE_SUB_LINKS, AdExtensionType_NAVER_BLOG_REVIEW,
    AdExtensionType_NAVER_TV_VIDEO, AdExtensionType_PRICE_LINKS, AdExtensionType_PROMOTION, AdExtensionType_SUB_LINKS
} from '../../../common/services/models/naver/AdExtensionTypes';
import { AdResType, AdType_BRAND_THUMBNAIL, AdType_SHOPPING_BRAND_AD, AdType_SHOPPING_PRODUCT_AD, AdType_TEXT_45 } from '../../../common/services/models/naver/AdTypes';
import { BusinessChannelInfoType, BusinessChannelResType, BusinessInfoType, BusinessInfoType_ADDRESS, BusinessInfoType_PHONE } from '../../../common/services/models/naver/BusinessChannelTypes';
import NaverCode from '../../../modules/code/NaverCode';
import Formatter from '../../modules/Formatter';

export default class NaverFormatter {
    //소재 Formater
    public static AdFormater(value:any, row?:AdResType):string|React.ReactNode {
        switch(row?.type){
            case NaverCode.data.type.ad.TEXT_45.value : {
                const data = value as AdType_TEXT_45;
                return data ? (<ul>
                    {data?.headline && <li>제목 : {data?.headline} </li>}
                    {data?.description && <li>설명 : {data?.description}</li>}
                </ul>) : value;
            }
            case NaverCode.data.type.ad.CATALOG_AD.value :{
                const data = row?.referenceData;
                return data ? (<div className='d-flex'>
                    <div><img src={data?.imageUrl} style={{maxWidth:'80px', maxHeight:'80px'}} alt={data?.productName} /></div>
                    <ul>
                        {data.productName && <li>상품명 : {data.productName} </li>}
                        {data.productTitle && <li>상품제목 : {data.productTitle}</li>}
                        {data.lowPrice && <li>최저가 : {data.lowPrice.addComma()}</li>}
                        {data.mobilePrice && <li>모바일 : {data.mobilePrice.addComma()}</li>}
                        {data.maker && <li>제조사 : {data.maker}</li>}
                        {data.brand && <li>브랜드 : {data.brand}</li>}
                        {data.mallCount && <li>판매처 : {data.mallCount.addComma()}</li>}
                        {data.dvlryFeeCont === "0" && <li>무료배송</li>}
                        {data.brand && <li>브랜드 : {data.brand}</li>}
                    </ul>
                </div>) : value;
            }

            case NaverCode.data.type.ad.SHOPPING_BRAND_IMAGE_THUMBNAIL_AD.value :{
                const data = row?.adInfo as AdType_BRAND_THUMBNAIL;
                return data ? (<div className='d-flex'>
                    <div><img src={`https://searchad-phinf.pstatic.net${data?.image}?type=f80_80`} style={{maxWidth:'80px', maxHeight:'80px'}} alt={data?.headline} /></div>
                    <ul>
                        {data.headline && <li>{data.headline} </li>}
                        {data.description && <li>{data.description}</li>}
                        {data.extraDescription && <li>{data.extraDescription}</li>}
                    </ul>
                </div>) : value;
            }

            case NaverCode.data.type.ad.SHOPPING_BRAND_IMAGE_BANNER_AD.value :{
                const data = row?.adInfo as AdType_BRAND_THUMBNAIL;
                return data ? (<div className='d-flex'>
                    <div><img src={`https://searchad-phinf.pstatic.net${data?.image}?type=f80_80`} style={{maxWidth:'80px', maxHeight:'80px'}} alt={data?.headline} /></div>
                    <ul>
                        {data.headline && <li>{data.headline} </li>}
                        {data.description && <li>{data.description}</li>}
                    </ul>
                </div>) : value;
            }

            case NaverCode.data.type.ad.SHOPPING_BRAND_AD.value :{
                const data = row?.adInfo as AdType_SHOPPING_BRAND_AD;
                return data ? (<div className='d-flex'>
                    <div className='me-3'>
                        <img src={`https://searchad-phinf.pstatic.net${data?.logoImage}?type=f80_80`} style={{maxWidth:'80px', maxHeight:'80px'}} 
                        alt={data?.brandDescription} />
                    </div>
                    <ul>
                        <li>{data.brandDescription && data.brandDescription}</li>
                    </ul>
                </div>) : value;
            }

            // 유형 미표시 타입 - SHOPPING_PRODUCT_AD
            case NaverCode.data.type.ad.SHOPPING_PRODUCT_AD.value :{
                const data = row?.referenceData as AdType_SHOPPING_PRODUCT_AD;
                return data ? (<div className='d-flex'>
                    <div><img src={data?.imageUrl} style={{maxWidth:'80px', maxHeight:'80px'}} alt={data?.productTitle} /></div>
                    <ul>
                        {data.productTitle && <li>{data.productTitle} </li>}
                        {data.lowPrice && <li>PC : {data.lowPrice?.addComma()}{data.priceUnit==='KRW' && '원'}</li>}
                        {data.mobilePrice && <li>모바일 : {data.mobilePrice?.addComma()}{data.priceUnit==='KRW' && '원'}</li>}
                        {!data.dvlryFeeCont && <li>무료배송</li>}
                    </ul>
                </div>) : value;
            }

            default : { return <>알수 없는 타입입니다! 관리자에게 문의바랍니다.</>}
        }
    }
    
    //확장소재 Formater
    public static AdExtensionFormater(
        value:string,
        row?:AdExtensionResType,
        index?:number,
        selectOption?:FilterOptionType[],
        bizchannels?:BusinessChannelResType<BusinessChannelInfoType|BusinessInfoType>[]
    ):string|React.ReactNode {

        //공통
        const pcBiz:BusinessChannelResType<BusinessChannelInfoType|BusinessInfoType>|undefined = (bizchannels || []).find((v)=>v.businessChannelId===row?.pcChannelId);
        const moBiz:BusinessChannelResType<BusinessChannelInfoType|BusinessInfoType>|undefined = (bizchannels || []).find((v)=>v.businessChannelId===row?.mobileChannelId);

        switch(row?.type) {
            case NaverCode.data.type.adExtension.SUB_LINKS.value : {
                let num=0;
                const data = row?.adExtension as AdExtensionType_SUB_LINKS[];
                return (
                    Array.isArray(data) && data?.map((v:AdExtensionType_SUB_LINKS, index)=>(
                        <div className="adextention_list" key={index}>
                            <span>서브링크 {++num}</span>    
                            <ul style={{margin:0}}>
                                <li>링크제목 : {v.name}</li>
                                <li>연결URL : {Formatter.UrlHiperLinkFormatter(v.final)}</li>
                            </ul>
                        </div>
                        )
                    )
                );
            }
                
            case NaverCode.data.type.adExtension.POWER_LINK_IMAGE.value : 
            case NaverCode.data.type.adExtension.CATALOG_IMAGE.value : {
                const data = row?.adExtension as AdExtensionType_CATALOG_IMAGE;
                return (<img className="adExtensionListIMG" alt="썸네일" src={`https://searchad-phinf.pstatic.net${data?.imagePath}?type=THUMBNAIL`} />);
            }
            case NaverCode.data.type.adExtension.HEADLINE.value : {
                const data = row?.adExtension as AdExtensionType_HEADLINE;
                return (data?.headline);
            }
            case NaverCode.data.type.adExtension.DESCRIPTION.value : {  //홍보문구
                const data = row?.adExtension as AdExtensionType_DESCRIPTION;
                return (
                    <div className="adextention_list">  
                        <ul style={{margin:0}}>
                            {data?.heading && <li>제목 : {data?.heading} </li>}
                            {data?.description && <li>설명 : {data?.description}</li>}
                        </ul>
                    </div>
                );
            }
                
            case NaverCode.data.type.adExtension.DESCRIPTION_EXTRA.value : { //추가설명
                const data = row?.adExtension as AdExtensionType_DESCRIPTION_EXTRA;
                return (
                    <div className="adextention_list">  
                        <ul style={{margin:0}}>{data?.description && <li>설명 : {data?.description}</li>}</ul>
                    </div>
                );
            }
            case NaverCode.data.type.adExtension.PHONE.value : {
                const pc = pcBiz?.businessInfo as BusinessInfoType_PHONE;
                const mo = moBiz?.businessInfo as BusinessInfoType_PHONE;
                if(pc?.phone !== mo?.phone){
                    return (
                        <div className="adextention_list">  
                            <ul style={{margin:0}}>
                                <li>PC : {pc?.phone.formatPhone()}</li>
                                <li>MO : {mo?.phone.formatPhone()}</li>
                            </ul>
                        </div>);
                }else{
                    return (<span>{pc?.phone.formatPhone()}</span>);
                }
            }
            case NaverCode.data.type.adExtension.LOCATION.value : {
                const pc = pcBiz?.businessInfo as BusinessInfoType_ADDRESS;
                const mo = moBiz?.businessInfo as BusinessInfoType_ADDRESS;
                return (<>
                    { pcBiz?.channelKey !== moBiz?.channelKey &&
                        <div className="adextention_list">  
                            <ul style={{margin:0}}>
                                <li>PC : {this.locationFormater(pc)}</li>
                                <li>MO : {this.locationFormater(mo)}</li>
                            </ul>
                        </div>
                    }
                    { pcBiz?.channelKey === moBiz?.channelKey &&
                        this.locationFormater(pc)
                    }
                </>)
            }

            case NaverCode.data.type.adExtension.NAVER_BLOG_REVIEW.value : {
                const data = row?.referenceData as AdExtensionType_NAVER_BLOG_REVIEW;
                return (
                    <div>
                        <img 
                            className="adExtensionListIMG" 
                            alt="썸네일" 
                            src={`https://search.pstatic.net/common/?src=${encodeURIComponent(data?.thumbnailUrl)}&type=f80_80`}
                        />
                        <div className="adextention_list" style={{display:"inline-block"}}> 
                            <ul style={{margin:0}}>
                                <li>블로그제목 :  {data?.title}</li>
                                <li>연결URL : {Formatter.UrlHiperLinkFormatter(data?.postUrl)}</li>
                            </ul>
                        </div>
                    </div>
                );
            }
            case NaverCode.data.type.adExtension.PRICE_LINKS.value : {
                const data = row?.adExtension as AdExtensionType_PRICE_LINKS[];
                let num=0;
                    return (
                        Array.isArray(data) && data?.map((v:AdExtensionType_PRICE_LINKS, index:number)=>(
                        <div className="adextention_list" key={index}>
                            <span>가격링크 {++num}</span>
                            <ul style={{margin:0}}>
                                <li>가격제목 : {v.name}</li>
                                {v.price    && <li>가격 :   {v.price.addComma() } 원</li>}
                                {v.modifier && <li>가격수식 : {v.modifier}</li>}
                                {v.final    && <li>연결URL : {Formatter.UrlHiperLinkFormatter(v.final)}</li>}
                            </ul>
                        </div>
                    ))
                );
            }
            case NaverCode.data.type.adExtension.CALCULATION.value : {
                const data = row?.adExtension as AdExtensionType_CALCULATION;
                return (<a href={data?.final} target='blank' >{data?.final}</a>);
            }
            //서브 이미지링크
            case NaverCode.data.type.adExtension.IMAGE_SUB_LINKS.value : {
                const data = row?.adExtension as AdExtensionType_IMAGE_SUB_LINKS[];
                let num=0;
                return (
                     Array.isArray(data) && data?.map((v:AdExtensionType_IMAGE_SUB_LINKS, index:number)=>(
                        <div key={index}>
                            <img 
                                alt="서브이미지"
                                className="adExtensionListIMG" style={{verticalAlign:"unset"}} 
                                src={`https://searchad-phinf.pstatic.net${v.imagePath}?type=THUMBNAIL`}
                            />
                            <div className="adextention_list" style={{display:"inline-block"}}>
                                <span>이미지형 서브링크 {++num}</span>
                                <ul style={{margin:0}}>
                                    <li>링크이름 : {v.name}</li>
                                    <li>연결URL : {Formatter.UrlHiperLinkFormatter(v.final)}</li>
                                </ul>
                            </div>
                        </div>
                    ))
                );
            }
            //비디오
            case NaverCode.data.type.adExtension.NAVER_TV_VIDEO.value : {
                const data = row?.adExtension as AdExtensionType_NAVER_TV_VIDEO;
                return (<>
                    <img
                        alt="썸네일"
                        className="adExtensionListIMG"
                        style={{verticalAlign:"unset"}} 
                        src={`https://searchad-phinf.pstatic.net${data?.imagePath}?type=THUMBNAIL`}
                    />
                    <div className="adextention_list" style={{display:"inline-block"}}>
                        <ul style={{margin:0}}>
                            <li>설명 : {data?.description}</li>
                        </ul>
                    </div>
                </>)
            }
            
            //// 미검증 포맷
            //PROMOTION, CATALOG_PROMOTION
            case NaverCode.data.type.adExtension.PROMOTION.value : //추가홍보문구
            case NaverCode.data.type.adExtension.CATALOG_PROMOTION.value : {
                const data = row?.adExtension as AdExtensionType_PROMOTION;
                return (<div className="adextention_list">
                    <ul style={{margin:0}}>
                        <li>문구1 : {data?.basicText}</li>
                        {data?.additionalText && <li>문구2 : ${data?.additionalText}</li>}
                    </ul>
                </div>);
            }
            //CATALOG_RELEASE_DATE
            case NaverCode.data.type.adExtension.CATALOG_RELEASE_DATE.value : {
                const data = row?.adExtension as AdExtensionType_CATALOG_RELEASE_DATE;
                return (Formatter.ChangeDateFormat(data?.releaseDate, '출시일 : yyyy.MM.dd'));
            }
            
            //CATALOG_DETAIL
            case NaverCode.data.type.adExtension.CATALOG_DETAIL.value : {
                const data = row?.adExtension as AdExtensionType_CATALOG_DETAIL;
                return (data?.detail);
            }
            
            //CATALOG_BRAND_MESSAGE
            case NaverCode.data.type.adExtension.CATALOG_BRAND_MESSAGE.value : {
                const data = row?.adExtension as AdExtensionType_CATALOG_BRAND_MESSAGE;
                return (data?.text);
            }
            
            //CATALOG_MOVIE, CATALOG_EVENT
            case NaverCode.data.type.adExtension.CATALOG_MOVIE.value : 
            case NaverCode.data.type.adExtension.CATALOG_EVENT.value : {
                const data = row?.adExtension as AdExtensionType_CATALOG_MOVIE;
                 return (<img alt="썸네일" src={data?.thumbnail}>${data?.dittoId}</img>);
            }
            default : return undefined;
                // return (<div style={{color:"red"}}>* 해당 확장소재 유형은 준비되지 않았습니다. 관리자에게 문의 바랍니다! *</div>);
        }
    }

    public static locationFormater(channel:BusinessInfoType_ADDRESS) {
        return (
            <div className="adextention_list"> 
                <ul style={{margin:0}}>
                    <li>도로명 : {channel.roadNameAddress} {channel.roadNameExtendAddress}</li>
                    <li>지번 : {channel.lotNumberAddress} {channel.lotNumberExtendAddress}</li>
                </ul>
            </div>
        );
    }

    public static dailyBudgetFormatter(value:number, row?:any, index?:number){
        const sharedDailyBudget:number = row?.sharedBudgetId ? row.sharedDailyBudget : 0;
        const useDailyBudget:boolean = row?.useDailyBudget || false;
        return sharedDailyBudget > 0
            ? <>[공유] {sharedDailyBudget.addComma()}</>
            : (useDailyBudget && value>0 ? value.addComma() : <div className='text-center'>제한없음</div>);
    }
    public static dailyBudgetFilter(value:number, row?:any, index?:number){
        const sharedDailyBudget:number = row?.sharedBudgetId ? row.sharedDailyBudget : 0;
        const dailyBudget:number = row?.useDailyBudget ? value : 0;
        return sharedDailyBudget || dailyBudget || 0;
    }

    /** 현재입찰가 표시용 - 광고그룹 표시 - 쇼핑검색 : 미검증*/
    public static BidAmountFormatter(value:number, row?:{useGroupBidAmt:boolean}, index?:number){
        return <>{row?.useGroupBidAmt && <>[그룹]</>} {value?.addComma()}</>
    }
}


 