import clsx from "clsx";
import { useEffect, useState } from "react";
import { NaverService } from "../../../common/services";
import { RequestSearchType } from "../../../common/services/models/RequestTypes";
import { MediaResResultType } from "../../../common/services/models/ResponseTypes";
import { AdExtensionResKeys, AdExtensionResType } from "../../../common/services/models/naver/AdExtensionTypes";
import { AdResKeys, AdResType } from "../../../common/services/models/naver/AdTypes";
import { StatReportInfoType, StatReportResType } from "../../../common/services/models/naver/StatReportType";
import { useUserContext } from "../../../contexts/UserContext";
import AdminCode from "../../../modules/code/AdminCode";
import NaverCode from "../../../modules/code/NaverCode";
import { HiperLinkEllisis } from "../../../modules/layer/HiperLink";
import { ModifyViewerType } from "../../modules/modify/Modify";
import NaverFormatter from "../modules/NaverFormatter";


export interface ConvertStatInfoViewDataType extends Omit<ModifyViewerType,"title">{
    title: string|React.ReactNode;
}

export const convertStatInfoViewData = (level?:string, data?:StatReportInfoType):ConvertStatInfoViewDataType[]|undefined=>{
    switch(level){
        case NaverCode.data.options.ExplorerLevel.bizChannel.value : {
            return [
                // {title:'비즈채널명', value:tmp?.name},
                {title:'비즈채널 ID', value:data?.businessChannelId},
                {title:'비즈채널 유형', value: NaverCode.getLabel(NaverCode.data.explorer.bizchannel, data?.businessChannelTypeCode?.toString() || '')},
                {title:'콘텐츠 URL', value: <HiperLinkEllisis href={data?.businessChannelContents || ''} >{data?.businessChannelContents}</HiperLinkEllisis>},
            ];
        }
        case NaverCode.data.options.ExplorerLevel.campaign.value : {
            return [
                {title:'캠페인 ID', value:data?.campaignId},
                {title:'캠페인 유형', value: NaverCode.getLabel(NaverCode.data.explorer.campaign, data?.campaignTypeCode?.toString() || '')},
            ];
        }
        case NaverCode.data.options.ExplorerLevel.adGroup.value : {
            return [
                // {title:'광고그룹명', value:tmp?.adGroupName},
                {title:'광고그룹 ID', value:data?.adgroupId},
                {title:'광고그룹 유형', value: NaverCode.getLabel(NaverCode.data.explorer.adgroup, data?.adgroupTypeCode?.toString() || '')},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인 ID', value:data?.campaignId},
                {title:'캠페인 유형', value: NaverCode.getLabel(NaverCode.data.explorer.campaign, data?.campaignTypeCode?.toString() || '')},
            ];
        }
        case NaverCode.data.options.ExplorerLevel.keyword.value : {
            return [
                // {title:'키워드', value:tmp?.keyword},
                {title:'키워드 ID', value:data?.keywordId},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인 ID', value:data?.campaignId},
                {title:'캠페인 유형', value: NaverCode.getLabel(NaverCode.data.explorer.campaign, data?.campaignTypeCode?.toString() || '')},
                {title:'광고그룹명', value:data?.adgroupName},
                {title:'광고그룹 ID', value:data?.adgroupId},
                {title:'광고그룹 유형', value: NaverCode.getLabel(NaverCode.data.explorer.adgroup, data?.adgroupTypeCode?.toString() || '')},
            ];
        }
        case NaverCode.data.options.ExplorerLevel.ad.value : {
            return convertStatInfoViewData_AD(data);  //목록호출
        }
        case NaverCode.data.options.ExplorerLevel.searchKwd.value : {
            const result:ConvertStatInfoViewDataType[] = [
                {title:'캠페인 유형', value: NaverCode.getLabel(NaverCode.data.explorer.campaign, data?.campaignTypeCode?.toString() || '')},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인 ID', value:data?.campaignId},
                {title:'광고그룹 유형', value: NaverCode.getLabel(NaverCode.data.explorer.adgroup, data?.adgroupTypeCode?.toString() || '')},
                {title:'광고그룹명', value:data?.adgroupName},
                {title:'광고그룹 ID', value:data?.adgroupId},
            ];
            data?.adId && result.push( {title:'소재 ID', value:data?.adId} );
            data?.adId && result.push( {title:'소재', value: <AdFormater value={data?.adId} />} );
            result.push( {title:'매체이름(매체코드)', value:data?.mediaName && `${data?.mediaName}(${data?.mediaCode})`});
            return result;
        }

        case NaverCode.data.options.ExplorerLevel.criterion?.value : {
            const result:ConvertStatInfoViewDataType[] = [
                {title:'캠페인 유형', value: NaverCode.getLabel(NaverCode.data.explorer.campaign, data?.campaignTypeCode?.toString() || '')},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인 ID', value:data?.campaignId},
                {title:'광고그룹 유형', value: NaverCode.getLabel(NaverCode.data.explorer.adgroup, data?.adgroupTypeCode?.toString() || '')},
                {title:'광고그룹명', value:data?.adgroupName},
                {title:'광고그룹 ID', value:data?.adgroupId},
            ];
            data?.criterionDictionaryCode && result.push( {title:'타겟팅 ID', value:data?.criterionDictionaryCode} );
            data?.criterionId && result.push( {title:'크리테리온 ID', value:data?.criterionId} );
            return result;
        }

        case NaverCode.data.options.ExplorerLevel.adExtension.value : {
            const ownerType = getOwnerIdType(data?.ownerId);
            const result:ConvertStatInfoViewDataType[] = [
                {title:'생성 레벨', value: NaverCode.getLabel(NaverCode.data.options.ExplorerLevel, ownerType)},
            ];
        
            // 캠페인정보
            if([
                NaverCode.data.options.ExplorerLevel.campaign.value,    //캠페인
                NaverCode.data.options.ExplorerLevel.adGroup.value,     //광고그룹
                NaverCode.data.options.ExplorerLevel.ad.value,          //소재
            ].includes(ownerType || '')){ 
                result.push(
                    {title:'캠페인 유형', value: NaverCode.getLabel(NaverCode.data.explorer.campaign, data?.campaignTypeCode?.toString() || '')},
                    {title:'캠페인명', value: data?.campaignName},
                );
            }
        
            // 광고그룹 정보
            if([
                NaverCode.data.options.ExplorerLevel.adGroup.value,     //광고그룹
                NaverCode.data.options.ExplorerLevel.ad.value,          //소재
            ].includes(ownerType || '')){ 
                result.push(
                    {title:'광고그룹 유형', value: NaverCode.getLabel(NaverCode.data.explorer.adgroup, data?.adgroupTypeCode?.toString() || '')},
                    {title:'광고그룹명', value: data?.adgroupName},
                );
            }
        
            // 소재 정보
            if([
                NaverCode.data.options.ExplorerLevel.ad.value,          //소재
            ].includes(ownerType || '')){ 
                result.push(
                    {title:'소재 ID', value: data?.adId},
                    {title:'소재', value: <AdFormater value={data?.adId} />},
                );
            }
        
            // 확장소재 정보 - 기본
            result.push(
                {title:'확장소재 ID', value: data?.adextensionId},
                {title:'확장소재 유형', value: NaverCode.getLabel(NaverCode.data.explorer.adExtension, data?.adextensionTypeCode?.toString() || '')},
            );
        

            return result;
        }
    }
    return [];
}

const getOwnerIdType = (value?:string):string=>{
    if(value?.indexOf("cmp-")===0){ return NaverCode.data.options.ExplorerLevel.campaign.value; }
    if(value?.indexOf("grp-")===0){ return NaverCode.data.options.ExplorerLevel.adGroup.value; }
    if(value?.indexOf("nad-")===0){ return NaverCode.data.options.ExplorerLevel.ad.value; }
    return '';
}
// const getOwnerType = (value?:StatReportInfoType):string=>{
//     if(value?.adId){ return NaverCode.data.options.ExplorerLevel.ad.value; }
//     if(value?.adgroupId){ return NaverCode.data.options.ExplorerLevel.adGroup.value; }
//     return NaverCode.data.options.ExplorerLevel.campaign.value;
// }

const AdFormater:React.FC<{value?:string}> = (props) =>{
    const userContext = useUserContext();
    const [useData, setUseData] = useState<AdResType|undefined>();

    useEffect(()=>{
        if(props.value){

            // setPageNumber(pageNum);
            const requestData:RequestSearchType= {
                filters: [{key:AdResKeys.adId, operation:'In', values:[props.value]}],
                orders: [],
                page:0,
                pageSize:1,
            };
            userContext.modalLoading.show();
            NaverService.ad.getList<MediaResResultType<AdResType>>(requestData)
            .then(((res) => {
                if(res.status===200){
                    setUseData(res.data.result?.items?.[0]);
                }
            }))
            .catch((e)=>{
                setUseData(undefined);
                userContext.alertMessage.add({
                    variant:"danger",
                    title:"[Explorer] 소재 데이터 조회 오류",
                    body:<>[Explorer] 소재 데이터 조회 중 오류가 발생했습니다.<br />{NaverService.ErrorMessage(e)}</>
                });
            })
            .finally(()=>{ userContext.modalLoading.hide(); });
        }
    // eslint-disable-next-line
    }, [props.value]);

    return <>{NaverFormatter.AdFormater(useData, useData)}</>;
}


export const convertStatInfoViewData_AD = (data?:StatReportInfoType):ModifyViewerType[]=>{
    switch(data?.adTypeCode?.toString()){
        case NaverCode.data.explorer.ad.TEXT_45.value : {
            return [
                {title:'소재ID', value:data?.adId},
                {title:'소재 유형', value: NaverCode.getLabel(NaverCode.data.explorer.ad, data?.adTypeCode?.toString() || '')},
                // {title:'소재 제목', value:adInfo?.headline},
                // {title:'소제 설명', value:adInfo?.description},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인ID', value:data?.campaignId},
                {title:'캠페인 유형', value: NaverCode.getLabel(NaverCode.data.explorer.campaign, data?.campaignTypeCode?.toString() || '')},
                {title:'광고그룹명', value:data?.adgroupName},
                {title:'광고그룹ID', value:data?.adgroupId},
                {title:'광고그룹 유형', value: NaverCode.getLabel(NaverCode.data.explorer.adgroup, data?.adgroupTypeCode?.toString() || '')},
            ];
        }

        case NaverCode.data.explorer.ad.SHOPPING_PRODUCT_AD.value : {
            return [
                {title:'소재ID', value:data?.adId},
                {title:'소재 유형', value: NaverCode.getLabel(NaverCode.data.explorer.ad, data?.adTypeCode?.toString() || '')},
                // {title:'상품 제목', value: adInfo?.productTitle},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인ID', value:data?.campaignId},
                {title:'캠페인 유형', value: NaverCode.getLabel(NaverCode.data.explorer.campaign, data?.campaignTypeCode?.toString() || '')},
                {title:'광고그룹명', value:data?.adgroupName},
                {title:'광고그룹ID', value:data?.adgroupId},
                {title:'광고그룹 유형', value: NaverCode.getLabel(NaverCode.data.explorer.adgroup, data?.adgroupTypeCode?.toString() || '')},
            ];
        }

        case NaverCode.data.explorer.ad.CATALOG_AD.value : {
            return [
                {title:'소재ID', value:data?.adId},
                {title:'소재 유형', value: NaverCode.getLabel(NaverCode.data.explorer.ad, data?.adTypeCode?.toString() || '')},
                // {title:'상품명', value: adInfo?.productName},
                // {title:'상품 제목', value: adInfo?.productTitle},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인ID', value:data?.campaignId},
                {title:'캠페인 유형', value: NaverCode.getLabel(NaverCode.data.explorer.campaign, data?.campaignTypeCode?.toString() || '')},
                {title:'광고그룹명', value:data?.adgroupName},
                {title:'광고그룹ID', value:data?.adgroupId},
                {title:'광고그룹 유형', value: NaverCode.getLabel(NaverCode.data.explorer.adgroup, data?.adgroupTypeCode?.toString() || '')},
            ];
        }

        case NaverCode.data.explorer.ad.SHOPPING_BRAND_IMAGE_THUMBNAIL_AD.value : {
            return [
                {title:'소재ID', value:data?.adId},
                {title:'소재 유형', value: NaverCode.getLabel(NaverCode.data.explorer.ad, data?.adTypeCode?.toString() || '')},
                // {title:'소제 제목', value: adInfo?.headline},
                // {title:'소제 설명', value: adInfo?.description},
                // {title:'소재 설명2', value: adInfo?.extraDescription},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인ID', value:data?.campaignId},
                {title:'캠페인 유형', value: NaverCode.getLabel(NaverCode.data.explorer.campaign, data?.campaignTypeCode?.toString() || '')},
                {title:'광고그룹명', value:data?.adgroupName},
                {title:'광고그룹ID', value:data?.adgroupId},
                {title:'광고그룹 유형', value: NaverCode.getLabel(NaverCode.data.explorer.adgroup, data?.adgroupTypeCode?.toString() || '')},
            ];
        }

        case NaverCode.data.explorer.ad.SHOPPING_BRAND_IMAGE_BANNER_AD.value :  {
            return [
                {title:'소재ID', value:data?.adId},
                {title:'소재 유형', value: NaverCode.getLabel(NaverCode.data.explorer.ad, data?.adTypeCode?.toString() || '')},
                // {title:'소제 제목', value: adInfo?.headline},
                // {title:'소제 설명', value: adInfo?.description},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인ID', value:data?.campaignId},
                {title:'캠페인 유형', value: NaverCode.getLabel(NaverCode.data.explorer.campaign, data?.campaignTypeCode?.toString() || '')},
                {title:'광고그룹명', value:data?.adgroupName},
                {title:'광고그룹ID', value:data?.adgroupId},
                {title:'광고그룹 유형', value: NaverCode.getLabel(NaverCode.data.explorer.adgroup, data?.adgroupTypeCode?.toString() || '')},
            ];
        }

        case NaverCode.data.explorer.ad.SHOPPING_BRAND_AD.value : {
            return [
                {title:'소재ID', value:data?.adId},
                {title:'소재 유형', value: NaverCode.getLabel(NaverCode.data.explorer.ad, data?.adTypeCode?.toString() || '')},
                // {title:'소제 설명', value: adInfo?.brandDescription},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인ID', value:data?.campaignId},
                {title:'캠페인 유형', value: NaverCode.getLabel(NaverCode.data.explorer.campaign, data?.campaignTypeCode?.toString() || '')},
                {title:'광고그룹명', value:data?.adgroupName},
                {title:'광고그룹ID', value:data?.adgroupId},
                {title:'광고그룹 유형', value: NaverCode.getLabel(NaverCode.data.explorer.adgroup, data?.adgroupTypeCode?.toString() || '')},
            ];
        }

        default :
            return [
                {title:'소재ID', value:data?.adId},
                {title:'소재 유형', value: NaverCode.getLabel(NaverCode.data.explorer.ad, data?.adTypeCode?.toString() || '')},
                // {title:'소제 설명', value: adInfo?.brandDescription},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인ID', value:data?.campaignId},
                {title:'캠페인 유형', value: NaverCode.getLabel(NaverCode.data.explorer.campaign, data?.campaignTypeCode?.toString() || '')},
                {title:'광고그룹명', value:data?.adgroupName},
                {title:'광고그룹ID', value:data?.adgroupId},
                {title:'광고그룹 유형', value: NaverCode.getLabel(NaverCode.data.explorer.adgroup, data?.adgroupTypeCode?.toString() || '')},
            ];
    }
}


export const AdTitle:React.FC<{data?:StatReportInfoType}> = (props)=>{
    return <>{props.data?.adValue1 || props.data?.adValue2 || props.data?.adValue3}</>;
}

export const ExplorerTitle:React.FC<{level?:string, data?:StatReportInfoType}> = (props)=>{
    switch(props.level){
        case NaverCode.data.options.ExplorerLevel.bizChannel.value : return <>비즈채널명 : {props.data?.businessChannelName}</>;
        case NaverCode.data.options.ExplorerLevel.campaign.value : return <>캠페인명 : {props.data?.campaignName}</>;
        case NaverCode.data.options.ExplorerLevel.adGroup.value : return <>광고그룹명 : {props.data?.adgroupName}</>;
        case NaverCode.data.options.ExplorerLevel.keyword.value : return <>키워드 : {props.data?.keyword}</>;
        case NaverCode.data.options.ExplorerLevel.ad.value : return <>소재명 : <AdTitle data={props.data}/></>;
        case NaverCode.data.options.ExplorerLevel.adExtension.value : return <>확장소재 ID : {props.data?.adextensionId}</>;
        case NaverCode.data.options.ExplorerLevel.searchKwd.value : return <>검색어 : {props.data?.searchKeyword}</>;
    }
    return <>Unknown</>;
}
/**
 * 20230622 --> 2023-06-22
 * @param txt 
 * @returns 
 */
export const getStatDateFormatter = (txt:string)=>{
    const tmp:string = txt.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    if(isNaN(new Date(tmp).getTime())){ return txt; }
    return new Date(tmp).format('yyyy-MM-dd');
}


/** Stat 컬럼만 Sum 처리 합니다. */
export const getStatReportTotal = (data:StatReportResType[]):StatReportResType => {
    const total:StatReportResType = {
        key:'합계',
        impressionCount: 0,
        clickCount:0,
        adCost:0,
        adRank:0,
        videoCount:0,
        dcPurchaseCount:0,
        dcPurchaseValue:0,
        dcSubscriptionCount:0,
        dcSubscriptionValue:0,
        dcCartCount:0,
        dcCartValue:0,
        dcAdvPurchaseCount:0,
        dcAdvPurchaseValue:0,
        dcOthersCount:0,
        dcOthersValue:0,
        icPurchaseCount:0,
        icPurchaseValue:0,
        icSubscriptionCount:0,
        icSubscriptionValue:0,
        icCartCount:0,
        icCartValue:0,
        icAdvPurchaseCount:0,
        icAdvPurchaseValue:0,
        icOthersCount:0,
        icOthersValue:0,
    
        purchaseCount:0,
        purchaseValue:0,
        subscriptionCount:0,
        subscriptionValue:0,
        averageAdRank:0,
        clickThroughRate:0,
        costPerClick:0,
        conversionRate:0,
        costPerAction:0,
        costPerSubscription:0,
        returnOnAdSpend:0,

        cartCount: 0,
        cartValue: 0,
        advPurchaseCount: 0,
        advPurchaseValue: 0,
        othersCount: 0,
        othersValue: 0,
    };

    const entries = Object.entries(total);
    data.forEach((v)=>{
        const tmp:Map<string,number> = new Map(Object.entries(v));
        entries.forEach((v2)=>{
            if(v2[0]==='key'){return; }
            v2[1] += tmp.get(v2[0]) || 0;
        });
    });

    return {...total, ...Object.fromEntries(entries)};
}

export const AppendStatReportTotal = (data:StatReportResType[]):StatReportResType[] => {
    const total:StatReportResType = getStatReportTotal(data);
    data.push(total);
    return data;
}

export const AdExtensionFormater:React.FC<{level?:string, data?:StatReportInfoType, isDetail?:boolean}> = (props) =>{
    const userContext = useUserContext();
    const [useData, setUseData] = useState<AdExtensionResType|undefined>();

    useEffect(()=>{
        if(props.data?.adextensionId){

            // setPageNumber(pageNum);
            const requestData:RequestSearchType= {
                filters: [{key:AdExtensionResKeys.adExtensionId, operation:'In', values:[props.data?.adextensionId]}],
                orders: [],
                page:0,
                pageSize:1,
            };
            userContext.modalLoading.show();
            NaverService.adextension.getList<MediaResResultType<AdExtensionResType>>(requestData)
            .then(((res) => {
                if(res.status===200){
                    setUseData(res.data.result?.items?.[0]);
                }
            }))
            .catch((e)=>{
                setUseData(undefined);
                userContext.alertMessage.add({
                    variant:"danger",
                    title:"[Explorer] 확장소재 데이터 조회 오류",
                    body:<>[Explorer] 확장소재 데이터 조회 중 오류가 발생했습니다.<br />{NaverService.ErrorMessage(e)}</>
                });
            })
            .finally(()=>{ userContext.modalLoading.hide(); });
        }
    // eslint-disable-next-line
    }, [props.data]);

    const result:ModifyViewerType[] = [
        {title:'생성 레벨', value: AdminCode.getLabel(useData?.ownerType || '')},
    ];

    // 캠페인정보
    if(['NET0003', 'NET0004', 'NET0005'].includes(useData?.ownerType || '')){      // 캠페인, 광고그룹, 
        result.push(
            {title:'캠페인 유형', value: NaverCode.getLabel(NaverCode.data.explorer.campaign, props.data?.campaignTypeCode?.toString() || '')},
            {title:'캠페인명', value:props.data?.campaignName},
        );
    }

    // 광고그룹 정보
    if(['NET0004', 'NET0005'].includes(useData?.ownerType || '')){      // 광고그룹, 소재 
        result.push(
            {title:'광고그룹 유형', value: NaverCode.getLabel(NaverCode.data.explorer.adgroup, props.data?.adgroupTypeCode?.toString() || '')},
            {title:'광고그룹명', value:props.data?.adgroupName},
        );
    }

    // 소재 정보
    if(['NET0005'].includes(useData?.ownerType || '')){      // 소재 
        result.push(
            {title:'소재 ID', value:useData?.ad?.adId},
            {title:'소재', value: <>{NaverFormatter.AdFormater(useData?.ad, useData?.ad)}</>},
        );
    }

    // 확장소재 정보 - 기본
    result.push(
        {title:'확장소재 ID', value:props.data?.adextensionId},
        {title:'확장소재 유형', value: NaverCode.getLabel(NaverCode.data.explorer.adExtension, props.data?.adextensionTypeCode?.toString() || '')},
    );


    return <>
        {result.map((v,index)=><tr key={index}>
            <th className={clsx('text-gray-500 me-3 text-right', props.isDetail ? '' : 'p-1')}>{v.title}</th>
            <td className={clsx(props.isDetail ? '' : 'p-1')}>{v.value}</td>
        </tr>)}
    </>;
}