/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { CSSProperties, useEffect, useState } from 'react';
import { StatReportResKeys, StatReportResType } from '../../../common/services/models/kakao/StatReportType';
import { KakaoService } from '../../../common/services';
import { KakaoOxfordColumns, StatDataExtends } from '../modules/KakaoOxfordColumns';
import { TablePropsType } from '../../modules/table/TableType';
import ExplorerChart from './ExplorerChart';
import Table from '../../modules/table/Table';
import { LoadingLayer } from '../../../modules/modal/LoadingModal';
import { getStatDateFormatter, getStatReportTotal } from './StatInfoData';


interface ExplorerDetailProps{
    level?:string;
    id?:string;
    className?:string;
    style?:CSSProperties;
}
const ExplorerDetail:React.FC<ExplorerDetailProps> = (props)=>{
    const [data, setData] = useState<StatReportResType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(()=>{
        if(props.level && props.id){
            setLoading(true);
            KakaoService.performance(props.level, props.id).then((res)=>{
                const data:any[] = res?.data?.result?.daily?.map((v:StatReportResType)=>StatDataExtends(v)) || [];  //일부러 any처리함 - 아래 빠진 일자데이터 추가
                const dateRange = KakaoService.common.getDateRange();
                const days = dateRange.end.dateDiff(dateRange.start);
                for(let i=0; i<=days; i++){
                    const date = new Date(dateRange.start).addDays(i).format('yyyyMMdd');
                    if( !data.find((v)=>v.key === date)){ data.push({key:date})}
                }
                setData(data.sort((a,b)=>a.key-b.key)); //날짜 정렬
            })
            .finally(()=>{setLoading(false);});
        }
    },[props.level, props.id]);

    const tableProps:TablePropsType<StatReportResType> = {
        columns:[
            { accessor: StatReportResKeys.key, header: '날짜', style:{minWidth:'100px', textAlign: 'center'}, useSort:false, formatter:getStatDateFormatter, },
            ...KakaoOxfordColumns,
        ],
        data: data,
        staticData: [getStatReportTotal(data)],
        filterable: false,
        showColumns:false,
        showDeviceType: false,
        pageable:false,
    }

    return <div className={props.className} style={props.style}>
        {loading && <LoadingLayer className='border bg-gray-100 pt-20 pb-20 text-secondary' variant='secondary' style={{paddingLeft:'350px'}} />}
        {!loading && <>
            <ExplorerChart data={data} />
            <Table {...tableProps} />
        </>}
    </div>
}


export default ExplorerDetail;
