import React, { CSSProperties } from "react";
import styles from './explorer.module.scss';
import ExplorerListDetail from "./ExplorerListDetail";
import UserSelect from "../../../common/helpers/UserSelect";
import { Table } from "react-bootstrap";
import clsx from "clsx";
import KakaoCode from "../../../modules/code/KakaoCode";
import { StatReportInfoType } from "../../../common/services/models/kakao/StatReportType";
import { convertStatInfoViewData } from "./StatInfoData";

export interface ItemsProps{
    index?:number;
    data: StatReportInfoType;
    media?: string;
    level?: string;
    search?: string;
    show?: boolean;
    isDetail?:boolean;
    setSelect?: (id:string)=>void;
}

export const Items:React.FC<ItemsProps> = (props)=>{
    const Item = getItemTag(props);
    const id = getItemId(props.level, props.data);
    if(!Item){ return <>Unknown {props.level}</> }
    return <>
        {props.show && <DetailWindow level={props.level} search={id} />}
        {!props.isDetail && <>
            <div>
                {props.index!==undefined && <span className='me-3 fw-bold'>{props.index+1}</span>}
                <Item  {...props}/>
            </div>
        </>}
        <Viewer {...props} />
        {props.show && <ExplorerListDetail {...props} id={ getItemId(props.level, props.data)} />}
    </>;
}

export const ItemsBizChannel:React.FC<ItemsProps> = (props)=>(<SearchHighlight
    className={styles.link}
    text={props.data.businessChannelName || ''}
    search={props.search}
    onClick={()=>{props.setSelect && props.setSelect(props.data.businessChannelId || '')}} 
/>);

export const ItemsCampaign:React.FC<ItemsProps> = (props)=>(<SearchHighlight
    className={styles.link}
    text={props.data.campaignName || ''}
    search={props.search}
    onClick={()=>{props.setSelect && props.setSelect(props.data.campaignId || '')}} 
/>);

export const ItemsAdGroup:React.FC<ItemsProps> = (props)=>(<SearchHighlight
    className={styles.link}
    text={props.data.adgroupName || ''}
    search={props.search}
    onClick={()=>{props.setSelect && props.setSelect(props.data.adgroupId || '')}} 
/>);

export const ItemsKeyword:React.FC<ItemsProps> = (props)=>(<SearchHighlight
    className={styles.link}
    text={props.data?.keyword || ''}
    search={props.search}
    onClick={()=>{props.setSelect && props.setSelect(props.data.keywordId || '')}} 
/>);

export const ItemsAd:React.FC<ItemsProps> = (props)=>{
    const onClick = ()=>{ props.setSelect && props.setSelect(props.data.adId || ''); }
    return (<div className='mb-5 p-3 ps-6 border border-gray-300'>
        <SearchHighlight className={styles.link} text={props.data.adName || ''} search={props.search} onClick={onClick} /><br/>
        {props.data?.title && <><SearchHighlight className={styles.link} text={props.data?.title || ''} search={props.search} onClick={onClick} /><br/></>}
        {props.data?.description && <><SearchHighlight className={styles.link} text={props.data?.description || ''} search={props.search} onClick={onClick} /></>}
    </div>);
}

export const ItemsAdLink:React.FC<ItemsProps> = (props)=>(<SearchHighlight
    className={styles.link}
    text={props.data.adLinkId || ''}
    search={props.search}
    onClick={()=>{ props.setSelect && props.setSelect(props.data.adLinkId || ''); }}
/>);



const Viewer:React.FC<{level?:string, data?:StatReportInfoType, isDetail?:boolean}> = (props) =>{
    const viewData = convertStatInfoViewData(props.level, props.data);
    return <Table className={props.isDetail ? 'table border table-bordered border-gray-300 table-row-bordered table-row-gray-300 mb-0' : ''}>
        <colgroup><col width={props.isDetail ? '200px' : '100px'} /><col /></colgroup>
        <tbody>
            {viewData.map((v,index)=><tr key={index}>
                <th className={clsx('text-gray-500 me-3 text-right', props.isDetail ? '' : 'p-1')}>{v.title}</th>
                <td className={clsx(props.isDetail ? '' : 'p-1')}>{v.value}</td>
            </tr>)}
        </tbody>
    </Table>;
}


export const getItemId = (level?:string, item?:StatReportInfoType):string|undefined => {
    switch(level){
        case KakaoCode.data.options.ExplorerLevel.campaign.value :  return item?.campaignId;
        case KakaoCode.data.options.ExplorerLevel.adGroup.value :   return item?.adgroupId;
        case KakaoCode.data.options.ExplorerLevel.keyword.value :   return item?.keywordId;
        case KakaoCode.data.options.ExplorerLevel.creative.value:   return item?.adId;
        case KakaoCode.data.options.ExplorerLevel.creativLink.value:return item?.adLinkId;
        case KakaoCode.data.options.ExplorerLevel.bizChannel.value :return item?.businessChannelId;
        default: ;
    } 
    return undefined;
}


export const getItemTag = (props:ItemsProps):React.FC<ItemsProps>|undefined =>{
    switch(props.level){
        case KakaoCode.data.options.ExplorerLevel.campaign.value :  return ItemsCampaign;   
        case KakaoCode.data.options.ExplorerLevel.adGroup.value :   return ItemsAdGroup;    
        case KakaoCode.data.options.ExplorerLevel.keyword.value :   return ItemsKeyword;    
        case KakaoCode.data.options.ExplorerLevel.creative.value :  return ItemsAd; 
        case KakaoCode.data.options.ExplorerLevel.creativLink.value:return ItemsAdLink; 
        case KakaoCode.data.options.ExplorerLevel.bizChannel.value :return ItemsBizChannel; 
    }
    return undefined;
}


interface DetailWindowProps{
    level?:string;
    search?:string;
}
const DetailWindow:React.FC<DetailWindowProps> = (props)=>{
    const userselect = UserSelect.storage.get();
    const params:string[][] = [
        ['select', JSON.stringify(userselect || '')],
        ['level', props.level || ''],
        ['key', props.search || ''],
    ];
    const qry = new URLSearchParams(params).toString();
    return <a style={{float:'right', position:'absolute', right:'20px', marginTop:'-20px'}}
        target='_blank' rel="noreferrer"  href={`/popup/kakao/explorer/?${qry}`}>자세히 보기 <i className="bi bi-box-arrow-up-right" /></a>;
}

/**
 * 검색키워드 하일라이트 처리
 * @param props 
 * @returns 
 */
const SearchHighlight:React.FC<{text:string, search?:string, className?:string, style?:CSSProperties, onClick?:()=>void}> = (props)=>{
    const replaceText = (text: string, needle:string, replace:React.ReactNode): React.ReactNode => {
        return <>{text?.split(needle).map((v,index)=><span key={index}>{index>0 && replace}{v}</span>)}</>;
    };
    return <span className={props.className} style={props.style} onClick={props.onClick}>
        {props.search ? replaceText(props.text, props.search, <span className='text-danger'>{props.search}</span>) : props.text}
    </span>
}
