import { NaverServer } from '../../axios';
import Utils from '../../utils/Utils';
import { AdKeywordCopyType } from '../models/naver/AdKeywordTypes';
import NaverHelperComponent from './NaverHelperComponent';

/** 네이버 키워드 Helper */
export default class KeywordHelper extends NaverHelperComponent{
    static path="/media/ad-keywords"; //기본 Path
    static stat='/performance/keywords';   //실적데이터
    static performances='/performances/keywords';   //실적데이터
    static downloadPath='/api/naversa/keyword';

    public static copy(body:AdKeywordCopyType){
        return Utils.apiRequestValidation({}) || NaverServer.post<AdKeywordCopyType>(`/media/copy-ad-keywords`, body, {headers:{useAccount:true}});
    }
}

