/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import Config from '../../../../app/common/conf/Config';
import AuthService from '../../../../app/common/services/AuthService';
import { useAuth } from '../../../../app/modules/auth';
import UserSymbol from '../../../../app/pages/modules/symbol/UserSymbol';
import { MenuInnerWithSub } from '../../../layout/components/header/header-menus/MenuInnerWithSub';
import { MenuItem } from '../../../layout/components/header/header-menus/MenuItem';


const HeaderUserMenu: FC = () => {
  const { logout} = useAuth();
  const info = AuthService.storage.get();
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          {/* <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} />
          </div> */}
          <UserSymbol name={info?.userInfo.user_signature || "손님"} size='md' style={{marginRight:'0.5em'}} />

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {info?.userInfo.user_signature || "손님"}
              {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
            </div>
            {/* <a href='#' className='fw-bold text-muted text-hover-primary fs-7'> */}
              {info?.userInfo.user_name}
            {/* </a> */}
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-6'>
        <MenuInnerWithSub
          title='마이페이지'
          to='/pages/settings/profile'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <div className='mx-3'>
            <MenuItem to='/pages/settings/profile#advertiser' title={<div className='me-6'>광고주 현황</div>} hasBullet={true} />
            {Config.app.Notification.enable && <MenuItem className="menu-rounded" to='/pages/settings/profile#notification' title={<div className='me-6'>알림</div>} hasBullet={true} />}
          </div>
        </MenuInnerWithSub>
      </div>

      <div className='menu-item px-5 my-1'>
        {/* <Link to='/pages/settings/settings' className='menu-link px-5'> */}
        <a href={`${Config.service.portal}/modifypassword`} className='menu-link px-5'>
          비밀번호 변경
        </a>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          로그아웃
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu };

