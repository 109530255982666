import { NaverServer } from '../../axios';
import Utils from '../../utils/Utils';
import { RequestSearchType } from '../models/RequestTypes';
import { MediaResponseType } from '../models/ResponseTypes';
import { AdExtensionGetCountType, AdExtensionPostCopyType } from '../models/naver/AdExtensionTypes';
import NaverHelperComponent from './NaverHelperComponent';

/** 네이버 확장소재 Helper */
export default class AdExtensionHelper extends NaverHelperComponent{
    static path="/media/ad-extensions"; //기본 Path
    static stat='/performance/ad-extensions';   //실적데이터
    static performances='/performances/ad-extensions';   //실적데이터
    static downloadPath='/api/naversa/adextension';   //표준 목록조회
    public static getCounts(params:RequestSearchType){
        return Utils.apiRequestValidation({}) || NaverServer.get<MediaResponseType<AdExtensionGetCountType>>(`${this.path}/counts`,{params:params, headers:{useAccount:true}});
    }

    public static copy(body:AdExtensionPostCopyType){
        const valid = Utils.apiRequestValidation({}); if(valid){ return valid; }
        return NaverServer.post<AdExtensionPostCopyType>(`/media/copy-ad-extensions`, body, {headers:{useAccount:true}});
    }
}
