import { LabelColumnType } from "../LabelTypes";
import { StatReportResKeys, StatReportResType } from "./StatReportType";

export interface AdLinksType{
    display: string;
    final: string;
    punyCode: string;
}

export interface AdType_TEXT_45{
    headline: string;
    description: string;
    pc: AdLinksType;
    mobile: AdLinksType;
}

export interface AdType_SHOPPING_BRAND_AD{
    brandDescription:string;
    landingUrl:string;
    logoImage:string;
}

/** 브랜드 이미지 썸네일 */
export interface AdType_BRAND_THUMBNAIL{
    headline: string;
    description: string; 
    extraDescription: string;
    image: string;
    landingUrl: string;
    logoImage: string;
}

export type AdType_SHOPPING_PRODUCT_AD = AdReferenceDataType;
export type AdType_CATALOG_AD = AdReferenceDataType;

export interface AdDataType{
    ad?: AdType_TEXT_45;
    code: string|null;
    title: string|null;
    detail: string|null;
    nccAdId: string;
    nccAdgroupId: string;
    customerId: number;
    inspectStatus: string;
    type: string;
    userLock: boolean;
    enable: boolean
    inspectRequestMsg: string|null;
    nccQi: string|null;
    status: string|null;
    statusReason: string|null;
}

export interface AdReferenceDataType{
    adCustomerId: string;
    rcvYn: string;
    prodTypeCd: string;
    imgStatusCd: string;
    prodStatusCd:string;
    fullMallCatNm: string;
    dvlryFeeCont: string;
    hasDvlryFeeCont: string;
    makerNo: string;
    brandNo: string;
    productName: string;
    productTitle: string;
    lowPrice: string;
    mobilePrice: string;
    overseaTp: string;
    prodTp: string;
    saleTp: string;
    purchaseCnt: string;
    mallProductId: string;
    mallProductUrl: string;
    mallProdMblUrl: string;
    keepCnt: string;
    _7daysSaleSum: string;
    reviewCount: string;
    scoreInfo: string;
    smryReview: string;
    attrVals: string;
    charVals: string;
    modelImgUrlList: string;
    modelCreTpCd: string;
    imageUrl: string;
    parentId: string;
    openDate: string;
    maker: string;
    brand: string;
    mallCount: string;
    mpTp: string;
    series: string;
    seriesNo: string;
    prchCondInfo: string;
    id: string;
    catId: string;
    svcYn: string;
    displayNx: string;
    categoryLevel: string;
    category1Id: string;
    category2Id: string;
    category3Id: string;
    category4Id: string;
    category1Name: string;
    category2Name: string;
    category3Name: string;
    category4Name: string;
    referenceKey: string;
    APPLY_TM: string;
    DEL_FLAG: string;
    priceUnit: string;
}

// export interface AdPostType{
//     campaignId: string;
//     adgroupId: string;
//     useYn: string;
//     ad: AdType_TEXT_45[];
// }

/**
 * Naver- 소재 조회용 데이터타입
 */
export interface AdResType extends Omit<StatReportResType, 'key'>, LabelColumnType{
    adId: string,
    accountGroupId: number,
    clientId: number,
    campaignId: string,
    adGroupId: string,
    inspectStatus: string,
    type: string,
    userLock: boolean,
    enable: boolean,
    inspectRequestMsg: string,
    nccQiGrade: number,
    status: string,
    statusReason: string,
    adInfo: any,
    adAttr: any,
    referenceKey: string,
    referenceData: AdReferenceDataType|null,
    categoryMerged: string,
    regTm: string,
    editTm: string,
    campaignName: string,
    campaignType: string,
    adGroupName: string,
    adGroupType: string,
    groupBidAmt: number,
    headline: string,
    description: string,
    pcDisplayUrl: string,
    pcFinalUrl: string,
    pcPunyCode: string,
    moDisplayUrl: string,
    moFinalUrl: string,
    moPunyCode: string,
    mallProductId: string,
    mallProductUrl: string,
    mallProdMblUrl: string,
    landingUrl: string,
    useGroupBidAmt: true,
    bidAmt: number,
    labelValue: number,
}

/**
 * Naver-소재 데이터 출력용 컬럼용 키
 */
export enum AdResKeys{
    labelValue = "labelValue",
    adId = 'adId',
    accountGroupId = 'accountGroupId',
    clientId = 'clientId',
    campaignId = 'campaignId',
    adGroupId = 'adGroupId',
    inspectStatus = 'inspectStatus',
    type = 'type',
    userLock = 'userLock',
    enable = 'enable',
    inspectRequestMsg = 'inspectRequestMsg',
    nccQiGrade = 'nccQiGrade',
    status = 'status',
    statusReason = 'statusReason',
    adInfo = 'adInfo',
    adAttr = 'adAttr',
    referenceKey = 'referenceKey',
    referenceData = 'referenceData',

    categoryMerged = 'categoryMerged',
    regTm = 'regTm',
    editTm = 'editTm',
    campaignName = 'campaignName',
    campaignType = 'campaignType',
    adGroupName = 'adGroupName',
    adGroupType = 'adGroupType',
    groupBidAmt = 'groupBidAmt',
    headline = 'headline',
    description = 'description',
    pcDisplayUrl = 'pcDisplayUrl',
    pcFinalUrl = 'pcFinalUrl',
    pcPunyCode = 'pcPunyCode',
    moDisplayUrl = 'moDisplayUrl',
    moFinalUrl = 'moFinalUrl',
    moPunyCode = 'moPunyCode',
    mallProductId = 'mallProductId',
    mallProductUrl = 'mallProductUrl',
    mallProdMblUrl = 'mallProdMblUrl',
    landingUrl = 'landingUrl',
    useGroupBidAmt = 'useGroupBidAmt',
    bidAmt = 'bidAmt',
    productId = 'productId',
}

/**
 * Naver - 소재 생성용 타입
 */
export interface AdPostType{
    text45AdInfos?:AdPostType_Text45[];
}

/**
 * Naver - 소재 Text45타입
 */
export interface AdPostType_Text45{
    adGroupId: string,
    headline: string,
    description: string,
    pcUrl: string,
    moUrl: string,
    userLock: boolean;
}

/**
 * Naver- 소재 수정용 타입
 */
export interface AdPutType{
    adIds: string[];
    userLock?: boolean;
    bidAmt?: number;
    useGroupBidAmt?:boolean;
    adType?:string;
}

/**
 * Naver - 소재 삭제 타입
 */
export interface AdDeleteType{
    adIds:string[];
}

/**
 * Naver - 소재 복사 타입
 */
export interface AdPostCopyType{
    targetAdGroupIds: string[];
    originalAdIds: string[];
    userLock: boolean;
  }


export const AdKeys = {...AdResKeys, ...StatReportResKeys, lockState:"lockState"};



export enum AdReqKeys {
    adGroupId = 'adGroupId',
    headline = 'headline',
    description = 'description',
    pcUrl = 'pcUrl',
    moUrl = 'moUrl',
    campaign = 'campaign',
    adgroup = 'adgroup',
    userLock = 'userLock',
    adList = 'adList',
    adGroupList = 'adGroupList',
    possibleRegAd = 'possibleRegAd',
    isExcess = 'isExcess',
    bidAmt = 'bidAmt',
    useGroupBidAmt='useGroupBidAmt',
    adGroupType = 'adGroupType',
    maxRegCount = 'maxRegCount',

}