import clsx from 'clsx';
import { CSSProperties, useEffect, useState } from 'react';
import { OptionType } from '../../../../modules/types/Types';
import ThemeConfig from '../../../common/conf/ThemeConfig';
import RadioGroup, { RadioGroupProps } from '../form/RadioGroup';
import { ButtonDropDown } from './ButtonDropDown';

export interface DropDownRadioProps extends Omit<RadioGroupProps, 'title'>{
    style?:CSSProperties;
    menu?:{
        title?:string|React.ReactNode;
        style?:CSSProperties;
        className?:string;
    }
    radio?:{
        className?:string;
        style?:CSSProperties;
    };
    formatter?:(item:OptionType)=>string|React.ReactNode;
    onChange?:(value:string, item?:OptionType)=>void;
    disabled?:boolean;
}

/**
 * 드롭다운 타입의 라디오 선택버튼
 * @param props 
 * @returns 
 */
const DropDownRadio:React.FC<DropDownRadioProps> = (props:DropDownRadioProps)=>{
    const [selectOption, setSelectOption] = useState<OptionType>();
    const [applyOption, setApplyOption] = useState<OptionType>();

    const setSelect = (value:string)=>{
        const item:OptionType|undefined = props.options.find((v) => v.value === value);
        if(item !== undefined){ setSelectOption(item); }
    }

    useEffect(()=>{
        const item:OptionType|undefined = props.options.find((v) => props.value===undefined || v.value === props.value);
        setApplyOption(item);
        setSelect(props.value || '');
    // eslint-disable-next-line
    },[props.options, props.value]);

    const onChange = (value:string) => {
        setSelect(value);
    }

    const handleOnClick = () => {
        setApplyOption(selectOption);
        props.onChange && props.onChange(selectOption?.value || '', selectOption);
    }
    
    let title:string|React.ReactNode = applyOption?.label || '';
    if(props.formatter && applyOption){ title = props.formatter(applyOption); }

    const btnCfg = ThemeConfig.Button.Sub;

    return <ButtonDropDown
        className={clsx('d-inline', props.className)}
        title={title}
        onSubmit={handleOnClick}
        style={props.style}
        menu={{
            className:'p-4',
        }}
        useCaret={true}
    >     
        <div className={clsx('mb-3', props.menu?.className)} style={props.menu?.style}>
            {props.menu?.title && <>
                <h6>{props.menu.title}</h6>
                <hr className='m-0 mb-3' />
            </>}
            <RadioGroup
                name={props.name}
                readOnly={props.readOnly} 
                disabled={props.disabled} 
                options={props.options}
                value={props.value}
                size={props.size}
                vertical={true} 
                onChange={onChange}
                radio={props.radio}
            />
        </div>
    </ButtonDropDown>;
}

export default DropDownRadio;