/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {CSSProperties, useEffect, useState} from 'react'
import { OptionType } from '../../../../modules/types/Types';
import {Form} from "react-bootstrap";
import BarLineCharts from './BarLineCharts';
import { ChartDataType } from './Types';

export interface ChartOptionType extends OptionType{
    unit?:string;
}

export interface StatChartConfigType{
    data:ChartDataType[];
    value?:ChartOptionType;
    onChange?:(value:ChartOptionType)=>void;
    color?:string;
}
export interface StatReportChartProps{
    className?: string;
    style?:CSSProperties;
    options: ChartOptionType[];
    barChart:StatChartConfigType;
    lineChart:StatChartConfigType;
}

const StatReportChart:React.FC<StatReportChartProps> = (props) => {
    const [useSelect1, setUseSelect1] = useState<ChartOptionType>();
    const [useSelect2, setUseSelect2] = useState<ChartOptionType>();

    useEffect(()=>{
        setUseSelect1(props.barChart.value);
        setUseSelect2(props.lineChart.value);
    },[props.barChart.value, props.lineChart.value]);

    const onChange1:React.ChangeEventHandler<HTMLInputElement & HTMLSelectElement> = (e)=>{
        const value:ChartOptionType|undefined = props.options.find((v) => {return v.value === e.target.value});
        setUseSelect1(value);
        value && props.barChart.onChange && props.barChart.onChange(value);
    }
    const onChange2:React.ChangeEventHandler<HTMLInputElement & HTMLSelectElement> = (e)=>{
        const value:ChartOptionType|undefined = props.options.find((v) => {return v.value === e.target.value});
        setUseSelect2(value);
        value && props.lineChart.onChange && props.lineChart.onChange(value);
    }

    const color1 = props.barChart.color || "var(--bs-primary)";
    const color2 = props.lineChart.color || "var(--bs-info)";
    // console.log({data:props.lineChart.data, title:useSelect2?.label, unit:useSelect2?.unit, color:color1});
    // console.log({data:props.barChart.data, title:useSelect1?.label, unit:useSelect1?.unit, color:color2});
    return (<div className={props.className} style={props.style}>
        <div className='d-flex'>
            {/* <i className="bi bi-circle-fill mt-5 fs-3" style={{color:color1}} /> */}
            <Form.Select onChange={onChange1} size='sm' className='m-2 w-200px' value={useSelect1?.value} style={{color:color1}}>
                { props.options?.map((v) => { return (<option key={v.value} value={v.value}>{v.label}</option>);}) }
            </Form.Select>
            {/* <i className="bi bi-circle-fill mt-5 ms-2 fs-3" style={{color:color2}} /> */}
            <Form.Select onChange={onChange2} size='sm' className='m-2 w-200px' value={useSelect2?.value} style={{color:color2}} >
                { props.options?.map((v) => { return (<option key={v.value} value={v.value}>{v.label}</option>);}) }
            </Form.Select>
        </div>

        <BarLineCharts style={{height:'calc(100% - 5em)'}}
            lineChart={{data:props.lineChart.data, title:useSelect2?.label, unit:useSelect2?.unit, color:color1}} 
            barChart={{data:props.barChart.data, title:useSelect1?.label, unit:useSelect1?.unit, color:color2}} 
        />
    </div>)
}

export default StatReportChart;
