import UserSettings from "../../../common/helpers/UserSettings";
// import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import Symbol, { SymbolProps } from "./Symbol";
import { useEffect, useState } from "react";

export interface UserSymbolProps extends Omit <SymbolProps, 'state | src'> {
    useReset?:boolean;
}

/**
 * 심볼마크를 생성합니다.
 * @param props 
 *    name : 이름
 *    initials : 이미지가 없는 경우, 표시된 이니셜(기본값 : 이름의 첫글자)
 *    size : 크기
 *    type : 형태
 * @returns 
 */
const UserSymbol = (props:UserSymbolProps) => {
    const [useSymbol, setUseSymbol] = useState<number>(1);
    const color = UserSettings.symbol.getColor();

    const onDoubleClick = props.useReset ? (e:React.MouseEvent<HTMLDivElement>)=>{
        const symbol = UserSettings.symbol.nextAvatar();
        setUseSymbol(symbol);
    } : undefined;

    useEffect(()=>{ 
        const avatar = UserSettings.symbol.getAvatar();
        setUseSymbol(avatar || 1);

        const tm = window.setInterval(()=>{
            const symbol = UserSettings.symbol.getAvatar();
            if(symbol !== useSymbol){ setUseSymbol(symbol || 1);}
        }, 100);

        return ()=>{
            window.clearInterval(tm);
        }
    // eslint-disable-next-line
    }, []);
    return (<Symbol {...props} state={color} src={`/media/avatars/300-${useSymbol}.jpg`} onDoubleClick={onDoubleClick} />);
}

export default UserSymbol;

