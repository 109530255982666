
export const KakaoConfig = {
    /**
     * 계정 키워드 등록 최대 개수
     */
    maxKeywordCount:100000,

    /** 등록가능한 소재연결 최대 개수 */
    maxCreativeLinkCount: 20000000,

    /**
     * lock 상태값 갱신 주기(초)
     */
    lockStateInterval:60,
    /** Lock 상태인 경우, 갱신주기 */
    lockStateAliveInterval:2,
    
    keywordAd:{
        bizchannel:{
            /** 등록가능한 소재연결 최대 개수 */
            maxCreativeLinkCount: 10000,
        },
        campaign:{
            /**
             * 캠페인당 키워드 등록 최대 개수
             */
            maxKeywordCount:300000,
            /**
             * 캠페인당 광고그룹 등록 최대 개수
             */
            maxAdGroupCount:1000,

            /**
             * 캠페인 등록 최대 개수
             */
            maxRegCount:1000,

            /** 등록가능한 소재연결 최대 개수 */
            maxCreativeLinkCount: 2000,
        },
        adgroup:{
            /**
             * 광고그룹 등록 최대 개수
             */
            maxRegCount:1000,

            /**
             * 광고그룹 키워드 등록 최대 개수
             */
            maxKeywordCount: 1000,

            /** 제외키워드 최대 개수 */
            maxRegExceptionKeywordCount: 100,

            /** 등록가능한 소재연결 최대 개수 */
            maxCreativeLinkCount: 20,
        },

        keyword:{
            /**
             * 키워드 등록 최대 개수
             */
            maxRegCount:1000,
        },

        ad:{
            /**
             * 소재 등록 최대 개수
             */
            maxRegCount:20,

            /** 소재등록 관련 설정사항 */
            create:{
                /** 광고그룹 선택 최대 개수 */
                maxAdGroupSelectCount:100,
            },
        },
    },
}


