import { CSSProperties, useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import style from './LoadingModal.module.scss';

interface LodingProps {
    isShow:boolean,
}

export interface LoadginModalHandler{
    count:number;
    /**
     * 로딩 표시
     * @returns 
     */
    show:()=>void;
    /**
     * 로딩 완료
     * @returns 
     */
    hide:()=>void;
    /**
     * 로딩 표시 모두 삭제
     * @returns 
     */
    clear:()=>void;
}
export const UseLoadingModal = ():LoadginModalHandler=>{
    const [data, setData] = useState<number>(0);
    const show=()=>{ setData((prev)=>++prev); };
    const hide=()=>{ setData((prev)=>{ --prev; return prev < 0 ? 0 : prev} ); };
    const clear=()=>{ setData(0); }
    return {count:data, show, hide, clear};
}


function LoadingModal(props:LodingProps) {
    return (<Modal show={props.isShow} className={style.loading} dialogClassName={style.loading_dialog}>
        <LoadingLayer className={style.overlay_box} />
    </Modal>);
}

export const LoadingLayer:React.FC<{className?:string, style?:CSSProperties, variant?:string}> = (props) => 
    <div className={props.className} style={props.style}>
        <Spinner animation="border" variant={props.variant||"primary"} role='status' />
        <TextSlide width={100} style={{ marginTop: "1em", marginLeft: "-18px", color: "var(--bs-gray-600)", fontWeight: "bold" }}
        >Loading......</TextSlide>
    </div>


function TextSlide(props:{width:number, children?:string|React.ReactNode, style?:CSSProperties}) {
    const [width, setWidth] = useState<string>('');
    useEffect(()=>{
        let w=0;
        const tm = window.setInterval(()=>{
            setWidth(`${w}px`);
            if(++w && w > (props.width+50)){ w=0; }
        }, 20);
        return ()=>{ window.clearInterval(tm); }
    // eslint-disable-next-line
    }, []);
    return (<p className='text-nowrap text-left' style={{ overflow:'hidden', width:width, maxWidth:`${props.width}px`, ...props.style }}>{props.children}</p>);
}

// function LoadingContainer(props:LodingProps) {
//     return (
//         <div style={{display:props.isShow ? "" : "none"}} className={`text-center align-middle align-items-center ${style.loading_container}`}>
//             <div className="text-center align-middle align-items-center" style={{border:"1px solid red", marginTop:"200px"}}>
//                 <Spinner animation="border" variant="primary" role='status' size='sm' />
//                 <p style={{ marginTop: "40px", marginLeft: "-18px", color: "rgb(1B1D1D)", fontWeight: "bold" }}>Loading...</p>
//             </div>
//         </div>
//     );
// }


export default LoadingModal;
// export {
//     LoadingContainer,
// }