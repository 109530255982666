import { AdKeywordResType } from "../naver/AdKeywordTypes";
import { CampaignResType } from "../naver/CampaignTypes";

/** simple List */
export interface TaskSimpleResType{
    id: number;
    name: string;
    customerId: number;
    budgetGoal: number;
    roasGoal: number;
    status: string;
    dayOfWeek: string;
    emails: string;
    conversionType: string;
    campaignids: string[];
    createdDatetime: string;
    modifiedDatetime: string;
    completeDatetime: string;
}

export interface TaskDetailResType{
    id: number;
    name: string;
    customerId: number;
    budgetGoal: number;
    roasGoal: number;
    status: string;
    dayOfWeek: string;
    emails: string;
    conversionType: string;
    campaignList: TaskCampaignResType[];
    createdDatetime: string;
    modifiedDatetime: string;
}

/** task용 캠페인 정보 */
export interface TaskCampaignResType{
    campaignId: string,
    clientId: number;
    dailyBudget: number;
    sharedDailyBudget: number;
    sharedBudgetExpectCost: number;
    numberInUse: number;
    numOfAdGroups: number;
    numOfAdKeywords: number;
    campaignName: string;
    startDate: string;
    endDate: string;
    regTm: string;
    editTm: string;
    status: string;
    campaignType: string;
    deliveryMethod: string;
    trackingMode: string;
    trackingUrl: string;
    statusReason: string;
    sharedBudgetId: string;
    sharedBudgetName: string;
    sharedBudgetDeliveryMethod: string;
    usePeriod: boolean;
    userLock: boolean;
    useDailyBudget: boolean;
    sharedBudgetLock: boolean;
}

export interface TaskResultSuccessTaskResType{
    taskId: number;
    taskExecId: number;
    name: string;
    customerId:number;
    budgetGoal: number;
    roasGoal: number;
    status: string;
    dayOfWeek: string;
    emails: string;
    conversionType: string;
    createdDatetime: string;
    modifiedDatetime: string;
    createdBy: string;
}

/** 오늘 작업 완료 리스트 */
export interface TaskResultTodayResType{
    taskExecId: number;
    taskId: number;
    taskName: string;
    createdDatetime: string;
    keywordCnt: number;
}

/** Task History */
export interface TaskHistoryResType{
    id: number;
    type: string;
    historyContent: string;
    taskId: number;
    beforeData: string;
    afterData: string;
    modifiedBy: string;
    modifiedUserEmail: string;
    modifiedDatetime: string;
}

/** 최적화 결과 키워드 OFF 대상 키워드 조회 */
export interface TaskResultBidAdjustResType{
    taskId: number;
    taskExecId: number;
    customerId: number;
    keywordId: string;
    keyword: string;
    proposalBidding: number;
    proposalReason: string;
    proposalOnOffYn: string;
}

export interface TaskResultKeyworddOffResType extends TaskResultBidAdjustResType{
}

export interface TaskResultSummaryResType{
    taskId: number;
    taskExecId: number;
    customerId: number;
    startDatetime: string;
    endDatetime: string;
    resultDatetime: string;
    budgetGoal: number;
    roasGoal: number;
    conversionType: string;
    bidAdjustCount: number;
    keywordOffCount: number;
    totalCount: number;
}

/**
 * 조회 데이터 타입 
 */
export interface TaskResType{
    id: number;
    name: string;
    customerId: number;
    budgetGoal: number;
    roasGoal: number;
    status: string;
    conversionType: string;
    dayOfWeek: string;
    emails: string;
    campaignids?: string[];
    campaignList?: CampaignResType[];
    createdDatetime: string;
    modifiedDatetime: string;
    createdBy:number;
    createdUserEmail:string;
    completeDatetime: string;
}

/**
 * 조회 데이터 컬럼용 키
 */
export enum TaskResKeys{
    customerId = 'customerId',
    id  = 'id',
    name = 'name',
    operateYn = 'operateYn',
    status = 'status',
    campaignids = 'campaignids',
    budgetGoal = 'budgetGoal',
    roasGoal = 'roasGoal',
    dayOfWeek = 'dayOfWeek',
    emails = 'emails',
    CampaignResType = 'CampaignResType',
    createdDatetime = 'createdDatetime',
    modifiedDatetime = 'modifiedDatetime',
    completeDatetime = 'completeDatetime',
}

/**
 * Post 생성 타입
 */
export interface TaskPostType{
    name: string;
    customerId: number;
    budgetGoal: number;
    roasGoal: number;
    dayOfWeek: string;
    conversionType?: string;    /** 버전전환 임시 : undefined 허용, 1.2버전 이후 undefined 제거 */
    emails: string;
    campaignIds: string[];
}

/**
 *  Put 수정 타입
 */
export interface TaskPutType{
    taskIds: number[];
    name?: string;
    budgetGoal?: number;
    roasGoal?: number;
    dayOfWeek?: string;
    emails?: string;
    conversionType?: string;
    campaignIds?: string[];
}

/**
 * 등록 및 수정 데이터 키
 */
export enum TaskReqKeys{
    ids         = 'ids',
    name        = 'name',
    budgetGoal  = 'budgetGoal',
    roasGoal    = 'roasGoal',
    dayOfWeek   = 'dayOfWeek',
    emails      = 'emails',
    campaignIds = 'campaignIds',
    campaignList= 'campaignList',
    emailList   = 'emailList',
    stop        = 'stop',
    taskList    = 'taskList',
    selectList  = 'selectList',
    conversionType='conversionType',
}

/**
 * 검색 조건
 */
export interface TaskReqSearchType {
    customerId: number;
    taskName?: string;
    status?: string;
    sort?: string[],//RequestOrderType[];
    page?:number;
    size?:number;
}

/** 대시보드용 Task 현황 목록 */
export interface TaskDashboardResType
{
    magId: number;
    magName: string;
    clientId: number;
    clientName: string;
    taskId: number;
    taskName: string;
    dayOfWeek: string;  //MONDAY,
    completeDateTime: string;
    taskExecId: number;
}

/** 연산 결과조회 */
export interface TaskResultResType{
    taskResultId: number;
    bidAmt: number;
    status: string;
    customerId: number;
    taskId: number;
    taskExecId: number;
    keywordId: string;
    keyword: string;
    campaignId: string;
    campaignName: string;
    adGroupId: string;
    adGroupName: string;
    proposalBidding: number;
    proposalReason: string;
    proposalOnOffYn: string;
    preClick: number;
    preCost: number;
    impressionCnt: number;
    clickCnt: number;
    cost: number;
    conversionCnt: number;
    conversionValue: number;
    roas: number;
    useGroupBidAmt: boolean;

    /** 실시간 키워드 정보 */
    info?: AdKeywordResType;
}

/** 연산 결과 조회용 키 */
export enum TaskResultResKeys{
    taskResultId    = "taskResultId",
    bidAmt	        = "bidAmt",
    status	        = "status",
    customerId      = "customerId",
    taskId          = "taskId",
    taskExecId      = "taskExecId",
    keywordId       = "keywordId",
    keyword         = "keyword",
    campaignId      = "campaignId",
    campaignName    = "campaignName",
    adGroupId       = "adGroupId",
    adGroupName     = "adGroupName",
    proposalBidding = "proposalBidding",
    proposalReason  = "proposalReason",
    proposalOnOffYn = "proposalOnOffYn",
    preClick        = "preClick",
    preCost         = "preCost",
    impressionCnt   = "impressionCnt",
    clickCnt        = "clickCnt",
    cost            = "cost",
    conversionCnt   = "conversionCnt",
    conversionValue = "conversionValue",
    roas            = "roas",

    info            = "info",
}

/** 연산 결과 데이터 관리용 */
export interface TaskResultAdjustType{
    keywordId:string;
    adGroupId:string;
    
    // 제어용
    /** 제안 입찰가 사용여부 */
    useProposal?:boolean;

    /** 사용자 정의 입찰가 사용여부 */
    useCustomBid?:boolean;

    /** 변경입찰가 */
    customBidAmt?:number;

    /** 수정모드여부 */
    editMode?:boolean;

    /** 수정대상여부 */
    disabled?:boolean;

    /** 키워드 정보 */
    info?: TaskResultResType;
}

/** 연산 결과 데이터 관리용 */
export enum TaskResultAdjustKeys{
    keywordId = "keywordId",
    useProposal = "useProposal",
    useCustomBid = "useCustomBid",
    customBidAmt = "customBidAmt",
    editMode = "editMode",
    disabled = "disabled",
    info = "info",
    lockState = "lockState",
}

/** 연산 결과 예측 순위조회 목록 */
export interface TaskResultRankResType{
    keywordId: string;
    taskExecId: number;
    rank: number;
    preClick: number;
    preCost: number;
    proposalBidding: number;
}
