import { NaverServer } from '../../axios';
import Utils from '../../utils/Utils';

/** 네이버 동기화 Helper - 미작업분 */
export default class SyncHelper{
    // 동기화 목록 로딩
    public static getList(){
        return Utils.apiRequestValidation({}) || NaverServer.get(`/api/sync/naversa/status`,{params:{useAccount:true}});
    }

    /**
     * 빠른 동기화를 위한 API
     * @param type : sync.put과 맞추기 위한 파라미터이며 사용되지 않습니다.
     * @param id : targetId 사용안함
     * @returns Promise
     */
    public static fast(type:string, id?:string){
        const url =`/api/sync/naversa/advertiser/fast?id=0`;
        return Utils.apiRequestValidation({}) ||  NaverServer.put(url,{params:{useAccount:true}});
    }

    //동기화 요청
    public static put(type:string, id?:string){
        let targetName="";
        switch(type){
            case "AllSyncButton":
            case "E01N1001" : targetName = "advertiser?id="; break;
            case "E01N1002" : targetName = "campaign?campaignId="; break;
            case "E01N1003" : targetName = "channel?id="; break;
            case "E01N1004" : targetName = "adgroup?adgroupId="; break;
        }
        let url =`/api/sync/naversa/${targetName}${id}`;
        return Utils.apiRequestValidation({}) || NaverServer.put(url,{params:{useAccount:true}});
    }

    // case "AllSyncButton" :  //전체 동기화버튼
    // case "E01N1001" : uri = "/sync/naversa/advertiser?id="; msg="해당 계정의 모든정보가 동기화 됩니다!\n예상소요시간 : 보통 20~30분, 최대 5시간"; break;
    // case "E01N1002" : uri = "/sync/naversa/campaign?campaignId="; msg="해당 캠페인과 하위 광고그룹 및 광고키워드, 소재, 확장소재, 제외키워드 등 모든 정보가 동기화 됩니다!"; break;
    // case "E01N1003" : uri = "/sync/naversa/channel?id="; msg="해당 계정의 모든 채널 정보가 동기화 됩니다!"; break;
    // case "E01N1004" : uri = "/sync/naversa/adgroup?adgroupId="; msg="해당 광고그룹 및 하위 광고키워드, 소재, 확장소재, 제외키워드 등 모든 정보가 동기화 됩니다!"; break;
    // default : alert("잘 못된 명령입니다!"); return; break;


    //테이블 파티션 생성 
    // public static partitionAdd(targetId:string, userContext?:UserContextInfo){
    //     const valid = Utils.apiRequestValidation({userContext}); if(valid){ return valid; }
    //     return naverServer.post(`/api/partition/add`,{params:{addAdvertiser:true}, data:[{targetId:targetId}]});
    // }
}

