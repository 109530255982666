import { LabelColumnType } from "../LabelTypes";
import { StatReportResKeys, StatReportResType } from "./StatReportType";

/**
 * 광고키워드 조회 데이터 타입 
 */
export interface AdKeywordResType extends Omit<StatReportResType, 'key'>, LabelColumnType{
    keywordId: string;
    accountGroupId: number;
    clientId: number;
    campaignId: string;
    campaignName: string;
    campaignType: string;
    adGroupId: string;
    adGroupName: string;
    adGroupType: string;
    keyword: string;
    pcLinkDisplayUrl: string;
    pcLinkFinalUrl: string;
    pcLinkPunyCode: string;
    moLinkDisplayUrl: string;
    moLinkFinalUrl: string;
    moLinkPunyCode: string;
    userLock: boolean;
    inspectStatus: string;
    bidAmt: number;
    groupBidAmt: number;
    useGroupBidAmt: boolean;
    regTm: string;
    editTm: string;
    dbidTaskId: number|null;
    dbidStatus: string|null;
    autoBidYn: string;
    labelValue: number;
}

/**
 * 광고키워드 조회 데이터 컬럼용 키
 */
export enum AdKeywordResKeys{
    labelValue = "labelValue",
    keywordId  = 'keywordId',
    accountGroupId = 'accountGroupId',
    clientId = 'clientId',
    campaignId = 'campaignId',
    campaignName = 'campaignName',
    campaignType = 'campaignType',
    adGroupId = 'adGroupId',
    adGroupName = 'adGroupName',
    adGroupType = 'adGroupType',
    keyword = 'keyword',
    pcLinkDisplayUrl = 'pcLinkDisplayUrl',
    pcLinkFinalUrl = 'pcLinkFinalUrl',
    pcLinkPunyCode = 'pcLinkPunyCode',
    moLinkDisplayUrl = 'moLinkDisplayUrl',
    moLinkFinalUrl = 'moLinkFinalUrl',
    moLinkPunyCode = 'moLinkPunyCode',
    userLock = 'userLock',
    inspectStatus = 'inspectStatus',
    bidAmt = 'bidAmt',
    groupBidAmt = 'groupBidAmt',
    useGroupBidAmt = 'useGroupBidAmt',
    regTm = 'regTm',
    editTm = 'editTm',
    status = 'status',
    statusReason = 'statusReason',
    dbidTaskId = 'dbidTaskId',
    dbidStatus = 'dbidStatus',
    autoBidYn = 'autoBidYn',
}

/**
 * 키워드 추가 데이터 타입
 */
export interface AdKeywordPostType{
    keywordInfos: AdKeywordPostKeywordType[];
}

/**
 * 광고키워드 Post 키워드 데이터 타입
 */
export interface AdKeywordPostKeywordType{
    keyword: string;
    useGroupBidAmt: boolean;
    bidAmt: number;
    userLock: boolean;
    clearUrl?: boolean;
    pcUrl?: string;
    moUrl?: string;
}

/**
 * 광고키워드 수정 데이터 타입
 */
export interface AdKeywordPutType{
    keywordInfos: AdKeywordPutKeywordType[];
}

/**
 * 광고키워드 수정 키워드 데이터 타입
 */
export interface AdKeywordPutKeywordType{
    adGroupId: string;
    keywordId: string;
    keyword?: string;
    useGroupBidAmt?: boolean;
    bidAmt?: number;
    userLock?: boolean;
    clearUrl?: boolean;
    pcUrl?: string;
    moUrl?: string;
}

/**
 * 광고키워드 복사 POST Body
 */
export interface AdKeywordCopyType{
    targetAdGroupId:string;
    keywordIds:string[];
    includeUrl:boolean;
    includeBidAmtConfig:boolean;
    userLock:boolean;
}

/**
 * 광고키워드 등록 및 수정 데이터 키
 */
export enum AdKeywordReqKeys{
    adGroupId = 'adGroupId',
    keywordId = 'keywordId',
    useGroupBidAmt = 'useGroupBidAmt',
    bidAmt = 'bidAmt',
    userLock = 'userLock',
    clearUrl = 'clearUrl',
    pcUrl = 'pcUrl',
    moUrl = 'moUrl',
    keywordList = 'keywordList',
    campaignId = 'campaignId',
    keyword = 'keyword',
    campaign = 'campaign',
    campaignLimit = 'campaignLimit',
    adgroup = 'adgroup',
    adgroupLimit = 'adgroupLimit',
    possibleRegCnt = 'possibleRegCnt',
    landingUrlType = 'landingUrlType',
    bidAmtType = 'bidAmtType',
    adGroupBidAmt = 'adGroupBidAmt',

    includeUrl='includeUrl',
    includeBidAmtConfig='includeBidAmtConfig',

}


export const AdKeywordKeys = {...AdKeywordResKeys, ...StatReportResKeys, lockState:"lockState"};