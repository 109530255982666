
export const NotificationConfig = {
    /** 알림 팝업 표시개수 */
    popupListCount: 10,
    /** 알림 목록 표시개수 */
    pageListCount: 30,

    /** 알림 체크 주기(초) */
    statusCheckInterval:10,

}


