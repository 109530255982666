import clsx from 'clsx';
import styles from './ellisis.module.scss';
import { CSSProperties } from 'react';
import {omit} from 'lodash';

interface EllipsisProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>{
    layerClassName?:string;
    layserStyle?:CSSProperties;

}
const Ellipsis:React.FC<EllipsisProps> = (props)=>{
    return <div className={props.layerClassName} style={{display:'inline-flex', ...props.layserStyle}}>
        <div {...omit(props, ['layerClassName', 'layserStyle'])} className={clsx(styles.ellisis, props.className)}></div>
    </div>
}

export default Ellipsis;