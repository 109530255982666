/* eslint-disable jsx-a11y/anchor-is-valid */
// import {useState} from 'react';
// import {useLayout} from '../../../core';
import { ThemeControll } from '../../../../../app/common/conf/ThemeConfig';
import UserSelect from '../../../../../app/common/helpers/UserSelect';
import AdPloDateRangePicker from '../../../../../app/pages/components/input/AdPloDateRangePicker';

const ToolbarCustom= () => {
//   const {config} = useLayout()
//   const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
//   const daterangepickerButtonClass = config.app?.toolbar?.fixed?.desktop
//         ? 'btn-light'
//         : 'bg-body btn-color-gray-700 btn-active-color-primary';
    const range = UserSelect.dateRange.get();
    const isShow = ThemeControll.isStatShow();
    
    return (
        <div className='d-flex align-items-center gap-2 gap-lg-3'>
            {isShow && <AdPloDateRangePicker
                onChange={(startDate, endDate)=>{ UserSelect.dateRange.set(startDate.format('yyyy-MM-dd'), endDate.format('yyyy-MM-dd'))}}
                startDate={range?.startDate ? new Date(range?.startDate) : undefined}
                endDate={range?.endDate ? new Date(range?.endDate) : undefined}
            />}
        </div>
    )
}

export { ToolbarCustom };

