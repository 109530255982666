import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import './ToggleSwitch.scss';

interface ToggleSwitchProps{
    name?: string;
    'data-no'?: string;
    'data-yes'?: string;
    onChange?: (e:React.ChangeEvent<HTMLInputElement>)=>void;
    style?: React.CSSProperties;
    className?: string;
    checked?:boolean;
}
const ToggleSwitch:React.FC<ToggleSwitchProps> = (props)=>{
    const uid = uuid();
    return <div className={clsx("toggle-switch w-40px", props.className)} style={props.style}>
        <input
            type="checkbox"
            className="toggle-switch-checkbox"
            name={props.name || uid}
            id={props.name || uid}
            onChange={props.onChange}
            checked={props.checked}
        />
        <label className="toggle-switch-label" htmlFor={props.name || uid}>
        <span className="toggle-switch-inner" data-no={props['data-no']} data-yes={props['data-yes']} />
        <span className="toggle-switch-switch" />
        </label>
    </div>
};

export default ToggleSwitch;