import { StatReportResType } from "./StatReportType";

export interface BusinessInfoType_SITE {
    site:string;
    isNaverLogin: number;
    isMobileNaverLogin: number;
    naAccountId:string;
    naAccountType: number;
    useNaverPayNaScript: number;
    useStoreFarmNaScript: number;
    isNaverPay: number;
    isMobileNaverPay: number;
    useSaNaScript: number;
    mobileCertStatus: number;
    isStoreFarm: number;
    thumbnailPath: string;
    originalPath: string;

    inspectId: string;
    inspectPw: string;
    isMedical: number;
    isNaverTalkTalk: number;
    isMobileNaverTalkTalk: number;
    accumulatedAdditionalPoint: string;
}

export interface BusinessInfoType_PHONE{
    phone: string;
    primary: string|null;
    secondary: string|null;
    countryCallingCode: string;
    phoneTp: string;
    mobileCertStatus: number;
}
export interface BusinessInfoType_ADDRESS{
    roadNameAddress: string;
    roadNameExtendAddress: string;
    lotNumberAddress: string;
    lotNumberExtendAddress: string;
    mapZoomLevel: number;
    latitude: number;
    longitude: number;
    zipCode: string;
    mobileCertStatus: number;
}

export interface BusinessInfoType_MALL{
    mallNm: string;
    mallUrl: string;
    mallId: string;
    isNaverPay: number;
    isMobileNaverPay: number;
    useNaverPayNaScript: number;
    naAccountId: string;
    useSaNaScript: number;
    regmStatCd: string;
    adsrTypeCd: string;
    useStoreFarmNaScript: number;
    mallTpCd: string;
    naverPayServiceTpCd: string;
    naverPayMerchantNo: number;
    naAccountType: number;
    mobileCertStatus: string|null;
    isStoreFarm: number;
    isMedical: number;
}

export interface BusinessInfoType_BOOKING{
    url: string;
    bookingStatus: string;
    bookingId: number;
    representativeName:string;
    bookingName: string;
    mobileCertStatus: number;
}

export interface BusinessInfoType_TALK{
    talkAccountId: string;
    talkChannelId: string;
    channelKey: string;
    talkStatus: number;
    url: string;
}

export interface BusinessInfoType_CATALOG{
    makerName:string;
    naAccountId:string;
}

export interface BusinessInfoType_NAVER_TV{
    title:string;
    category:string;
}

export type BusinessInfoType =
    BusinessInfoType_SITE |
    BusinessInfoType_PHONE |
    BusinessInfoType_ADDRESS |
    BusinessInfoType_BOOKING |
    BusinessInfoType_TALK |
    BusinessInfoType_CATALOG |
    BusinessInfoType_NAVER_TV |
    BusinessInfoType_MALL;


/**
 * naver - 비스니스 채널 정보
 */
export interface BusinessChannelInfoType{
    site: string;
    isNaverPay: number;
    isMobileNaverPay: number;
    isStoreFarm: number;
    isNaverLogin: number;
    isMobileNaverLogin: number;
    isNaverTalkTalk: number;
    isMobileNaverTalkTalk: number;
    useSaNaScript: number;
    useNaverPayNaScript: number;
    useStoreFarmNaScript: number;
    naAccountId: string;
    naAccountType: number;
    mobileCertStatus: number;
    inspectId: string;
    inspectPw: string;
}


/**
 * 비즈니스채널 데이터 타입
 */
export interface BusinessChannelResType<T=BusinessChannelInfoType> extends Omit<StatReportResType, 'key'>{
    businessChannelId: string;
    accountGroupId: number;
    clientId: number;
    name: string;
    channelType: string;
    adultStatus: string;
    blackStatus: string;
    businessInfo: T;
    channelKey: string;
    inspectRequestMsg: string;
    pcInspectStatus: string;
    mobileInspectStatus: string;
    status: string;
    statusReason: string;
    regTm: string;
    editTm: string;
}

/**
 * Naver-비즈니스채널 : 데이터 출력용 키
 */
export enum BusinessChannelResKeys{
    businessChannelId = 'businessChannelId',
    accountGroupId = 'accountGroupId',
    clientId = 'clientId',
    name = 'name',
    channelType = 'channelType',
    adultStatus = 'adultStatus',
    blackStatus = 'blackStatus',
    businessInfo = 'businessInfo',
    channelKey = 'channelKey',
    inspectRequestMsg = 'inspectRequestMsg',
    pcInspectStatus = 'pcInspectStatus',
    mobileInspectStatus = 'mobileInspectStatus',
    status = 'status',
    statusReason = 'statusReason',
    regTm = 'regTm',
    editTm = 'editTm',

    businessInfo_site = 'businessInfo.site',
    businessInfo_isNaverPay = 'businessInfo.isNaverPay',
    businessInfo_isMobileNaverPay = 'businessInfo.isMobileNaverPay',
    businessInfo_isStoreFarm = 'businessInfo.isStoreFarm',
    businessInfo_isNaverLogin = 'businessInfo.isNaverLogin',
    businessInfo_isMobileNaverLogin = 'businessInfo.isMobileNaverLogin',
    businessInfo_isNaverTalkTalk = 'businessInfo.isNaverTalkTalk',
    businessInfo_isMobileNaverTalkTalk = 'businessInfo.isMobileNaverTalkTalk',
    businessInfo_useSaNaScript = 'businessInfo.useSaNaScript',
    businessInfo_useNaverPayNaScript = 'businessInfo.useNaverPayNaScript',
    businessInfo_useStoreFarmNaScript = 'businessInfo.useStoreFarmNaScript',
    businessInfo_naAccountId = 'businessInfo.naAccountId',
    businessInfo_naAccountType = 'businessInfo.naAccountType',
    businessInfo_mobileCertStatus = 'businessInfo.mobileCertStatus',
    businessInfo_inspectId = 'businessInfo.inspectId',
    businessInfo_inspectPw = 'businessInfo.inspectPw',
}
