import {CSSProperties, useEffect, useState} from 'react';
import { Dropdown, Form } from "react-bootstrap";
import { OptionType } from '../../../../modules/types/Types';
import clsx from 'clsx';
import { FormCheckInputProps } from 'react-bootstrap/esm/FormCheckInput';
import styles from './multiselect.module.scss';
import { v4 as uuid} from 'uuid';


export interface MultiSelectProps {
    placeholder?: string;
    search?:string;
    value?: string[];
    size?: SizeType;
    onChange?: (values:string[] ,items?:OptionType[])=>void;
    options:OptionType[];
    className?:string;
    style?:CSSProperties;
    disabled?:boolean;
    isViewer?:boolean;
    disableSearch?:boolean;
    hasSelectAll?:boolean;
    scrollHeight?:string;
}
export type SizeType = 'xs' | 'sm' | 'lg';

interface OptionSizeType{
    style?:CSSProperties;
    className?:string;
    checkbox:{
        style?:CSSProperties;
        className?:string;
    }
    item:{
        style?:CSSProperties;
        className?:string;
    }
}

const getStyle = (size?:SizeType):OptionSizeType=>{
    const option:OptionSizeType = {
        checkbox:{},
        item:{},
    };

    switch(size){
        case "xs" :
            option.className = 'p-0';
            option.checkbox.style = {width:'1em', height:'1em', borderRadius:'0.23em'};
            option.item.className = 'm-1';
            option.style={fontSize:'var(--bs-btn-font-size)'};
            break;
        case "sm" :
            option.className = 'p-2 ps-4';
            option.checkbox.style = {width:'1.35em', height:'1.35em', borderRadius:'0.3em'};
            option.item.className = 'm-3 ms-1';
            option.style={fontSize:'var(--bs-btn-font-size)'};
            break;
        case "lg" :
            option.className = 'p-5';
            option.checkbox.style = {width:'2em', height:'2em', borderRadius:'0.3em'};
            option.item.className = 'm-5 ms-3';
            break;
        default :
            option.item.className = 'p-2 pt-2';
    }
    return option;
}

/**
 * 드롭다운 버튼
 * @param props 
 * @returns 
 */
const MultiSelect:React.FC<MultiSelectProps> = (props)=>{
    const [useSearch, setUseSearch] = useState<string>('');
    const [useValues, setUseValues] = useState<OptionType[]>([]);
    //선택창 옵션처리는 모두 필터된 목록을 기반으로 처리합니다.
    const filteredOtions:OptionType[] = props.options.filter((v)=>!useSearch || v.label.includes(useSearch) );

    useEffect(()=>{
        setUseValues( props.options.filter((v)=>props.value?.includes(v.value)) || []);
    },[props.options, props.value]);
    const uid = uuid();

    const handleOnChange = (item:OptionType|null, checked:boolean)=>{
        if(props.disabled){ return; }
        let prev = useValues.filter((v)=>item!==null && v.value!==item.value);
        if(checked){ item!==null ? prev.push(item) : prev = [...props.options]; }
        const values:string[] = prev.map((v)=>v.value);
        props.onChange && props.onChange(values, prev);
        setUseValues(prev);
    }

    const handleOnChangeAll = (checked:boolean)=>{
        if(props.disabled){ return; }
        const selectIds:string[] = filteredOtions.map((v)=>v.value);
        let prev = useValues.filter((v)=>!selectIds.includes(v.value));
        if(checked){ prev.push(...filteredOtions); }
        const values:string[] = prev.map((v)=>v.value);
        props.onChange && props.onChange(values, prev);
        setUseValues(prev);
    }

    const isAllSelected = useValues.length>0 && useValues.length === props.options.length;
    const isAllChecked = filteredOtions.length>0 && useValues.length>0 && useValues.filter(v=>filteredOtions.find(v2=>v.value===v2.value)).length === filteredOtions.length;

    /// Size ///
    const option:OptionSizeType = getStyle(props.size);

    return (<Dropdown style={{width:props.style?.width}} className={props.className}>
        <div style={{position:'relative'}}>
            <Dropdown.Toggle as="label"
                className={clsx('d-flex form-control', styles.multiselect_toggle, option.className )} 
                style={{...option.style, ...props.style}}
                disabled={props.disabled}
            >
                <div style={{width: `calc(100% - ${useValues.length>0 ? "2em" : "1em"})`}}>
                    <div style={{whiteSpace: 'nowrap', height:'1.5em', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        {isAllSelected && '전체'}
                        {!isAllSelected && useValues.map((v)=>v.label).join(', ')}
                        {useValues.length===0 && (props.placeholder || '선택')} 
                    </div>
                </div>
                { useValues.length > 0 &&
                    <i className={clsx('bi bi-x-lg', styles.multiselect_remove_icon)} title='비우기'
                        style={{position:'absolute', marginTop:'0.23em', right:'1.5em'}} onClick={()=>{handleOnChange(null, false)}}></i>
                }
                <i className={clsx('bi bi-chevron-down')} style={{position:'absolute', marginTop:'0.23em', right:'0.3em'}} />
            </Dropdown.Toggle>
        </div>

        <Dropdown.Menu className='p-3' style={{display:props.disabled===true ? 'none' : ''}}>
            {!props.disableSearch && <>
                <div className='d-flex' style={{position:'relative'}}>
                    <Form.Control className='p-0 ps-2 border-0' value={useSearch} onChange={(e)=>{setUseSearch(e.target.value)}} 
                        disabled={props.disabled} placeholder={props.search || 'Search'} 
                        style={{width: 'calc(100% - 1em)', height:'inherit'}} />
                    <i className={clsx('bi bi-x-lg', styles.multiselect_remove_icon)} title='비우기'
                        style={{position:'absolute', marginTop:'0.5em', right:'0'}} onClick={()=>{setUseSearch('')}}></i>
                </div>
                <hr className='mt-2 mb-2' />
            </>}
            <div className='scroll' style={{maxHeight:props.scrollHeight || '50vh', }}>
                {props.hasSelectAll!==false && <CheckBox 
                    id={`multiselect_chk-${uid}-total`}
                    option={{value:'', label:'전체'}}
                    className={clsx(styles.multiselect_item, option.item.className)} 
                    checkbox={option.checkbox}
                    isViewer={props.isViewer}
                    disabled={props.disabled}
                    onChange={(item, checked)=>{handleOnChangeAll(checked);}}
                    checked={isAllChecked} 
                />}

                {filteredOtions.map((v,index)=>{
                    return (<CheckBox
                        key={index} 
                        id={`multiselect_chk-${uid}-${index}`}
                        option={v}
                        className={clsx(styles.multiselect_item, option.item.className)} 
                        checkbox={option.checkbox}
                        isViewer={props.isViewer}
                        disabled={props.disabled}
                        onChange={handleOnChange}
                        checked={useValues.find((v1)=>v1.value===v.value) ? true : false}
                    />);
                })}
            </div>
        </Dropdown.Menu>
    </Dropdown>)
}

interface CheckBoxProps extends Omit<FormCheckInputProps, 'onChange'>{
    isViewer?: boolean;
    option:OptionType;
    onChange:(item:OptionType, checked:boolean)=>void;
    checkbox?:{
        style?:CSSProperties;
        className?:string;
    }
}
const CheckBox:React.FC<CheckBoxProps> = (props)=>{
    return (<div className={clsx('d-flex align-items-center', props.className, styles.multiselect_remove_itemm)} style={{whiteSpace:'nowrap', ...props.style}}>
        <Form.Control 
            className={clsx('form-check-input cursor-pointer',props.checkbox?.className)} 
            type="checkbox"
            id={props.id}
            style={{minHeight:"auto", minWidth:'auto', padding:0, display:props.isViewer===true ? "none" : "", ...props.checkbox?.style}}
            disabled={props.disabled}
            onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{props.onChange(props.option, e.target.checked);}}
            checked={props.checked} />
        <label className="me-3 ps-2" htmlFor={props.id}>{props.option.label}</label>
    </div>)
}


// interface RemoveContainerProps extends React.HTMLAttributes<HTMLDivElement>{
//     onRemove?: ()=>void;
// }

// const RemoveContainer:React.FC<RemoveContainerProps> = (props)=>{
//     return (<div {...omit(props, ['onRemove'])} style={{position:'relative', ...props.style}}>
//         <div style={{width:'calc(100% - 1em'}}>{props.children}</div>
//         <i className={clsx('bi bi-x-lg', styles.multiselect_remove_icon)} 
//             style={{position:'absolute', top:'0.3em', right:0}} title='삭제'
//             onClick={props.onRemove}></i>
//     </div>)
// }

// /**
//  * 선택된 항목 개별삭제용 아웃렛
//  * @param props 
//  * @returns 
//  */
// const RemoveLabel:React.FC<RemoveContainerProps> = (props)=>{
//     return (<span {...omit(props, ['onRemove'])} className='border ms-1 me-1' style={{position:'relative', backgroundColor:'var(--bs-gray-200)', ...props.style}}>
//         {props.children}
//         <i className={clsx('bi bi-x-lg ms-1', styles.multiselect_remove_icon)} title='삭제' onClick={props.onRemove}></i>
//     </span>)
// }

export default MultiSelect;