import React, {useState} from 'react';
import {Fade} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import {AlertMessageType} from './AlertMessageType';
import { MessageHandler } from '../Types';
import { IconDanger, IconInfo, IconSuccess, IconTypeProps, IconWarning } from '../../icon';
import clsx from 'clsx';

interface AlertMessageProps{
    data:AlertMessageType[];
    onClose(flag:boolean, index:number):void;
    className?:string;
}

/**
 * Alert 메세지를 관리합니다.
 * @returns 
 */
export const UseAlertMessage = ():MessageHandler<AlertMessageType>=>{
    const [data, setData] = useState<AlertMessageType[]>([]);
    const close=(flag:boolean, index:number)=>{
        setData((prev)=>{ prev[index].show = flag; return [...prev]});
    };
    const add=(item:AlertMessageType)=>{
        // if(data.find((v)=>(
        //     v.body?.toString()===item.body?.toString() 
        //     && v.title?.toString()===item.title?.toString()
        //     && v.show
        // ))){ return; }  //동일한 메세지 중복 등록 방지 - 잘 안됨
        setData((prev)=>{
            prev = prev.filter((v)=>v.show);    //기존 닫힌 메세지는 제거
            prev.push({show:true, ...item});
            return [...prev];
        });
     };
    const clear=()=>{setData([]);};
    return {data, setData, close, add, clear};
}

const AlertMessage = (props:AlertMessageProps)=>{
    return (<div className={clsx('position-sticky', props.className)}
        style={{
            left:'calc(var(--bs-app-sidebar-width) + 1px)',
            top:'var(--bs-app-header-height)', 
            maxWidth:'calc(100vw - var(--bs-app-sidebar-width) - 18px)',
            zIndex:10,
        }}
    >{
        props.data.map((v,i)=>{
            let Icon: React.ReactNode | React.FC<IconTypeProps> | undefined = v.icon;
            if(!Icon){
                switch(v.variant){
                    case "danger" : Icon = IconDanger; break;
                    case "warning" : Icon = IconWarning; break;
                    case "success" : Icon = IconSuccess; break;
                    case "primary" : break;
                    case "secondary" : break;
                    case "light" : break;
                    case "dark" : break;
                    case "info" :
                    default:
                        Icon = IconInfo; break;
                }
            }
            return (
                <Alert
                    key={i}
                    className='collapsed d-flex p-2 ps-3 mb-1'
                    variant={v.variant || "info" }
                    show={typeof(v.show)!=='undefined' ? v.show : true} 
                    transition={Fade} 
                    onClose={()=>props.onClose(false, i)} 
                    bsPrefix={v.bsPrefix || "alert"} 
                    dismissible={typeof(v.dismissible)!=='undefined' ? v.dismissible : true}
                >
                    {Icon && <Icon className='mt-1 me-2' style={{fontSize:'1.5em'}} />}
                    <div className="flex-column" style={{fontSize:'1em'}}>
                        <Alert.Heading className='m-0' style={{fontSize:'1em'}}>{v.title}</Alert.Heading>
                        <div>{v.body}</div>
                    </div>
                </Alert>  
            )
        })
    }
    </div>);
}

export default AlertMessage;