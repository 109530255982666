import React, {CSSProperties, useState } from "react";
import { ChartDataType, ChartOptionType, StatReportChart } from "../../modules/charts";
import { StatReportResType} from "../../../common/services/models/kakao/StatReportType";
import clsx from "clsx";
import { KakaoOxfordColumns } from "../modules/KakaoOxfordColumns";

const options:ChartOptionType[] = KakaoOxfordColumns.map((v)=>({
    label: v.header?.toString() || '-',
    value: v.accessor || '',
    unit: v.unit,
}));


interface ExplorerChartProps{
    className?: string;
    style?: CSSProperties;
    data: StatReportResType[];
}

const ExplorerChart:React.FC<ExplorerChartProps> = (props) => {
    const [value1, setValue1] = useState<ChartOptionType>(options[0]);
    const [value2, setValue2] = useState<ChartOptionType>(options[1]);

    return <StatReportChart 
        options={options}
        style={{height:'20em', ...props.style}}
        className={clsx('border mt-3', props.className)}
        barChart={{
            value: value1,
            data: props.data.map((v,index):ChartDataType=>[getTime(v.key), getColumn(v, value1.value) || 0]),   // 선이 이어지게하는 경우, '0' | 아에 빼는 경우 null
            onChange:(value)=>{setValue1(value);},
        }}
        lineChart={{
            value: value2,
            data: props.data.map((v,index):ChartDataType=>[getTime(v.key), getColumn(v, value2.value) || 0]),   // 선이 이어지게하는 경우, '0' | 아에 빼는 경우 null
            onChange:(value)=>{setValue2(value);},
        }}
    />
}

const getColumn = (value:StatReportResType, key:string) => {
    return new Map(Object.entries(value)).get(key)
}

/**
 * 20230622 --> 2023-06-22
 * @param txt 
 * @returns 
 */
const getTime = (txt:string)=>{
    return new Date(txt.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')).getTime();
}

export default ExplorerChart;