import {KakaoServer} from '../../axios';
import Utils from '../../utils/Utils';

/** 카카오 동기화 */ 
export default class SyncHelper{
    // 동기화 목록 로딩
    public static getList(){
        return Utils.apiRequestValidation({}) || KakaoServer.get(`/api/kakaokeywordad/sync/status`,{headers:{useAccount:true}});
    }

    //동기화 요청
    public static put(type:string, id?:string){
        let targetName="";
        //AD_ACCOUNT,CAMPAIGN,BIZ_CHANNEL, ADGROUP, KEYWORD, CREATIVE, CREATIVE_LINK
        switch(type){
            case "E01N1201" : targetName = "AD_ACCOUNT"; break;
            case "E01N1203" : targetName = "BIZ_CHANNEL"; break;
            case "E01N1202" : targetName = "CAMPAIGN"; break;
            case "E01N1204" : targetName = "ADGROUP"; break;
        }
        let url =`/api/kakaokeywordad/sync/execute?targetName=${targetName}&targetId=${id}`;
        return Utils.apiRequestValidation({}) || KakaoServer.put(url,{params:{addAdvertiserClientId:true}});
    }
    //테이블 파티션 생성 
    public static partitionAdd(targetId:string){
        return Utils.apiRequestValidation({}) || KakaoServer.post(`/api/partition/add`,{headers:{useAccount:true}, data:[{targetId:targetId}]});
    }
}
