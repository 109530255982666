import {KakaoServer} from '../../axios';
import Utils from '../../utils/Utils';
import { ImageFilePostType } from '../models/kakao/ImageTypes';
import KakaoHelperComponent from './KakaoHelperComponent';

/** 카카오 이미지관리 */
export default class ImageHelper extends KakaoHelperComponent{
    static path="/media/image"; //기본 Path

    /** 파일을 S3에 업로드합니다.  */
    public static fileUpload(data:ImageFilePostType){
        const url = new URLSearchParams(Object.entries(data));
        return Utils.apiRequestValidation({}) || KakaoServer.post<any>(`/media/upload-images?${url}`, undefined, {headers:{useAccount:true}});
    }
}
