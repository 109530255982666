
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import './customSelect.css';

interface CustomSelectProps{
    className?:string;
    options:CustomSelectOption[];
    value?:string;
    onChange?(value:string,label?:string | React.ReactNode):void;
    container?(v:string):string | Element | HTMLElement | React.RefObject<HTMLElement>;
    style?:React.CSSProperties;
    menuStyle?:React.CSSProperties;
    itemStyle?:React.CSSProperties;
    placeholder?:string;
    variant?:string;
}

export interface CustomSelectOption{
    label:string | React.ReactNode;
    value:string;
    type?:"header" | "text" | "divider" | "disabled" | "client";
}

function CustomSelect(props:CustomSelectProps) {
    const [useValue, setUseValue] = useState('');
    useEffect(()=>{ setUseValue(props.value || ''); },[props.value]);

    const label = props.options.find((v)=>v.value===useValue)?.label || props.placeholder || "선택";
    // const style:CSSProperties = Object.assign({maxHeight:"80vh", overflowY: "auto"}, props.style || {});

    return (<Dropdown className={clsx('custom-select', props.className)} defaultValue={useValue}>
        <Dropdown.Toggle className="d-flex btn-sm" variant={props.variant} style={props.style}>{label}</Dropdown.Toggle>
        <Dropdown.Menu style={{overflowY:'scroll', ...props.menuStyle}}>{
            props.options.map((v)=>{
                const handleOnClick = ()=>{
                    setUseValue(v.value);
                    props.onChange && props.onChange(v.value, v.label);
                }
                return (<Dropdown.Item key={v.value} className={v.value===useValue ? "checked active" : ""}
                    disabled={ ['header', 'disabled', 'divider'].includes(v.type || "") || v.value===useValue}
                    // header={v.type==="header"}
                    // divider={v.type==="divider"}
                    // text={v.type==="text"}
                    defaultValue={v.value}
                    onClick={handleOnClick}
                    style={{
                        margin: 0,
                        padding: v.type==='header' ? 'var(--bs-dropdown-item-padding-y) 0 0.2em var(--bs-dropdown-item-padding-x)' : '0.2em 0.2em 0.2em 2em',
                        ...props.itemStyle,
                    }}
                >{v.label}</Dropdown.Item>);
            })
        }</Dropdown.Menu>
    </Dropdown>);
}

export default CustomSelect;