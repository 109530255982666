import clsx from "clsx";
import { omit } from 'lodash';
import { Card } from "react-bootstrap";
import { CustomCardProps, Warning } from "./CustomCard";

const CustomBaseCard:React.FC<CustomCardProps> = (props)=>{  
    const warning_props = omit(props, ['className', 'style']);  
    return (<Card className={props.className} style={{display:props.show===false ? 'none' : '', ...props.style}}>
        <Card.Body className={props.bodyClassName}>
            {props.title && (<div key='title' className="fw-bolder mb-3">
                <div className={props.titleClassName}>{props.title}</div>
                {props.description && (<div key='description' className={clsx("fw-normal text-gray-700 lh-sm", props.descriptionClassName)}>{props.description}</div>)}
            </div>)}
            <div key='body' className={props.bodyClassName}>
                {props.children}
            </div>
            <Warning className='pt-5' {...warning_props} />
        </Card.Body>
    </Card>);
}

export default CustomBaseCard;