import { createContext, useContext } from 'react';
import { AlertMessageType, MessageHandler, ModalMessageType, ToastMessageType } from '../modules/message';
import { LoadginModalHandler } from '../modules/modal/LoadingModal';
// import { StateExplorerType } from '../pages/explorer/ExplorerDrawer';
import { KakaoTreeResType, KakaoTreeStateType } from '../common/helpers/KakaoTreeStorage';
import { LabelStateType } from '../common/helpers/LabelStorage';
import { NaverTreeDataType, NaverTreeStateType } from '../common/helpers/NaverTreeStorage';
import { UserSelectStateType, UserSelectType } from '../common/helpers/UserSelect';
import { LabelResType } from '../common/services/models/LabelTypes';

export interface UserContextModel{
    navigate: Function;
    alertMessage:MessageHandler<AlertMessageType>;
    toastMessage:MessageHandler<ToastMessageType>;
    modalMessage:MessageHandler<ModalMessageType>;
    clearMessage():void;
    modalLoading:LoadginModalHandler;
    // explorer:{set:(data:StateExplorerType)=>void, data:StateExplorerType|undefined};
    userSelect:UserSelectStateType;
    /** 레이블 연동처리용 */
    label:{
        naver:LabelStateType,
        kakao:LabelStateType,
    },
    /** tree 모드용 데이터 */
    tree:{
        /** 네이버 */
        naver: NaverTreeStateType,
        /** 카카오 */
        kakao: KakaoTreeStateType,
    },
}

const UserContext = createContext<UserContextModel>({
    navigate: Function,
    alertMessage: {data:[], setData:(data)=>{}, close:(flag, index)=>{}, add:(item)=>{},clear:()=>{}},
    toastMessage: {data:[], setData:(data)=>{}, close:(flag, index)=>{}, add:(item)=>{},clear:()=>{}},
    modalMessage: {data:[], setData:(data)=>{}, close:(flag, index)=>{}, add:(item)=>{},clear:()=>{}},
    clearMessage:()=>{},
    modalLoading: {count:0, show:()=>{}, hide:()=>{}, clear:()=>{}},
    // explorer:{set:()=>{}, data:undefined},
    userSelect: {set: (data:UserSelectType)=>{}, load: ()=>{}, data:undefined,},
    label:{
        naver: {set: (data:LabelResType[])=>{}, load: ()=>{}, data:[],},
        kakao: {set: (data:LabelResType[])=>{}, load: ()=>{}, data:[],},
    },
    /** 트리모드용 데이터 */
    tree:{
        /** 네이버용 */
        naver: {set: (data:NaverTreeDataType)=>{}, load: async ():Promise<NaverTreeDataType>=>{return {powerlink:[], shopping:[]}}, data:{powerlink:[], shopping:[]},},
        /** 카카오 */
        kakao: {set: (data:KakaoTreeResType[])=>{},load: async ():Promise<KakaoTreeResType[]>=>{return []}, data:[],},
    }
});


export { UserContext };

export function useUserContext() {
    return useContext(UserContext);
}
