import { UserContextModel } from "../../../contexts/UserContext";
import { KakaoService, NaverService } from "../../../common/services";
import { BookmarkResType } from "../../../common/services/models/admin/BookmarkTypes";
import { DatshBoardStatQryType, DatshBoardStatResType, DatshBoardStatTableType } from "../../../common/services/models/DashboardTypes";
import { StatReportResType as NaverStatReportResType} from "../../../common/services/models/naver/StatReportType";
import { StatReportResType as KakaoStatReportResType} from "../../../common/services/models/kakao/StatReportType";


export const loadStatResportNaver = async (ids:string[], startDate:Date, endDate:Date, userContext:UserContextModel):Promise<DatshBoardStatResType[]>=>{
    const body:DatshBoardStatQryType = {
        deviceTypes: ["P","M"],
        startDate: startDate.format('yyyyMMdd'),
        endDate: endDate.format('yyyyMMdd'),
        ids: ids,
    };
        
    // userContext.modalLoading.show();
    return await NaverService.dashboard.getStat(body)
    .then((res):DatshBoardStatResType[]=>{
        const tmp:DatshBoardStatResType<NaverStatReportResType>[] = res.data.result;
        return tmp.map((v1)=>({
            ...v1, 
            clients: v1.clients?.map((v2)=>({
                ...v2,
                stats: v2.stats?.map((v3)=>({
                    key: v3.key,
                    impressionCount: v3.impressionCount,
                    clickCount: v3.clickCount,
                    cost: (v3.adCost * 1.1).toFixed(0).toNumber(),
                    purchaseCount: v3.dcPurchaseCount + v3.icPurchaseCount,
                    purchaseValue: v3.dcPurchaseValue + v3.icPurchaseValue,
                })) || [],
            })) || [],
        }));
    })
    .catch((e) => {
        userContext.alertMessage.add({variant:"danger", title:"네이버 성과데이터 조회 오류", body:`네이버 성과데이터 조회 중 오류가 발생했습니다. ${NaverService.ErrorMessage(e)}`});
        return [];
    })
    // .finally(()=>{ userContext.modalLoading.hide(); });
}
   
export const loadStatResportKakao= async (ids:string[], startDate:Date, endDate:Date, userContext:UserContextModel):Promise<DatshBoardStatResType[]>=>{
    const body:DatshBoardStatQryType = {
        deviceTypes: ["P","M"],
        startDate: startDate.format('yyyyMMdd'),
        endDate: endDate.format('yyyyMMdd'),
        ids: ids,
    };
        
    // userContext.modalLoading.show();
    return await KakaoService.dashboard.getStat(body)
    .then((res):DatshBoardStatResType[]=>{
        const tmp:DatshBoardStatResType<KakaoStatReportResType>[] = res.data.result;
        return tmp.map((v1)=>({
            ...v1, 
            clients: v1.clients?.map((v2)=>({
                ...v2,
                stats: v2.stats?.map((v3)=>({
                    key: v3.key,
                    impressionCount: v3.impressionCount,
                    clickCount: v3.clickCount,
                    cost: v3.adCost,
                    purchaseCount: v3.cvPurchase7D,
                    purchaseValue: v3.cvPurchasePrice7D,
                })) || [],
            })) || [],
        }));
    })
    .catch((e) => {
        userContext.alertMessage.add({variant:"danger", title:"카카오 성과데이터 조회 오류", body:`카카오 성과데이터 조회 중 오류가 발생했습니다. ${KakaoService.ErrorMessage(e)}`});
        return [];
    })
    // .finally(()=>{ userContext.modalLoading.hide(); });
}

   
export const mergeWeekData = (bookmark:BookmarkResType[], week1:DatshBoardStatResType[], week2:DatshBoardStatResType[]):DatshBoardStatTableType[]=>{
    return bookmark.map(v1=>({
        ...v1,
        clients: v1.clients.map(v2=>{
            const mag1 = week1.find(v21=>v21.accountGroupId===v1.magId);
            const mag2 = week2.find(v21=>v21.accountGroupId===v1.magId);
            return {
                ...v2,
                week1: mag1?.clients.find(v22=>v22.clientId.toString()===v2.clientId.toString())?.stats || [],
                week2: mag2?.clients.find(v22=>v22.clientId.toString()===v2.clientId.toString())?.stats || [],
            }
        })
    }));
}

