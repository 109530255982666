import { NaverServer } from '../../axios';
import Utils from '../../utils/Utils';
import { AdGroupCopyType, AdGroupRestrictKeywordResType } from '../models/naver/AdGroupTypes';
import { TargetResType, TargetRestrictKeywordResType } from '../models/naver/TargetTypes';
import NaverHelperComponent from './NaverHelperComponent';

/** 네이버 광고그룹 Helper */
export default class AdGroupHelper extends NaverHelperComponent{
    static path="/media/ad-groups"; //기본 Path
    static simple="/media/simple-ad-groups";
    static stat='/performance/ad-groups';   //실적데이터
    static performances='/performances/ad-groups';   //실적데이터
    static downloadPath='/api/naversa/adgroup';

    /**
     * Naver- AdKeyword 추가
     * @param body 
     * @param userContext 
     * @param adGroupId : 기본구조를 확장하였기에 Argument를 undefined 등록 채우고 adGroupId를 꼭 넣어주세요!
     * @returns 
     */
    public static createKeywords<S>(body:S, adGroupId?:string){
        return Utils.apiRequestValidation({}) || NaverServer.post<S>(`${this.path}/${adGroupId}/ad-keywords`, body, {headers:{useAccount:true}});
    }

    public static copy(body:AdGroupCopyType){
        return Utils.apiRequestValidation({}) || NaverServer.post<AdGroupCopyType>(`/media/copy-ad-groups`, body, {headers:{useAccount:true}});
    }

    /**
     * 광고그룹의 제외키워드 데이터를 받아옵니다.
     * @param id 광고그룹 아이디
     * @returns 
     */
    public static getRestrictKeywords(id:string){
        return Utils.apiRequestValidation({}) || NaverServer.get<AdGroupRestrictKeywordResType>(`/media/ad-groups/${id}/restrict-keywords`, {headers:{useAccount:true}});
    }

    /**
     * 광고그룹의 제외키워드 데이터를 받아옵니다.
     * @param id 광고그룹 아이디
     * @returns 
     */
    public static getTargets(id:string, type:string){
        return Utils.apiRequestValidation({}) || NaverServer.get<TargetResType<TargetRestrictKeywordResType>>(`/media/ad-groups/${id}/targets/${type}`, {headers:{useAccount:true}});
    }
}
