import { Variant } from 'react-bootstrap/esm/types';
import clsx from 'clsx';
import { CSSProperties } from 'react';

export interface BadgeProps{
    bg?:Variant;
    className?:string;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    children?:React.ReactChild|React.ReactNode;
    opacity?:string;
    style?:CSSProperties;
    displayInline?:boolean;
}
const Badge:React.FC<BadgeProps> = (props)=>{
    let size:number = 1;
    switch(props.size){
        case 'xs' : size=0.75; break;
        case 'sm' : size=1; break;
        case 'md' : size=1.35; break;
        case 'lg' : size=1.75; break;
        case 'xl' : size=2.35; break;
    }
    const fontSize = `${size}rem`;
    const width = `${size*2}rem`;

    let color:string|undefined = undefined;
    let bg:string|undefined = props.bg;
    if(bg && bg.includes('light-') && !(bg.includes('light-secondary') || bg.includes('light-light'))){
        bg = bg.replace('light-','');
        color = `rgba(var(--bs-${bg}-rgb) , ${props.opacity || '0.1'})`;
    }

    return <div 
        className={clsx('badge badge-square', props.displayInline!==false ? 'd-inline' : '', `badge-${props.bg || 'primary'}`, props.className)}
        style={{fontSize:fontSize, height:width, width:width, backgroundColor:color, ...props.style}}
    >{props.children}</div>
}


export default Badge;