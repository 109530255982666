import { isBoolean } from 'lodash';
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import { MessageHandler } from '../Types';

interface ButtonType{
    title?:string;
    click?:React.MouseEventHandler;
}

export interface ModalMessageType{
    ok?:ButtonType|boolean;
    cancel?:ButtonType|boolean;
    show?:boolean;
    title:string;
    body:string | React.ReactNode;
}

interface ModalMessageProps{
    data:ModalMessageType[];
    onClose:(flag:boolean, index:number)=>void;
    className?:string;
}

export const UseModalMessage = ():MessageHandler<ModalMessageType>=>{
    const [data, setData] = useState<ModalMessageType[]>([]);
    const close=(flag:boolean, index:number)=>{
        setData((prev)=>{ prev[index].show = flag; return [...prev]}); 
    };
    const add=(item:ModalMessageType)=>{
        if(data.find((v)=>(v.body?.toString()===item.body?.toString() && v.title?.toString()===item.title?.toString() && v.show))){ return; }  //동일한 메세지 중복 등록 방지
        setData((prev)=>{
            prev = prev.filter((v)=>v.show);    //기존 닫힌 메세지는 제거
            prev.push({show:true, ...item});
            return [...prev];
        });
    };  //1개이하로 유지
    const clear=()=>{setData([]);};
    return {data, setData, close, add, clear};
}

const ModalMessage = (props:ModalMessageProps)=>{
    return (
        <div className={props.className}>{
            props.data.map((v,i)=>{
                const okClick:React.MouseEventHandler = (e:React.MouseEvent<HTMLButtonElement,MouseEvent>)=>{
                    !isBoolean(v.ok) && v.ok?.click && v.ok?.click(e);
                    props.onClose(false,i);
                }
                const cancelClick:React.MouseEventHandler = (e:React.MouseEvent<HTMLButtonElement,MouseEvent>)=>{
                    !isBoolean(v.cancel) && v.cancel?.click && v.cancel?.click(e);
                    props.onClose(false,i);
                }
                return (
                    <Modal key={i} isOpen={v.show}>
                        <ModalHeader>{v.title}</ModalHeader>
                        <ModalBody className='whiteSpace'>{v.body}</ModalBody>
                        <ModalFooter>
                            {v.ok && (<Button color="primary" onClick={okClick}>{!isBoolean(v.ok) && v.ok.title ? v.ok.title : "OK"}</Button>)}
                            {v.cancel && (<Button color="secondary" onClick={cancelClick}>{!isBoolean(v.cancel) && v.cancel.title ? v.cancel.title : "Cancel"}</Button>)}
                        </ModalFooter>
                    </Modal> 
                )
            })
        }</div>
    );
}

export default ModalMessage;