import { NaverServer } from '../../axios';
import Utils from '../../utils/Utils';
import { MediaResponseType } from '../models/ResponseTypes';
import { BidBaseCountResType, BidBaseHistoryResType } from '../models/naver/BidBaseTypes';
import NaverHelperComponent from './NaverHelperComponent';

/** 네이버 자동입찰 Helper */
export default class BidBaseHelper extends NaverHelperComponent{
    static path="/auto-bidding/keywords"; //기본 Path

    /** 상세조회 */
    public static get<T=any>(id:string){
        return Utils.apiRequestValidation({}) || NaverServer.get<MediaResponseType<T>>(`${this.path}/keyword-id/${id}`,{headers:{useAccount:true,},});
    }

    /** 자동입찰 키워드 ON/OFF */
    public static onOff(body:{keywordIds:string[], userLock:boolean}){
        return Utils.apiRequestValidation({}) || NaverServer.put(`${this.path}/user-lock`, body, {headers:{useAccount:true}});
    }

    /** 자동입찰 해제 */
    public static delete<T=string[]>(ids:T){
        return Utils.apiRequestValidation({}) || NaverServer.delete(this.path, {params:{keywordIds:ids}, headers:{useAccount:true}});
    }

    /** 주기별 키워드수 조회 */
    public static getCount(){
        return Utils.apiRequestValidation({}) || NaverServer.get<MediaResponseType<BidBaseCountResType>>(`${this.path}/count`,{headers:{useAccount:true,}});
    }
}

/** 네이버 자동입찰 History Helper */
export class BidBaseHistoryHelper extends NaverHelperComponent{
    static path="/auto-bidding/history"; //기본 Path

    /** 자동입찰 히스토리 목록 조회 */
    public static getList<T=any>(params:T){
        return Utils.apiRequestValidation({}) || NaverServer.get<MediaResponseType<BidBaseHistoryResType[]>>(`${this.path}/keywords`,{params:params, headers:{useAccount:true}});
    }

    /** 자동입찰 실패목록 조회 - 대시보드용 */
    public static getFailList(){
        return Utils.apiRequestValidation({}) || NaverServer.get<MediaResponseType<BidBaseHistoryResType[]>>(`${this.path}/fail-keywords`,{headers:{useAccount:true}});
    }

}

