import UserSelect from '../../common/helpers/UserSelect';
import ExplorerHeader from './ExplorerHeader';
import { Explorer as NaverExplorer } from '../naver/explorer/Explorer';
import Code from '../../modules/code/Code';
import CustomCard from '../modules/card/CustomCard';
import { Explorer as KaKaoExplorer } from '../kakao/explorer/Explorer';

export function ExplorerDrawer() {
    const account  = UserSelect.account.get();
    const isNaver = account?.accountClient?.mediaType === Code.base.mediaType.NAVER.value;
    const isKakao = account?.accountClient?.mediaType === Code.base.mediaType.KAKAO.value;

    return (<>
        {/* begin::explorer drawer */}
        <div
            id='kt_engage_explorer'
            className='bg-body'
            data-kt-drawer='true'
            data-kt-drawer-name='explore'
            data-kt-drawer-activate='true'
            data-kt-drawer-overlay='true'
            data-kt-drawer-width="{default:'350px', 'lg': '800px'}"
            data-kt-drawer-direction='end'
            data-kt-drawer-toggle='#kt_engage_explorer_toggle'
            data-kt-drawer-close='#kt_engage_explorer_close'
        >
            {/* begin::Card  */}
            <div className='card shadow-none w-100'>
                {/* begin::Header */}
                <ExplorerHeader />
                {/* end::Header */}

                {/* begin::Body */}
                <div className='card-body bg-light p-5 pb-0' id='kt_explore_body'>
                    {/* begin::Content */}
                    <div
                        id='kt_explore_scroll'
                        className='scroll-y me-n5 pe-5'
                        data-kt-scroll='true'
                        data-kt-scroll-height='auto'
                        data-kt-scroll-wrappers='#kt_engage_explorer_body'
                        data-kt-scroll-dependencies='#kt_engage_explorer_header'
                        data-kt-scroll-offset='5px'
                        style={{maxHeight:'calc(100vh - 102px)'}}
                    >
                        {!isNaver && !isKakao && <CustomCard className='text-center pt-9 pb-9'>광고주 계정을 선택하셔야 합니다!</CustomCard>}
                        {isNaver && <NaverExplorer />}
                        {isKakao && <KaKaoExplorer />}
                        
                    </div>
                    {/* end::Content */}
                </div>
                {/* end::Body */}
            </div>
            {/* begin::Card */}
        </div>
        {/* end::explorer drawer */}
    </>)
}
