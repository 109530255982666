import { CSSProperties, FC } from 'react';
import icons from '../icons-config/icons';

type Props = {
  className?: string;
  iconType?: 'duotone' | 'solid' | 'outline';
  iconName: string;
  style?: CSSProperties;
}

const KTIcon: FC<Props> = ({className = '', iconType, iconName, style}) => {
  if (!iconType) {
    iconType = "duotone";//getLayoutFromLocalStorage().main?.iconType
  }

  return (
    <i className={`ki-${iconType} ki-${iconName}${className && ' ' + className}`}>
      {iconType === 'duotone' &&
        [...Array(icons[iconName])].map((_e, i) => {
          return (
            <span
              key={`${iconType}-${iconName}-${className}-path-${i + 1}`}
              className={`path${i + 1}`}
              style={style}
            ></span>
          )
        })}
    </i>
  )
}

export { KTIcon };

