import NaverHelperComponent from './NaverHelperComponent';

/** 네이버 캠페인 Helper */
export default class CampaignHelper extends NaverHelperComponent{
    static path='/media/campaigns';         //기본 Path
    static simple='/media/simple-campaigns'
    static stat='/performance/campaigns';   //실적데이터
    static performances='/performances/campaigns';   //실적데이터
    static downloadPath='/api/naversa/campaign';
}

