/* eslint-disable react/jsx-no-target-blank */
import clsx from 'clsx';
import { CSSProperties, useEffect, useState } from 'react';
import Config from '../../../../../../app/common/conf/Config';
import { KakaoTreeResType } from '../../../../../../app/common/helpers/KakaoTreeStorage';
import { AdGroupSimpleResType } from '../../../../../../app/common/services/models/kakao/AdGroupTypes';
import { CampaignSimpleResType } from '../../../../../../app/common/services/models/kakao/CampaignTypes';
import { useUserContext } from '../../../../../../app/contexts/UserContext';
import Ellipsis from '../../../../../../app/modules/layer/Ellipsis';
import { SidebarTreeItem } from '../SidebarTreeItem';
import { SidebarTreeTitle } from '../SidebarTreeTitle';
import styles from '../style.module.scss';

const SidebarMenuMainKakaoTree = () => {
    const userContext =useUserContext();

    useEffect(()=>{
        if(Config.app.TreeMode.enable){
            userContext.tree.kakao.set([]); //잔상방지
            userContext.modalLoading.show();
            userContext.tree.kakao.load()
            .finally(()=>{ userContext.modalLoading.hide();});
        }
    // eslint-disable-next-line
    }, [userContext.userSelect.accountState?.account]);
    
    const basePath:string = '/pages/kakao/tree';
    return <SidebarTreeTitle
        to={`${basePath}/keywordad`}
        icon='/media/icons/duotune/general/gen022.svg'
        title='키워드 광고'
    >
        {userContext.tree.kakao.data.map((v:KakaoTreeResType)=>
            <SidebarMenuAdGroup key={v.data.campaignId} value={v.data} adgroup={v.children} to='/pages/kakao/tree/keywordad/campaign'/>
        )}
    </SidebarTreeTitle>
}

export default SidebarMenuMainKakaoTree;


const SidebarMenuAdGroup:React.FC<{value:CampaignSimpleResType, adgroup:AdGroupSimpleResType[], to?:string}> = (props)=>{
    const [useData, setUseData] = useState<AdGroupSimpleResType[]>([]);
    useEffect(()=>{
        setUseData(props.adgroup);
    }, [props.adgroup])

    const border ='1px solid var(--bs-gray-600)';
    const to:string = `${props.to}/${props.value.campaignId}`;
    return <SidebarTreeTitle to={to}
        treeIcon={true}
        minimizeHidden={true}
        title={<>
            <IconUserLock userLock={props.value && false}/>
            <Ellipsis style={{width:'130px'}} title={props.value.campaignName}>{props.value.campaignName}</Ellipsis>
        </>}
    >
        <div className={clsx('scroll-y', styles.tree_item)} style={{maxHeight:'300px'}}>
            {useData.map((v,index)=><SidebarTreeItem
                key={v.adGroupId} 
                className='p-1'
                to={`${to}/adgroup/${v.adGroupId}`}
                title={<>
                    <div className={styles.tree_item_bar}></div>
                    <IconUserLock userLock={v && false} style={{fontSize:'1.2em'}}/>
                    <Ellipsis style={{width:'160px'}} title={v.adGroupName}>{v.adGroupName}</Ellipsis>
                </>}
            />)}
        </div>
    </SidebarTreeTitle>
}

const IconUserLock:React.FC<{userLock?:boolean, style?:CSSProperties}> = (props)=><span 
    className={clsx('me-1', props.userLock ? 'text-danger' : 'text-success')}
    style={{fontSize:'1.5em', lineHeight:'100%', ...props.style}}
>●</span>;
