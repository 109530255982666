import clsx from 'clsx';
import React, { CSSProperties } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap';
import AdploButton from '../../../pages/components/buttons/AdploButton';

interface ButtonType{
    title?:string;
    onClick?:React.MouseEventHandler;
    className?: string;
    style?:CSSProperties;
    variant?:string;
    disabled?: boolean;
}

export interface ConfirmProps{
    ok?:ButtonType;
    cancel?:ButtonType;
    title:string;
    children:string | React.ReactNode;
    className?: string;
    style?:CSSProperties;
    state:[boolean, React.Dispatch<React.SetStateAction<boolean>>];
    size?:"sm" | "lg" | "xl";
    onSubmit?: ()=>boolean|Promise<boolean|undefined>;
}

const Confirm:React.FC<ConfirmProps> = (props)=>{
    const [show, setShow] = props.state;

    const okClick = async (e:React.MouseEvent<HTMLButtonElement>)=>{
        const check:boolean|undefined = await props.onSubmit?.();
        if(props.onSubmit && !check){ return; }   // 검증 실패
        setShow(false);
        props.ok?.onClick?.(e);
    }
    const cancelClick = (e:React.MouseEvent<HTMLButtonElement>)=>{
        setShow(false);
        props.cancel?.onClick?.(e);
    }
    return (
        <Modal show={show} className={props.className} style={props.style} size={props.size}>
            <ModalHeader className='fw-bold'>{props.title}</ModalHeader>
            <ModalBody className='whiteSpace'>{props.children}</ModalBody>
            <ModalFooter>
                {props.cancel && (
                    <AdploButton variant={props.cancel.variant || 'secondary'} onClick={cancelClick} 
                        className={clsx('me-1', props.cancel.className)} 
                        style={props.cancel.style}
                        disabled={props.cancel.disabled}
                    >{props.cancel.title || "Cancel"}</AdploButton>)}
                {props.ok && (
                    <AdploButton variant={props.ok.variant || "primary"} onClick={okClick} 
                        className={props.ok.className}
                        style={props.ok.style}
                        disabled={props.ok.disabled}
                    >{props.ok.title || "OK"}</AdploButton>)}
            </ModalFooter>
        </Modal> 
    );
}

export default Confirm;