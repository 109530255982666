import { useEffect, useState } from "react";
import { NaverService } from "../../../common/services";
import { AdGroupResType } from "../../../common/services/models/naver/AdGroupTypes";
import { AdKeywordResKeys, AdKeywordResType } from "../../../common/services/models/naver/AdKeywordTypes";
import { AdResKeys, AdResType, AdType_BRAND_THUMBNAIL, AdType_CATALOG_AD, AdType_SHOPPING_BRAND_AD, AdType_SHOPPING_PRODUCT_AD, AdType_TEXT_45 } from "../../../common/services/models/naver/AdTypes";
import { BusinessChannelResKeys, BusinessChannelResType } from "../../../common/services/models/naver/BusinessChannelTypes";
import { CampaignResType } from "../../../common/services/models/naver/CampaignTypes";
import { StatReportInfoType } from "../../../common/services/models/naver/StatReportType";
import { MediaResResultType } from "../../../common/services/models/ResponseTypes";
import NaverCode from "../../../modules/code/NaverCode";
import { HiperLinkEllisis } from "../../../modules/layer/HiperLink";
import { ModifyViewerType } from "../../modules/modify/Modify";

export const getMasterData = (level?:string, data?:any)=>{
    switch(level){
        case NaverCode.data.options.ExplorerLevel.bizChannel.value : {
            const tmp:StatReportInfoType|undefined = data;
            if(!tmp?.businessChannelId){ return undefined; }
            return NaverService.bizchannel.getList<MediaResResultType<BusinessChannelResType>>({filters: [{key:BusinessChannelResKeys.businessChannelId, operation:'EQ', value:tmp.businessChannelId}]})
                .then((res)=>{res.data.result = res.data.result.items?.[0]; return res; });
        }
        case NaverCode.data.options.ExplorerLevel.campaign.value : {
            const tmp:StatReportInfoType|undefined = data;
            return tmp?.campaignId ? NaverService.campaign.get(tmp?.campaignId) : undefined;
        }
        case NaverCode.data.options.ExplorerLevel.adGroup.value : {
            const tmp:StatReportInfoType|undefined = data; 
            return tmp?.adgroupId ? NaverService.adgroup.get(tmp?.adgroupId) : undefined;
        }
        case NaverCode.data.options.ExplorerLevel.keyword.value : {
            const tmp:StatReportInfoType|undefined = data;
            if(!tmp?.keywordId){ return undefined; }
            return NaverService.keyword.getList<MediaResResultType<AdKeywordResType>>({filters: [{key:AdKeywordResKeys.keywordId, operation:'EQ', value:tmp.keywordId}]})
                .then((res)=>{res.data.result = res.data.result.items?.[0]; return res; });
        }
        case NaverCode.data.options.ExplorerLevel.ad.value : {
            const tmp:StatReportInfoType|undefined = data;
            if(!tmp?.adId){ return undefined; }
            return NaverService.ad.getList<MediaResResultType<AdResType>>({filters:[{key:AdResKeys.adId, operation:'EQ', value:tmp?.adId}]})
                .then((res)=>{res.data.result = res.data.result.items?.[0]; return res; });  //개별 호출이 없음
        }
    }
    return undefined;
}


export const convertMasterViewData = (level?:string, data?:any):ModifyViewerType[]=>{
    switch(level){
        case NaverCode.data.options.ExplorerLevel.bizChannel.value : {
            const tmp:BusinessChannelResType|undefined = data;
            return [
                // {title:'비즈채널명', value:tmp?.name},
                {title:'비즈채널ID', value:tmp?.businessChannelId},
                {title:'비즈채널 유형', value: NaverCode.getLabel(NaverCode.data.type.bizchannel, tmp?.channelType || '')},
                {title:'콘텐츠 URL', value: <HiperLinkEllisis href={tmp?.channelKey} >{tmp?.channelKey}</HiperLinkEllisis>},
            ];
        }
        case NaverCode.data.options.ExplorerLevel.campaign.value : {
            const tmp:CampaignResType|undefined = data;
            return [
                // {title:'캠페인명', value:tmp?.campaignName},
                {title:'캠페인ID', value:tmp?.campaignId},
                {title:'캠페인 유형', value: NaverCode.getLabel(NaverCode.data.type.campaign, tmp?.campaignType || '')},
            ];
        }
        case NaverCode.data.options.ExplorerLevel.adGroup.value : {
            const tmp:AdGroupResType|undefined = data;
            return [
                // {title:'광고그룹명', value:tmp?.adGroupName},
                {title:'광고그룹ID', value:tmp?.adGroupId},
                {title:'광고그룹 유형', value: NaverCode.getLabel(NaverCode.data.type.adgroup, tmp?.adGroupType || '')},
                {title:'캠페인명', value:tmp?.campaignName},
                {title:'캠페인ID', value:tmp?.campaignId},
                {title:'캠페인 유형', value: NaverCode.getLabel(NaverCode.data.type.campaign, tmp?.campaignType || '')},
            ];
        }
        case NaverCode.data.options.ExplorerLevel.keyword.value : {
            const tmp:AdKeywordResType|undefined = data;
            return [
                // {title:'키워드', value:tmp?.keyword},
                {title:'키워드ID', value:tmp?.keywordId},
                {title:'캠페인명', value:tmp?.campaignName},
                {title:'캠페인ID', value:tmp?.campaignId},
                {title:'캠페인 유형', value: NaverCode.getLabel(NaverCode.data.type.campaign, tmp?.campaignType || '')},
                {title:'광고그룹명', value:tmp?.adGroupName},
                {title:'광고그룹ID', value:tmp?.adGroupId},
                {title:'광고그룹 유형', value: NaverCode.getLabel(NaverCode.data.type.adgroup, tmp?.adGroupType || '')},
            ];
        }
        case NaverCode.data.options.ExplorerLevel.ad.value : {
            return convertMasterViewData_AD(data);  //목록호출
        }
    }
    return [];
}


export const convertMasterViewData_AD = (data?:AdResType):ModifyViewerType[]=>{
    switch(data?.type){
        case NaverCode.data.type.ad.TEXT_45.value : {
            // const adInfo:AdType_TEXT_45|undefined = data?.adInfo;
            return [
                {title:'소재ID', value:data?.adId},
                {title:'소재 유형', value: NaverCode.getLabel(NaverCode.data.type.ad, data?.type || '')},
                // {title:'소재 제목', value:adInfo?.headline},
                // {title:'소제 설명', value:adInfo?.description},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인ID', value:data?.campaignId},
                {title:'캠페인 유형', value: NaverCode.getLabel(NaverCode.data.type.campaign, data?.campaignType || '')},
                {title:'광고그룹명', value:data?.adGroupName},
                {title:'광고그룹ID', value:data?.adGroupId},
                {title:'광고그룹 유형', value: NaverCode.getLabel(NaverCode.data.type.adgroup, data?.adGroupType || '')},
            ];
        }

        case NaverCode.data.type.ad.SHOPPING_PRODUCT_AD.value : {
            // const adInfo:AdType_SHOPPING_PRODUCT_AD|undefined|null = data?.referenceData;
            return [
                {title:'소재ID', value:data?.adId},
                {title:'소재 유형', value: NaverCode.getLabel(NaverCode.data.type.ad, data?.type || '')},
                // {title:'상품 제목', value: adInfo?.productTitle},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인ID', value:data?.campaignId},
                {title:'캠페인 유형', value: NaverCode.getLabel(NaverCode.data.type.campaign, data?.campaignType || '')},
                {title:'광고그룹명', value:data?.adGroupName},
                {title:'광고그룹ID', value:data?.adGroupId},
                {title:'광고그룹 유형', value: NaverCode.getLabel(NaverCode.data.type.adgroup, data?.adGroupType || '')},
            ];
        }

        case NaverCode.data.type.ad.CATALOG_AD.value : {
            // const adInfo:AdType_CATALOG_AD|undefined|null = data?.referenceData;
            return [
                {title:'소재ID', value:data?.adId},
                {title:'소재 유형', value: NaverCode.getLabel(NaverCode.data.type.ad, data?.type || '')},
                // {title:'상품명', value: adInfo?.productName},
                // {title:'상품 제목', value: adInfo?.productTitle},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인ID', value:data?.campaignId},
                {title:'캠페인 유형', value: NaverCode.getLabel(NaverCode.data.type.campaign, data?.campaignType || '')},
                {title:'광고그룹명', value:data?.adGroupName},
                {title:'광고그룹ID', value:data?.adGroupId},
                {title:'광고그룹 유형', value: NaverCode.getLabel(NaverCode.data.type.adgroup, data?.adGroupType || '')},
            ];
        }

        case NaverCode.data.type.ad.SHOPPING_BRAND_IMAGE_THUMBNAIL_AD.value : {
            // const adInfo:AdType_BRAND_THUMBNAIL|undefined|null = data?.adInfo;
            return [
                {title:'소재ID', value:data?.adId},
                {title:'소재 유형', value: NaverCode.getLabel(NaverCode.data.type.ad, data?.type || '')},
                // {title:'소제 제목', value: adInfo?.headline},
                // {title:'소제 설명', value: adInfo?.description},
                // {title:'소재 설명2', value: adInfo?.extraDescription},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인ID', value:data?.campaignId},
                {title:'캠페인 유형', value: NaverCode.getLabel(NaverCode.data.type.campaign, data?.campaignType || '')},
                {title:'광고그룹명', value:data?.adGroupName},
                {title:'광고그룹ID', value:data?.adGroupId},
                {title:'광고그룹 유형', value: NaverCode.getLabel(NaverCode.data.type.adgroup, data?.adGroupType || '')},
            ];
        }

        case NaverCode.data.type.ad.SHOPPING_BRAND_IMAGE_BANNER_AD.value :  {
            // const adInfo:AdType_BRAND_THUMBNAIL|undefined|null = data?.adInfo;
            return [
                {title:'소재ID', value:data?.adId},
                {title:'소재 유형', value: NaverCode.getLabel(NaverCode.data.type.ad, data?.type || '')},
                // {title:'소제 제목', value: adInfo?.headline},
                // {title:'소제 설명', value: adInfo?.description},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인ID', value:data?.campaignId},
                {title:'캠페인 유형', value: NaverCode.getLabel(NaverCode.data.type.campaign, data?.campaignType || '')},
                {title:'광고그룹명', value:data?.adGroupName},
                {title:'광고그룹ID', value:data?.adGroupId},
                {title:'광고그룹 유형', value: NaverCode.getLabel(NaverCode.data.type.adgroup, data?.adGroupType || '')},
            ];
        }

        case NaverCode.data.type.ad.SHOPPING_BRAND_AD.value : {
            // const adInfo:AdType_SHOPPING_BRAND_AD|undefined|null = data?.adInfo;
            return [
                {title:'소재ID', value:data?.adId},
                {title:'소재 유형', value: NaverCode.getLabel(NaverCode.data.type.ad, data?.type || '')},
                // {title:'소제 설명', value: adInfo?.brandDescription},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인ID', value:data?.campaignId},
                {title:'캠페인 유형', value: NaverCode.getLabel(NaverCode.data.type.campaign, data?.campaignType || '')},
                {title:'광고그룹명', value:data?.adGroupName},
                {title:'광고그룹ID', value:data?.adGroupId},
                {title:'광고그룹 유형', value: NaverCode.getLabel(NaverCode.data.type.adgroup, data?.adGroupType || '')},
            ];
        }
    }
    return [];
}

export const AdTitle:React.FC<{data?:any}> = (props)=>{
    const [data, setData] = useState<AdResType|undefined>();
    useEffect(()=>{
        getMasterData(NaverCode.data.options.ExplorerLevel.ad.value, props.data)?.then((res)=>{ setData(res.data.result);})
    }, [props.data]);
    
    let title:string|React.ReactNode|undefined = undefined;
    switch(data?.type){
        case NaverCode.data.type.ad.SHOPPING_PRODUCT_AD.value : {
            const adInfo:AdType_SHOPPING_PRODUCT_AD|undefined|null = data?.referenceData;
            title = adInfo?.productName || adInfo?.productTitle;
            break;
        }

        case NaverCode.data.type.ad.CATALOG_AD.value : {
            const adInfo:AdType_CATALOG_AD|undefined|null = data?.referenceData;
            title = adInfo?.productName || adInfo?.productTitle;
            break;
        }

        case NaverCode.data.type.ad.SHOPPING_BRAND_IMAGE_BANNER_AD.value :
        case NaverCode.data.type.ad.SHOPPING_BRAND_IMAGE_THUMBNAIL_AD.value : {
            const adInfo:AdType_BRAND_THUMBNAIL|undefined|null = data?.adInfo;
            title = adInfo?.headline;
            break;
        }

        case NaverCode.data.type.ad.SHOPPING_BRAND_AD.value : {
            const adInfo:AdType_SHOPPING_BRAND_AD|undefined|null = data?.adInfo;
            title = adInfo?.brandDescription;
            break;
        }
        case NaverCode.data.type.ad.TEXT_45.value : 
        default : {
            const adInfo:AdType_TEXT_45|undefined = data?.adInfo;
            title = adInfo?.headline;
            break;
        }
    }
    return <>{title}</>;
}

export const ExplorerTitle:React.FC<{level?:string, data?:StatReportInfoType}> = (props)=>{
    switch(props.level){
        case NaverCode.data.options.ExplorerLevel.bizChannel.value : return <>비즈채널명 : {props.data?.businessChannelName}</>;
        case NaverCode.data.options.ExplorerLevel.campaign.value : return <>캠페인명 : {props.data?.campaignName}</>;
        case NaverCode.data.options.ExplorerLevel.adGroup.value : return <>광고그룹명 : {props.data?.adgroupName}</>;
        case NaverCode.data.options.ExplorerLevel.keyword.value : return <>키워드 : {props.data?.keyword}</>;
        case NaverCode.data.options.ExplorerLevel.ad.value : return <>소재명 : <AdTitle data={props.data}/></>;
        case NaverCode.data.options.ExplorerLevel.adExtension.value : return <>확장소재ID : {props.data?.adextensionId}</>;
        case NaverCode.data.options.ExplorerLevel.searchKwd.value : return <>검색어 : {props.data?.searchKeyword}</>;
        case NaverCode.data.options.ExplorerLevel.criterion?.value : return <>타겟팅 : {
            NaverCode.getLabel([
                NaverCode.data.assets.age,
                NaverCode.data.assets.audience,
                NaverCode.data.assets.gender,
                NaverCode.data.assets.region,
            ], props.data?.criterionDictionaryCode || "")
        }</>;
    }
    return <>Unknown</>;
}
