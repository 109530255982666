/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Config from '../../../../../../app/common/conf/Config';
import UserSelect from '../../../../../../app/common/helpers/UserSelect';
import Code from '../../../../../../app/modules/code/Code';
import NotificationCode from '../../../../../../app/modules/code/NotificationCode';
import ToggleSwitch from '../../../../../../app/modules/toggle/ToggleSwitch';
import { SidebarMenuItem } from '../SidebarMenuItem';
import { SidebarMenuItemWithSub } from '../SidebarMenuItemWithSub';
import SidebarMenuMainKakaoLabel from './SidebarMenuMainKakaoLabel';
import SidebarMenuMainKakaoTree from './SidebarMenuMainKakaoTree';

const SidebarMenuMainKakao = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [treeMode, setTreeMode] = useState<boolean>(false);
    const setMenuMode = (value:boolean)=>{
        setTreeMode(value);
        UserSelect.sidebar.set(value ? Code.base.sidebarMode.TREE.value : Code.base.sidebarMode.ALL.value);

        //현재 페이지가 메인 또는 대시보드페이지가 아닌 경우
        if(!['/', '/dashboard'].includes(location.pathname)){
            navigate('/');
        }
    }

    useEffect(()=>{
        if(Config.app.TreeMode.enable){
            const pathTreeMode:boolean = window.document.location.pathname.indexOf("/pages/kakao/tree/") !== -1;    //URL로 바로 진입 시에도 TreeMode 표시하도록 함
            const selTreeMode:boolean = UserSelect.sidebar.get()!==Code.base.sidebarMode.ALL.value;
            const treeMode:string = (pathTreeMode || selTreeMode) ? Code.base.sidebarMode.TREE.value : Code.base.sidebarMode.ALL.value;
            UserSelect.sidebar.set(treeMode);
            setTreeMode(treeMode===Code.base.sidebarMode.TREE.value);
        }
    // eslint-disable-next-line
    }, []);
    return <>
        <div className='menu-item'>
            <div className='menu-content pt-3 pb-2'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Kakao</span>
                {Config.app.TreeMode.enable && 
                    <ToggleSwitch
                        className='w-80px'
                        style={{float:'right'}}
                        data-yes={Code.base.sidebarMode.TREE.label}
                        data-no={Code.base.sidebarMode.ALL.label}
                        onChange={(e)=>setMenuMode(e.target.checked)}
                        checked={treeMode}
                    />
                }
            </div>
        </div>
        <SidebarMenuItem to='/pages/kakao/history' icon='/media/icons/duotune/abstract/abs027.svg' title='관리이력 & 대량요청' />
        {/* <SidebarMenuItem to='/pages/kakao/downloadlist' icon='/media/icons/duotune/files/fil017.svg' title='다운로드' /> */}
        
        {treeMode && <>
            <SidebarMenuMainKakaoTree />
            <SidebarMenuItem to='/pages/kakao/keywordad/creative' icon='/media/icons/duotune/general/gen006.svg' title='소재 관리' />
        </>}
        {!treeMode &&
            <SidebarMenuItemWithSub to='/pages/kakao/keywordad' icon='/media/icons/duotune/general/gen022.svg' title='키워드광고'>
                <SidebarMenuItem to='/pages/kakao/keywordad/campaign' title='캠페인' hasBullet={true} />
                <SidebarMenuItem to='/pages/kakao/keywordad/adgroup' title='광고그룹' hasBullet={true} />
                <SidebarMenuItem to='/pages/kakao/keywordad/adkeyword' title='키워드' hasBullet={true} />
                <SidebarMenuItem to='/pages/kakao/keywordad/creative' title='소재' hasBullet={true} />
                <SidebarMenuItem to='/pages/kakao/keywordad/creativelink' title='소재연결' hasBullet={true} />
            </SidebarMenuItemWithSub>
        }
        <SidebarMenuItem to='/pages/kakao/keywordad/bizchannel' icon='/media/icons/duotune/abstract/abs031.svg' title='비즈채널' />
        <SidebarMenuItem to='/pages/kakao/imagelist' keenIcon={NotificationCode.data.type.CNM0004.icon} title='이미지 관리' />
        
        <span className='text-muted'><hr /></span>
        {Config.app.Label.enable && <SidebarMenuMainKakaoLabel />}
    </>
}

export default SidebarMenuMainKakao;
