import {useState} from 'react';
import {Fade} from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer'
import {ToastMessageType} from './ToastMessageType';
import { MessageHandler } from '../Types';

interface ToastMessageProps{
    data:ToastMessageType[];
    onClose:(flag:boolean, index:number)=>void;
}

export const UseToastMessage = ():MessageHandler<ToastMessageType> => {
    const [data, setData] = useState<ToastMessageType[]>([]);
    const close=(flag:boolean, index:number)=>{
        data[index].show = flag;
        setData((prev)=>{ prev[index].show = flag; return [...prev]; });
    }
    const add=(item:ToastMessageType)=>{
        setData((prev)=>{
            prev = prev.filter((v)=>v.show);    //기존 닫힌 메세지는 제거
            prev.push({show:true, ...item});
            return [...prev];
        });
    }
    const clear=()=>{setData([]);};
    return {data, setData, close, add, clear};
}

function ToastMessage(props:ToastMessageProps) {
    return (<ToastContainer className='position-fixed' position={'bottom-end'}>{
        props.data.map((v,i)=>(
            <Toast
                key={i}
                className={"collapsed"}
                animation={true}//{typeof(v.animation)!=='undefined' ? v.animation : true} 
                autohide={true}//{typeof(v.autohide)!=='undefined' ? v.autohide : true} 
                show={typeof(v.show)!=='undefined' ? v.show : true} 
                delay={typeof(v.delay)!=='undefined' ? v.delay : 5000} 
                bg={`light-${v.bg || "info"}`} 
                transition={Fade} 
                onClose={()=>props.onClose(false, i)} 
                bsPrefix={v.bsPrefix || "toast"} 
            >
                <Toast.Header><strong className={"me-auto"}>{v.title}</strong></Toast.Header>
                <Toast.Body>{v.body}</Toast.Body>
            </Toast>  
        ))
    }
    </ToastContainer>);
}

export default ToastMessage;