import Config from "./Config";


const SsoConfig =(() => {
    return {
        clientId: Config.api.clientId || '',            // ClientID - auth Server에서 발행한 키
        secretKey: Config.api.secretKey || '',
        scope: "trust",                             // Auth 서버 통신 타입                  : trust
        redirectUri: `${Config.project.path}/login/oauth2`,         // Callback URI - auth, backend 동일 : http://localhost:8002/login/oauth2/code/tbase
        authorizationUri: `${Config.api.sso}/oauth/authorize`,   // Auth Login page                  : http://sso.dbase.co.kr/oauth/authorize
        userInfoUri:`${Config.api.sso}/oauth/user_info`,       // 사용자 접속정보 로딩 - Auth           : http://sso.dbase.co.kr/oauth/user_info
        logoutUri: `${Config.api.sso}/userLogout`,                // Auth Logout - Auth   : http://sso.dbase.co.kr/logout    //GET http://sso.dbase.co.kr/userLogout?userName=&clientId=<클리이언트아이디>
        tokenUri: `${Config.api.sso}/oauth/token`,            // Access Token 획득 - Backend       : http://10.205.1.13:23300/oauth/token
        introspect:`${Config.api.sso}/oauth/introspect`, 
    }
})();

export default SsoConfig;
