// import React from 'react';
import { AxiosResponse } from 'axios';

export async function callBackDownload(res:AxiosResponse, filename: string) {
    const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});               
    const link = document.createElement('a');
    const uri = window.URL.createObjectURL(blob);
    link.href = uri;
    link.target = '_self';
    link.download = filename;
    link.click();
    link.remove();
    window.URL.revokeObjectURL(uri);


    // var fs = require('fs');
    // var writer = fs.createWriteStream(filename);
    // axios.then((res:AxiosResponse) => {
  
    //   //ensure that the user can call `then()` only when the file has
    //   //been downloaded entirely.
    //   return new Promise((resolve, reject) => {
    //     res.data.pipe(writer);
    //     let error:OnErrorEventHandler = null;
    //     writer.on('error', (err:OnErrorEventHandler) => {
    //       error = err;
    //       writer.close();
    //       reject(err);
    //     });
    //     writer.on('close', () => {
    //       if (!error) {
    //         resolve(true);
    //       }
    //       //no need to call the reject here, as it will have been called in the
    //       //'error' stream;
    //     });
    //   });
    // });
  }