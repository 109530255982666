
export const EqInExSelectOptions = [
    {value:'In', label:'일치'},
    {value:'LikeIn', label:'포함'},
    {value:'NotIn', label:'제외'},
];

export const EqNotSelectOptions = [
    {value:'In', label:'일치'},
    {value:'NotIn', label:'제외'},
];

export const InetrSectSelectOptions = [
    {value:'INTERSECT', label:'배열포함'},
    {value:'ArrayEq', label:'배열일치'},
];


// LowerEqual
// LowerNotEqual
// LowerLike
// LowerNotLike
// LowerRegExp
// LowerIn
// LowerNotIn
// LowerIntersect
// LowerLikeIn
export const LowerEqInExSelectOptions = [
    {value:'LowerIn', label:'일치'},
    {value:'LowerLikeIn', label:'포함'},
    {value:'LowerNotIn', label:'제외'},
];

export const LowerEqNotSelectOptions = [
    {value:'LowerIn', label:'일치'},
    {value:'LowerNotIn', label:'제외'},
];

export const LowerInetrSectSelectOptions = [
    {value:'LowerIntersect', label:'배열포함'},
];

// - MatchAnyBit
// - MatchAllBits
// - NotMatchAnyBit
/** Bit 연산을 사용하는 경우 */
export const MatchBitOptions = [
    {value:'MatchAnyBit', label:'포함'},
    {value:'MatchAllBits', label:'일치'},
    {value:'NotMatchAnyBit', label:'제외'},
];


export const CompareSelectOption = [
    // {value: 'GT', label: '>(보다 큼)'},
    // {value: 'LT', label: '<(보다 작음)'},
    // {value: 'GTE', label: '>=(크거나 같음)'},
    // {value: 'LTE', label: '<=(작거나 같음)'},
    // {value: 'EQ', label: '=(같음)'},
    {value: 'GT', label: '>'},
    {value: 'LT', label: '<'},
    {value: 'GTE', label: '>='},
    {value: 'LTE', label: '<='},
    {value: 'EQ', label: '='},
];


