/* eslint-disable jsx-a11y/anchor-is-valid */
import CustomBaseCard from '../modules/card/CustomBaseCard';
import BizMoneyList from './BizMoneyList';
import AppsDashboard from './appsDashboard/AppsDashboard';
import HistoryDashboard from './historyDashboard/HistoryDashboard';
import ReportDashboard from './reportDashboard/ReportDashboard';

const DashboardWrapper: React.FC = () => {
    return <>
        <div className='mb-3'>
            <div className='fw-bolder fs-3'>AdPlo 현황</div>
            <div className='text-gray-600'>AdPlo 운영 현황을 확인합니다.</div>
        </div>

        <div className='row'>
            <div className='col-xxl-5 mb-6'>
                <AppsDashboard />
            </div>
            <div className='col-xxl-4 mb-6'>
                <BizMoneyList />
            </div>
            <div className='col-xxl-3 mb-6'>
                <CustomBaseCard style={{height:'320px'}} title='진행중인 작업'>
                    <HistoryDashboard />
                </CustomBaseCard>
            </div>
        </div>

        <ReportDashboard />
    </>;
}

export default DashboardWrapper;
