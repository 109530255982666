import React from 'react';
import { Form } from "react-bootstrap";
import { FilterType, HeaderColumn, SelectType } from './TableType';
// type : select, mSelect, selectList, selectText, selectDate, text
import clsx from 'clsx';
import { RequestFilterType } from '../../../common/services/models/RequestTypes';
import MultiSelect from '../select/MultiSelect';
import './tableFilter.css';

export interface FilterColumnProps {
    id:string;
    type:FilterType;
    options?:SelectType[];
    onChange:(e:RequestFilterType)=>void;
    operation?:string;
    value?:string;
    numberOnly?:boolean;
}
interface InputFormProps{
    id:string;
    type:FilterType;
    options?:{value:string,label:string}[];
    onChange?:(value:string)=>void;
    value?:string;
    placeholder?:string;
    maxLength?:number;
    style?: React.CSSProperties;
    numberOnly?:boolean;
    className?:string;
}


export interface TableFilterProps{
    columns: HeaderColumn[];
    className?:string;
    style?:React.CSSProperties;
    data: RequestFilterType[];
    onChange: (data:RequestFilterType[])=>void;
    useReset?:boolean;
    onReset?:()=>void;
}

const CustomFilter = (props:TableFilterProps)=>{
    const setData=({key, operation, value}:RequestFilterType)=>{
        let prev = props.data;
        const old = prev.find((v)=>v.key===key);
        operation = operation || old?.operation || '';
        value = value || '';
        prev = prev.filter(v=>v.key !== key);
        prev.push({key,value,operation});
        
        props.onChange([...prev]);
    }

    const onReset=(e:React.MouseEvent)=>{
        // props.onChange([]);      //Custom Table 모듈에서 필터값을 기본필터값으로 치환함
        props.onReset && props.onReset();
    }

    return (
        <tr className={clsx('custom-table-filter', props.className)} style={props.style}>
            { props.useReset && (<th className='filter-reset p-2 ps-0 pe-0'><i className="bi bi-arrow-repeat cursor-pointer fs-2 p-1" title='초기화' onClick={onReset} /></th>) }
            {
                props.columns.map((item, index) => {
                    if(!item.filter){ return (<th key={item.key || item.accessor} />); }
                    const key = item.filter.key || item.key || item.accessor;  //데이터 읽을 때와 filter로 사용되는 값이 다름
                    const value = props.data.find((v)=>v.key===key);
                    const operation = value?.operation || defaultOperation(item.filter?.type, item.filter.operation, item.filter?.options);
                    return (<th key={key} className='p-1 ps-1 pe-1' style={{zIndex:2, ...item.style}}>
                        <Column 
                            id={key}
                            type={item.filter.type}
                            options={item.filter.options?.filter((v)=>v.isView!==false)}
                            onChange={(e:RequestFilterType)=>{e.operation = defaultOperation(item.filter?.type, e.operation, item.filter?.options);  setData(e); }}
                            operation={operation}
                            value={value?.value || ""}
                            numberOnly={item.filter.numberOnly}
                        />
                    </th>);
                })
            }
        </tr>
    );
}



// operation, value Type지정
const defaultOperation=(type?:FilterType, operation?:string, options?:SelectType[] ):string => {
    if(operation){ return operation; }
    switch(type){
        case FilterType.arraySelect : return options?.find((v)=>v.value==='LowerIntersect')?.value || "INTERSECT";   //카카오 배열영역용 - 배열중 하나라도 포함된 경우

        case FilterType.list :
        case FilterType.mSelect : return options?.find((v)=>v.value==='LowerIn')?.value ||"In";

        case FilterType.date : return options?.find((v)=>v.value==='LowerLike')?.value || "Like";
        
        case FilterType.select :
        case FilterType.text :
        case FilterType.number :
        default : return "EQ";
    }
}
const defaultValidation=(props:FilterColumnProps):[InputFormProps,InputFormProps] => {
    let operation:InputFormProps = {id:props.id, type:FilterType.none};
    let value:InputFormProps = {id:props.id, type: props.type};
    const operationValue = defaultOperation(props.type, props.operation, props.options);

    switch(props.type){
        case FilterType.select :
        case FilterType.mSelect :
        case FilterType.arraySelect :
        case FilterType.date :
            operation = {...operation, value: operationValue};
            value = {...value, options: props.options, value: props.value};
            break;

        case FilterType.list :
        case FilterType.text :
        case FilterType.number :
            operation = {...operation, type: FilterType.select, value: operationValue, options: props.options};
            value = {...value, value: props.value};
            break;
    }
    return [operation, value];
}


// type : select, selectList, selectText, selectDate, text, mSelect
const Column = (props: FilterColumnProps) => {
    const [operation, value]:[InputFormProps,InputFormProps] = defaultValidation(props);
    const setData = ({val, oper}:{val?:string, oper?:string})=>{
        const data:RequestFilterType = {
            key : props.id,
            operation : (oper!==undefined ? oper : operation?.value) || '',
            value : (val!==undefined ? val : value.value) || '',
        }
        props.onChange(data);
    }

    const setValue = (value:string) => { setData({val:value}); }
    const setOperation = (value:string) => { setData({oper:value}); }
    const length = operation.options?.reduce((prev:number,v)=>Math.max(prev, v.label.length),2) || 2;     //최대글자수 체크
    
    return operation.type!==FilterType.none
        ? (
            <Form.Group className='input-group' key={props.id}>
                <InputForm {...operation} style={{maxWidth:length*10+20}} onChange={setOperation} />
                <InputForm {...value} onChange={setValue} />
            </Form.Group>
        ) 
        : (<InputForm {...value} onChange={setValue} />);
}


const InputForm = (props:InputFormProps)=>{
    const setData = (value:string)=>{ props.onChange && props.onChange(value); }
    const onChange:React.ChangeEventHandler<HTMLInputElement & HTMLSelectElement> = (e)=>{
        validation(e);
        setData(e.target.value);
    }
    const onChangeMulti = (values:string[])=>{
        // const value = e.map((v)=>{return new Map(Object.entries(v)).get('value'); }).join("\n");
        const value = values.join("\n");
        setData(value);
    }

    const validation = (e:React.ChangeEvent<HTMLInputElement & HTMLSelectElement>)=>{
        let value = e.target.value;
        if(props.numberOnly && props.numberOnly===true) {
            //eslint-disable-next-line
            value = value.replace(/[a-zA-Zㄱ-ㅎ|ㅏ-ㅣ|가-힣\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g, '');
        }
        e.target.value = value;
    }

    switch(props.type){
        case FilterType.select :
            return (
                <Form.Select onChange={onChange} value={props.value} style={props.style}>
                    { props.options?.map((v) => { return (<option key={v.value} value={v.value}>{v.label}</option>);}) }
                </Form.Select>
            );

        case FilterType.arraySelect : {
            const value = props.value?.toString().split("\n");
            const list:SelectType[] = props.options?.map(v=>{return {label:v.label, value:v.value}}) || [];
            const selected:SelectType[] = !value ? [] : list.filter((v)=>value?.indexOf(v.value)!==-1);
            return (<MultiSelect options={list} value={selected.map((v)=>v.value)} style={{minWidth:'6em'}} onChange={onChangeMulti} size='sm' />);
        }
        case FilterType.mSelect : {
            const value = props.value?.toString().split("\n");
            const list:SelectType[] = props.options?.map(v=>{return {label:v.label, value:v.value}}) || [];
            const selected:SelectType[] = !value ? [] : list.filter((v)=>value?.indexOf(v.value)!==-1);
            return (<MultiSelect options={list} value={selected.map((v)=>v.value)} style={{minWidth:'6em'}} onChange={onChangeMulti} size='sm' />);
        }
            // case FilterType.mSelect :
        //     const value = props.value?.toString().split("\n");
        //     const list:SelectType[] = props.options?.map(v=>{return {label:v.label, value:v.value}}) || [];
        //     const selected:SelectType[] = !value ? [] : list.filter((v)=>value?.indexOf(v.value)!==-1);
        //     return (<MultiSelect options={list} value={selected} onChange={onChangeMulti} labelledBy={props.id} />);

        case FilterType.list :
            return (
                <Form.Control as="textarea" rows={1} 
                    onChange={onChange} 
                    placeholder={props.placeholder || ''}//'목록을 입력하세요'} 
                    value={props.value} 
                    maxLength={props.maxLength || 100000}
                    style={props.style}
                />);

        case FilterType.date : 
        case FilterType.number : 
        case FilterType.text :
            return (
                <Form.Control type={props.type} 
                    onChange={onChange} 
                    placeholder={props.placeholder || ''}// '입력하세요'} 
                    value={props.value} 
                    maxLength={props.maxLength || (props.type===FilterType.date ? 20 : 1024)}
                    style={props.style}
                    className='ps-1 pe-1'
                />);
        default : return null;
    }
}

export default CustomFilter;