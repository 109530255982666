import Config from "../../../common/conf/Config";
import { KakaoService, NaverService } from "../../../common/services";
import { EstimateAccountGroupType } from "../../../common/services/models/admin/EstimateTypes";
import { BizMoneyResType } from "../../../common/services/models/BizMoneyTypes";
import Code from "../../../modules/code/Code";
import { sleep } from "./BizMoneyLibs";

/** 비즈머니 데이터 동기화 및 로딩 */
export const bizMoneyDataSync = async (
    data:EstimateAccountGroupType[], 
    naverCallback:(v:BizMoneyResType[])=>void, 
    kakaoCallback:(v:BizMoneyResType[])=>void,
)=>{
    const now = new Date().getTime() - 10 * 1000;   //갱신 시간 기준을 10초갭을 두도록(5분정각으로 진행하는 경우, 갱신 상태 체크시 약간의 오차 발생)

    // 네이버 자산 ID
    const naverIds:number[] = data.reduce((prev:number[], v)=>{
        const tmp:number[] = v.clients
            .filter((v2)=>v2.useYn===Code.base.useYn.Y.value)
            .filter((v2)=>v2.mediaType===Code.base.mediaType.NAVER.value)
            .map((v2)=>v2.clientId.toNumber());
        return [...prev, ...tmp];
    },[]);

    //카카오 자산 ID
    const kakaoIds:number[] = data.reduce((prev:number[], v)=>{
        const tmp:number[] = v.clients
            .filter((v2)=>v2.useYn===Code.base.useYn.Y.value)
            .filter((v2)=>v2.mediaType===Code.base.mediaType.KAKAO.value)
            .map((v2)=>v2.clientId.toNumber());
        return [...prev, ...tmp];
    },[]);

    return Promise.all([
        naverPolling(naverIds, now, naverCallback),    //네이버
        kakaoPolling(kakaoIds, now, kakaoCallback),    //카카오
    ]);
}

/** 네이버 비즈머니 Sync */
const naverDataSync = async (ids:number[])=>{
    if(!ids.length){ return [] as BizMoneyResType[]; }
    return await NaverService.dashboard.cash.post(ids);
}

/** 카카오 비즈머니 Sync */
const kakaoDataSync = async (ids:number[])=>{
    if(!ids.length){ return [] as BizMoneyResType[]; }
    return await KakaoService.dashboard.cash.put(ids, 5);
}

/**
 * 네이버 동기화 체크
 * @param data 광고주 데이터
 * @param time 기준 시간값
 * @param callback 데이터 업데이트
 * @returns 
 */
const naverPolling = async (
    ids:number[],
    time:number, 
    callback:(data:BizMoneyResType[])=>void,
)=>{
    let cnt=0;
    while(
        ids.length>0            // 데이터가 없으면 더이상 호출하지 않음
        && cnt++ < Config.system.bizmoney.data.sync_retry_max_count // 최대 호출 회수
    ){
        if((cnt % 4)===0){
            await naverDataSync(ids);    //동기화 요청
        }
        await sleep(1);

        const result:BizMoneyResType[]|undefined = await NaverService.dashboard.cash.get(ids).then((res)=>{
            callback(res.data.result || []);
            return res.data.result || [];
        });
        const tmp = result?.filter((v)=>ids.includes(v.clientId)).find((v)=>time - new Date(v.syncDateTime || 0).getTime() > Config.system.bizmoney.data.expire_time);   //5분 경과한 건이 있으면
        if(!tmp){ break; }    // 5분 경과한 건이 없으면 종료
        await sleep(4); // 5초 대기
    }
    return true;
}

/**
 * 카카오 동기화 체크
 * @param data 광고주 데이터
 * @param time 기준 시간값
 * @param callback 데이터 업데이트
 * @returns 
 */
const kakaoPolling = async (
    ids:number[],
    time:number, 
    callback:(data:BizMoneyResType[])=>void,
)=>{
    let cnt=0;
    while(
        ids.length>0            // 데이터가 없으면 더이상 호출하지 않음
        && cnt++ < Config.system.bizmoney.data.sync_retry_max_count // 최대 호출 회수
    ){
        if((cnt % 4)===0){
            await kakaoDataSync(ids);    //동기화 요청
        }
        await sleep(1); // 5초 대기

        // 데이터 호출
        const result:BizMoneyResType[]|undefined = await KakaoService.dashboard.cash.get(ids).then((res)=>{
            const data:BizMoneyResType[] = res.data.result?.map((v:BizMoneyResType):BizMoneyResType=>({
                ...v, 
                successSyncDateTime: v.successSyncDateTime?.split(".")[0] || "",
                syncDateTime: v.syncDateTime?.split(".")[0] || "",
                clientId:String(v.clientId).toNumber()})
            ) || [];
            callback(data); // 상위 노드에 데이터 전달
            return data;
        });
        const tmp = result?.filter((v)=>ids.includes(v.clientId)).find((v)=>time - new Date(v.syncDateTime || 0).getTime() > Config.system.bizmoney.data.expire_time);   //5분 경과한 건이 있으면
        if(!tmp){ break; }    // 5분 경과한 건이 없으면 종료
        await sleep(4); // 5초 대기
    }
    return true;
}

