import clsx from 'clsx';
import Config from '../../../../../app/common/conf/Config';
import AdvertiserSelect from "../../../../../app/pages/components/account/AccountSelect";
import { SidebarMenuMain } from './SidebarMenuMain';
import styles from './style.module.scss';

const SidebarMenu = () => {
  return (
    <div className={clsx('app-sidebar-menu overflow-hidden flex-column-fluid', styles.sidebar)}>
      <div
        id='kt_app_sidebar_menu_wrapper'
        className='app-sidebar-wrapper scroll-y my-5'
        // className='app-sidebar-wrapper hover-scroll-overlay-y my-5'
        data-kt-scroll='true'
        data-kt-scroll-activate='true'
        data-kt-scroll-height='auto'
        data-kt-scroll-dependencies='#kt_app_sidebar_logo, #kt_app_sidebar_footer'
        data-kt-scroll-wrappers='#kt_app_sidebar_menu'
        data-kt-scroll-offset='5px'
        data-kt-scroll-save-state='true'
      >
        <div
          className='menu menu-column menu-rounded menu-sub-indention px-3'
          id='#kt_app_sidebar_menu'
          data-kt-menu='true'
          data-kt-menu-expand='false'
        >
          {Config.app.ClientSelectV2.enable && <>
            <AdvertiserSelect/>
          </>}
          <SidebarMenuMain />
        </div>
      </div>
    </div>
  )
}

export { SidebarMenu };

