import { addDays } from "date-fns";
import ko from 'date-fns/locale/ko';
import { CSSProperties, useEffect, useRef, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { DateRangePicker as DateRangePickerOld, Range, RangeKeyDict } from "react-date-range";
import { definedDates, formatDateDisplay, staticDefinedRanges } from "../../modules/calendar/common";

import clsx from "clsx";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Config from "../../../common/conf/Config";
import styles from "../../modules/calendar/calendar.module.scss";
import "../../modules/calendar/styles.css"; // main css file
import { Warning } from "../../modules/card/CustomCard";
import AdploButton from "../buttons/AdploButton";
import AdPloInput from "./AdPloInput";

export interface DateRangeDataType{ startDate:Date, endDate:Date; }

export interface AdPloDateRangePickerProps{
    disabled?:boolean;
    readOnly?:boolean;
    startDate?:Date;
    endDate?:Date;
    maxDate?:Date;
    onChange?:(startDate:Date, endDate:Date)=>void;
    isViewer?:boolean;
    formatter?:(startDate:Date, endDate:Date)=>string|React.ReactNode;
    /**
     * Date Format : 
     *  default "yyyy/MM/dd",
     * date-fns/format
     */
    format?:string;

    size?:'sm';

    className?: string;
    style?: CSSProperties;
}

const isDevel:boolean = ["local", "devel"].includes(Config.project.mode);

const AdPloDateRangePicker = (props:AdPloDateRangePickerProps) => {
    const format = props.format || "yyyy/MM/dd";
    const startDate = props.startDate || definedDates.sevenDay.startDate;
    const endDate = props.endDate || definedDates.sevenDay.endDate;

    const [show, setShow] = useState(false);
    const [state, setState] = useState<Range>({});
    const [tag, setTag] = useState('');/* 달력선택 후 input text */
    const [warning, setWarning] = useState<string|React.ReactNode|undefined>();

    const refInput = useRef<HTMLInputElement>(null);
    const refContain = useRef<HTMLDivElement>(null);
    const refInputContain = useRef<HTMLDivElement>(null);
    const refInpIcon = useRef<HTMLDivElement>(null);

    //달력영역 밖에 클릭시 닫기
    const handleCloseModal= (e:any) => {
        if(refInput.current?.contains(e.target)) return;
        if(refInpIcon.current?.contains(e.target)) return;
        if(!refContain.current?.contains(e.target)) setShow(false);
    }
    useEffect(() => {
        window.addEventListener('click', handleCloseModal);
        return () => {
            window.removeEventListener('click', handleCloseModal);
        };
    },[show]);
    //==================================================


    useEffect(()=>{
        setState({
            startDate:startDate,
            endDate:endDate,
            key:'selection',
        });
        setTag(formatDateDisplay(startDate, format) +' ~ '+ formatDateDisplay(endDate, format));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[startDate, endDate]);

    //달력 기간 선택
    const handleOnChange = (ranges:RangeKeyDict) => {
        const data = ranges['selection'];
        const days:number = new Date(data.endDate || 0).dateDiff(new Date(data.startDate || 0));
        if(days > 90){
            setWarning(`선택하신 구간은 ${days}일입니다. 최대 90일까지만 선택가능합니다.`);
            return;
        }
        setState({
            startDate: data.startDate,
            endDate: data.endDate,
            key: data.key,
        });
    };

    //사용자지정 기간 적용버튼 클릭
    const onClickApply = () => {
        setTag(formatDateDisplay(state.startDate, props.format) +' ~ '+ formatDateDisplay(state.endDate, props.format));
        props.onChange && props.onChange(state.startDate as unknown as Date, state.endDate as unknown as Date);
        setShow(false);
    };

    //달력 오픈
    const openDateRangePicker = () => {
        if(props.readOnly===true || props.disabled===true) return;
        setShow(true);
    };

    return (<>
        {props.isViewer && formatDateDisplay(state.startDate, format) +' ~ '+ formatDateDisplay(state.endDate, format)}
        {!props.isViewer && (<>
            <div ref={refInputContain} className={props.size==='sm' ? styles.inputRangePickerSmall : styles.inputRangePicker} onClick={openDateRangePicker}>
                <AdPloInput ref={refInput} className={clsx("inputDate", props.className)} style={props.style}
                     type="text" value={tag} readOnly={true} disabled={props.disabled} />
                <i ref={refInpIcon} className="bi bi-calendar-range"></i>
            </div>
            <Overlay
                ref={refContain}
                show={show}
                target={refInput.current}
                placement="bottom-start"
                containerPadding={0}
            >
                <Popover style={{maxWidth:'inherit'}}>
                    <DateRangePickerOld 
                        locale={ko} 	// 한국어 달력
                        onChange={handleOnChange}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        // minDate={isDevel ? undefined :addDays(new Date(), -90)}
                        maxDate={props.maxDate|| addDays(new Date(), -1)}
                        ranges={[state]}
                        direction="horizontal"
                        inputRanges={[]}
                        showDateDisplay={false}
                        staticRanges={staticDefinedRanges}
                        weekStartsOn={1}
                    />
                    <div className={clsx('p-3', styles.DateRangePickerButton)}>
                        <Warning className="m-3" warning={warning}/>
                        <p className="my-auto d-inline px-4">{formatDateDisplay(state.startDate, format) +' ~ '+ formatDateDisplay(state.endDate, format)}</p>
                        <AdploButton variant="secondary" className="me-1" onClick={() => {setShow(false);}}>취소</AdploButton>
                        <AdploButton variant="primary" onClick={onClickApply}>적용</AdploButton>
                    </div>
                </Popover>
            </Overlay>
        </>)}
    </>);
};


export default AdPloDateRangePicker;
