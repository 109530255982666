import {useState, useEffect, CSSProperties} from 'react';
import { ButtonGroup  } from 'react-bootstrap';
import Radio, { RadioProps } from "./Radio";
import { OptionType } from '../../../../modules/types/Types';

export interface RadioGroupProps extends Omit<RadioProps, 'onChange'>{
    vertical?: boolean;
    value?: string;
    onChange?: (value:string)=>void;
    options: OptionType[];
    className?:string;
    style?:CSSProperties;
    radio?:{
        className?:string;
        style?:CSSProperties;
    };
}

function RadioGroup(props:RadioGroupProps) {
    const [value, setValue] = useState<string>();
    useEffect(()=>{ setValue(props.value); },[props.value]);

    const handleOnClick = (value:string) => {
        if(props.readOnly || props.disabled) return;
        setValue(value);
        props.onChange && props.onChange(value);
    }
    return(
        <ButtonGroup vertical={props.vertical} className={props.className} >
            {props.isViewer && props.options.find((v)=>props.value===v.value)?.label}
            {!props.isViewer && props.options.map((v, index)=>(
                <Radio key={index} name={props.name}
                    checked={value === v.value}
                    onClick={props.readOnly ? undefined : ()=>handleOnClick(v.value)}
                    value={v.value} 
                    readOnly={props.readOnly} 
                    disabled={props.disabled}
                    size={props.size}
                    {...props.radio}
                >{v.label}</Radio>
           ))}
        </ButtonGroup>
    );
}

export default RadioGroup;