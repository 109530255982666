export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname)
  if (!current || !url) {
    return false
  }

  if (current === url) {
    return true
  }

  const p = current.indexOf(url);
  if (p > -1) {   // 'ad' 'adgroup' 비슷한 path인 경우 혼동되는 이슈 수정
    const temp = current.substring(p);
    if(url === temp){
      return true
    }
  }

  return false
}

export function checkIsActiveNew(pathname: string, url: string) {
  const current = getCurrentUrl(pathname)
  if (!current || !url) {
    return false
  }

  if (current === url) {
    return true
  }

  const t1 = current.split('/');
  const t2 = url.split('/');
  if(t1.length < t2.length){ return false; }
  for(var i=0; i<t2.length; i++){
    if(t1[i]!==t2[i]){ return false; }
  }
  
  return true;
}
