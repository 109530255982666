import { NaverServer } from '../../axios';
import Utils from '../../utils/Utils';
import { MediaResponseType } from '../models/ResponseTypes';
import NaverHelperComponent from './NaverHelperComponent';

/** 네이버 사용자 Summary Helper */
export default class ClientSummaryHelper extends NaverHelperComponent{
    static path="/media/account-summary";          //기본 Path    //표준 상세조회
    public static get<T=any>(){
        return Utils.apiRequestValidation({}) || NaverServer.get<MediaResponseType<T>>(this.path,{headers:{useAccount:true,}});
    }
}