import clsx from "clsx";
import { useEffect, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { KakaoService, NaverService } from '../../../common/services';
import { DashBoardStatusListType, DashBoardStatusResType } from '../../../common/services/models/DashboardTypes';
import { useUserContext } from "../../../contexts/UserContext";
import MediaTypeIcon, { getMediaTypeColor } from "../../modules/symbol/MediaTypeIcon";

interface HistoryDashboardProps{
}

const HistoryDashboard:React.FC<HistoryDashboardProps> = (props)=>{
    const userContext = useUserContext();
    const [useData, setUseData] = useState<DashBoardStatusListType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const loadData = async ()=>{
        setLoading(true);
        const naver:DashBoardStatusResType[] = await NaverService.dashboard.getStatus(['CTS0001','CTS0002'])
        .then((res)=>res.data.result)
        .catch((e) => {
            userContext.alertMessage.add({variant:"danger", title:"네이버 작업현황 조회 오류", body:`네이버 작업현황 데이터 조회 중 오류가 발생했습니다. ${NaverService.ErrorMessage(e)}`});
            return [];
        });

        const kakao:DashBoardStatusResType[] = await KakaoService.dashboard.getStatus(['CTS0001','CTS0002'])
        .then((res)=>res.data.result)
        .catch((e) => {
            userContext.alertMessage.add({variant:"danger", title:"카카오 작업현황 조회 오류", body:`카카오 작업현황 데이터 조회 중 오류가 발생했습니다. ${KakaoService.ErrorMessage(e)}`});
            return [];
        });
        setLoading(false);

        const data:DashBoardStatusListType[] = [...naver, ...kakao].reduce((prev:DashBoardStatusListType[], v1:DashBoardStatusResType)=>{
            const tmp:DashBoardStatusListType[] = v1.clients.map((v2):DashBoardStatusListType=>({...v1, ...v2}));
            return [...prev, ...tmp];
        }, []);

        // 빈데이터 제외, 1 - count 내림차 정렬, 자산명 오름차 정렬
        setUseData( data.filter(v=>v.count>0).sort((a,b)=>a.count===b.count ? (a.clientName<b.clientName ? -1 : 1) : (a.count>b.count ? -1 : 1)) );
    }

    useEffect(()=>{
        loadData();
    // eslint-disable-next-line
    }, []);

    return <div className="scroll" style={{height:"230px"}}>
        {loading && <div className="text-center" style={{marginTop:'100px'}}><Spinner animation="border" variant={"secondary"} role='status' /></div>}
        {!loading && !useData?.length && <div className="text-center" style={{marginTop:'100px'}}>모든 작업이 완료되었습니다.</div>}
        {useData.map((v,index)=>{
            const bgColor:string|undefined = getMediaTypeColor(v.mediaType);
            return <div key={index} className='d-flex mb-3 border p-1 rounded me-1'>
                <span className={clsx('bullet bullet-vertical h-50px')} style={{backgroundColor:bgColor}}></span>
                <Container className="p-1 d-flex align-items-center">
                    <div className='w-40px text-center'><MediaTypeIcon code={v.mediaType} iconStyle={{width:'1.35em', height:'1.35em', margin:0}} /></div>
                    <div className='flex-grow-1'>
                        <div className='text-gray-800 fw-bold fs-6'>{v.clientName}</div>
                        <span className='text-muted fw-semibold d-block'>{v.accountGroupName}</span>
                    </div>
                    <span className='badge badge-light-dark fs-8 fw-bold' style={{height:'fit-content'}}>{v.count.addComma()}</span>
                    <Link
                        to={`/pages/${v.mediaType.toLowerCase()}/history`}
                        onClick={()=>{userContext.userSelect.accountState?.select(v.accountGroupId, v.mediaType, v.clientId.toString())}}
                        className="text-hover-primary bg-hover-light rounded ms-2 h-40px w-20px pt-3 text-center">
                        <i className="bi bi-chevron-right fw-bolder" />
                    </Link>
                </Container>
            </div>
        })}
    </div>
}
export default HistoryDashboard;



