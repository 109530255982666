import React, { CSSProperties, useEffect, useState } from "react";
import { StatReportResType, StatReportResKeys} from "../../../common/services/models/naver/StatReportType";
import { NaverOxfordColumns, StatDataExtends } from "../modules/NaverOxfordColumns";
import ExplorerChart from "./ExplorerChart";
import Table from "../../modules/table/Table";
import { HeaderColumn, TablePropsType } from "../../modules/table/TableType";
import { LoadingLayer } from "../../../modules/modal/LoadingModal";
import { NaverService } from "../../../common/services";
import { getStatDateFormatter, getStatReportTotal } from "./StatInfoData";

interface ExplorerListDetailProps{
    level?:string;
    id?:string;
    className?:string;
    style?:CSSProperties;
}
const ExplorerListDetail:React.FC<ExplorerListDetailProps> = (props) => {
    const [data, setData] = useState<StatReportResType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(()=>{
        if(props.level && props.id){
            setLoading(true);
            NaverService.performance(props.level, props.id).then((res)=>{
                const data:any[] = res?.data?.result?.daily?.map((v:StatReportResType)=>StatDataExtends(v)) || [];  //일부러 any처리함 - 아래 빠진 일자데이터 추가
                const dateRange = NaverService.common.getDateRange();
                const days = dateRange.end.dateDiff(dateRange.start);
                for(let i=0; i<=days; i++){
                    const date = new Date(dateRange.start).addDays(i).format('yyyyMMdd');
                    if( !data.find((v)=>v.key === date)){ data.push({key:date})}
                }
                setData(data.sort((a,b)=>a.key-b.key)); //날짜 정렬
            })
            .finally(()=>{setLoading(false);});
        }
    },[props.level, props.id]);
    

    const columns:HeaderColumn[] = [
        { accessor: StatReportResKeys.key, header: '날짜', style:{minWidth:'100px', textAlign: 'center'}, useSort:false, formatter:getStatDateFormatter, },
        ...NaverOxfordColumns,
    ];

    const tableProps:TablePropsType<StatReportResType> = {
        columns:columns,
        data: data,
        filterable: false,
        showColumns:false,
        showDeviceType: false,
        pageable:false,
        staticData: [getStatReportTotal(data)],
    }

    return <div className={props.className} style={props.style}>
        {loading && <LoadingLayer className='border bg-gray-100 pt-20 pb-20 text-secondary' variant='secondary' style={{paddingLeft:'350px'}} />}
        {!loading && <>
            <ExplorerChart data={data} />
            <Table {...tableProps} />
        </>}
    </div>
}

export default ExplorerListDetail;