import { OptionType } from "../../../modules/types/Types";

class CodeData{    
    /**
    * Code 데이터로부터 category에 대한 데이터를 Option타입으로 반환합니다.
    * @param category 
    * @returns 
    */
    public static getCodeOptions = (category:string) => {
        const data:[string,OptionType][] = this.code.filter((v)=>v.code.indexOf(category)===0)
                        .map((v):[string,OptionType]=>{return [v.code, {value:v.code, label:v.name}];});
        return Object.fromEntries<OptionType>(data) || {};
    }

    //DB코드 데이터
    public static readonly code = [
        { code: "KTT2001", codeGroup1: "K", codeGroup2: "TT", name: "생성(연결)", description: "생성(연결)", category: "KTT2000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "NET0011", codeGroup1: "N", codeGroup2: "ET", name: "검색어", description: "네이버 검색광고 검색어", category: "NET0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "KDT0008", codeGroup1: "K", codeGroup2: "DT", name: "레포트", description: "레포트 데이터 다운로드", category: "KDT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-08-31T15:06:16.604215+09:00", modifiedDatetime: "2023-08-31T15:06:16.604215+09:00" },
        { code: "NET0001", codeGroup1: "N", codeGroup2: "ET", name: "전체(동기화)", description: "네이버 검색광고 전체", category: "NET0000", useYn: "YES", createdBy: null, modifiedBy: "17", createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-09-04T14:48:40.859563+09:00" },
        { code: "DST0003", codeGroup1: "D", codeGroup2: "ST", name: "READY", description: "Dbid 테스크 대기중 상태코드", category: "DST0000", useYn: "YES", createdBy: "127", modifiedBy: "127", createdDatetime: "2023-09-07T11:01:07.601459+09:00", modifiedDatetime: "2023-09-07T11:01:07.601459+09:00" },
        { code: "DST0004", codeGroup1: "D", codeGroup2: "ST", name: "SUCCESS", description: "Dbid 테스크 연산성공 상태코드", category: "DST0000", useYn: "YES", createdBy: "127", modifiedBy: "127", createdDatetime: "2023-09-07T11:01:33.822163+09:00", modifiedDatetime: "2023-09-07T11:01:33.822163+09:00" },
        { code: "DST0006", codeGroup1: "D", codeGroup2: "ST", name: "IMPOSSIBLE", description: "Dbid 테스크 연산불가 상태코드", category: "DST0000", useYn: "YES", createdBy: "127", modifiedBy: "127", createdDatetime: "2023-09-07T11:02:24.298078+09:00", modifiedDatetime: "2023-09-07T11:02:24.298078+09:00" },
        { code: "DST0005", codeGroup1: "D", codeGroup2: "ST", name: "FAIL", description: "Dbid 테스크 연산불가 상태코드", category: "DST0000", useYn: "YES", createdBy: "127", modifiedBy: "127", createdDatetime: "2023-09-07T11:02:24.298078+09:00", modifiedDatetime: "2023-09-07T11:02:24.298078+09:00" },
        { code: "NDT0008", codeGroup1: "N", codeGroup2: "DT", name: "레포트", description: "레포트 데이터 다운로드", category: "NDT0000", useYn: "YES", createdBy: "53", modifiedBy: "53", createdDatetime: "2023-09-14T10:19:46.78498+09:00", modifiedDatetime: "2023-09-14T10:19:46.78498+09:00" },
        { code: "DST0002", codeGroup1: "D", codeGroup2: "ST", name: "RUNNING", description: "Dbid 테스크 연산중 상태코드", category: "DST0000", useYn: "YES", createdBy: "127", modifiedBy: "127", createdDatetime: "2023-09-07T10:59:32.543055+09:00", modifiedDatetime: "2023-09-07T10:59:32.543055+09:00" },
        { code: "DST0001", codeGroup1: "D", codeGroup2: "ST", name: "END", description: "Dbid 테스크 종료 상태코드", category: "DST0000", useYn: "YES", createdBy: "127", modifiedBy: "127", createdDatetime: "2023-09-07T11:00:38.082717+09:00", modifiedDatetime: "2023-09-07T11:00:38.082717+09:00" },
        { code: "CTS0001", codeGroup1: "C", codeGroup2: "TS", name: "대기중", description: "대기중", category: "CTS0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "CTS0003", codeGroup1: "C", codeGroup2: "TS", name: "완료", description: "완료", category: "CTS0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "CTS0002", codeGroup1: "C", codeGroup2: "TS", name: "진행중", description: "진행중", category: "CTS0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "CRC0003", codeGroup1: "C", codeGroup2: "RC", name: "배치", description: "요청타입 배치", category: "CRC0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NTT0001", codeGroup1: "N", codeGroup2: "TT", name: "동기화", description: "동기화", category: "NTT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NTT0002", codeGroup1: "N", codeGroup2: "TT", name: "수정", description: "수정", category: "NTT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NTT0003", codeGroup1: "N", codeGroup2: "TT", name: "등록", description: "등록", category: "NTT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NTT0004", codeGroup1: "N", codeGroup2: "TT", name: "삭제", description: "삭제", category: "NTT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NTT0005", codeGroup1: "N", codeGroup2: "TT", name: "복사", description: "복사", category: "NTT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "CTS0004", codeGroup1: "C", codeGroup2: "TS", name: "실패", description: "실패", category: "CTS0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "NTT0006", codeGroup1: "N", codeGroup2: "TT", name: "ON/OFF 상태 변경", description: "ON/OFF 상태 변경", category: "NTT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NTT0007", codeGroup1: "N", codeGroup2: "TT", name: "입찰가 수정", description: "입찰가 수정", category: "NTT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NET0002", codeGroup1: "N", codeGroup2: "ET", name: "비즈채널", description: "네이버 검색광고 비즈채널", category: "NET0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NET0003", codeGroup1: "N", codeGroup2: "ET", name: "캠페인", description: "네이버 검색광고 캠페인", category: "NET0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NET0004", codeGroup1: "N", codeGroup2: "ET", name: "광고그룹", description: "네이버 검색광고 광고그룹", category: "NET0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NET0005", codeGroup1: "N", codeGroup2: "ET", name: "소재", description: "네이버 검색광고 소재", category: "NET0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NET0006", codeGroup1: "N", codeGroup2: "ET", name: "키워드", description: "네이버 검색광고 키워드", category: "NET0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NET0007", codeGroup1: "N", codeGroup2: "ET", name: "확장소재", description: "네이버 검색광고 확장소재", category: "NET0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NET0008", codeGroup1: "N", codeGroup2: "ET", name: "크리테리온", description: "네이버 검색광고 크리테리온", category: "NET0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NET0009", codeGroup1: "N", codeGroup2: "ET", name: "타겟", description: "네이버 검색광고 타겟", category: "NET0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NET0010", codeGroup1: "N", codeGroup2: "ET", name: "제외키워드", description: "네이버 검색광고 제외키워드", category: "NET0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NET9999", codeGroup1: "N", codeGroup2: "ET", name: "모든계정", description: "네이버 검색광고 모든계정", category: "NET0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NBS0001", codeGroup1: "N", codeGroup2: "BS", name: "준비", description: "배치 준비", category: "NBS0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NBS0002", codeGroup1: "N", codeGroup2: "BS", name: "실행중", description: "배치 실행중", category: "NBS0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NBS0003", codeGroup1: "N", codeGroup2: "BS", name: "완료", description: "배치 완료", category: "NBS0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NBS0004", codeGroup1: "N", codeGroup2: "BS", name: "작업오류", description: "배치 작업 중 문제 발생", category: "NBS0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NBT0001", codeGroup1: "N", codeGroup2: "BT", name: "마스터", description: "배치 타입 마스터", category: "NBT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NBT0002", codeGroup1: "N", codeGroup2: "BT", name: "실적", description: "배치 타입 실적", category: "NBT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NBZ0001", codeGroup1: "N", codeGroup2: "BZ", name: "동기화 실행중", description: "배치 동기화 상태 실행 중", category: "NBZ0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NBZ0002", codeGroup1: "N", codeGroup2: "BZ", name: "동기화 완료", description: "배치 동기화 상태 완료", category: "NBZ0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NBZ0003", codeGroup1: "N", codeGroup2: "BZ", name: "비어있음", description: "배치 동기화 상태 비어있음", category: "NBZ0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NBM0001", codeGroup1: "N", codeGroup2: "BM", name: "전체 동기화", description: "배치 전체 동기화", category: "NBM0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NBM0002", codeGroup1: "N", codeGroup2: "BM", name: "변경분 동기화", description: "배치 변경분 동기화", category: "NBM0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NTE0001", codeGroup1: "N", codeGroup2: "TE", name: "작업결과파일", description: "작업 결과 파일", category: "NTE0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NTE0002", codeGroup1: "N", codeGroup2: "TE", name: "작업결과메타파일", description: "작업 결과 메타 파일", category: "NTE0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "CRC0002", codeGroup1: "C", codeGroup2: "RC", name: "파일", description: "요청타입 파일", category: "CRC0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "CRC0001", codeGroup1: "C", codeGroup2: "RC", name: "UI", description: "요청타입 UI", category: "CRC0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "KTT2002", codeGroup1: "K", codeGroup2: "TT", name: "복사", description: "복사", category: "KTT2000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "KTT2003", codeGroup1: "K", codeGroup2: "TT", name: "수정", description: "수정", category: "KTT2000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "KTT2004", codeGroup1: "K", codeGroup2: "TT", name: "수정(ON/OFF)", description: "수정(ON/OFF)", category: "KTT2000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "KTT2005", codeGroup1: "K", codeGroup2: "TT", name: "수정(입찰가)", description: "수정(입찰가)", category: "KTT2000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "KTT2006", codeGroup1: "K", codeGroup2: "TT", name: "삭제", description: "삭제", category: "KTT2000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "KTT1001", codeGroup1: "K", codeGroup2: "TT", name: "마스터 동기화", description: "마스터 동기화", category: "KTT1000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "KTT1002", codeGroup1: "K", codeGroup2: "TT", name: "실적 동기화", description: "실적 동기화", category: "KTT1000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "KTT1003", codeGroup1: "K", codeGroup2: "TT", name: "배치 동기화", description: "배치 동기화", category: "KTT1000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "KTT1004", codeGroup1: "K", codeGroup2: "TT", name: "배치 동기화 마스터", description: "배치 동기화 마스터", category: "KTT1000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "NPT0001", codeGroup1: "N", codeGroup2: "PT", name: "파워링크", description: "네이버 파워링크", category: "NPT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "NPT0002", codeGroup1: "N", codeGroup2: "PT", name: "쇼핑검색", description: "네이버 쇼핑검색", category: "NPT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:36:48.225366+09:00", modifiedDatetime: "2023-07-05T14:36:48.225366+09:00" },
        { code: "KET0007", codeGroup1: "K", codeGroup2: "ET", name: "카카오 키워드광고 소재연결", description: "카카오 키워드광고 소재연결", category: "KET0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "NDT0001", codeGroup1: "N", codeGroup2: "DT", name: "전체데이터", description: "전체데이터 다운로드", category: "NDT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-21T15:03:52.320271+09:00", modifiedDatetime: "2023-07-21T15:03:52.320271+09:00" },
        { code: "NDT0002", codeGroup1: "N", codeGroup2: "DT", name: "등록", description: "등록 템플릿 다운로드", category: "NDT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-21T15:03:52.320271+09:00", modifiedDatetime: "2023-07-21T15:03:52.320271+09:00" },
        { code: "NDT0003", codeGroup1: "N", codeGroup2: "DT", name: "삭제", description: "삭제 템플릿 다운로드", category: "NDT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-21T15:03:52.320271+09:00", modifiedDatetime: "2023-07-21T15:03:52.320271+09:00" },
        { code: "NDT0004", codeGroup1: "N", codeGroup2: "DT", name: "ON/OFF 변경", description: "ON/OFF 변경용 템플릿 다운로드", category: "NDT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-21T15:03:52.320271+09:00", modifiedDatetime: "2023-07-21T15:03:52.320271+09:00" },
        { code: "NDT0005", codeGroup1: "N", codeGroup2: "DT", name: "입찰가 변경", description: "입찰가 변경용 템플릿 다운로드", category: "NDT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-21T15:03:52.320271+09:00", modifiedDatetime: "2023-07-21T15:03:52.320271+09:00" },
        { code: "NDT0006", codeGroup1: "N", codeGroup2: "DT", name: "수정", description: "수정용 템플릿 다운로드", category: "NDT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-21T15:03:52.320271+09:00", modifiedDatetime: "2023-07-21T15:03:52.320271+09:00" },
        { code: "NDT0007", codeGroup1: "N", codeGroup2: "DT", name: "복사", description: "복사용 템플릿 다운로드", category: "NDT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-21T15:03:52.320271+09:00", modifiedDatetime: "2023-07-21T15:03:52.320271+09:00" },
        { code: "NDS0001", codeGroup1: "N", codeGroup2: "DS", name: "대기", description: "다운로드 데이터 생성 대기", category: "NDS0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-28T15:02:05.263568+09:00", modifiedDatetime: "2023-07-28T15:02:05.263568+09:00" },
        { code: "NDS0002", codeGroup1: "N", codeGroup2: "DS", name: "생성중", description: "다운로드 데이터 생성중", category: "NDS0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-28T15:02:05.263568+09:00", modifiedDatetime: "2023-07-28T15:02:05.263568+09:00" },
        { code: "NDS0003", codeGroup1: "N", codeGroup2: "DS", name: "성공", description: "다운로드 데이터 생성 성공", category: "NDS0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-28T15:02:05.263568+09:00", modifiedDatetime: "2023-07-28T15:02:05.263568+09:00" },
        { code: "NDS0004", codeGroup1: "N", codeGroup2: "DS", name: "실패", description: "다운로드 데이터 생성 실패", category: "NDS0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-28T15:02:05.263568+09:00", modifiedDatetime: "2023-07-28T15:02:05.263568+09:00" },
        { code: "CDR0001", codeGroup1: "C", codeGroup2: "RT", name: "입찰가 조정", description: "DBID 입찰가조정 대상", category: "CDR0000", useYn: "YES", createdBy: "17", modifiedBy: "17", createdDatetime: "2023-08-23T14:51:30.970716+09:00", modifiedDatetime: "2023-08-23T14:51:30.970716+09:00" },
        { code: "CDR0002", codeGroup1: "C", codeGroup2: "RT", name: "키워드 오프", description: "DBID 키워드 오프 대상", category: "CDR0000", useYn: "YES", createdBy: "17", modifiedBy: "17", createdDatetime: "2023-08-23T14:52:01.522291+09:00", modifiedDatetime: "2023-08-23T14:52:01.522291+09:00" },
        { code: "KTT1005", codeGroup1: "K", codeGroup2: "TT", name: "배치 동기화 실적", description: "배치 동기화 실적", category: "KTT1000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "KET0001", codeGroup1: "K", codeGroup2: "ET", name: "카카오 키워드광고 광고계정", description: "카카오 키워드광고 광고계정", category: "KET0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "KET0002", codeGroup1: "K", codeGroup2: "ET", name: "카카오 키워드광고 비즈채널", description: "카카오 키워드광고 비즈채널", category: "KET0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "KET0003", codeGroup1: "K", codeGroup2: "ET", name: "카카오 키워드광고 캠페인", description: "카카오 키워드광고 캠페인", category: "KET0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "KET0004", codeGroup1: "K", codeGroup2: "ET", name: "카카오 키워드광고 광고그룹", description: "카카오 키워드광고 광고그룹", category: "KET0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "KET0005", codeGroup1: "K", codeGroup2: "ET", name: "카카오 키워드광고 키워드", description: "카카오 키워드광고 키워드", category: "KET0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "KET0006", codeGroup1: "K", codeGroup2: "ET", name: "카카오 키워드광고 소재", description: "카카오 키워드광고 소재", category: "KET0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "KET0008", codeGroup1: "K", codeGroup2: "ET", name: "카카오 키워드광고 이미지", description: "카카오 키워드광고 이미지", category: "KET0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-05T14:20:50.396977+09:00", modifiedDatetime: "2023-07-05T14:20:50.396977+09:00" },
        { code: "KDT0001", codeGroup1: "K", codeGroup2: "DT", name: "전체 데이터", description: "전체 데이터 다운로드", category: "KDT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-27T10:33:28.400247+09:00", modifiedDatetime: "2023-07-27T10:33:28.400247+09:00" },
        { code: "KDT0002", codeGroup1: "K", codeGroup2: "DT", name: "수정", description: "수정 템플릿 다운로드", category: "KDT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-27T10:33:28.400247+09:00", modifiedDatetime: "2023-07-27T10:33:28.400247+09:00" },
        { code: "KDT0003", codeGroup1: "K", codeGroup2: "DT", name: "ON/OFF 변경", description: "ON/OFF 수정 템플릿 다운로드", category: "KDT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-27T10:33:28.400247+09:00", modifiedDatetime: "2023-07-27T10:33:28.400247+09:00" },
        { code: "KDT0004", codeGroup1: "K", codeGroup2: "DT", name: "입찰가 변경", description: "입찰가 수정 템플릿 다운로드", category: "KDT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-27T10:33:28.400247+09:00", modifiedDatetime: "2023-07-27T10:33:28.400247+09:00" },
        { code: "KDT0005", codeGroup1: "K", codeGroup2: "DT", name: "삭제", description: "삭제 템플릿 다운로드", category: "KDT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-27T10:33:28.400247+09:00", modifiedDatetime: "2023-07-27T10:33:28.400247+09:00" },
        { code: "KDT0006", codeGroup1: "K", codeGroup2: "DT", name: "복사", description: "복사 템플릿 다운로드", category: "KDT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-07-27T10:33:28.400247+09:00", modifiedDatetime: "2023-07-27T10:33:28.400247+09:00" },
        { code: "KDT0007", codeGroup1: "K", codeGroup2: "DT", name: "연결", description: "소재연결 템플릿 다운로드", category: "KDT0000", useYn: "YES", createdBy: null, modifiedBy: null, createdDatetime: "2023-08-11T13:28:28.49928+09:00", modifiedDatetime: "2023-08-11T13:28:28.49928+09:00" },
    ];
}


 
export default class Code{
    /**
     * Object형을 OptionType 구조로 변형하여 value,label을 사용할 수 있도록 합니다.
     */
    static convert = (data:Object)=>{
       return Object.entries(data).map((v):[string,OptionType]=>[v[0], {value:v[0], label:v[1]}]);
    };


    private static getValueCore = (data:any, key:string|string[]):OptionType|undefined => {
        const list = this.getOptions(data);
        const key2:string[] = typeof(key)!=='object' ? [key] : key;
        // return list.find((v)=>_.isEqual(v.value,key));  //배열비교도 확장 - 순서 영향있음(순서동일)
        // return list.find((v)=>v.value===key);    //기본
        return list.find((v)=>key2.equal(v.value.split(",")));
        // return Utils.DotNotation.getValue(key, data);
    }

    /**
     * 지정한 데이터에서 검색하여 해당 객체를 반환합니다.
     * @param data - NaverCode.data
     * @returns 
     */
    public static search = (data:object[]|object, filter?:string|string[], isView?:boolean):OptionType|undefined=>{ 
        const result:OptionType[] = [];
        const data2:object[] = Array.isArray(data) ? data : [data];
        const filter2:string[]|undefined = !filter ? undefined : (filter && Array.isArray(filter) ? filter : [filter]);
        data2.forEach((v)=>{
            const tmp = Object.entries(v)
                .map((v2)=>v2[1])
                .filter((v4)=>!isView || v4.isView)
                .filter((v3)=>!filter2 || filter2?.includes(v3.value));
            result.push(...tmp);
        });
        return result.find((v)=>!filter2 || filter2?.includes(v.value));;
    }

    /**
     * 지정한 데이터를 OptionType으로 변환합니다.
     * @param data - NaverCode.data
     * @returns 
     */
    public static getOptions = <T=string,S=string>(data:object[]|object, filter?:string|string[], isView?:boolean):OptionType<T,S>[]=>{ 
        const result:OptionType<T,S>[] = [];
        const data2:object[] = Array.isArray(data) ? data : [data];
        const filter2:string[]|undefined = !filter ? undefined : (filter && Array.isArray(filter) ? filter : [filter]);
        data2.forEach((v)=>{
            const tmp = Object.entries(v)
                .map((v2)=>v2[1])
                .filter((v3)=>!filter2 || filter2?.includes(v3.value))
                .filter((v4)=>!isView || v4.isView);
            result.push(...tmp);
        });
        return result.sort((a,b)=>a.order && b.order ? a.order-b.order : 0);
    }

    /**
     * 해당 카테고리 하위 항목들의 value값들을 반환합니다.
     * @param data 
     * @returns 
     */
    public static getCategoryValues = (data:object[]|object):string[]=>{
        const list:object[] = [];
        const data2:object[] = Array.isArray(data) ? data : [data];
        data2.forEach((v)=>{
            const tmp = Object.entries(v).map((v2)=>v2[1]);
            list.push(...tmp);
        });
        return this.getValues(list);
    }

    /**
     * 해당 항목들의 value값들을 반환합니다.
     * @param data 
     * @returns 
     */
    public static getValues = (data:object[]|object):string[]=>{
        const result:OptionType[] = [];
        const data2:object[] = Array.isArray(data) ? data : [data];
        data2.forEach((v)=>{
            const tmp = Object.entries(v).map((v2)=>v2[1]);
            result.push(...tmp);
        });
        return result.map((v)=>v.value);
    }

    /**
     * 데이터로부터 키에 해당하는 Label을 반환합니다.
     * @param data - NaverCode.data
     * @param key 
     * @returns 
     */
    public static getLabel = (data:any, key:string|string[]):string =>{
        return this.getValueCore(data, key)?.label || '';
    }

    /**
     * 데이터로부터 여러 키에 해당하는 Label을 배열로 반환합니다.
     * @param data 
     * @param keys
     * @returns 
     */
    public static getLabels = (data:any, keys?:string[]):string[]|undefined => keys?.map((v):string=>this.getValueCore(data, v)?.label || '');

    public static formatter = (value:string, data:any):string|undefined => {
        return this.getLabel(data, value) || value;
    }


    public static readonly base = {
        sidebarMode:{
            ALL:{value:'all', label:'전체 모드'},
            TREE:{value:'tree', label:'트리 모드'},
        },

        /** 네이버/카카오/구글 */
        mediaType:{
            NAVER: {value:'NAVER', label:'네이버'},
            KAKAO: {value:'KAKAO', label:'카카오'},
            GOOGLE: {value:'GOOGLE', label:'구글'},
        },
        /** ON/OFF */
        onOff : {
            ON: {value:'ON', label:'ON'},
            OFF: {value:'OFF', label:'OFF'},
        },

        /** 사용/사용 안 함 */
        boolean : {
            true : {value : 'true', label : '사용'}, 
            false : {value : 'false', label : '사용 안 함'},
        },

        /** 사용/사용 안 함 */
        useYn : {
            Y : {value : 'Y', label : '사용'}, 
            N : {value : 'N', label : '사용 안 함'},
        },
        /** 사용 중/사용 안 함 */
        useYnExt : {
            Y : {value : 'Y', label : '사용 중'}, 
            N : {value : 'N', label : '사용 안 함'},
        },


        /** 잠김여부 */
        locked : {
            true : {value : 'true', label : '잠김'}, 
            false : {value : 'false', label : '풀림'},
        },

        /** 접근권한여부 */
        permit : {
            true : {value : 'true', label : '풀림'}, 
            false : {value : 'false', label : '잠김'},
        },


        /** 설정/미설정 */
        useSet : {
            true : {value : 'true', label : '설정'},
            false : {value : 'false', label : '미설정'},
        },
        /** 수정 / ON/OFF */
        DownloadTemplateType : {
            update : {value : 'update', label : '수정'},
            uselock : {value : 'uselock', label : 'ON/OFF'},
        },
        /** 파일/UI */
        RequestType : {
            file : {value : 'file', label : '파일'},
            ui : {value : 'ui', label : 'UI'},
        },
        /** 하루 최대 비용 설정 / 제한 없음 */
        DayliBuget : {
            true : {value : 'true', label : '하루 최대 비용 설정'},
            false : {value : 'false', label : '제한 없음'},
        },
        ContentMedia : {
            false : {value : 'false', label : '미설정'},
            true : {value : 'true', label : '컨텐츠 입찰가(금액)'},
        },
        /** 데이터 값이 true | false 나옴 - 옵션설정용 */
        PeriodDate : {
            unlimited : {value : 'false', label : '오늘부터 종료일 없이 계속노출'},
            limited : {value : 'true', label : '시작 및 종료 날짜 설정'},
        },
        /** 데이터 값이 true | false 나옴 - 컬럼표시용 */
        usePeriodDate : {
            unlimited : {value : 'false', label : '계속 노출'},
            limited : {value : 'true', label : '기간 설정'},
        },
        
        BidAmtType : {
            AD_GROUP: {value:'AD_GROUP', label: '광고그룹 입찰가'},
            MANUAL: {value:'MANUAL', label: '키워드별 입찰가'},
            setAll: {value:'setAll', label: '입찰가 일괄 적용'},
        },
        
        LandingUrlType: {
            adUrl:{value: 'adUrl', label: '소재에 입력된 연결  URL 사용'},
            keywordUrl:{value: 'keywordUrl', label: '키워드별 연결 URL 사용'},
        },
        deviceTypeButton: {
            ALL: {value: 'P,M', label: 'PC+MO 합산'},
            P: {value: 'P', label: 'PC'},
            M: {value: 'M', label: 'MO'},
        },
        
        deviceTypeCheck: {
            PC: {value: 'PC', label: 'PC'},
            MOBILE: {value: 'MOBILE', label: 'MO'},
        },

        /** 자동입찰 게제위치 */
        mediaTarget: {
            PC: {value: 'PC', label: 'PC'},
            MOBILE: {value: 'MO', label: 'MO'},
        },

        targetAllMedia: {
            true: {value:"true", label:"전체 매체"},
            false: {value:"false", label:"일부 매체"},
        },

        hasSchedule: {
            false: {value:"false", label:"전체 요일/시간"},
            true: {value:"true", label:"일부 요일/시간"},
        },
        
        targetType: {
            select:{value:'select', label:'선택한 대상'}, 
            filter: {value:'filter', label:'조회한 대상'},
        },
        
        // Code Data
        EntityType: CodeData.getCodeOptions('E01N12'),
        TaskType: CodeData.getCodeOptions('T01T10'),
        TaskStatus: CodeData.getCodeOptions('T01S10'),
        UpdateType: CodeData.getCodeOptions('H01T10'),
        UpdateCommandType: CodeData.getCodeOptions('H01C100'),
        NaverDownType: CodeData.getCodeOptions('NDT00'),
        CTS: CodeData.getCodeOptions('CTS00'),
    }

};

