import NotificationServer from '../axios/NotificationServer';
import { Service } from './Service';
import { MediaResResultType, MediaResponseType } from './models/ResponseTypes';


export default class NotificationService extends Service{
    static path="/notification";

    //표준 상세조회
    public static get<T=any>(params?:any){
        return NotificationServer.get<MediaResponseType<MediaResResultType<T>>>(this.path, {params:params});
    }

    //표준 수정
    public static put<S=any>(ids:number[], body?:S){
        return NotificationServer.put(this.path, body, {params:{notiIds:ids}});
    }

    /** 모든 알림 읽음처리 */
    public static readAll(){
        return NotificationServer.put(`${this.path}/all`);
    }

}
