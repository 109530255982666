declare global{
    interface Date{
        /**
         * 날짜값을 포맷에 맞추어 출력합니다.
         * @param format 
         *  ex) 'yyyy-MM-dd HH:mm:ss'
         *  @Year    yyyy : 1900, 1901, ..., 2099;
         *          yy : 00, 01, ..., 99;
         *  @Month   MM : 01, 02, ..., 12
         *          M : 1, 2, ..., 12
         *  @Date    dd : 01, 02, ..., 31
         *          D : 1, 2, ..., 31
         *  @Hour    HH : 00, 01, ... 23
         *          H : 0, 1, ... 23
         *  @Minute  mm : 00, 01, ..., 59
         *          m : 0, 1, ..., 59
         *  @Second  ss : 00, 01, ..., 59
         *          s : 0, 1, ..., 59
         *  @Millisecond SSS : 000, 001, ..., 999
         */
        format(format:string):string;
        /**
         * 해당 월의 첫 날짜값을 반환합니다.
         */
        getFirstDate():Date;

        /**
         * 해당 월의 마지막 날짜값을 반환합니다.
         */
        getLastDate():Date;


        add(year:number, month:number, day:number):Date;
        addYears(add:number):Date;
        addMonths(add:number):Date;
        addDays(add:number):Date;
        addDate(add:string):Date;

        /** 날짜 차이일수 반환 */
        dateDiff(date:Date):number;

        /** 시간 차이 값 - getTime해당 */
        timeDiff(date:Date):number;

        /** 해당 날짜이후 요일에 해당하는 날짜를 구합니다. (0: 일요일, 1: 월요일, ..., 6: 토요일) */
        getNextDayOfWeek(week:number):Date;
    }
}

// eslint-disable-next-line no-extend-native
Date.prototype.format = function(format:string)
{
    switch(format){
        case 'full' : format = 'yyyy-MM-dd HH:mm:ss'; break;
        case 'time' : format = 'HH:mm:ss'; break;
        case 'date' : format = 'yyyy-MM-dd'; break;
    }
    const Y = this.getFullYear().toString().slice(-2);
    const M = (this.getMonth()+1).toString();
    const D = this.getDate().toString();
    const H = this.getHours().toString();
    const I = this.getMinutes().toString();
    const S = this.getSeconds().toString();
    const U = this.getMilliseconds().toString();

    const y = this.getFullYear().toString();
    const m = ("0" + M).slice(-2);
    const d = ("0" + D).slice(-2);
    const h = ("0" + H).slice(-2);
    const i = ("0" + I).slice(-2);
    const s = ("0" + S).slice(-2);
    const u = ("000" + U).slice(-3);

    let re = format.replace(/yyyy/gi, y);	//Y-m-d H:i:s:u 기준으로 처리
    re = re.replace(/MM/g, m);
    re = re.replace(/dd/g, d);
    re = re.replace(/HH/g, h);
    re = re.replace(/mm/g, i);
    re = re.replace(/ss/g, s);
    re = re.replace(/SSS/g, u);

    re = re.replace(/yy/gi, Y);
    re = re.replace(/M/g, M);
    re = re.replace(/d/g, D);
    re = re.replace(/H/g, H);
    re = re.replace(/m/g, I);
    re = re.replace(/s/g, S);
    return re;
}

// eslint-disable-next-line no-extend-native
Date.prototype.getFirstDate = function()	//해당 월의 1일
{
    return new Date(this.getFullYear(), this.getMonth(), 1);
}


// eslint-disable-next-line no-extend-native
Date.prototype.getLastDate = function()	//해당 월의 말일
{
    return new Date(this.getFullYear(), this.getMonth()+1, 0);
}



// eslint-disable-next-line no-extend-native
Date.prototype.add = function (year:number, month:number, day:number) { 
    const t=new Date(this.getTime());
	t.setFullYear(t.getFullYear()+ year); 
	t.setMonth(t.getMonth()+ month); 
	t.setDate(t.getDate()+ day); 
	return t;
};
// eslint-disable-next-line no-extend-native
Date.prototype.addYears		= function (add:number) { const t=new Date(this.getTime()); t.setFullYear(this.getFullYear() + add); return t; };
// eslint-disable-next-line no-extend-native
Date.prototype.addMonths	= function (add:number) { const t=new Date(this.getTime()); t.setMonth(this.getMonth() + add); return t; };
// eslint-disable-next-line no-extend-native
Date.prototype.addDays		= function (add:number) { const t=new Date(this.getTime()); t.setDate(this.getDate() + add); return t; };
// eslint-disable-next-line no-extend-native
Date.prototype.addDate 		= function (add:string)
{
	add = add || "0000-00-00";
	const sign = add[0]==='-' ? -1 : 1; //음수부호 처리용 - 첫토큰이 빈문자열이면 음수부호임
	const t = ("0-0-0"+add).split("-").slice(-3); //자릿수 맞추기 : yyyy-mm-dd, mm-dd, dd
	return this.add(Number(t[0])*sign, Number(t[1])*sign, Number(t[2])*sign);
}

// eslint-disable-next-line no-extend-native
Date.prototype.dateDiff = function (date:Date)
{
	return Math.ceil((this.getTime()-date.getTime())/(1000*3600*24));
}
// eslint-disable-next-line no-extend-native
Date.prototype.timeDiff = function (date:Date)
{
	return this.getTime()-date.getTime();
}

// eslint-disable-next-line no-extend-native
Date.prototype.getNextDayOfWeek = function (week:number) {
    // 원하는 요일에 해당하는 숫자를 구합니다. (0: 일요일, 1: 월요일, ..., 6: 토요일)
    const daysUntilNextDay = (week - this.getDay() + 7) % 7;

    // 오늘 날짜에 원하는 요일까지의 일수를 더하여 새로운 날짜를 계산합니다.
    const nextDate = new Date(this);
    nextDate.setDate(this.getDate() + daysUntilNextDay);

    return nextDate;
}


export{}
