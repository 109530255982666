declare global{
    interface Map<K,V>{
        /**
         * Map에서 해당 keys에 해당하는 값을 배열로 반환합니다.
         * @param keys 찾고자 하는 키 배열을 입력합니다.
         */
        getValues(keys:K|K[]):V[]|undefined;

        /**
         * Map filter
         * @param predicate 
         */
        filter(predicate: (value: V, key: K) => boolean): Map<K, V>;
    }
}

// eslint-disable-next-line no-extend-native
Map.prototype.getValues = function<K,V>(keys:K|K[]):V[]|undefined{
    const values:V[] = [];
    (Array.isArray(keys) ? keys : [keys]).forEach((v)=>{
        const tmp = this.get(v);
        if(tmp!==undefined){
            values.push(tmp);
        }
    });
    return values.length>0 ? values : undefined;
};

// eslint-disable-next-line no-extend-native
Map.prototype.filter = function<K, V>(predicate: (value: V, key: K) => boolean): Map<K, V> {
    const result = new Map<K, V>();
    this.forEach((value:V, key:K)=>{
        if (predicate(value, key)) {
            result.set(key, value);
        }
    });
    return result;
};

export{}
