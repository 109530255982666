import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { SuspensedView } from '../../routing/PrivateRoutes';

const SubRoutes = () => { 
    const NaverRoutes = lazy(() => import('./naver/Routes'));
    const KakaoRoutes = lazy(() => import('./kakao/Routes'));
    const GoogleRoutes = lazy(() => import('./google/Routes'));
    const NaloRoutes = lazy(() => import('./nalo/Routes'));
    return (
        <Routes>
            <Route path='naver/*' element={<SuspensedView><NaverRoutes /></SuspensedView>} />
            <Route path='kakao/*' element={<SuspensedView><KakaoRoutes /></SuspensedView>} />
            <Route path='google/*' element={<SuspensedView><GoogleRoutes /></SuspensedView>} />
            <Route path='nalo/*' element={<SuspensedView><NaloRoutes /></SuspensedView>} />
        </Routes>
    )
}

export default SubRoutes;
