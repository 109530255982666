import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { NotificationConfig } from "../../common/conf/NotificationConfig";
import { AdminService, AuthService } from "../../common/services";
import { NotificationResType } from "../../common/services/models/NotificationTypes";
import { EstimateAccountGroupType } from "../../common/services/models/admin/EstimateTypes";
import { useUserContext } from "../../contexts/UserContext";
import { notiPolling, notiRefresh } from "./NotificationDataManager";
import NotificationPopupList from "./NotificationPopupList";
import styles from './style.module.scss';

type Props = {
    toggleBtnClass?: string
    toggleBtnIconClass?: string
    menuPlacement?: string
    menuTrigger?: string
}

const NotificationIcon = ({
    toggleBtnClass = '',
    toggleBtnIconClass = 'svg-icon-2',
    menuPlacement = 'bottom-end',
    menuTrigger = "{default: 'click', lg: 'hover'}",
}: Props) => {
    const userContext = useUserContext();
    const [useData, setUseData] = useState<NotificationResType[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [maxNotiId, setMaxNotiId] = useState<number>(0);  // 새로운 메세지 체크용
    const refPolling = useRef<boolean>(false);
    const estimate:EstimateAccountGroupType[] = userContext.userSelect.accountState?.list || [];

    // 최초 접속시 알림설정 초기값 설정되도록 호출
    useEffect(()=>{
        const info = AuthService.storage.get();
        AdminService.notification.init(info?.userInfo.user_id || 0);
    }, []);

    // 마우스 파업상태 체크
    const show = useRef<boolean>(false);
    const setShow = (e:React.MouseEvent<HTMLSpanElement, MouseEvent>)=>{
        const isShow = e.currentTarget.className.split(" ").includes("show");
        if(!isShow){ return; }
        if(show.current){ return; }
        show.current = true;
        refresh();
        window.setTimeout(()=>{ show.current=false; }, 2000);    //2초 동안 동작하지 않도록함
    }

    /** 상태 체크 및 목록 갱신 */
    const refresh = async ()=>{
        if(refPolling.current){ return }   // 중복동작 방지
        refPolling.current = true;

        const data = await notiRefresh(useData);
        if(data!==undefined){
            setUseData(total===0 ? data.slice(0, NotificationConfig.popupListCount) : data);  //빈 상태인 경우, 제한 개수만 출력
        } 
        refPolling.current = false;
    }
    useEffect(()=>{ refresh(); }, [total, maxNotiId]);  //총개수나 최근 탑 메세지가 변경된 경우

    return <>
        {/* begin::Menu toggle */}
        <span
            className={clsx('btn btn-icon ', toggleBtnClass)}
            data-kt-menu-trigger={menuTrigger}
            data-kt-menu-attach='parent'
            data-kt-menu-placement={menuPlacement}
            onMouseOver={setShow}
            
        >
            <Bell state={[useData, setUseData]} notiId={[maxNotiId, setMaxNotiId]} total={[total, setTotal]}/>
        </span>
        {/* begin::Menu toggle */}

        {/* begin::Menu */}
        <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold fs-base w-400px text-left'
            data-kt-menu='true'
        >
            {/* begin::Menu item */}
            <NotificationPopupList state={[useData, setUseData]} refresh={()=>{refresh();}} total={total} />
            {/* end::Menu item */}
        </div>
        {/* end::Menu */}
    </>
}
export default NotificationIcon;


interface BellProps{
    state: [NotificationResType[], React.Dispatch<React.SetStateAction<NotificationResType[]>>];
    total: [number, React.Dispatch<React.SetStateAction<number>>];
    notiId: [number, React.Dispatch<React.SetStateAction<number>>];
    onClick?:()=>void;
}

const Bell:React.FC<BellProps> = (props)=>{
    const [useData, setUseData] = props.state;
    const [total, setTotal] = props.total;
    const [maxNotiId, setMaxNotiId] = props.notiId;  // 새로운 메세지 체크용
    const refPolling = useRef<boolean>(false);
    const [call, setCall] = useState<boolean>(false);
    const [blink, setBlink] = useState<boolean>(false);

    useEffect(()=>{
        if(useData.length > 0){
            setCall(true);
            setBlink(true);
            window.setTimeout(()=>{ setCall(false)}, 1300);
            window.setTimeout(()=>{ setBlink(false)}, 5000);
        }
    },[maxNotiId]);

    // 상태정보 체크
    useEffect(()=>{
        refresh(); 
        //신규 추가된 항목 추가
        const tm=window.setInterval(()=>{ refresh(); }, NotificationConfig.statusCheckInterval * 1000);
        return ()=>{ window.clearInterval(tm);}
    // eslint-disable-next-line
    },[]);

    useEffect(()=>{ refresh(); }, [useData]);   //데이터 목록 변경 시

    /** 상태 체크 및 목록 갱신 */
    const refresh = async ()=>{
        if(refPolling.current){ return }   // 중복동작 방지
        refPolling.current = true;

        const status = await notiPolling();
        if( status !== undefined){
            setMaxNotiId((prev)=>Math.max(prev, status[0]));    //번호가 큰 경우만 변경
            setTotal(status[1]);
        }
        refPolling.current = false;
    }
    
    return <div className={styles.bell}>
        {useData.length>0 && <div className={clsx(styles.dot, {[styles.blink]:blink})}>●</div>}
        <div className={clsx({[styles.vibration]:call})}>
            <i className="bi bi-bell-fill fs-2"/>
        </div>
    </div>
}