import Code from "./Code";

/** 알림 중요도 */
export interface NotificationSettingDataChildrenType{
    level: "HIGH"|"MIDDLE"|"LOW";
    title: string|React.ReactNode;
}
/** 알림 항목 */
export interface NotificationSettingDataType{
    value: string;
    label: string|React.ReactNode;
    description: string|React.ReactNode;
    icon?: string;
    levels: NotificationSettingDataChildrenType[];
    disabled?: boolean;
}

// interface NotificatoinCodeType{
//     type: {[key:string]:NotificationSettingDataType};
//     level: {[key:string]:OptionType};
//     readStatus: {[key:string]:OptionType};
// }

export default class NotificationCode extends Code{
    public static readonly data = {
        /** 항목 정보 */
        type : {
            /** 동기화 */
            CNM0001: {
                value: "CNM0001",
                label: "동기화",
                description: "매일 새벽 매체로부터 광고 설정 및 성과 데이터를 자동 수집하여 AdPlo에 반영합니다.",
                icon: "cloud-download",
                levels: [
                    { level: "HIGH", title: "처리 결과 실패 시 알림 받기", },
                ],
            },
            /** 대량관리(UI) */
            CNM0002: {
                value: "CNM0002",
                label: "대량관리(UI)",
                description: "대량의 광고 설정을 AdPlo 화면을 통해 매체에 반영합니다.",
                icon: "mouse-square",
                levels: [
                    { level: "HIGH", title: "처리 결과 실패 시 알림 받기", },
                    { level: "MIDDLE", title: "처리 결과 완료 시 알림 받기", },
                ],
            },
            /** 대량관리(파일) */
            CNM0003: {
                value: "CNM0003",
                label: "대량관리(파일)",
                description: "대량의 광고 설정을 CSV 형식의 템플릿 파일을 실행하여 매체에 반영합니다.",
                icon: "update-file",
                levels: [
                    { level: "HIGH", title: "처리 결과 실패 시 알림 받기", },
                    { level: "MIDDLE", title: "처리 결과 완료 시 알림 받기", },
                ],
            },
            /** 이미지 관리 */
            CNM0004: {
                value: "CNM0004",
                label: "이미지 관리",
                description: "(카카오 키워드광고) 이미지형 확장소재의 이미지를 등록 및 관리합니다.",
                icon: "picture",
                levels: [
                    { level: "HIGH", title: "처리 결과 실패 시 알림 받기", },
                    { level: "MIDDLE", title: "처리 결과 완료 시 알림 받기", },
                ],
            },
            /** D-Bid */
            CNM0005: {
                value: "CNM0005",
                label: "D-Bid",
                description: "목표 달성을 위한 키워드들의 최적 순위와 키워드 입찰가를 제안합니다.",
                icon: "technology-4",
                levels: [
                    { level: "HIGH", title: "최적화 연산 불가 시 알림 받기", },
                    { level: "MIDDLE", title: "최적화 연산 완료 시 알림 받기", },
                ],
            },
            /** 자동입찰 */
            CNM0006: {
                value: "CNM0006",
                label: "자동입찰",
                description: "키워드 입찰가를 자동 조정하여 키워드를 희망 순위에 노출 및 유지합니다.",
                icon: "ranking",
                levels: [
                    { level: "HIGH", title: "입찰 오류 발생 시 알림 받기", },
                ],
                disabled: true,
            },
            /** 권한 관리 */
            CNM0007: {
                value: "CNM0007",
                label: "권한 관리",
                description: "광고 설정 및 운영이 필요한 광고주(하위자산) 권한을 관리합니다.",
                icon: "user-edit",
                levels: [
                    { level: "LOW", title: "광고주 설정 변경 시 알림 받기", },
                ],
            },
            /** 권한 관리(전체) */
            CNM0008: {
                value: "CNM0008",
                label: "권한 관리(전체)",
                description: "사용자 권한을 관리합니다.",
                icon: "user-edit",
                levels: [
                    { level: "LOW", title: "사용자 설정 변경 시 알림 받기", },
                ],
                disabled: true, //CNM0007과 연동관리로 페이지 표시되지 않음
            },
            /** 검수 관리 */
            CNM0009: {
                value: "CNM0009",
                label: "검수 관리",
                description: "키워드, 소재 등 검수가 필요한 광고요소의 검수 상태를 관리합니다.",
                icon: "verify",
                levels: [
                    { level: "HIGH", title: "처리 결과 실패, 검수 결과 보류/제한시 알림 받기", },
                    { level: "MIDDLE", title: "처리 결과 완료 시 알림 받기", },
                ],
                disabled: true,
            },
            /** NALO */
            CNM0010: {
                value: "CNM0010",
                label: "NALO",
                description: "Comming Soon.",
                icon: "abstract",
                levels: [
                    { level: "HIGH", title: "오더 실행 또는 액션이 실패/에러 시 알림 받기", },
                    { level: "MIDDLE", title: "오더 액션 완료 시 알림 받기", },
                ],
                disabled: true,
            },
            /** 메일 수신 */
            CNM0011: {
                value: "CNM0011",
                label: "메일 수신",
                description: <>
                    알림의 메일 수신 여부를 설정합니다. 필요시 알림 중요도 단위로 세부 설정이 가능합니다.<br/>
                    Viewer 권한을 가진 광고주의 알림은 메일로 수신되지 않습니다.
                </>,
                icon: "directbox-default",
                levels: [
                    { level: "HIGH", title: "알림 메일로 받기", },
                    { level: "MIDDLE", title: "알림 메일로 받기", },
                    { level: "LOW", title: "알림 메일로 받기", },
                ],
                isView: false,
            },
        },

        /** 중요도 */
        level:{
            /** 높음 */
            HIGH:{value:"HIGH", label:"높음"},
            /** 보통 */
            MIDDLE:{value:"MIDDLE", label:"보통"},
            /** 낮음 */
            LOW:{value:"LOW", label:"낮음"},
        },

        /** 읽음 여부 */
        readStatus:{
            /** 읽음 */
            true: {value:"true", label:"읽음"},
            /** 안읽음 */
            false: {value:"false", label:"안읽음"},
        },
    }
}


