import {FC} from 'react'
import {ExplorerToggleDrawer} from './explorer-drawer/ExplorerToggleDrawer'
import { ExplorerDrawer } from '../../../app/pages/explorer/ExplorerDrawer'

// import {ExplorerDrawer} from './explorer-drawer/ExplorerDrawer'

const RightToolbar: FC = () => {
  return (
    <>
      <div className='engage-toolbar d-flex position-fixed px-5 fw-bolder top-50 end-0 transform-90 mt-20 gap-2' style={{zIndex:10}}>
        <ExplorerToggleDrawer />
        {/* <ToggleHelpDrawer />
        <PurchaseButton /> */}
      </div>

      <ExplorerDrawer />
      {/* <HelpDrawer /> */}
    </>
  )
}

export {RightToolbar}
