import {KakaoServer} from '../../axios';
import Utils from '../../utils/Utils';
import { HistoryPostType } from '../models/kakao/HistoryTypes';
import KakaoHelperComponent from './KakaoHelperComponent';

/** 카카오 이력관리 */
export default class HistoryHelper extends KakaoHelperComponent{
    static path="/history"; //기본 Path

    public static getDownloadPath(objectKey:string){
        return Utils.apiRequestValidation({}) || KakaoServer.get<any>(`${this.path}/download`, {params:{objectKey},headers:{useAccount:true}});
    }

    /** 파일을 S3에 업로드합니다.  */
    public static execute(uploadId:number){
        return Utils.apiRequestValidation({}) || KakaoServer.put<any>(`/media/upload-files/${uploadId}/execute`,undefined, {headers:{useAccount:true}});
    }

    /** 파일을 S3에 업로드합니다.  */
    public static fileUpload(data:HistoryPostType){
        const url = new URLSearchParams(Object.entries(data));
        return Utils.apiRequestValidation({}) || KakaoServer.post<any>(`/media/upload-files?${url}`, undefined, {headers:{useAccount:true}});
    }
}
