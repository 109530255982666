import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import CustomBaseCard from "../../modules/card/CustomBaseCard";
import BidBaseList from "./BidBaseList";
import DBidList from "./DBidList";

interface AppsDashboardProps{
}

const AppsDashboard:React.FC<AppsDashboardProps> = (props)=>{
    const [activeKey, setActiveKey] = useState<string>('bidbase');
    const onSelect = (arg:any)=>{
        setActiveKey(arg);
    }
    return <CustomBaseCard style={{height:'320px'}}  title='기능 운영 현황'>
        <Tabs
            defaultActiveKey="bidbase"
            id="dashboard-apps-tab"
            className="mb-1"
            onSelect={onSelect}
        >
            <Tab eventKey="bidbase" title={<div style={{color: activeKey==='bidbase' ? 'var(--bs-nav-link-color)' : 'var(--bs-gray-900)'}}>자동입찰</div>}>
                <BidBaseList />
            </Tab>

            <Tab eventKey="d-bid" title={<div style={{color: activeKey==='d-bid' ? 'var(--bs-nav-link-color)' : 'var(--bs-gray-900)'}}>D-Bid</div>}>
                <DBidList />
            </Tab>
        </Tabs>
    </CustomBaseCard>
}
export default AppsDashboard;