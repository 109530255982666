import clsx from 'clsx';
import React, { CSSProperties } from 'react';
import ThemeConfig from '../../../common/conf/ThemeConfig';
import { ColumnsGroup } from '../../../modules/code/Config';
import { ButtonDropDown } from '../dropdown/ButtonDropDown';
import Checkbox from '../form/Checkbox';
import { HeaderColumn } from './TableType';
import style_colum from './table.column.module.scss';

export interface ColumnListProps {
    columns: HeaderColumn[];
    columnOnChange: Function;
    headerColumns: {[key:string]:boolean}[];
    className?: string;
    style?: CSSProperties;
}

function ColumnList(props:ColumnListProps) {
    const columnMap = new Map(Object.entries(props.headerColumns));
    const columnOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.columnOnChange(event.target.value, event.target.checked);
    }
    const columnData:HeaderColumn[][] = getColumns(props.columns);
    const limited = Object.values(props.headerColumns).filter((v)=>v).length <= 1;  //마지막 하나는 남김
    const btnCfg = ThemeConfig.Button.Sub;

    return (<ButtonDropDown
        className={clsx('d-inline', props.className)}
        title={<><i className="bi bi-layout-three-columns me-1" />Columns</>}
        style={props.style}
        useCaret={true}
    >
        <div className='p-4'>
            <div className="d-flex scroll-y" style={{maxHeight:'400px'}}>
                {/* <Button className="btn-light btn-sm" onClick={()=>{ props.columnOnChange(null, false); }}>선택정보 초기화</Button> */}
                {
                    columnData.map((groups, i) => (<div key={i} className={clsx('p-0', 'border-secondary', i>0 ? 'border-start' :'')} style={{minWidth:'15em'}}>
                        <div className="border-bottom border-secondary fw-bold py-2 ps-3">
                            {ColumnsGroup[i] ? ColumnsGroup[i] : 'Group ' + (i+1)}
                        </div>
                        <ul className='m-0 p-0 pt-1 ps-2' style={{listStyle:'none'}}>{
                            groups.map((item, k) => {
                                const checked = columnMap.get(item.key || item.accessor) as boolean|undefined;
                                return(<li key={`${i}_${k}`} className={clsx('p-1', style_colum.columnlist_item)} >
                                    <Checkbox checked={item.isFixed || checked} disabled={item.isFixed || (checked && limited) || false}
                                        onChange={columnOnChange} value={item.key || item.accessor} size='sm'
                                    >
                                        <span className='d-inline-block text-truncate'>{item.group && <>{item.group.header} - </>}{item.header}</span>
                                    </Checkbox>
                                </li>)
                            })
                        }</ul>
                    </div>)
                )}
            </div>
        </div>
    </ButtonDropDown>);
}

const getColumns = (columns:HeaderColumn[]):HeaderColumn[][] => {
    let resData:HeaderColumn[][] = [];
    const groups:(string|undefined)[] = columns.map((v)=>v.colGroup).filter((v)=>v).unique();

    columns.forEach((v, index) => {
        const idx:number = v.colGroup ? groups.indexOf(v.colGroup)+1 : 0;
        if(resData[idx] === undefined) resData[idx] = [];
        resData[idx].push(v);
    })

    return resData;
}

export default ColumnList;