import { NotificationConfig } from "../../common/conf/NotificationConfig";
import NotificationService from "../../common/services/NotificationService";
import { NotificationResType } from "../../common/services/models/NotificationTypes";
import { RequestFilterType } from "../../common/services/models/RequestTypes";
import { SortKeys } from "../modules/table/Table";

/** 데이터 로딩 또는 추가 - 로딩된 데이터가 있으면 이후 데이터 추가됨 */
export const notiLoad = async (data:NotificationResType[], total:number):Promise<NotificationResType[]|undefined> => {
    const min:number = data.length > 0 ? Math.min(...data.map((v)=>v.notiId)) : 0;   // 최소값
    const filters:RequestFilterType[] = [{key:"readStatus", operation:"EQ", value:"false"}];

    if(data.length >= total && min > 0){ return; }  //더 로딩할 데이터 없음

    //로딩된 데이터가 있는 경우만 조천 추가
    if(data.length > 0){
        filters.push({key:"notiId", operation:"LT", value: min?.toString()});   //로딩된 이후 데이터만 읽어옴
    }
    
    return NotificationService.get<NotificationResType>({
        page: 0,
        pageSize: NotificationConfig.popupListCount,
        orders: [{key:"notiId", direction:SortKeys.Desc}],
        filters: filters,
    }).then((res)=>res.data?.result?.items || [])
    .catch((e)=>undefined);

}

/** 신규 데이터만 로딩 - 신규데이터 추가와 전체 데이터 개수 관리 */
export const notiPolling = async ():Promise<number[]|undefined>=>{
    return NotificationService.get<NotificationResType>({
        page: 0,
        pageSize: NotificationConfig.popupListCount,
        orders: [{key:"notiId", direction:SortKeys.Desc}],
        filters: [{key:"readStatus", operation:"EQ", value:"false"}],
    }).then((res)=>{
        const data:NotificationResType[] = res.data?.result?.items || [];
        const max:number = data.length > 0 ? Math.max(...data.map((v)=>v.notiId)) : 0;   // 최대값
        return [max, res.data?.result?.totalItems || 0];
    })
    .catch((e)=>undefined);
}

/** 현재 로딩된 데이터를 갱신합니다. */
export const notiRefresh = async (data:NotificationResType[]):Promise<NotificationResType[]|undefined>=>{
    const min:number = data.length > 0 ? Math.min(...data.map((v)=>v.notiId)) : 0;   // 최소값
    return NotificationService.get<NotificationResType>({
        page: 0,
        pageSize: 1000,
        orders: [{key:"notiId", direction:SortKeys.Desc}],
        filters: [
            {key:"readStatus", operation:"EQ", value:"false"},
            {key:"notiId", operation:"GTE", value: min?.toString()},
        ],
    }).then((res)=>res.data?.result?.items || [])
    .catch((e)=>undefined);
}
