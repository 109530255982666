import clsx from "clsx";
import { forwardRef } from "react";
import AdPloInput from "./AdPloInput";
import styles from './style.module.scss';

const AdPloSearch = forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>((props,ref)=>
    <div className={clsx('d-inline align-content-center', styles.search)}>
        <i className="bi bi-search"/>
        <AdPloInput {...props} ref={ref} />
    </div>);

export default AdPloSearch;