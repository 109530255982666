import { KakaoService, NaverService } from "../../../common/services";
import { EstimateAccountGroupType, EstimateUserResType } from "../../../common/services/models/admin/EstimateTypes";
import { BizMoneyResType } from "../../../common/services/models/BizMoneyTypes";
import Code from "../../../modules/code/Code";
import { BizMoneyAccountClientType, BizMoneyAccountGroupType, BizMoneyDataType, checkTimeExpired, getLoadingStatus } from "./BizMoneyLibs";


// 비즈머니 데이터 로딩
export const bizMoneyDataLoad = async (data:EstimateAccountGroupType[])=>{
    const ps = await Promise.all([
        naverDataLoad(data), // 네이버 비즈머지
        kakaoDataLoad(data), // 카카오 비즈머니
    ])
    .catch((e)=>{ throw new Error(e); });

    const result:BizMoneyResType[]=[];
    for(let i=0; i<ps.length; i+=1){
        result.push(...ps[i]);
    }
    return result;
}

/** 네이버 비즈머니 로딩 */
const naverDataLoad = async (data:EstimateAccountGroupType[]):Promise<BizMoneyResType[]>=>{
    const ids:number[] = data.reduce((prev:number[], v)=>{
        const tmp:number[] = v.clients
            .filter((v2)=>v2.mediaType===Code.base.mediaType.NAVER.value)
            .map((v2)=>v2.clientId.toNumber());
        return [...prev, ...tmp];
    },[]);
    if(!ids.length){ return [] as BizMoneyResType[]; }
    return await NaverService.dashboard.cash.get(ids).then((res)=>{
        return (res.data.result || []) as BizMoneyResType[];
    })
}

/** 카카오 비즈머니 로딩 */
const kakaoDataLoad = async (data:EstimateAccountGroupType[]):Promise<BizMoneyResType[]>=>{
    const ids:number[] = data.reduce((prev:number[], v)=>{
        const tmp:number[] = v.clients
            .filter((v2)=>v2.mediaType===Code.base.mediaType.KAKAO.value)
            .map((v2)=>v2.clientId.toNumber());
        return [...prev, ...tmp];
    },[]);
    if(!ids.length){ return [] as BizMoneyResType[]; }
    return await KakaoService.dashboard.cash.get(ids).then((res)=>{
        return (res.data.result || []) as BizMoneyResType[];
    });
}

/** 비즈머니 데이터 머징 */
export const bizMoneyDataMerge = (
    mags:EstimateAccountGroupType[], 
    users:EstimateUserResType[], 
    results:BizMoneyResType[], 
    loading:boolean, 
    sync:boolean, 
    nowTime:number
):BizMoneyAccountGroupType[] => {
    return mags.map((mag)=>{
        // Client 데이터 매칭
        const clients:BizMoneyAccountClientType[] = mag.clients.map((client)=>{
            const biz:BizMoneyResType|undefined = results.find((v)=>v.clientId.toString() === client.clientId);
            return {...client, result:biz ? {
                ...biz, 
                total:biz.cash+biz.freeCash, 
                loaded: !getLoadingStatus(loading, sync, nowTime, biz.syncDateTime),  //로딩중 표시여부 - false 로딩중임
            } : undefined};
        });

        // 합계 취합
        let isNull:boolean = true;
        const result:BizMoneyDataType = {
            clientId: 0,
            clientName: mag.magName,
            total: 0,
            cash: 0,
            freeCash: 0,
            status: 'CTS0003',
            message: '',
            successSyncDateTime: '',
            syncDateTime: '',
        };
        let syncDateTime:number = new Date('2100-12-31 23:59:59').getTime();
        clients.forEach((client) => {
            if(client.result){
                //로딩 상태가 끝났음에도 데이터 갱신되지 못한 경우,
                const expired = checkTimeExpired(nowTime, client.result?.syncDateTime);  //로딩중 표시여부 - false 로딩중임
                if( !loading && !sync && expired){
                    // console.log({loading, sync, expired, client:JSON.stringify(client)});
                    client.result.status = 'CTS0004';   //갱신실패
                    client.result.message = '잔액 갱신 중 일시적인 오류가 발생했습니다.';
                }

                if(client.result.status === 'CTS0003'){
                    isNull = false;
                    result.total += client.result.total || 0;
                    result.cash += client.result.cash || 0;
                    result.freeCash += client.result.freeCash || 0;
                }
                result.status = client.result.status !== 'CTS0003' ? client.result.status : result.status;
                // result.syncDateTime = new Date( Math.min(new Date(biz.syncDateTime || '2024-01-01').getTime(), syncDateTime) ).format('yyyy-MM-dd HH:mm:ss');
                syncDateTime = new Date( Math.min(new Date(client.result.syncDateTime || '2024-01-01').getTime(), syncDateTime) ).getTime();
            }
        });
        result.syncDateTime = new Date(syncDateTime).format('yyyy-MM-dd HH:mm:ss');
        return {
            ...mag,
            result:isNull ? undefined : {
                ...result, 
                loaded: !getLoadingStatus(loading, sync, nowTime, result.syncDateTime),  //로딩중 표시여부 - false 로딩중임
            },
            accountUsers: users.find((v)=>v.magId===mag.magId)?.userAccounts,
            clients: clients};
    });
}

