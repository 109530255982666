import clsx from "clsx";
import { CSSProperties, useEffect, useState } from "react";
import ThemeConfig from "../../../common/conf/ThemeConfig";
import AdploButton from "../../components/buttons/AdploButton";
import AdPloDateRangePicker from "../../components/input/AdPloDateRangePicker";
import { DateRangeDataType } from "../../modules/calendar/DateRangePicker";

interface DashboardDateRangeProps{
    variant?: string;
    className?: string;
    style?: CSSProperties;
    defaultValue?: {week1:DateRangeDataType, week2:DateRangeDataType};
    onChange?: (week1?:DateRangeDataType, week2?:DateRangeDataType)=>void;
}

const DashboardDateRange:React.FC<DashboardDateRangeProps> = (props)=>{
    const btnCfg = ThemeConfig.Button.Sub;
    const [show, setShow] = useState<boolean>(false);
    const [dateRange1, setDateRange1] = useState<{startDate:Date, endDate:Date}|undefined>();
    const [dateRange2, setDateRange2] = useState<{startDate:Date, endDate:Date}|undefined>();
    useEffect(()=>{
        if(props.defaultValue){
            setDateRange1(props.defaultValue.week1);
            setDateRange2(props.defaultValue.week2);
        }
    }, [props.defaultValue]);

    return <div>
        <AdploButton className={clsx(btnCfg.className)} onClick={()=>setShow(!show)} data-type='icon'>
            <i className="bi bi-calendar3-week p-0"/>
        </AdploButton>
        {show && <div className={clsx("fade modal-backdrop", {'show':show})}></div>}
        <div className={clsx('fade dropdown-menu p-6', {'show':show})} style={{transform:'translate(-291px,2px)', zIndex: 1069}}>
            <h6>기간 설정</h6>
            <div className='mt-6 mb-6 d-flex'>
                <div className='p-2 w-80px'>조회 기간</div>
                <AdPloDateRangePicker
                    onChange={(startDate, endDate)=>{ setDateRange1({startDate, endDate})}}
                    startDate={dateRange1?.startDate}
                    endDate={dateRange1?.endDate}
                />
            </div>

            <div className='mt-6 mb-6 d-flex'>
                <div className='p-2 w-80px'>비교 기간</div>
                <AdPloDateRangePicker
                    onChange={(startDate, endDate)=>{ setDateRange2({startDate, endDate})}}
                    startDate={dateRange2?.startDate}
                    endDate={dateRange2?.endDate}
                />
            </div>

            <div className="text-right">
                <AdploButton variant='secondary' onClick={()=>setShow(false)}>취소</AdploButton>
                <AdploButton variant="primary" className='ms-2' onClick={()=>{
                    props.onChange && props.onChange(dateRange1, dateRange2);
                    setShow(false);
                }}>적용</AdploButton>
            </div>
        </div>
    </div>
}
export default DashboardDateRange;

