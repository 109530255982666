import axios, { AxiosResponse } from 'axios';
import Config from '../conf/Config';
import { UserContextModel } from '../../contexts/UserContext';
import AuthService from './AuthService';

export interface SseTokenType{
    header:string,
    name:string,
    browser?:string,
    session:string,
}

interface MessageType{
    type:string;
    title:string;
    code:string;
    message:string;
}
    
export const sseService = class{
    static key:SseTokenType|null=null;
    static sid(){ return `${process.env.REACT_APP_CLIENT_NAME}-SSEM`; }
    static event:EventSource|undefined=undefined;

    static start(userContext:UserContextModel){
        if(this.event){ return; }
        // 토큰첨부
        // let tmp:string | null = localStorage.getItem(`${process.env.REACT_APP_CLIENT_NAME}-user`);
        // const userInfo = tmp==null ? {} : JSON.parse(tmp);
        // config.headers['Authorization'] = `Bearer ${userInfo.accessToken}`;

        //var evtSource = new EventSource("http://localhost.igaworks.com:10040/subscribe", {withCredentials: true});
        // if (evtSource != null && (evtSource.readyState == 1 || evtSource.readyState == 0)) {
        //     evtSource.close();
        // }{ authorizationHeader: "Bearer ..." }
        const url = new URL(Config.api.sse+'/ssem/subscribe');
        this.event = new EventSource(url, {withCredentials: true});
        // if (this.event != null && (this.event.readyState === 1 || this.event.readyState === 0)) {
        //     this.event.close();
        // }
        this.event.onopen= function(e) {
            // console.log("SSE - Server connected :)",e);
        }

        this.event.onmessage = (e) => {
            //console.log("SSE - receive message:", e);
            const key = this.keyParse(e.data);
            if(key && key.header==='HELO'){
                // console.log(key);
                this.setKey(key);     // 세션정보 기록
                this.sendAuth();
            }else if(e.data!=="PING"){
                this.message(userContext, e);
            }
        };

        this.event.onerror = function(error) {
            // console.log('SSE - Server unavailable : ',error);
        }
    }

    static message(userContext:UserContextModel, e:MessageEvent)
    {
        let data:MessageType|undefined|string = undefined;
        try{
            data = JSON.parse(e.data);
        }catch(e2){
            userContext.alertMessage.add({title:"형식에 맞지 않은 메세지", body:e.data});
            return;
        }

        if(!data){ return ; }
        if(typeof(data) !== "object"){ 
            userContext.alertMessage.add({title:"형식에 맞지 않은 메세지", body:e.data});
            return ; 
        }
        const msg:string = `${data.message}${data.code!=="0" ? `(${data.code})` : ''}`;
        switch(data.type){
            case "alert" :
                userContext.alertMessage.add({title:data.title, body:msg});
                break;

            case "toast" : 
            default :
                userContext.toastMessage.add({title:data.title, body:msg}); 
                break;
        }
    }

    // 세션 정보 파싱
    static keyParse(txt:string):SseTokenType|null{
        const tmp = txt.split("::");
        return tmp.length>=3 && tmp[0]==="HELO" ? {header:tmp[0], name:tmp[1], session:tmp[2], browser:this.Session.get()} : null;
    }

    // 세션정보 저장
    static setKey(key:SseTokenType|null){
        if(this.key!==null){ return false; }
        if(key){
            this.key = key;
            sessionStorage.setItem(this.sid(),JSON.stringify(key));
            // this.setCookie("SSEM.BROWSER",key.browser,10, "/","*");
            // this.setCookie("SSEM.SESSION",key.session,10, "/","*");
            // this.setCookie("SSEM.BROWSER",key.browser,10,"/","tbase.co.kr");
            // this.setCookie("SSEM.SESSION",key.session,10,"/","tbase.co.kr");
        }
    }
    // 세션정보 로딩
    static getKey():SseTokenType|null{
        let tmp:string | null = sessionStorage.getItem(this.sid());
        return tmp ? JSON.parse(tmp) : null; 
    }
    static getCookieStringify():string|boolean{
        const key = this.getKey();
        if(!key){ return false; }
        const data:Object = {
            "SSEM.BROWSER" : key.browser,
            "SSEM.SESSION" : key.session,
        };
        return Object.entries(data).map(v=>v.join("=")).join("; ");
    }
    static setCookie (name:string, value:string, miuntes?:number|null, path?:string|null, domain?:string|null) {
        let data:string[] = [];
        data.push(value);
        if(miuntes){
            const exdate = new Date();
            exdate.setMinutes(exdate.getMinutes() + miuntes);
            data.push(`Expires=${exdate.toUTCString()}`);
        }
        if(path){
            data.push(`Path=${path}`);
        }
        if(domain){
            data.push(`Domain=${domain}`);
        }
        // console.log(`${name}=${data.join("; ")}`);
        document.cookie = `${name}=${data.join("; ")}`;
    }

    static sendAuth() {
        const userInfo = AuthService.storage.get()?.userInfo;
        const key = this.getKey();
        if(!key){ return; }
        const data = {
            verticleName: key.name,
            clientKey: key.session,
            accessToken : userInfo?.access_token,
            userId: userInfo?.user_name,
            browserId: key.browser,
        };
        const server = axios.create({
            baseURL: Config.api.sse,
            headers: { 'X-Requested-With': 'XMLHttpRequest', 'Content-Type':'application/json; charset=utf-8' }
        });
        server.defaults.withCredentials = true;
        server.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

        server.post(Config.api.sse + '/ssem/auth',{...data})
        .then((res:AxiosResponse)=>{
            // console.log(res.data);
            // li("auth: " + data.clientKey + ", userId:" + data.userId + ", browserId:" + data.browserId);
        }).catch((e:AxiosResponse)=>{
            console.log(e);
        });
    }

    static Session = class{
        static sid(){ return `${process.env.REACT_APP_CLIENT_NAME}-SSEM-BrowserID`; }
        static get(){
            const id = sessionStorage.getItem(this.sid());
            const newId = this.generateRandomString(32);
            if( !id || id===""){
                this.set(newId);
                return newId;
            }else{
                return id;
            }
        }
        static set(id:string){ sessionStorage.setItem(this.sid(),id); }
        static generateRandomString(num:number) {
            const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
            let result = '';
            const charactersLength = characters.length;
            for (let i = 0; i < num; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }
    }

}