import clsx from 'clsx';
import {KTSVG} from '../../../helpers';
import { MenuInnerWithSub } from '../../../layout/components/header/header-menus/MenuInnerWithSub';
import FamilySite from '../../../../app/common/conf/FamilySite';
import { MenuItem } from '../../../layout/components/header/header-menus/MenuItem';

/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
    toggleBtnClass?: string
    toggleBtnIconClass?: string
    menuPlacement?: string
    menuTrigger?: string
}


const FamilySiteBtn = ({
    toggleBtnClass = '',
    toggleBtnIconClass = 'svg-icon-2',
    menuPlacement = 'bottom-end',
    menuTrigger = "{default: 'click', lg: 'hover'}",
}: Props) => {
    // const switchMode = () => {
    // }

    return (
        <>
            {/* begin::Menu toggle */}
            <a
                href='#'
                className={clsx('btn btn-icon ', toggleBtnClass)}
                data-kt-menu-trigger={menuTrigger}
                data-kt-menu-attach='parent'
                data-kt-menu-placement={menuPlacement}
            >
                <KTSVG
                    path='/media/icons/duotune/general/gen022.svg'
                    className={clsx('theme-light-hide', toggleBtnIconClass)}
                />
            </a>
            {/* begin::Menu toggle */}

            {/* begin::Menu */}
            <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
                data-kt-menu='true'
            >
                {/* begin::Menu item */}

                {/* DBN Solution */}
                <MenuInnerWithSub
                    title='DBN Solution'
                    to=''
                    // fontIcon='bi-archive'
                    hasArrow={true}
                    menuPlacement='right-start'
                    menuTrigger={`{default:'click', lg: 'hover'}`}
                    icon='/media/icons/duotune/abstract/abs022.svg'
                >
                    {FamilySite.list.map((v,index)=><MenuItem key={index} target='_blank' to={v.href} title={v.label} hasBullet={true} />)}
                </MenuInnerWithSub>

                {/* Help Desk */}
                <MenuInnerWithSub
                    title='Help Desk'
                    to=''
                    hasArrow={true}
                    menuPlacement='right-start'
                    menuTrigger={`{default:'click', lg: 'hover'}`}
                    icon='/media/icons/duotune/general/gen046.svg'
                >
                    <MenuItem target='_blank' to='https://wiki.cjenm.com/x/aY7YBg' title='사용가이드' hasBullet={true} />
                    <MenuItem target='_blank' to='https://wiki.cjenm.com/x/a47YBg' title='오류신고&문의' hasBullet={true} />
                </MenuInnerWithSub>
                {/* end::Menu item */}

            </div>
            {/* end::Menu */}
        </>
    )
}

export {FamilySiteBtn}
