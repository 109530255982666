import clsx from 'clsx';
import styles from './ellisis.module.scss';
import { CSSProperties } from 'react';
import Ellipsis from './Ellipsis';


interface HiperLinkProps{
    className?:string;
    style?:CSSProperties;
    href?:string;
    target?:string;
    title?:string;
    children?:string|React.ReactNode;
}
const HiperLink:React.FC<HiperLinkProps> = (props) => {
    let url:string|undefined = props.href;
    try{
        url = decodeURIComponent(props.href || '');
    }catch(e){}
    return (<a 
        target='blank'
        href={props.href}
        title={props.title || url}
        className={clsx(styles.hiperLink, props.className)} 
        style={props.style}
    >{props.children}</a>)
}

export const HiperLinkEllisis:React.FC<HiperLinkProps> = (props) => {
    return <HiperLink href={props.href}>
        <Ellipsis style={{maxWidth:props.style?.maxWidth, width:props.style?.width}}>
            {props.children || props.href}
        </Ellipsis>
    </HiperLink>
}


export default HiperLink;