import {KakaoServer} from '../../axios';
import Utils from '../../utils/Utils';
import KakaoHelperComponent from './KakaoHelperComponent';

/** 카카오 소재연결 */ 
export default class CreativeLinkHelper extends KakaoHelperComponent{
    static path="/media/creative-links"; //기본 Path
    static stat='/performance/creative-links';    //실적데이터
    static performances='/performances/creative-links';    //실적데이터
    static onOff="/media/creative-links/on-off"; //onOff modify Path
    static downloadPath='/api/kakaokeywordad/xlsxdnld/creative-links';

    /** 소재연결 생성 중복확인 */
    public static getDuplicate(adGroupIds:string[], creativeIds:string[]){
        return Utils.apiRequestValidation({}) || KakaoServer.get('/media/creative-links-duplicate', {params:{adGroupIds, creativeIds}, headers:{useAccount:true}});
    }

    /** 소재연결 광고그룹 목록 */
    public static getAdGroup(creativeId:string){
        return Utils.apiRequestValidation({}) || KakaoServer.get(`/media/creatives/${creativeId}/ad-groups`, { headers:{useAccount:true}});
    }

    // public static post(body:CreativeLinkPostType, userContext?:UserContextModel){
    //     const valid = Utils.apiRequestValidation({userContext}); if(valid){ return valid; }
    //     return KakaoServer.post(this.path,{...body}, {headers:{useAccount:true}});
    // }

}