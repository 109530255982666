import qs from 'qs';
import { NaverServer } from '../../axios';
import Utils from '../../utils/Utils';
import { DatshBoardStatQryType } from '../models/DashboardTypes';


/** 네이버 비즈머니 Helper */
class CashHelper{
    static path='/dashboard/cash';         //기본 Path
    
    public static get(clientIds:number[]){
        return Utils.apiRequestValidation({useAccount:false}) || NaverServer.get(this.path,{params:{clientIds}});
    }
    public static post(clientIds:number[], renewAfter?:number){
        const url:string = `${this.path}?${qs.stringify({clientIds, renewAfter}, { arrayFormat: 'repeat', encodeValuesOnly: true })}`;
        return Utils.apiRequestValidation({useAccount:false}) || NaverServer.post(url);
    }
}


/** 네이버 대시보드용 Helper */
export default class DashboardHelper{
    static path="/dashboard"; //기본 Path
    /** 네이버 대시보드 작업이력 정보 */
    public static getStatus(code:string|string[]){
        return Utils.apiRequestValidation({useAccount:false}) || NaverServer.get(`${this.path}/status`,{params:{status:code}});
    }

    /** 네이버 대시보드 통계정보 */
    public static getStat(params:DatshBoardStatQryType){
        return Utils.apiRequestValidation({useAccount:false}) || NaverServer.get(`${this.path}/stat`,{params});
    }

    /** 네이버 대시보드 자동입찰 현황 */
    public static getBidBase(){
        return Utils.apiRequestValidation({useAccount:false}) || NaverServer.get(`${this.path}/auto-bidding`,);
    }

    /** 비즈머니 */
    public static cash = CashHelper;
}

