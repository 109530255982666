import { useState } from "react";
import { labelSimpleLoad as kakaoSimpleLoad} from "../../pages/kakao/label/modules/DataLoad";
import { labelSimpleLoad as naverSimpleLoad} from "../../pages/naver/label/modules/DataLoad";
import { LabelResType } from "../services/models/LabelTypes";
import UserSelect from "./UserSelect";

enum LabelServerTypeKeys{
    naver='naver',
    kakao='kakao',
}

export interface LabelStateType{
    set: (data:LabelResType[])=>void;
    load: ()=>void;
    data: LabelResType[];
}

const LabelStorageComponent = class {
    public state = ():LabelStateType=>{
        const [data, setData] = useState<LabelResType[]>([]);
        const load = ()=>{ this.load().then((data)=>setData(data)); }
        const set = (data:LabelResType[])=>{ setData(data); }
        return {set,load, data};
    }

    private type:LabelServerTypeKeys|undefined = undefined;
    private storageId:string='';
    constructor(type:LabelServerTypeKeys){
        this.type=type;
    }

    /** 데이터 로딩 */
    private async load():Promise<LabelResType[]>{
        const account = UserSelect.account.get();
        switch(this.type){
            case LabelServerTypeKeys.naver :
                if(account?.accountClient?.mediaType === 'NAVER'){
                    return naverSimpleLoad();
                }
                break;

            case LabelServerTypeKeys.kakao :
                if(account?.accountClient?.mediaType === 'KAKAO'){
                    return kakaoSimpleLoad();
                }
                break;
        }
        return [];
    }
}

/** 레이블 데이터를 관리합니다. */
class LabelStorage{
    public static naver = new LabelStorageComponent(LabelServerTypeKeys.naver);
    public static kakao = new LabelStorageComponent(LabelServerTypeKeys.kakao);
}
export default LabelStorage;