/* eslint-disable react/jsx-no-target-blank */
import {SidebarMenuItemWithSub} from '../SidebarMenuItemWithSub';
import {SidebarMenuItem} from '../SidebarMenuItem';
import Config from '../../../../../../app/common/conf/Config';
import { useEffect } from 'react';
import { KTSVG } from '../../../../../helpers';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../../../../../app/contexts/UserContext';
import styles from '../style.module.scss';
import clsx from 'clsx';
import { LabelResType } from '../../../../../../app/common/services/models/LabelTypes';

const SidebarMenuMainNaverLabel = () => {
    const userContext = useUserContext();

    useEffect(()=>{
        if(Config.app.Label.enable){
            userContext.label.naver.load();
        }
    // eslint-disable-next-line
    }, [userContext.userSelect.accountState?.account]);

    return <div className=''>
        <Link className={clsx('m-3', styles.item_badge_button)} to='/pages/naver/label/list' style={{position:'absolute', right:"2.2em"}}>
            <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-2' />
        </Link>
        <SidebarMenuItemWithSub to='/pages/naver/label' icon='/media/icons/duotune/general/gen028.svg' title='레이블'>
            {userContext.label.naver.data.length===0 && <SidebarMenuItem className='pt-1 pb-1' to='/pages/naver/label/list' title='레이블 사용 시작하기' hasBullet={true}/>}
            {userContext.label.naver.data.map((v:LabelResType, index)=><SidebarMenuItem key={index} className='pt-1 pb-1' to={`/pages/naver/label/${v.labelId}`} title={v.name} hasBullet={true} />)}
        </SidebarMenuItemWithSub>
    </div>
}

export default SidebarMenuMainNaverLabel;
