
export const NaverConfig = {
    /**
     * 계정 키워드 등록 최대 개수
     */
    maxKeywordCount:100000,

    /** lock 상태값 갱신 주기(초)*/
    lockStateInterval:60,
    /** Lock 상태인 경우, 갱신주기 */
    lockStateAliveInterval:2,

    searchAd:{
        powerlink:{
            campaign:{
                /** 파워링크 - 캠페인당 키워드 등록 최대 개수 */
                maxKeywordCount:100000,

                /** 파워링크 - 캠페인당 광고그룹 등록 최대 개수 */
                maxAdGroupCount:1000,

                /** 파워링크 - 캠페인 등록 최대 개수 */
                maxRegCount:200,
            },
            adgroup:{
                /** 파워링크 - 광고그룹 등록 최대 개수 */
                maxRegCount:1000,
                
                /** 제외키워드 등록 개수 */
                maxRegRestrictKeywordCount: 70,
            },

            keyword:{
                /** 파워링크 - 키워드 등록 최대 개수 */
                maxRegCount:1000,
            },

            ad:{
                /** 파워링크 - 소재 등록 최대 개수 */
                maxRegCount:5,

                /** 소재등록 관련 설정사항 */
                create:{
                    /** 광고그룹 선택 최대 개수 */
                    maxAdGroupSelectCount:100,
                },

                /** 소재복사 관련 설정사항 */
                copy:{
                    /** 소재 복사 허용 최대개수 */
                    maxAdCount:5,
                },
            },
            /** 확장소재 */
            adExtension:{
                /** 파워링크 - 확장소재 등록 최대 개수 */
                maxRegCount:{
                    DESCRIPTION : 2,        // 홍보문구
                    DESCRIPTION_EXTRA : 4,  // 추가설명
                    HEADLINE : 15,          // 추가제목
                    PRICE_LINKS : 2,        // 가격링크
                    SUB_LINKS : 2,          // 서브링크
                },

                /** 확장소재 등록 관련 설정사항 */
                create:{
                    /** 광고그룹 선택 최대 개수 */
                    maxAdGroupSelectCount:100,
                },

                /** 확장소재 복사 관련 설정사항 */
                copy:{
                    /** 확장소재 복사 허용 최대개수 */
                    maxAdCount:5,
                },
            },
        },

        shopping:{
            campaign:{
                /** 파워링크 - 캠페인당 키워드 등록 최대 개수 */
                maxKeywordCount:100000,

                /** 파워링크 - 캠페인당 광고그룹 등록 최대 개수 */
                maxAdGroupCount:1000,

                /** 파워링크 - 캠페인 등록 최대 개수 */
                maxRegCount:200,
            },
            adgroup:{
                /** 파워링크 - 광고그룹 등록 최대 개수 */
                maxRegCount:1000,

                /** 제외키워드 등록 개수 */
                maxRegRestrictKeywordCount: 70,
            },

            keyword:{
                /** 파워링크 - 키워드 등록 최대 개수 */
                maxRegCount:1000,
            },

            ad:{
                /** 파워링크 - 소재 등록 최대 개수 */
                maxRegCount:5,

                /** 소재등록 관련 설정사항 */
                create:{
                    /** 광고그룹 선택 최대 개수 */
                    maxAdGroupSelectCount:100,
                },

                /** 소재복사 관련 설정사항 */
                copy:{
                    /** 소재 복사 허용 최대개수 */
                    maxAdCount:{
                        PRODUCT : 100,
                        CATALOG : 100,
                        BRAND_SEARCH : 5,
                    },
                },
            },
        },

        /** 공통 */
        base:{
            criterion:{
                audience:{
                    /** 이용자 세그먼트 최대 선택 가능 개수 */
                    maxLength: 20,
                }
            },
            adExtension:{
                /** 확장소재 유형 허용 필터 */
                typeFilter:{
                    /** 파워링크 */
                    powerlink:['HEADLINE', 'DESCRIPTION_EXTRA', 'DESCRIPTION', 'SUB_LINKS', 'PRICE_LINKS'],
                    /** 쇼핑검색 */
                    shopping:['PROMOTION'],
                },
            },
        },
    },
}


