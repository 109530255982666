import { NaverDbidServer } from '../axios';
import UserSelect from '../helpers/UserSelect';
import Utils from '../utils/Utils';
import { Service } from './Service';
import { RequestSearchType } from './models/RequestTypes';
import { MediaResResultType, MediaResponseType } from './models/ResponseTypes';
import { TaskDashboardResType, TaskHistoryResType, TaskReqKeys, TaskReqSearchType, TaskResType, TaskResultBidAdjustResType, TaskResultKeyworddOffResType, TaskResultSuccessTaskResType, TaskResultSummaryResType, TaskResultTodayResType, TaskSimpleResType } from './models/d-bid/TaskTypes';

/*
    실적Level
    biz-channels
    campaigns
    ad-groups
    ads
    ad-extensions
    keywords
    creatives // 카카오
    creative-links // 카카오
 */

export class NaverDbidServiceComponent{
    static path="/task/customerId";

    /** CustomerID형 Path 반환 */
    public static getCustomerPath(path:string){
        const info = UserSelect.account.get();
        if(!info?.accountGroup?.magId || !info?.accountClient?.clientId){
            throw Object.assign(new Error('광고주 계정을 선택하지 않았습니다!'), { code: 500 });
        }
        return `${this.path}/${info.accountClient.clientId}${path}`;
    }

    //표준 목록조회
    public static getList(params:TaskReqSearchType){
        return Utils.apiRequestValidation({}) || NaverDbidServer.get<MediaResponseType<MediaResResultType<TaskResType[]>>>(this.path,{params:params});
    }

    //표준 Simple
    public static getSimpleList(params:RequestSearchType){
        const clientId:number = UserSelect.account.get()?.accountClient?.clientId?.toNumber() || 0;
        return Utils.apiRequestValidation({}) || NaverDbidServer.get<MediaResponseType<MediaResResultType<TaskSimpleResType[]>>>(`${this.path}/simple`,{params:{...params, customerId:clientId}});
    }

    //표준 상세조회
    public static get<T=any>(id:string, params?:any){
        params = {...params, customerId:UserSelect.account.get()?.accountClient?.clientId?.toNumber() || 0};
        return NaverDbidServer.get<MediaResponseType<T>>(`${this.path}/${id}`, {params:params});
    }

    //표준 수정
    public static put<S=any>(body:S){
        return Utils.apiRequestValidation({}) || NaverDbidServer.put(this.path, body);
    }


    //표준 추가
    public static post<S=any>(body:S, params?:any){
        return Utils.apiRequestValidation({}) || NaverDbidServer.post<S>(this.path, body, {params:params});
    }
}


class Task extends NaverDbidServiceComponent{
    /** 엔티티별 수정 */
    public static putEntity(entity:string, params:any){
        let path = '';
        switch(entity){
            case TaskReqKeys.roasGoal :   path='roas/goal'; break;
            case TaskReqKeys.budgetGoal : path='budget/goal'; break;
            case TaskReqKeys.dayOfWeek :  path='dayofweek'; break;
            case TaskReqKeys.name :       path='name'; break;
            case TaskReqKeys.emails :     path='emails'; break;
            case TaskReqKeys.stop :       path='stop'; break;
            case TaskReqKeys.conversionType : path='conversionType'; break;
        }
        return Utils.apiRequestValidation({}) || NaverDbidServer.put(`${this.path}/${path}`, undefined, {params:params});
    }

    /** 작업 이력 조회 */
    public static dashboard(){
        return Utils.apiRequestValidation({useAccount:false}) || NaverDbidServer.get<MediaResponseType<MediaResResultType<TaskDashboardResType[]>>>(`/task/my`);
    }

    /** 작업 이력 조회 */
    public static history(taskId:number){
        return Utils.apiRequestValidation({}) || NaverDbidServer.get<MediaResponseType<MediaResResultType<TaskHistoryResType[]>>>(`${this.path}/${taskId}/history`);
    }

    public static result = class{
        static path = Task.path;
        /** 연산 결과 조회 */
        public static async get(taskId:number, params:any){
            const path:string = NaverDbidServiceComponent.getCustomerPath(`/taskId/${taskId}/result`);
            return Utils.apiRequestValidation({}) || NaverDbidServer.get<MediaResponseType<MediaResResultType<TaskResultSummaryResType>>>(path, {params});
        }

        /** 최적화 결과 요약 조회 */
        public static async summary(taskId:number){
            const path:string = NaverDbidServiceComponent.getCustomerPath(`/taskId/${taskId}/result/summary`);
            return Utils.apiRequestValidation({}) || NaverDbidServer.get<MediaResponseType<MediaResResultType<TaskResultSummaryResType>>>(path);
        }

        /** 최적화 결과 키워드 OFF 대상 키워드 조회 */
        public static async keywordOff(taskId:number, ids:string[]){
            const path:string = NaverDbidServiceComponent.getCustomerPath(`/taskId/${taskId}/result/keyword-off`);
            return Utils.apiRequestValidation({}) || NaverDbidServer.get<MediaResponseType<MediaResResultType<TaskResultKeyworddOffResType[]>>>(path, {params:{ids:ids}});
        }

        /** 최적화 결과 입찰가 조정 대상 키워드 조회 */
        public static async bidAdjust(taskId:number, ids:string[]){
            const path:string = NaverDbidServiceComponent.getCustomerPath(`/taskId/${taskId}/result/bid-adjust`);
            return Utils.apiRequestValidation({}) || NaverDbidServer.get<MediaResponseType<MediaResResultType<TaskResultBidAdjustResType[]>>>(path, {params:{ids:ids},});
        }
    }
    
    public static customer = class{
        static path = Task.path;
        /** 연산 결과 예측순위 조회 */
        public static async taskExecResultRank(taskExecId:number, keywordId:string){
            const path:string = `${NaverDbidServiceComponent.path}/taskExecId/${taskExecId}/result/rank`;
            return Utils.apiRequestValidation({}) || NaverDbidServer.get<MediaResponseType<MediaResResultType<TaskResultSummaryResType>>>(path,{params:{keywordId}});
        }

        /** 최적화 결과가 있는 건에 대한 조회 */
        public static async successTasks(){
            const path:string = NaverDbidServiceComponent.getCustomerPath(`/success-tasks`);
            return Utils.apiRequestValidation({}) || NaverDbidServer.get<MediaResponseType<MediaResResultType<TaskResultSuccessTaskResType[]>>>(path);
        }

        /** 오늘 작업완료 리스트 조회 */
        public static async todayResults(){
            const path:string = NaverDbidServiceComponent.getCustomerPath(`/today/result`);
            return Utils.apiRequestValidation({}) || NaverDbidServer.get<MediaResponseType<MediaResResultType<TaskResultTodayResType[]>>>(path);
        }
    }
}

/** 네이버 D-Bid 다운로드 Helper */
class DownloadHelper extends NaverDbidServiceComponent{
    static path="/download/customerId"; //기본 Path

    /** 연산 결과 예측순위 조회 */
    public static async post(body:any, params:any){
        const path:string = this.getCustomerPath(`/task`);
        return Utils.apiRequestValidation({}) || NaverDbidServer.post(path,body, {params});
    }
}

export default class NaverDbidService extends Service{
    //////// Class /////////
    public static Task       = Task;
    public static download   = DownloadHelper;
}