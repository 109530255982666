import { NaverConfig } from "../../../../common/conf/NaverConfig";
import { StatReportResType, StatReportResultArrayType, StatReportResultType } from "../../../../common/services/models/naver/StatReportType";
import { RequestFilterType, RequestSearchType } from "../../../../common/services/models/RequestTypes";
import { MediaResResultType, MediaResponseListDataType } from "../../../../common/services/models/ResponseTypes";
import { StatDataExtends } from "../../modules/NaverOxfordColumns";
import { CampaignResType } from "../../../../common/services/models/naver/CampaignTypes";
import { AdGroupResType } from "../../../../common/services/models/naver/AdGroupTypes";
import { AdResType } from "../../../../common/services/models/naver/AdTypes";
import { AdKeywordResType } from "../../../../common/services/models/naver/AdKeywordTypes";
import { AdExtensionResType } from "../../../../common/services/models/naver/AdExtensionTypes";
import { LabelResType } from "../../../../common/services/models/LabelTypes";
import NaverHelperComponent from "../../../../common/services/naver/NaverHelperComponent";
import { NaverService } from "../../../../common/services";

/** 네이버 마스터 데이터를 모두 호출하여 반환합니다. */
export const masterDataLoad = async <T=CampaignResType|AdGroupResType|AdKeywordResType|AdResType|AdExtensionResType>(path:string, filters?:RequestFilterType[]):Promise<T[]> => {
    const result:T[] = [];
    let total:number = 3000;    //3000개만 제한 - 테스트
    
    for(let i=0; i<1 && result.length<total; i++){
        // setPageNumber(pageNum);
        const requestData:RequestSearchType = {
            filters: filters || [], 
            orders: [],
            page: i,
            pageSize: 3000,
        };
    
        const tmp:MediaResponseListDataType<T>|undefined = await NaverHelperComponent.getList<MediaResResultType<T>>(requestData, path)
            .then(((res) => {
                if(res.status===200){
                    const data:T[] = res.data.result.items;
                    // const labelId:number = Math.pow(2, filters?.[0]?.value?.toNumber() || 0);
                    return {
                        total : res.data.result.totalItems,
                        data : data,
                        // .map((v)=>({...v, labelId: labelId | (
                        //     (Math.random() * 131071).round(0) 
                        //     & (Math.random() * 131071).round(0) 
                        //     & (Math.random() * 131071).round(0)
                        //     & (Math.random() * 131071).round(0)
                        //     & (Math.random() * 131071).round(0)
                        // )})),
                    };
                }
                return undefined;
            }))
            .catch((e)=>{ throw new Error(e); });
        if(!tmp){ throw new Error("데이터를 로딩하지 못하였습니다."); }
        total = tmp.total;
        result.push(...tmp.data);
    }
    return result;
}

/** 리포트데이터를 로딩합니다. */
export const statDataLoad = async (path:string, ids:string[]):Promise<StatReportResType[]> => {
    const chunk:number = 300;
    const promise:Promise<StatReportResType[]>[] = [];
    for(let i=0; i<ids.length; i+=chunk){
        const id:string[] = ids.slice(i, i+chunk);
        promise.push( NaverHelperComponent.getStat<StatReportResultType<StatReportResType>>(id, undefined, undefined, path)
            .then(((res) => {
                if(res.status===200){
                    return res.data.result.total || [];
                }
                return [];
            })).catch(()=>[])
        );
    }

    const data:StatReportResType[][] = await Promise.all(promise)
    .catch((e)=>{ throw new Error(e); });

    const result:StatReportResType[]=[];
    for(let i=0; i<data.length; i+=1){
        result.push(...data[i].map(v=>StatDataExtends(v)));
    }
    return result;
}

export interface DataLoadMasterStatType<T=any>{
    master: T[];
    report: StatReportResType[];
    data: T[];
}

/**
 * 
 * @param path 마스터 호출 path
 * @param stat 리포트 호출 path
 * @param getId 마스터 리포트 조인 키
 * @param filters 마스터 검색 필터 조건
 * @returns 
 */
export const dataLoadMasterStat = async <T extends object>(
    path:string,
    stat:string,
    getId:((v:T)=>string),
    filters:RequestFilterType[]
):Promise<DataLoadMasterStatType<T>>=>{
    const master = await masterDataLoad<T>(path, filters).then((data)=>data).catch((e)=>{ throw new Error(e); })
    const report = master.length>0 ? await NaverHelperComponent.postPerformances<StatReportResultArrayType<StatReportResType>[]>(master.map((v)=>getId(v)), stat)
        .then((res) => res.data.result.map(v=>v.performance) || [])
        .catch((e)=>{ throw new Error(e); }) : [];

    const tmp:T[] = master.map((v:T)=>{
        const id:string = getId(v);
        const tmp = report.find((v2:StatReportResType)=>id===v2.key);
        return {...v, ...(tmp ? StatDataExtends(tmp) : {})};
    });
    return {master, report, data:tmp};
}

/** 레이블 목록 로딩 */
export const labelLoad = async ():Promise<LabelResType[]>=>{
    return NaverService.label.getList({}).then((res)=>{
        const data:LabelResType[]|undefined = res.data.result;
        return data?.sort((a,b)=>a.labelId<b.labelId ? -1 : 1) || [];
    });
}

/** 레이블 목록 로딩 */
export const labelSimpleLoad = async ():Promise<LabelResType[]>=>{
    return NaverService.label.getSimpleList({}).then((res)=>{
        const data:LabelResType[]|undefined = res.data.result;
        return data?.sort((a,b)=>a.labelId<b.labelId ? -1 : 1) || [];
    });
}

//데이터 호출텀 계산용
let _lockLoadTm:number|null = null;
export const getLockState = async <T extends object>(getId:((v:T)=>string), data?:T[], lock?:boolean):Promise<string[]|undefined|null>=>{
    const chunk:number = 300;
    const result:string[] = [];

    if(!data?.length){ return; }
    if(
        _lockLoadTm 
        && (new Date().getTime()-(_lockLoadTm || 0)) < NaverConfig.lockStateInterval * 1000 //롱타임
        && lock!==true //강제사항
    ){ return; }
    // return data?.filter((v)=>Math.random()>0.5).map(v=>v.campaignId);

    _lockLoadTm = new Date().getTime();
    const ids:string[] = data?.map((v)=>getId(v)) || [];
    for(let i=0; i<ids.length; i+=chunk){
        const id:string[] = ids.slice(i, i+chunk);
        // 리포트 데이터 로딩
        const tmp:string[] = await NaverHelperComponent.getLockState(id || [])
        .then(((res) => {
            if(res.status===200){
                return res.data?.result?.ids || [];
            }
            return [];
        })).catch(()=>[]);
        result.push(...tmp);
    }
    return result;
}