import Config from "../../../common/conf/Config";
import { EstimateAccountClientType, EstimateAccountGroupType, EstimateUserType } from "../../../common/services/models/admin/EstimateTypes";
import { BizMoneyResType } from "../../../common/services/models/BizMoneyTypes";

/** 비즈머니 데이터 표시용 타입 */
export interface BizMoneyDataType extends BizMoneyResType{
    total: number;

    /** 로딩 완료 여부 - 갱신중 표시용 */
    loaded?:boolean;
}

/** 비즈머니 자산 데이터 표시용 타입 */
export interface BizMoneyAccountClientType extends EstimateAccountClientType{
    result?:BizMoneyDataType;
}

/** 비즈머니 광고주 데이터 표시용 타입 */
export interface BizMoneyAccountGroupType extends EstimateAccountGroupType{
    result?:BizMoneyDataType;
    clients:BizMoneyAccountClientType[];

    /** 권한 사용자 목록 */
    accountUsers?:EstimateUserType[];
}


/** 폴링 매체 구분 코드 값 Bit */
export enum PollingMediaType {
    none = 0,
    naver = 1,
    kakao = 2,
}


/** 데이터 로딩중 표시 */
export const getLoadingStatus = (loading:boolean, sync:boolean, nowTime:number, time?:string):boolean => {
    return (loading || sync) && checkTimeExpired(nowTime, time);
}

/** 데이터 로딩중 표시 */
export const checkTimeExpired = (nowTime:number, time?:string):boolean => {
    const timeDiff:number|undefined = time ? new Date(nowTime).timeDiff(new Date(time)) : undefined;
    const timeCheck:boolean = !timeDiff || timeDiff > Config.system.bizmoney.data.expire_time;
    return timeCheck;
}

//출처: https://inpa.tistory.com/entry/JS-📚-자바스크립트에-sleep-wait-대기-함수-쓰기 [Inpa Dev 👨‍💻:티스토리]
export const sleep = (sec:number)=> {
    return new Promise(resolve => setTimeout(resolve, sec * 1000));
}