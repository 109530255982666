import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { omit } from 'lodash';
import { FormSelect, FormSelectProps } from "react-bootstrap";
import { OptionType } from '../../../../modules/types/Types';
import styles from '../input/style.module.scss';

export interface SelectType extends Omit<FormSelectProps,'type' | 'onChange'> {
    options: OptionType[];
    setValueYn?:boolean;
    onChange?: (value:string) => void;
}

function AdPloSelect(props:SelectType) {

    const [useValue, setUseValue] = useState<string | number | readonly string[] | undefined>('');

    useEffect(() => {
        setUseValue(props.value);
    }, [props.value]);

    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setUseValue(e.target.value);
        props.onChange && props.onChange(e.target.value);
    }

    const props_int = omit(props, ['onChange', 'options', 'value', 'setValueYn']);
    
    return <> 
        {!props.setValueYn && <FormSelect
            defaultValue={useValue}
            onChange={onChange}
            {...props_int} 
            className={clsx('form-control bg-transparent', styles.input, props.className)}
            autoComplete='off'
        >
            { props.options.map((v, index)=><option key={index} value={v.value}>{v.label}</option>) }
        </FormSelect> }
        {props.setValueYn && <FormSelect 
            value={useValue}
            onChange={onChange}
            {...props_int}
            className={clsx('form-control bg-transparent', styles.input, props.className)}
            autoComplete='off'
        >
            { props.options.map((v, index)=><option key={index} value={v.value}>{v.label}</option>) }
        </FormSelect> }
        </>;
}

export default AdPloSelect;