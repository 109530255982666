import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import styles from '../../../../../app/pages/modules/modify/modify.module.scss'
import { KTSVG, WithChildren, checkIsActiveNew } from '../../../../helpers'
import { useLayout } from '../../../core'

type Props = {
    to: string;
    title: string|React.ReactNode;
    icon?: string;
    fontIcon?: string;
    hasBullet?: boolean;
    badge?: string;
    treeIcon?:boolean;
    treeOpen?:boolean;
    /** 메뉴 축소모드에서 표시여부 */
    minimizeHidden?:boolean;
}

const SidebarTreeTitle: React.FC<Props & WithChildren> = (props) => {
    const targetDivRef = useRef<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const {pathname} = useLocation();
    const isActive = checkIsActiveNew(pathname, props.to) || pathname.indexOf(props.to) >= 0;
    // const {config} = useLayout();
    // const {app} = config;
    
    useEffect(() => {
        const tmp = checkIsActiveNew(pathname, props.to) || pathname.indexOf(props.to) >= 0;
        setIsOpen(tmp);
        // 콜백 함수: Mutation이 감지될 때 실행
        const mutationCallback = (mutationsList: MutationRecord[]) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    const currentClassName = (mutation.target as HTMLDivElement).className;
                    // 여기에 특정 동작을 수행할 수 있습니다.
                    setIsOpen(currentClassName.split(" ").includes("show"));
                }
            }
        };
    
        // MutationObserver 인스턴스 생성
        const observer = new MutationObserver(mutationCallback);
    
        // 감시할 대상 설정
        if (targetDivRef.current) {
            const config = { attributes: true, attributeFilter: ['class'] };
            observer.observe(targetDivRef.current, config);
        }
    
        // 컴포넌트 언마운트 시 옵저버 해제
        return () => { observer.disconnect(); };
    }, []);

    return (
        <div
            ref={targetDivRef}
            className={clsx('menu-item', {'menu-hidden':props.minimizeHidden}, {'here': isActive}, 'menu-accordion')}
            data-kt-menu-trigger='click'
        >
            <SidebarTreeAccodion
                {...props} 
                treeOpen={isOpen}
            />
            <div className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': isActive, ['show']:isOpen})}>
                {props.children}
            </div>
        </div>
    )
}

const SidebarTreeAccodion: React.FC<Props & WithChildren> = (props) => {
    const {pathname} = useLocation()
    const isActive = checkIsActiveNew(pathname, props.to)
    const {config} = useLayout()
    const {app} = config

    return <div className='menu-link without-sub bg-transparent'>
        {props.hasBullet && (
            <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
            </span>
        )}
        {(props.icon || props.fontIcon) && (
            <span className='menu-icon me-2'>
                {props.icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
                    <KTSVG path={props.icon} className='svg-icon-2' />
                )}
                {props.fontIcon && (//app?.sidebar?.default?.menu?.iconType === 'font' && (
                    <i className={clsx('bi fs-3', props.fontIcon)}></i>
                )}
            </span>
        )}
        {props.treeIcon && (props.treeOpen ? <i className="bi bi-dash-square me-2" /> : <i className="bi bi-plus-square me-2" />)} 
        <Link className={clsx('menu-link bg-transparent d-inline-block p-0', {active: isActive})} to={props.badge ? '#' : props.to} style={{maxWidth:'170px'}}>
            <span className='menu-title'>{props.title}</span>
        </Link>
        {props.badge && <span className={`badge badge-light ${styles.badge} ${styles.badge_light}`} style={{borderRadius:'1em'}}>{props.badge}</span>}
        {!props.treeIcon && <span className='menu-arrow'></span>}
    </div>
}
export { SidebarTreeTitle }

