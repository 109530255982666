import clsx from "clsx";
import _ from 'lodash';
import { CSSProperties, useState } from "react";
import { KTIcon } from "../../../_metronic/helpers/components/KTIcon";
import UserSelect from "../../common/helpers/UserSelect";
import { NotificationQueryType, NotificationResType } from "../../common/services/models/NotificationTypes";
import { EstimateAccountClientType, EstimateAccountGroupType, EstimateMagClientType, EstimateMagType } from "../../common/services/models/admin/EstimateTypes";
import { UserContextModel, useUserContext } from "../../contexts/UserContext";
import NotificationCode, { NotificationSettingDataType } from "../../modules/code/NotificationCode";
import TextButton from "../../modules/layer/TextButton";
import { Confirm } from "../../modules/message";
import MediaTypeIcon from "../modules/symbol/MediaTypeIcon";
import styles from './style.module.scss';

/** 자산정보 반환 */
export const getNotificationAccountInfo = (data:NotificationResType|NotificationQueryType):{magId?:string, clientId?:string, mediaType?:string}=>{
    //Admin에서 수신된 경우
    if([
        NotificationCode.data.type.CNM0007.value,
        NotificationCode.data.type.CNM0008.value,
    ].includes(data.notiType || '')){
        const info = data.additionalInfo;
        return {magId:data.accountGroupId?.toString(), clientId: info?.clientId, mediaType: info?.mediaType}; //기본값
    }
    return {magId:data.accountGroupId?.toString(), clientId: data.clientId, mediaType: data.assetType}; //기본값
}
const compareClient = (data?:EstimateMagClientType|EstimateAccountClientType, clientId?:string|number, mediaType?:string):boolean=>clientId===data?.clientId?.toString() && mediaType===data?.mediaType;

/** 입력된 알림 데이터에 맞는 광고주데이터를 반환합니다. */
export const getMagAccountFind = (data:NotificationResType, magList?:EstimateMagType[]):{account?:EstimateMagType, client?:EstimateMagClientType}=>{
    const {magId, clientId, mediaType} = getNotificationAccountInfo(data);
    const account:EstimateMagType|undefined = magList?.find((v)=>v.magId.toString()===magId);

    //광고주가 변경될 수 있기 때문에 전체 검색
    const client:EstimateMagClientType|undefined = magList?.map((v)=>v.clients.find((v2)=>compareClient(v2, clientId, mediaType))).find((v)=>v!==undefined);
    return {account, client}
}

/** 입력된 알림 데이터에 맞는 광고주데이터를 반환합니다. */
export const getEstimateAccountFind = (data:NotificationResType|NotificationQueryType, estimate?:EstimateAccountGroupType[]):{account?:EstimateAccountGroupType, client?:EstimateAccountClientType}=>{
    const {magId, clientId, mediaType} = getNotificationAccountInfo(data);
    const account:EstimateAccountGroupType|undefined = estimate?.find((v)=>v.magId.toString()===magId);
    //광고주가 변경될 수 있기 때문에 전체 검색
    const client:EstimateAccountClientType|undefined = estimate?.map((v)=>v.clients.find((v2)=>compareClient(v2, clientId, mediaType))).find((v)=>v!==undefined);
    return {account, client}
}

export const ClientCompare = (v1:NotificationResType|NotificationQueryType, v2:EstimateMagClientType)=>{
    return v1.clientId?.toString()===v2.clientId?.toString() && v1.assetType===v2.mediaType;
}
export const MessageItem:React.FC<{data:NotificationResType, estimate?:EstimateMagType[], onClick?:()=>void}> = (props)=>{
    const data=props.data; 
    const {account, client} = getMagAccountFind(props.data, props.estimate);
    const type:NotificationSettingDataType|undefined = (NotificationCode.getOptions(NotificationCode.data.type) as NotificationSettingDataType[]).find((v)=>v.value===data.notiType);

    return <div className={clsx(styles.notification, styles.layout)}>
        <div className={styles.type}>
            <div className={styles.circle}>
                <KTIcon iconName={type?.icon || ""}/>
            </div>
            <div className={styles.verticalBar}></div>
        </div>
        <div className={clsx(styles.body, styles.data)} data-status={data.readStatus}>
            <div>
                <div>
                    <LevelBadge value={data.importance} />
                    <span className="ms-3">{data.title}</span>
                    {/* <span className="ms-3 bg-gray-600 text-white px-2" style={{borderRadius:"1em", fontSize:"0.835em"}}>123</span> */}
                </div>
                <div>{data.description} <AdditionalInfoFomatter data={props.data}/></div>
            </div>
            <div>{account?.magName}</div>
            <div>{client ? <MediaTypeIcon code={client.mediaType} label={client.clientName} viewLabel={true} nowrap={false} /> : '-'}</div>
            <div>{data.readStatus ? '읽음' : <TextButton onClick={props.onClick}>안읽음</TextButton>}</div>
            <div>{new Date(data.createdDatetime).format("yyyy.MM.dd HH:mm:ss")}</div>
            <div><LinkPageFomatter data={props.data} onClick={props.onClick} account={account}/></div>
        </div>
    </div>;
}

interface LinkPageFomatterProps{
    data: NotificationResType;
    account?: EstimateMagType;
    onClick?: ()=>void;
}

/** 상세보기 링크 표시용 */
const LinkPageFomatter:React.FC<LinkPageFomatterProps> = (props)=>{
    const userContext = useUserContext();
    const [confirm, setConfirm] = useState<boolean>(false);
    const onClick = ()=>{
        props.onClick?.();  //읽음 처리
        LinkPageHandle(userContext, props.data, ()=>setConfirm(true));
    }

    return <>
        <TextButton style={{fontSize:"1.5em"}} onClick={onClick}>
            <i className="bi bi-arrow-right-square" style={{fontSize:"1em"}} />
        </TextButton>
        <Confirm ok={{title:'닫기', onClick:()=>{  }}} title='광고주 권한 확인 요청' state={[confirm, setConfirm]}>
            {props.account?.magName || '해당 광고주'} 권한이 없습니다.<br/>
            광고주 권한 획득 후, 다시 시도해 주세요.
        </Confirm>
    </>;
}

/** 상세보기 링크 이벤트 처리 */
export const LinkPageHandle = (
    userContext: UserContextModel,
    data: NotificationResType|NotificationQueryType,
    /** 링크처리 실패시 처리 - 권한이 없는 경우도 해당 */
    forbidden?:()=>void,
    defaultTo?: string,
    estimate?: EstimateAccountGroupType[],
)=>{
    estimate = estimate || userContext.userSelect.accountState?.list || [];
    const {account:account, client:client} = getEstimateAccountFind(data, estimate);
    //http://localhost:3000/pages/redirect/noti?query=%7B%22accountGroupId%22%3A%221%22%2C%22clientId%22%3A%22664333%22%2C%22notiLevel%22%3A%22MIDDLE%22%2C%22assetType%22%3A%22NAVER%22%2C%22additionalInfo%22%3A%7B%22taskId%22%3A%228282%22%2C%22entityType%22%3A%22NET0005%22%7D%2C%22notiType%22%3A%22CNM0003%22%7D

    /** 권한 없음 */
    if(!account?.roleAuth){
        forbidden?.();  //실패 후속 처리호출
        // console.log(defaultTo);
        defaultTo && userContext.navigate(defaultTo);    //기본페이지로 이동
        return;
    }

    const info = data.additionalInfo;
    const assetType:string = (data.assetType || "")?.toLocaleLowerCase();
    let to:string|undefined = undefined;
    switch(data.notiType){
        case NotificationCode.data.type.CNM0001.value : /** 동기화 */
        case NotificationCode.data.type.CNM0002.value : /** 대량관리(UI) */
        case NotificationCode.data.type.CNM0003.value : /** 대량관리(파일) */
        case NotificationCode.data.type.CNM0004.value : /** 이미지 관리 */
            to = `/pages/${assetType}/history?taskId=${info?.taskId}`; break;

        case NotificationCode.data.type.CNM0005.value :{ /** D-Bid */
            const tmp = info?.dBidId?.split("-") || ["",""];
            to = `/pages/naver/d-bid/task?taskId=${tmp[0]}&taskExecId=${tmp[1]}`;
            break;
        }

        case NotificationCode.data.type.CNM0010.value : /** NALO */
            // return <>[오더 실행 번호 : {info?.taskId}]</>;
            break;

        case NotificationCode.data.type.CNM0006.value : /** 자동입찰 */
            break;

        case NotificationCode.data.type.CNM0007.value : /** 권한 관리 */
        case NotificationCode.data.type.CNM0008.value : /** 권한 관리(전체) */
            to = `/pages/settings/profile?magId=${info?.taskId}#advertiser`;
            break;

        case NotificationCode.data.type.CNM0009.value : /** 검수 관리 */
            break;
    }
    // console.log(to);

    if(!to && !defaultTo){ return; }
    const accountInfo = UserSelect.account.get();

    // 선택항목 저장
    if(account && client){
        userContext.userSelect.accountState?.select(account.magId, client.mediaType, client.clientId, estimate);

        if( !_.isEqual(accountInfo, {accountGroup:account, accountClient:client}) ){
            UserSelect.account.set(account, client);
            UserSelect.deviceType.set(["P","M"]);   //광고주 변경하면 디바이스 선택정보 초기화
            // userContext.toastMessage.add({title:"광고주계정 선택", body: (<div>[{label}] 계정을 선택하셨습니다!</div>)});
        }
        userContext.navigate(to || defaultTo);
    }else{
        userContext.navigate(defaultTo);    //권한이 없는 경우,
    }
}


/** 추가정보 표시용 */
const AdditionalInfoFomatter:React.FC<{data:NotificationResType}> = (props)=>{
    const info = props.data.additionalInfo;
    switch(props.data.notiType){
        case NotificationCode.data.type.CNM0001.value : /** 동기화 */
        case NotificationCode.data.type.CNM0002.value : /** 대량관리(UI) */
        case NotificationCode.data.type.CNM0003.value : /** 대량관리(파일) */
        case NotificationCode.data.type.CNM0004.value : /** 이미지 관리 */
            return <>[작업번호 : {info?.taskId}]</>;

        case NotificationCode.data.type.CNM0005.value : /** D-Bid */
            return <>[최적화 ID : {info?.dBidId}]</>;

        case NotificationCode.data.type.CNM0010.value : /** NALO */
            return <>[오더 실행 번호 : {info?.taskId}]</>;

        case NotificationCode.data.type.CNM0006.value : /** 자동입찰 */
        case NotificationCode.data.type.CNM0007.value : /** 권한 관리 */
        case NotificationCode.data.type.CNM0008.value : /** 권한 관리(전체) */
        case NotificationCode.data.type.CNM0009.value : /** 검수 관리 */
    }
    return <></>;
}

export const LevelBadge:React.FC<{value?:string, className?:string, style?:CSSProperties}> = (props)=>{
    let className:string|undefined = undefined;
    switch(props.value){
        case NotificationCode.data.level.LOW.value : className = "bg-gray-200 text-gray-600"; break;
        case NotificationCode.data.level.MIDDLE.value : className = "bg-light-success text-success"; break;
        case NotificationCode.data.level.HIGH.value : className = "bg-light-danger text-danger"; break;
    }
    return <span
        className={clsx(styles.badge, className, props.className)}
        style={props.style}
    >{NotificationCode.getLabel(NotificationCode.data.level, props.value || "")}</span>
}


/** 알림 마킹 */
export const LevelMarking:React.FC<{value?:string, className?:string, style?:CSSProperties}> = (props)=>{
    let className:string|undefined = undefined;
    switch(props.value){
        case NotificationCode.data.level.LOW.value : className = "bg-gray-600"; break;
        case NotificationCode.data.level.MIDDLE.value : className = "bg-success"; break;
        case NotificationCode.data.level.HIGH.value : className = "bg-danger"; break;
    }
    return <div className={clsx("mt-1 rounded-circle w-10px h-10px", className)}></div>
}