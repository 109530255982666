import Code from "./Code";

export default class KakaoCode extends Code{

    public static readonly data = {
        options:{
            BizLandingUrlType:{
                RSPV:{value: 'RSPV', label: '반응형'},
                PCMO:{value: 'PCMO', label: '개별설정'},
            },

            /*
            실적Level
            biz-channels
            campaigns
            ad-groups
            ads
            ad-extensions
            keywords
            creatives // 카카오
            creative-links // 카카오
            */
            ExplorerLevel:{
                bizChannel: {value:'biz-channels', label:'비즈채널'},
                campaign: {value:'campaigns', label:'캠페인'},
                adGroup: {value:'ad-groups', label:'광고그룹'},
                creative: {value:'creatives', label:'소재'},
                creativLink: {value:'creative-links', label:'소재연결'},
                keyword: {value:'keywords', label:'키워드'},
            },

            LandingUrlType:{
                adUrl:{value: 'AD_GROUP', label: '소재에 입력된 연결  URL 사용'},
                keywordUrl:{value: 'MANUAL', label: '키워드별 연결 URL 사용'},
            },

            mediaTargetDevice: {
                ALL: {value: 'PC,MOBILE', label: '디바이스 전체', isView:false}, //- 포함하는 경우, 광고그룹 수정 deviceTypes 항목 영향있음
                PC : {value: 'PC', label: 'PC '},
                MOBILE: {value: 'MOBILE', label: 'MO '},
            },

            placement : {
                search:{
                    kakao:{value:'PREMIUM_LINK_SEARCH_KAKAO', label:'카카오'},
                    partner:{value:'PREMIUM_LINK_SEARCH_PARTNER', label:'파트너'},
                },
                content:{
                    kakao:{value:'PREMIUM_LINK_CONTENT_KAKAO', label:'카카오'},
                    partner:{value:'PREMIUM_LINK_CONTENT_PARTNER', label:'파트너'},
                },
            },
            
            PriceRangeOption:{
                STATIC:{value: 'STATIC', label: '원'},
                FROM:{value: 'FROM', label: '원 부터'},
                TO:{value: 'TO', label: '원 까지'},
            },

            SubjectOption:{
                NONE:{value: '', label: '말머리 선택'},
                DISCOUNT: {value: 'DISCOUNT', label: '할인'},
                EVENT: {value: 'EVENT', label: '이벤트'},
                AUTH: {value: 'AUTH', label: '인증'},
                RELATION: {value: 'RELATION', label: '연관기사'},
            },

            TrueFalseOver:{
                true:{value: 'true', label: '초과'},
                false:{value: 'false', label: '미초과'},
            },

            useCntsNetworkBidAmt:{
                false: {value:"false", label:"미설정"},
                true: {value:"true", label:"컨텐츠 입찰가(금액)"},
            },
            useContentBidAmountType:{
                NONE: {value:"none", label:"미설정"},
                AMOUNT: {value:"amount", label:"컨텐츠 입찰가(금액)"},
                PERCENT: {value:"percent", label:"컨텐츠 입찰가(비율)"},
            },

            usePlacement:{
                all:{value:'all', label:'전체'},
                detail:{value:'detail', label:'상세설정'},
            },
        },

        Assets:{
            AssetTypes:{
                // Name : {value : 'Name', label : 'Description'},
                THUMBNAIL : {value : 'THUMBNAIL', label : '썸네일'},
                MULTI_THUMBNAIL : {value : 'MULTI_THUMBNAIL', label : '멀티썸네일'},
                ADDITIONAL_TITLE : {value : 'ADDITIONAL_TITLE', label : '추가제목'},
                ADDITIONAL_LINK : {value : 'ADDITIONAL_LINK', label : '부가링크'},
                SUBJECT : {value : 'SUBJECT', label : '말머리'},
                PRICE_TABLE : {value : 'PRICE_TABLE', label : '가격테이블'},
                CALCULATE : {value : 'CALCULATE', label : '계산하기'},
                PHONE_NUMBER : {value : 'PHONE_NUMBER', label : '전화번호'},
                TALK_CHANNEL : {value : 'TALK_CHANNEL', label : '톡채널'},
            },
            Config:{
                ON : {value : 'ON', label : '활성화'},
                OFF : {value : 'OFF', label : '비활성화'},
                DEL : {value : 'DEL', label : '삭제'},
            },
            DeviceTypes:{
                MOBILE : {value : 'MOBILE', label : '모바일'},
                PC : {value : 'PC', label : 'PC'},
            },
            DeliveryMethod : { 
                ACCELERATED : {value : 'ACCELERATED', label : '기본'},
                STANDARD : {value : 'STANDARD', label : '균등배분 '},
            },
            EventStatus:{
                COLLECTION : {value : 'COLLECTION', label : '수집중'},
                PRE_COLLECTION : {value : 'PRE_COLLECTION', label : '수집전'},
            },

            MemberType:{
                MASTER : {value : 'MASTER', label : '마스터 권한'},
                MEMBER : {value : 'MEMBER', label : '멤버 권한'},
            },
            Placement:{
                PREMIUM_LINK_SEARCH_KAKAO : {value : 'PREMIUM_LINK_SEARCH_KAKAO', label : '검색 매체 카카오'},
                PREMIUM_LINK_SEARCH_PARTNER : {value : 'PREMIUM_LINK_SEARCH_PARTNER', label : '검색 매체 파트너'},
                PREMIUM_LINK_CONTENT_KAKAO : {value : 'PREMIUM_LINK_CONTENT_KAKAO', label : '콘텐츠 매체 카카오'},
                PREMIUM_LINK_CONTENT_PARTNER : {value : 'PREMIUM_LINK_CONTENT_PARTNER', label : '콘텐츠 매체 파트너'},
            },
            ReviewStatus:{
                APPROVED : {value : 'APPROVED', label : '심사승인'},
                WAITING : {value : 'WAITING', label : '심사중'},
                REJECTED : {value : 'REJECTED', label : '심사보류'},
            },
            Status:{
                Base:{
                    ON : {value : 'ON', label : '운영 가능'},
                    OFF : {value : 'OFF', label : '운영 불가'},
                    DELETED : {value : 'DELETED', label : '삭제'},
                },
                Account:{
                    OFF_BY_AD_ACCOUNT_ADMIN_STOP : {value : 'OFF_BY_AD_ACCOUNT_ADMIN_STOP', label : '운영 불가(광고계정 관리자정지)'},
                    OFF_BY_AD_ACCOUNT : {value : 'OFF_BY_AD_ACCOUNT', label : '운영 불가(광고계정 OFF)'},
                    OFF_BY_AD_ACCOUNT_USER_BALANCE : {value : 'OFF_BY_AD_ACCOUNT_USER_BALANCE', label : '운영 불가(잔액 부족)'},
                },
                BizChannel:{
                    OFF_BY_BIZ_CHANNEL_ADMIN : {value : 'OFF_BY_BIZ_CHANNEL_ADMIN', label : '운영 불가(비즈채널 관리자정지)'},
                    OFF_BY_BIZ_CHANNEL_WAITING : {value : 'OFF_BY_BIZ_CHANNEL_WAITING', label : '운영 불가(비즈채널 심사 미승인)'},
                },
                Campaign:{
                    OFF_BY_CAMPAIGN_DAILY_BUDGET : {value : 'OFF_BY_CAMPAIGN_DAILY_BUDGET', label : '운영 불가(캠페인 일예산 초과)'},
                    OFF_BY_CAMPAIGN : {value : 'OFF_BY_CAMPAIGN', label : '운영 불가(캠페인 OFF)'},
                },
                Adgroup:{
                    OFF_BY_AD_GROUP_DAILY_BUDGET : {value : 'OFF_BY_AD_GROUP_DAILY_BUDGET', label : '운영 불가(광고그룹 일예산 초과)'},
                    OFF_BY_AD_GROUP_DATE : {value : 'OFF_BY_AD_GROUP_DATE', label : '운영 불가(광고그룹 미노출 기간)'},
                    OFF_BY_AD_GROUP_HOUR : {value : 'OFF_BY_AD_GROUP_HOUR', label : '운영 불가(광고그룹 미노출 시간)'},
                    OFF_BY_AD_GROUP_DAY : {value : 'OFF_BY_AD_GROUP_DAY', label : '운영 불가(광고그룹 미노출 요일)'},
                    OFF_BY_AD_GROUP : {value : 'OFF_BY_AD_GROUP', label : '운영 불가(광고그룹 OFF)'},
                },
                Keyword:{
                    WAITING : {value : 'WAITING', label : '운영 불가(키워드 심사 미승인)'},
                },
                Ad:{
                    WAITING : {value : 'WAITING', label : '운영 불가(소재 심사 미승인)'},
                },
            },

            MetricsGroups:{
                Name : {value : 'Name', label : 'Description'},
                BASIC : {value : 'BASIC', label : '기본지표'},
                ADDITION : {value : 'ADDITION', label : '추가지표'},
                PIXEL_SDK_CONVERSION : {value : 'PIXEL_SDK_CONVERSION', label : '픽셀&SDK 전환 지표'},
            },
            Report:{
                DatePreset:{
                    TODAY : {value : 'TODAY', label : '없음'},
                    YESTERDAY : {value : 'YESTERDAY', label : '일단위'},
                    THIS_WEEK : {value : 'THIS_WEEK', label : '주단위'},
                    LAST_WEEK : {value : 'LAST_WEEK', label : '월단위'},
                    LAST_7DAY : {value : 'LAST_7DAY', label : '지난 7일'},
                    LAST_14DAY : {value : 'LAST_14DAY', label : '지난 14일'},
                    LAST_30DAY : {value : 'LAST_30DAY', label : '지난 30일'},
                    THIS_MONTH : {value : 'THIS_MONTH', label : '이번 달'},
                    LAST_MONTH : {value : 'LAST_MONTH', label : '지난 달'},
                },
                TimeUnit:{
                    NONE : {value : 'NONE', label : '없음'},
                    DAY : {value : 'DAY', label : '일단위(조회 기간을 31일 내 제공)'},
                    WEEK : {value : 'WEEK', label : '주단위(조회 기간을 62일 내 제공)'},
                    MONTH : {value : 'MONTH', label : '월단위(조회 기간을 12개월 내 제공)'},
                },
                Dimension:{
                    NONE:"없음",
                    HOUR:{//"시간대",
                        "00": {value:"00", label:"00:00 ~ 00:59"},
                        "01": {value:"01", label:"01:00 ~ 01:59"},
                        "02": {value:"02", label:"02:00 ~ 02:59"},
                        "03": {value:"03", label:"03:00 ~ 03:59"},
                        "04": {value:"04", label:"04:00 ~ 04:59"},
                        "05": {value:"05", label:"05:00 ~ 05:59"},
                        "06": {value:"06", label:"06:00 ~ 06:59"},
                        "07": {value:"07", label:"07:00 ~ 07:59"},
                        "08": {value:"08", label:"08:00 ~ 08:59"},
                        "09": {value:"09", label:"09:00 ~ 09:59"},
                        "10": {value:"10", label:"10:00 ~ 10:59"},
                        "11": {value:"11", label:"11:00 ~ 11:59"},
                        "12": {value:"12", label:"12:00 ~ 12:59"},
                        "13": {value:"13", label:"13:00 ~ 13:59"},
                        "14": {value:"14", label:"14:00 ~ 14:59"},
                        "15": {value:"15", label:"15:00 ~ 15:59"},
                        "16": {value:"16", label:"16:00 ~ 16:59"},
                        "17": {value:"17", label:"17:00 ~ 17:59"},
                        "18": {value:"18", label:"18:00 ~ 18:59"},
                        "19": {value:"19", label:"19:00 ~ 19:59"},
                        "20": {value:"20", label:"20:00 ~ 20:59"},
                        "21": {value:"21", label:"21:00 ~ 21:59"},
                        "22": {value:"22", label:"22:00 ~ 22:59"},
                        "23": {value:"23", label:"23:00 ~ 23:59"},
                    },
                    DEVICE:{//"디바이스",	
                        MOBILE : {value : 'MOBILE', label : '모바일'},
                        PC : {value : 'PC', label : 'PC'},
                    },
                    PLACEMENT:{//"매체유형",
                        PREMIUM_LINK_SEARCH_KAKAO : {value : 'PREMIUM_LINK_SEARCH_KAKAO', label : '검색 매체 카카오'},
                        PREMIUM_LINK_SEARCH_PARTNER : {value : 'PREMIUM_LINK_SEARCH_PARTNER', label : '검색 매체 파트너'},
                        PREMIUM_LINK_CONTENT_KAKAO : {value : 'PREMIUM_LINK_CONTENT_KAKAO', label : '콘텐츠 매체 카카오'},
                        PREMIUM_LINK_CONTENT_PARTNER : {value : 'PREMIUM_LINK_CONTENT_PARTNER', label : '콘텐츠 매체 파트너'},
                        KEYWORD_EX : {value : 'KEYWORD_EX', label : '키워드확장'},
                        ASSET_TYPE : {value : 'ASSET_TYPE', label : '확장소재 타입'},
                    },
                },
            },

            //업종데이터
            BusinessTypes:[
                {id:116,parentName:'건강_병원',name:'기타 병원',fullName:'기타 병원'},
                {id:117,parentName:'건강_병원',name:'내과',fullName:'내과'},
                {id:118,parentName:'건강_병원',name:'노인병원',fullName:'노인병원'},
                {id:119,parentName:'건강_병원',name:'대장항문과',fullName:'대장항문과'},
                {id:120,parentName:'건강_병원',name:'동물병원',fullName:'동물병원'},
                {id:121,parentName:'건강_병원',name:'물리치료과',fullName:'물리치료과'},
                {id:122,parentName:'건강_병원',name:'비뇨기과',fullName:'비뇨기과'},
                {id:123,parentName:'건강_병원',name:'산부인과',fullName:'산부인과'},
                {id:124,parentName:'건강_병원',name:'성형외과',fullName:'성형외과'},
                {id:125,parentName:'건강_병원',name:'소아과',fullName:'소아과'},
                {id:126,parentName:'건강_병원',name:'신경정신과',fullName:'신경정신과'},
                {id:127,parentName:'건강_병원',name:'안과',fullName:'안과'},
                {id:128,parentName:'건강_병원',name:'외과',fullName:'외과'},
                {id:129,parentName:'건강_병원',name:'요양병원',fullName:'요양병원'},
                {id:130,parentName:'건강_병원',name:'이비인후과',fullName:'이비인후과'},
                {id:131,parentName:'건강_병원',name:'재활의학과',fullName:'재활의학과'},
                {id:132,parentName:'건강_병원',name:'정신병원',fullName:'정신병원'},
                {id:133,parentName:'건강_병원',name:'정형외과',fullName:'정형외과'},
                {id:134,parentName:'건강_병원',name:'치과',fullName:'치과'},
                {id:135,parentName:'건강_병원',name:'피부과',fullName:'피부과'},
                {id:136,parentName:'건강_병원',name:'한의원',fullName:'한의원'},
                {id:137,parentName:'건강_건강식품',name:'건강기능식품',fullName:'건강기능식품'},
                {id:138,parentName:'건강_건강식품',name:'기타 건강식품',fullName:'기타 건강식품'},
                {id:139,parentName:'건강_건강식품',name:'다이어트기기',fullName:'다이어트기기'},
                {id:140,parentName:'건강_건강식품',name:'다이어트식품',fullName:'다이어트식품'},
                {id:141,parentName:'건강_건강식품',name:'단식원',fullName:'단식원'},
                {id:142,parentName:'건강_건강식품',name:'일반건강식품',fullName:'일반건강식품'},
                {id:143,parentName:'건강_건강식품',name:'해외직배송 건강기능식품',fullName:'해외직배송 건강기능식품'},
                {id:144,parentName:'건강_건강식품',name:'헬스보충제',fullName:'헬스보충제'},
                {id:145,parentName:'건강_의약품',name:'약국',fullName:'약국'},
                {id:146,parentName:'건강_의약품',name:'일반의약품',fullName:'일반의약품'},
                {id:147,parentName:'건강_의약품',name:'전문의약품',fullName:'전문의약품'},
                {id:148,parentName:'건강_의료기기',name:'기타 의료기기',fullName:'기타 의료기기'},
                {id:149,parentName:'건강_의료기기',name:'의료기기',fullName:'의료기기'},
                {id:150,parentName:'건강_대체의학',name:'기타 대체의학',fullName:'기타 대체의학'},
                {id:151,parentName:'건강_대체의학',name:'마사지',fullName:'마사지'},
                {id:152,parentName:'건강_대체의학',name:'명상/기공',fullName:'명상/기공'},
                {id:153,parentName:'건강_대체의학',name:'심리치료',fullName:'심리치료'},
                {id:154,parentName:'건강_대체의학',name:'최면',fullName:'최면'},
                {id:155,parentName:'건강_건강정보',name:'건강정보',fullName:'건강정보'},
                {id:156,parentName:'건강_기타건강사이트',name:'기타 건강사이트',fullName:'기타 건강사이트'},
                {id:157,parentName:'교육_오프라인학원',name:'검정고시',fullName:'검정고시'},
                {id:158,parentName:'교육_오프라인학원',name:'공무원학원',fullName:'공무원학원'},
                {id:159,parentName:'교육_오프라인학원',name:'기숙학원',fullName:'기숙학원'},
                {id:160,parentName:'교육_오프라인학원',name:'기술학원',fullName:'기술학원'},
                {id:161,parentName:'교육_오프라인학원',name:'기타 오프라인학원',fullName:'기타 오프라인학원'},
                {id:162,parentName:'교육_오프라인학원',name:'디자인학원',fullName:'디자인학원'},
                {id:163,parentName:'교육_오프라인학원',name:'미술학원',fullName:'미술학원'},
                {id:164,parentName:'교육_오프라인학원',name:'미용학원',fullName:'미용학원'},
                {id:165,parentName:'교육_오프라인학원',name:'부동산/주택',fullName:'부동산/주택'},
                {id:166,parentName:'교육_오프라인학원',name:'세무회계',fullName:'세무회계'},
                {id:167,parentName:'교육_오프라인학원',name:'스튜어디스학원',fullName:'스튜어디스학원'},
                {id:168,parentName:'교육_오프라인학원',name:'어학원',fullName:'어학원'},
                {id:169,parentName:'교육_오프라인학원',name:'연기학원',fullName:'연기학원'},
                {id:170,parentName:'교육_오프라인학원',name:'요리학원',fullName:'요리학원'},
                {id:171,parentName:'교육_오프라인학원',name:'운전전문학원',fullName:'운전전문학원'},
                {id:172,parentName:'교육_오프라인학원',name:'유학/어학연수',fullName:'유학/어학연수'},
                {id:173,parentName:'교육_오프라인학원',name:'음악학원',fullName:'음악학원'},
                {id:174,parentName:'교육_오프라인학원',name:'의료학원',fullName:'의료학원'},
                {id:175,parentName:'교육_오프라인학원',name:'일반운전학원',fullName:'일반운전학원'},
                {id:176,parentName:'교육_오프라인학원',name:'입시/편입',fullName:'입시/편입'},
                {id:177,parentName:'교육_오프라인학원',name:'체육학원',fullName:'체육학원'},
                {id:178,parentName:'교육_오프라인학원',name:'체험학습',fullName:'체험학습'},
                {id:179,parentName:'교육_오프라인학원',name:'컴퓨터학원',fullName:'컴퓨터학원'},
                {id:180,parentName:'교육_오프라인학원',name:'화술학원',fullName:'화술학원'},
                {id:181,parentName:'교육_온라인학원',name:'검정고시A',fullName:'검정고시A'},
                {id:182,parentName:'교육_온라인학원',name:'공무원학원A',fullName:'공무원학원A'},
                {id:183,parentName:'교육_온라인학원',name:'기타 온라인학원',fullName:'기타 온라인학원'},
                {id:184,parentName:'교육_온라인학원',name:'부동산/주택A',fullName:'부동산/주택A'},
                {id:185,parentName:'교육_온라인학원',name:'어학원A',fullName:'어학원A'},
                {id:186,parentName:'교육_온라인학원',name:'유아교육',fullName:'유아교육'},
                {id:187,parentName:'교육_온라인학원',name:'유학/어학연수A',fullName:'유학/어학연수A'},
                {id:188,parentName:'교육_온라인학원',name:'입시/편입A',fullName:'입시/편입A'},
                {id:189,parentName:'교육_온라인학원',name:'전화/화상교육',fullName:'전화/화상교육'},
                {id:190,parentName:'교육_온라인학원',name:'컴퓨터학원A',fullName:'컴퓨터학원A'},
                {id:191,parentName:'교육_온라인학원',name:'학점은행제',fullName:'학점은행제'},
                {id:192,parentName:'교육_학습지',name:'학습지',fullName:'학습지'},
                {id:193,parentName:'교육_학교',name:'고등학교',fullName:'고등학교'},
                {id:194,parentName:'교육_학교',name:'대안학교',fullName:'대안학교'},
                {id:195,parentName:'교육_학교',name:'대학',fullName:'대학'},
                {id:196,parentName:'교육_학교',name:'대학교',fullName:'대학교'},
                {id:197,parentName:'교육_학교',name:'대학원',fullName:'대학원'},
                {id:198,parentName:'교육_학교',name:'유치원',fullName:'유치원'},
                {id:199,parentName:'교육_학교',name:'중학교',fullName:'중학교'},
                {id:200,parentName:'교육_학교',name:'직업학교',fullName:'직업학교'},
                {id:201,parentName:'교육_학교',name:'초등학교',fullName:'초등학교'},
                {id:202,parentName:'교육_교육원',name:'문화센터',fullName:'문화센터'},
                {id:203,parentName:'교육_교육원',name:'어린이집',fullName:'어린이집'},
                {id:204,parentName:'교육_교육원',name:'평생교육원',fullName:'평생교육원'},
                {id:205,parentName:'교육_기타교육사이트',name:'기타 교육사이트',fullName:'기타 교육사이트'},
                {id:206,parentName:'금융_부동산',name:'부동산거래',fullName:'부동산거래'},
                {id:207,parentName:'금융_부동산',name:'부동산경매',fullName:'부동산경매'},
                {id:208,parentName:'금융_부동산',name:'부동산분양',fullName:'부동산분양'},
                {id:209,parentName:'금융_부동산',name:'부동산임대',fullName:'부동산임대'},
                {id:210,parentName:'금융_부동산',name:'부동산컨설팅',fullName:'부동산컨설팅'},
                {id:211,parentName:'금융_보험',name:'보험',fullName:'보험'},
                {id:212,parentName:'금융_대출',name:'1금융권',fullName:'1금융권'},
                {id:213,parentName:'금융_대출',name:'2금융 수탁사',fullName:'2금융 수탁사'},
                {id:214,parentName:'금융_대출',name:'2금융 은행권',fullName:'2금융 은행권'},
                {id:215,parentName:'금융_대출',name:'2금융 캐피탈',fullName:'2금융 캐피탈'},
                {id:216,parentName:'금융_대출',name:'보험사 대출',fullName:'보험사 대출'},
                {id:217,parentName:'금융_대출',name:'대부업',fullName:'대부업'},
                {id:218,parentName:'금융_대출',name:'전당포',fullName:'전당포'},
                {id:219,parentName:'금융_재테크',name:'자산관리',fullName:'자산관리'},
                {id:220,parentName:'금융_재테크',name:'증권',fullName:'증권'},
                {id:221,parentName:'금융_재테크',name:'투자정보서비스',fullName:'투자정보서비스'},
                {id:222,parentName:'금융_신용',name:'신용정보',fullName:'신용정보'},
                {id:223,parentName:'금융_신용',name:'신용조사/채권추심',fullName:'신용조사/채권추심'},
                {id:224,parentName:'금융_신용',name:'신용카드',fullName:'신용카드'},
                {id:225,parentName:'금융_기타금융서비스',name:'기타 금융서비스',fullName:'기타 금융서비스'},
                {id:226,parentName:'소매(쇼핑몰)_전자',name:'게임기기',fullName:'게임기기'},
                {id:227,parentName:'소매(쇼핑몰)_전자',name:'계절가전',fullName:'계절가전'},
                {id:228,parentName:'소매(쇼핑몰)_전자',name:'렌탈가전',fullName:'렌탈가전'},
                {id:229,parentName:'소매(쇼핑몰)_전자',name:'사무기기',fullName:'사무기기'},
                {id:230,parentName:'소매(쇼핑몰)_전자',name:'생활/소형가전',fullName:'생활/소형가전'},
                {id:231,parentName:'소매(쇼핑몰)_전자',name:'영상가전',fullName:'영상가전'},
                {id:232,parentName:'소매(쇼핑몰)_전자',name:'음향가전',fullName:'음향가전'},
                {id:233,parentName:'소매(쇼핑몰)_전자',name:'조명기기',fullName:'조명기기'},
                {id:234,parentName:'소매(쇼핑몰)_전자',name:'통신가전',fullName:'통신가전'},
                {id:235,parentName:'소매(쇼핑몰)_전자',name:'특수기기',fullName:'특수기기'},
                {id:236,parentName:'소매(쇼핑몰)_전자',name:'핸드폰',fullName:'핸드폰'},
                {id:237,parentName:'소매(쇼핑몰)_꽃배달/선물',name:'꽃배달',fullName:'꽃배달'},
                {id:238,parentName:'소매(쇼핑몰)_꽃배달/선물',name:'선물',fullName:'선물'},
                {id:239,parentName:'소매(쇼핑몰)_가구/인테리어',name:'사무/학원가구',fullName:'사무/학원가구'},
                {id:240,parentName:'소매(쇼핑몰)_가구/인테리어',name:'생활가구',fullName:'생활가구'},
                {id:241,parentName:'소매(쇼핑몰)_가구/인테리어',name:'인테리어소품',fullName:'인테리어소품'},
                {id:242,parentName:'소매(쇼핑몰)_가구/인테리어',name:'침구/홈패션',fullName:'침구/홈패션'},
                {id:243,parentName:'소매(쇼핑몰)_의류',name:'남성의류',fullName:'남성의류'},
                {id:244,parentName:'소매(쇼핑몰)_의류',name:'단체복/유니폼/이벤트의상',fullName:'단체복/유니폼/이벤트의상'},
                {id:245,parentName:'소매(쇼핑몰)_의류',name:'빅사이즈',fullName:'빅사이즈'},
                {id:246,parentName:'소매(쇼핑몰)_의류',name:'속옷',fullName:'속옷'},
                {id:247,parentName:'소매(쇼핑몰)_의류',name:'아웃도어',fullName:'아웃도어'},
                {id:248,parentName:'소매(쇼핑몰)_의류',name:'여성의류',fullName:'여성의류'},
                {id:249,parentName:'소매(쇼핑몰)_의류',name:'종합의류',fullName:'종합의류'},
                {id:250,parentName:'소매(쇼핑몰)_의류',name:'중년의류',fullName:'중년의류'},
                {id:251,parentName:'소매(쇼핑몰)_의류',name:'한복',fullName:'한복'},
                {id:252,parentName:'소매(쇼핑몰)_패션잡화',name:'가방/지갑',fullName:'가방/지갑'},
                {id:253,parentName:'소매(쇼핑몰)_패션잡화',name:'기타 패션잡화',fullName:'기타 패션잡화'},
                {id:254,parentName:'소매(쇼핑몰)_패션잡화',name:'시계',fullName:'시계'},
                {id:255,parentName:'소매(쇼핑몰)_패션잡화',name:'신발',fullName:'신발'},
                {id:256,parentName:'소매(쇼핑몰)_패션잡화',name:'안경/선글라스',fullName:'안경/선글라스'},
                {id:257,parentName:'소매(쇼핑몰)_패션잡화',name:'액세서리',fullName:'액세서리'},
                {id:258,parentName:'소매(쇼핑몰)_생활/주방/문구',name:'문구',fullName:'문구'},
                {id:259,parentName:'소매(쇼핑몰)_생활/주방/문구',name:'사무용품',fullName:'사무용품'},
                {id:260,parentName:'소매(쇼핑몰)_생활/주방/문구',name:'생활용품',fullName:'생활용품'},
                {id:261,parentName:'소매(쇼핑몰)_생활/주방/문구',name:'주방용품',fullName:'주방용품'},
                {id:262,parentName:'소매(쇼핑몰)_취미',name:'진검',fullName:'진검'},
                {id:263,parentName:'소매(쇼핑몰)_취미',name:'총포사',fullName:'총포사'},
                {id:264,parentName:'소매(쇼핑몰)_취미',name:'공예',fullName:'공예'},
                {id:265,parentName:'소매(쇼핑몰)_취미',name:'기타 취미',fullName:'기타 취미'},
                {id:266,parentName:'소매(쇼핑몰)_취미',name:'모형',fullName:'모형'},
                {id:267,parentName:'소매(쇼핑몰)_취미',name:'악기',fullName:'악기'},
                {id:268,parentName:'소매(쇼핑몰)_취미',name:'애완동물',fullName:'애완동물'},
                {id:269,parentName:'소매(쇼핑몰)_취미',name:'원예',fullName:'원예'},
                {id:270,parentName:'소매(쇼핑몰)_취미',name:'종교',fullName:'종교'},
                {id:271,parentName:'소매(쇼핑몰)_식품',name:'담배',fullName:'담배'},
                {id:272,parentName:'소매(쇼핑몰)_식품',name:'주류저도수',fullName:'주류저도수'},
                {id:273,parentName:'소매(쇼핑몰)_식품',name:'가공식품',fullName:'가공식품'},
                {id:274,parentName:'소매(쇼핑몰)_식품',name:'음료',fullName:'음료'},
                {id:275,parentName:'소매(쇼핑몰)_식품',name:'일반식품',fullName:'일반식품'},
                {id:276,parentName:'소매(쇼핑몰)_식품',name:'홈베이킹',fullName:'홈베이킹'},
                {id:277,parentName:'소매(쇼핑몰)_구매대행',name:'구매대행',fullName:'구매대행'},
                {id:278,parentName:'소매(쇼핑몰)_종합쇼핑몰',name:'종합쇼핑몰',fullName:'종합쇼핑몰'},
                {id:279,parentName:'소매(쇼핑몰)_기타소매',name:'기타 소매',fullName:'기타 소매'},
                {id:280,parentName:'일반서비스_생활서비스',name:'민원서비스',fullName:'민원서비스'},
                {id:281,parentName:'일반서비스_생활서비스',name:'방제서비스',fullName:'방제서비스'},
                {id:282,parentName:'일반서비스_생활서비스',name:'보안서비스',fullName:'보안서비스'},
                {id:283,parentName:'일반서비스_생활서비스',name:'사진/인화',fullName:'사진/인화'},
                {id:284,parentName:'일반서비스_생활서비스',name:'심부름센터',fullName:'심부름센터'},
                {id:285,parentName:'일반서비스_생활서비스',name:'장례서비스',fullName:'장례서비스'},
                {id:286,parentName:'일반서비스_생활서비스',name:'조경서비스',fullName:'조경서비스'},
                {id:287,parentName:'일반서비스_생활서비스',name:'청소서비스',fullName:'청소서비스'},
                {id:288,parentName:'일반서비스_결혼서비스',name:'국내 및 국제결혼',fullName:'국내 및 국제결혼'},
                {id:289,parentName:'일반서비스_결혼서비스',name:'국내결혼',fullName:'국내결혼'},
                {id:290,parentName:'일반서비스_결혼서비스',name:'국제결혼',fullName:'국제결혼'},
                {id:291,parentName:'일반서비스_결혼서비스',name:'소개팅',fullName:'소개팅'},
                {id:292,parentName:'일반서비스_결혼서비스',name:'예물/혼수',fullName:'예물/혼수'},
                {id:293,parentName:'일반서비스_결혼서비스',name:'예식장',fullName:'예식장'},
                {id:294,parentName:'일반서비스_결혼서비스',name:'웨딩스튜디오',fullName:'웨딩스튜디오'},
                {id:295,parentName:'일반서비스_결혼서비스',name:'웨딩커뮤니티',fullName:'웨딩커뮤니티'},
                {id:296,parentName:'일반서비스_결혼서비스',name:'웨딩컨설팅',fullName:'웨딩컨설팅'},
                {id:297,parentName:'일반서비스_결혼서비스',name:'청첩장',fullName:'청첩장'},
                {id:298,parentName:'일반서비스_결혼서비스',name:'폐백/이바지',fullName:'폐백/이바지'},
                {id:299,parentName:'일반서비스_결혼서비스',name:'하객대행',fullName:'하객대행'},
                {id:300,parentName:'일반서비스_취업',name:'과외',fullName:'과외'},
                {id:301,parentName:'일반서비스_취업',name:'국내외 취업',fullName:'국내외 취업'},
                {id:302,parentName:'일반서비스_취업',name:'국내취업',fullName:'국내취업'},
                {id:303,parentName:'일반서비스_취업',name:'기타 취업서비스',fullName:'기타 취업서비스'},
                {id:304,parentName:'일반서비스_취업',name:'아르바이트',fullName:'아르바이트'},
                {id:305,parentName:'일반서비스_취업',name:'해외취업',fullName:'해외취업'},
                {id:306,parentName:'일반서비스_인력파견',name:'가사도우미',fullName:'가사도우미'},
                {id:307,parentName:'일반서비스_인력파견',name:'건축인력',fullName:'건축인력'},
                {id:308,parentName:'일반서비스_인력파견',name:'기타 인력파견',fullName:'기타 인력파견'},
                {id:309,parentName:'일반서비스_인력파견',name:'모델파견',fullName:'모델파견'},
                {id:310,parentName:'일반서비스_인력파견',name:'베이비시터',fullName:'베이비시터'},
                {id:311,parentName:'일반서비스_인력파견',name:'산모도우미',fullName:'산모도우미'},
                {id:312,parentName:'일반서비스_기타일반서비스',name:'기타 일반서비스',fullName:'기타 일반서비스'},
                {id:313,parentName:'전문서비스_법률서비스',name:'기타 법률서비스',fullName:'기타 법률서비스'},
                {id:314,parentName:'전문서비스_법률서비스',name:'노무사',fullName:'노무사'},
                {id:315,parentName:'전문서비스_법률서비스',name:'법무사',fullName:'법무사'},
                {id:316,parentName:'전문서비스_법률서비스',name:'변리사',fullName:'변리사'},
                {id:317,parentName:'전문서비스_법률서비스',name:'변호사',fullName:'변호사'},
                {id:318,parentName:'전문서비스_법률서비스',name:'세무/회계',fullName:'세무/회계'},
                {id:319,parentName:'전문서비스_법률서비스',name:'신용회복',fullName:'신용회복'},
                {id:320,parentName:'전문서비스_법률서비스',name:'이민',fullName:'이민'},
                {id:321,parentName:'전문서비스_법률서비스',name:'행정사',fullName:'행정사'},
                {id:322,parentName:'전문서비스_통/번역',name:'통/번역',fullName:'통/번역'},
                {id:323,parentName:'전문서비스_경영컨설팅',name:'경영컨설팅',fullName:'경영컨설팅'},
                {id:324,parentName:'전문서비스_경영컨설팅',name:'창업컨설팅',fullName:'창업컨설팅'},
                {id:325,parentName:'전문서비스_경영컨설팅',name:'프랜차이즈',fullName:'프랜차이즈'},
                {id:326,parentName:'전문서비스_서식',name:'서식',fullName:'서식'},
                {id:327,parentName:'전문서비스_건축',name:'건축사무소',fullName:'건축사무소'},
                {id:328,parentName:'전문서비스_건축',name:'건축자재',fullName:'건축자재'},
                {id:329,parentName:'전문서비스_건축',name:'설비수리/공사',fullName:'설비수리/공사'},
                {id:330,parentName:'전문서비스_건축',name:'인테리어시공',fullName:'인테리어시공'},
                {id:331,parentName:'전문서비스_운송서비스',name:'국내외 이사',fullName:'국내외 이사'},
                {id:332,parentName:'전문서비스_운송서비스',name:'국내이사',fullName:'국내이사'},
                {id:333,parentName:'전문서비스_운송서비스',name:'지입',fullName:'지입'},
                {id:334,parentName:'전문서비스_운송서비스',name:'창고/컨테이너',fullName:'창고/컨테이너'},
                {id:335,parentName:'전문서비스_운송서비스',name:'콜밴',fullName:'콜밴'},
                {id:336,parentName:'전문서비스_운송서비스',name:'퀵서비스',fullName:'퀵서비스'},
                {id:337,parentName:'전문서비스_운송서비스',name:'택배',fullName:'택배'},
                {id:338,parentName:'전문서비스_운송서비스',name:'해외이사',fullName:'해외이사'},
                {id:339,parentName:'전문서비스_기타전문서비스',name:'기타 전문서비스',fullName:'기타 전문서비스'},
                {id:340,parentName:'여가_레포츠',name:'골프',fullName:'골프'},
                {id:341,parentName:'여가_레포츠',name:'기타레포츠',fullName:'기타레포츠'},
                {id:342,parentName:'여가_레포츠',name:'낚시',fullName:'낚시'},
                {id:343,parentName:'여가_레포츠',name:'등산',fullName:'등산'},
                {id:344,parentName:'여가_레포츠',name:'라켓스포츠',fullName:'라켓스포츠'},
                {id:345,parentName:'여가_레포츠',name:'래프팅/서바이벌',fullName:'래프팅/서바이벌'},
                {id:346,parentName:'여가_레포츠',name:'수상스포츠',fullName:'수상스포츠'},
                {id:347,parentName:'여가_레포츠',name:'스노보드/스키',fullName:'스노보드/스키'},
                {id:348,parentName:'여가_레포츠',name:'축구/야구/농구',fullName:'축구/야구/농구'},
                {id:349,parentName:'여가_레포츠용품',name:'골프A',fullName:'골프A'},
                {id:350,parentName:'여가_레포츠용품',name:'기타 레포츠용품',fullName:'기타 레포츠용품'},
                {id:351,parentName:'여가_레포츠용품',name:'낚시A',fullName:'낚시A'},
                {id:352,parentName:'여가_레포츠용품',name:'등산A',fullName:'등산A'},
                {id:353,parentName:'여가_레포츠용품',name:'라켓스포츠A',fullName:'라켓스포츠A'},
                {id:354,parentName:'여가_레포츠용품',name:'래프팅/서바이벌A',fullName:'래프팅/서바이벌A'},
                {id:355,parentName:'여가_레포츠용품',name:'수상스포츠A',fullName:'수상스포츠A'},
                {id:356,parentName:'여가_레포츠용품',name:'스노보드/스키A',fullName:'스노보드/스키A'},
                {id:357,parentName:'여가_레포츠용품',name:'축구/야구/농구A',fullName:'축구/야구/농구A'},
                {id:358,parentName:'여가_국내여행',name:'국내여행',fullName:'국내여행'},
                {id:359,parentName:'여가_해외여행',name:'해외여행',fullName:'해외여행'},
                {id:360,parentName:'여가_종합여행사',name:'종합여행사',fullName:'종합여행사'},
                {id:361,parentName:'여가_여권/비자',name:'여권/비자',fullName:'여권/비자'},
                {id:362,parentName:'여가_항공권',name:'항공권',fullName:'항공권'},
                {id:363,parentName:'여가_국내숙박',name:'리조트/콘도',fullName:'리조트/콘도'},
                {id:364,parentName:'여가_국내숙박',name:'민박/펜션',fullName:'민박/펜션'},
                {id:365,parentName:'여가_국내숙박',name:'호텔',fullName:'호텔'},
                {id:366,parentName:'여가_해외숙박',name:'리조트/콘도A',fullName:'리조트/콘도A'},
                {id:367,parentName:'여가_해외숙박',name:'민박/펜션A',fullName:'민박/펜션A'},
                {id:368,parentName:'여가_해외숙박',name:'종합숙박',fullName:'종합숙박'},
                {id:369,parentName:'여가_해외숙박',name:'호텔A',fullName:'호텔A'},
                {id:370,parentName:'여가_기타여가서비스',name:'기타 여가서비스',fullName:'기타 여가서비스'},
                {id:371,parentName:'엔터테인먼트_운세서비스',name:'무속인',fullName:'무속인'},
                {id:372,parentName:'엔터테인먼트_운세서비스',name:'운세',fullName:'운세'},
                {id:373,parentName:'엔터테인먼트_운세서비스',name:'작명',fullName:'작명'},
                {id:374,parentName:'엔터테인먼트_다운로드서비스',name:'P2P/웹하드',fullName:'P2P/웹하드'},
                {id:375,parentName:'엔터테인먼트_다운로드서비스',name:'영상',fullName:'영상'},
                {id:376,parentName:'엔터테인먼트_다운로드서비스',name:'음원',fullName:'음원'},
                {id:377,parentName:'엔터테인먼트_다운로드서비스',name:'출판',fullName:'출판'},
                {id:378,parentName:'엔터테인먼트_스트리밍서비스',name:'영상A',fullName:'영상A'},
                {id:379,parentName:'엔터테인먼트_스트리밍서비스',name:'음원A',fullName:'음원A'},
                {id:380,parentName:'엔터테인먼트_스트리밍서비스',name:'출판A',fullName:'출판A'},
                {id:381,parentName:'엔터테인먼트_제작서비스',name:'영상B',fullName:'영상B'},
                {id:382,parentName:'엔터테인먼트_제작서비스',name:'음원B',fullName:'음원B'},
                {id:383,parentName:'엔터테인먼트_제작서비스',name:'출판B',fullName:'출판B'},
                {id:384,parentName:'엔터테인먼트_공연',name:'공연',fullName:'공연'},
                {id:385,parentName:'엔터테인먼트_티켓예매',name:'티켓예매',fullName:'티켓예매'},
                {id:386,parentName:'엔터테인먼트_복권',name:'복권',fullName:'복권'},
                {id:387,parentName:'엔터테인먼트_게임',name:'게임',fullName:'게임'},
                {id:388,parentName:'엔터테인먼트_도서',name:'도서',fullName:'도서'},
                {id:389,parentName:'엔터테인먼트_기획사',name:'기획사',fullName:'기획사'},
                {id:390,parentName:'엔터테인먼트_이벤트',name:'이벤트대행',fullName:'이벤트대행'},
                {id:391,parentName:'엔터테인먼트_이벤트',name:'이벤트용품',fullName:'이벤트용품'},
                {id:392,parentName:'엔터테인먼트_채팅',name:'채팅',fullName:'채팅'},
                {id:393,parentName:'엔터테인먼트_기타엔터테인먼트',name:'기타 엔터테인먼트',fullName:'기타 엔터테인먼트'},
                {id:394,parentName:'자동차_신차판매',name:'기아차',fullName:'기아차'},
                {id:395,parentName:'자동차_신차판매',name:'대우차',fullName:'대우차'},
                {id:396,parentName:'자동차_신차판매',name:'삼성차',fullName:'삼성차'},
                {id:397,parentName:'자동차_신차판매',name:'수입차',fullName:'수입차'},
                {id:398,parentName:'자동차_신차판매',name:'쌍용차',fullName:'쌍용차'},
                {id:399,parentName:'자동차_신차판매',name:'현대차',fullName:'현대차'},
                {id:400,parentName:'자동차_차량렌트',name:'차량렌트',fullName:'차량렌트'},
                {id:401,parentName:'자동차_중고차',name:'중고차',fullName:'중고차'},
                {id:402,parentName:'자동차_자동차용품',name:'자동차용품',fullName:'자동차용품'},
                {id:403,parentName:'자동차_자동차관리',name:'자동차관리',fullName:'자동차관리'},
                {id:404,parentName:'자동차_폐차장',name:'폐차장',fullName:'폐차장'},
                {id:405,parentName:'자동차_기타자동차서비스',name:'기타 자동차서비스',fullName:'기타 자동차서비스'},
                {id:406,parentName:'광고_광고물',name:'옥외광고',fullName:'옥외광고'},
                {id:407,parentName:'광고_광고물',name:'인쇄/광고디자인',fullName:'인쇄/광고디자인'},
                {id:408,parentName:'광고_광고물',name:'판촉물',fullName:'판촉물'},
                {id:409,parentName:'광고_광고컨설팅',name:'광고컨설팅',fullName:'광고컨설팅'},
                {id:410,parentName:'광고_광고컨설팅',name:'선거',fullName:'선거'},
                {id:411,parentName:'광고_기타광고서비스',name:'기타 광고서비스',fullName:'기타 광고서비스'},
                {id:412,parentName:'출산/육아_출산',name:'산후조리원',fullName:'산후조리원'},
                {id:413,parentName:'출산/육아_출산',name:'임부복',fullName:'임부복'},
                {id:414,parentName:'출산/육아_출산',name:'임신/육아 정보',fullName:'임신/육아 정보'},
                {id:415,parentName:'출산/육아_출산',name:'제대혈',fullName:'제대혈'},
                {id:416,parentName:'출산/육아_육아',name:'돌잔치',fullName:'돌잔치'},
                {id:417,parentName:'출산/육아_육아',name:'아기사진',fullName:'아기사진'},
                {id:418,parentName:'출산/육아_육아',name:'유아동복',fullName:'유아동복'},
                {id:419,parentName:'출산/육아_육아',name:'유아동용품',fullName:'유아동용품'},
                {id:420,parentName:'출산/육아_육아',name:'한복/돌복',fullName:'한복/돌복'},
                {id:421,parentName:'출산/육아_기타출산/육아',name:'기타 출산/육아',fullName:'기타 출산/육아'},
                {id:422,parentName:'미용_미용',name:'메이크업',fullName:'메이크업'},
                {id:423,parentName:'미용_미용',name:'문신',fullName:'문신'},
                {id:424,parentName:'미용_미용',name:'미용용품/기기',fullName:'미용용품/기기'},
                {id:425,parentName:'미용_미용',name:'반영구화장',fullName:'반영구화장'},
                {id:426,parentName:'미용_미용',name:'뷰티케어/체형클리닉',fullName:'뷰티케어/체형클리닉'},
                {id:427,parentName:'미용_미용',name:'헤어클리닉',fullName:'헤어클리닉'},
                {id:428,parentName:'미용_화장품',name:'화장품',fullName:'화장품'},
                {id:429,parentName:'컴퓨터_하드웨어',name:'PC/노트북',fullName:'PC/노트북'},
                {id:430,parentName:'컴퓨터_하드웨어',name:'주변기기',fullName:'주변기기'},
                {id:431,parentName:'컴퓨터_하드웨어',name:'컴퓨터부품',fullName:'컴퓨터부품'},
                {id:432,parentName:'컴퓨터_소프트웨어',name:'기업솔루션',fullName:'기업솔루션'},
                {id:433,parentName:'컴퓨터_소프트웨어',name:'상용소프트웨어',fullName:'상용소프트웨어'},
                {id:434,parentName:'컴퓨터_컴퓨터수리/보수',name:'컴퓨터 수리/보수',fullName:'컴퓨터 수리/보수'},
                {id:435,parentName:'컴퓨터_기타컴퓨터서비스',name:'기타 컴퓨터 서비스',fullName:'기타 컴퓨터 서비스'},
                {id:436,parentName:'인터넷/통신_인터넷서비스',name:'방송',fullName:'방송'},
                {id:437,parentName:'인터넷/통신_인터넷서비스',name:'인터넷폰',fullName:'인터넷폰'},
                {id:438,parentName:'인터넷/통신_인터넷서비스',name:'전용선',fullName:'전용선'},
                {id:439,parentName:'인터넷/통신_웹서비스',name:'기타 웹서비스',fullName:'기타 웹서비스'},
                {id:440,parentName:'인터넷/통신_웹서비스',name:'웹호스팅/도메인',fullName:'웹호스팅/도메인'},
                {id:441,parentName:'인터넷/통신_웹서비스',name:'홈페이지제작',fullName:'홈페이지제작'},
                {id:442,parentName:'인터넷/통신_통신서비스',name:'국제전화',fullName:'국제전화'},
                {id:443,parentName:'인터넷/통신_통신서비스',name:'네트워크/키폰',fullName:'네트워크/키폰'},
                {id:444,parentName:'인터넷/통신_통신서비스',name:'모바일서비스',fullName:'모바일서비스'},
                {id:445,parentName:'인터넷/통신_포털서비스',name:'포털서비스',fullName:'포털서비스'},
                {id:446,parentName:'인터넷/통신_기타인터넷/통신',name:'기타 인터넷/통신',fullName:'기타 인터넷/통신'},
                {id:447,parentName:'산업_공구/산업용품',name:'공구/산업용품',fullName:'공구/산업용품'},
                {id:448,parentName:'산업_기계',name:'기계',fullName:'기계'},
                {id:449,parentName:'산업_화공약품',name:'화공약품',fullName:'화공약품'},
                {id:450,parentName:'성인_성인용품',name:'성인용품',fullName:'성인용품'},
                {id:451,parentName:'성인_성인구인구직',name:'성인구인구직',fullName:'성인구인구직'},
                {id:452,parentName:'성인_성인컨텐츠',name:'성인만화',fullName:'성인만화'},
                {id:453,parentName:'성인_성인컨텐츠',name:'성인영상물',fullName:'성인영상물'},
                {id:454,parentName:'성인_성인컨텐츠',name:'성인폰팅/채팅',fullName:'성인폰팅/채팅'},
                {id:455,parentName:'성인_기타성인서비스',name:'기타 성인서비스',fullName:'기타 성인서비스'},
                {id:456,parentName:'기타',name:'기타',fullName:'기타'},
                {id:457,parentName:'금융_할부금융',name:'할부금융',fullName:'할부금융'},
                {id:458,parentName:'소매(쇼핑몰)_명품',name:'명품/중고명품 쇼핑',fullName:'명품/중고명품 쇼핑'},
                {id:459,parentName:'소매(쇼핑몰)_경매',name:'경매',fullName:'경매'},
                {id:460,parentName:'소매(쇼핑몰)_네트워크마케팅',name:'네트워크마케팅쇼핑몰',fullName:'네트워크마케팅쇼핑몰'},
                {id:461,parentName:'여가_카지노',name:'카지노',fullName:'카지노'},
                {id:462,parentName:'자동차_대리운전',name:'대리운전',fullName:'대리운전'},
                {id:463,parentName:'광고_네트워크마케팅',name:'네트워크마케팅',fullName:'네트워크마케팅'},
                {id:464,parentName:'미용_기타 미용',name:'기타 미용',fullName:'기타 미용'},
                {id:465,parentName:'산업_기타 산업',name:'기타 산업',fullName:'기타 산업'},
                {id:466,parentName:'소매(쇼핑몰)_소셜네트워크',name:'소셜네트워크',fullName:'소셜네트워크'},
                {id:467,parentName:'자동차_주차장',name:'주차장',fullName:'주차장'},
                {id:468,parentName:'쇼핑몰(소매)',name:'쇼핑몰입점셀러',fullName:'쇼핑몰입점셀러'},
                {id:469,parentName:'엔터테인먼트_영상홍보',name:'영상홍보',fullName:'영상홍보'},
                {id:470,parentName:'기관_단체',name:'일반단체',fullName:'일반단체'},
                {id:471,parentName:'기관_단체',name:'정당/정치후보자',fullName:'정당/정치후보자'},
                {id:472,parentName:'기관_단체',name:'정부기관',fullName:'정부기관'},
                {id:473,parentName:'게임_PC게임',name:'기타',fullName:'기타'},
                {id:474,parentName:'게임_PC게임',name:'레이싱',fullName:'레이싱'},
                {id:475,parentName:'게임_PC게임',name:'롤플레잉',fullName:'롤플레잉'},
                {id:476,parentName:'게임_PC게임',name:'보드/퍼즐',fullName:'보드/퍼즐'},
                {id:477,parentName:'게임_PC게임',name:'스포츠',fullName:'스포츠'},
                {id:478,parentName:'게임_PC게임',name:'시뮬레이션',fullName:'시뮬레이션'},
                {id:479,parentName:'게임_PC게임',name:'액션/슈팅',fullName:'액션/슈팅'},
                {id:480,parentName:'게임_PC게임',name:'전략',fullName:'전략'},
                {id:481,parentName:'게임_모바일게임',name:'기타',fullName:'기타'},
                {id:482,parentName:'게임_모바일게임',name:'레이싱/런',fullName:'레이싱/런'},
                {id:483,parentName:'게임_모바일게임',name:'롤플레잉',fullName:'롤플레잉'},
                {id:484,parentName:'게임_모바일게임',name:'보드/퍼즐',fullName:'보드/퍼즐'},
                {id:485,parentName:'게임_모바일게임',name:'스포츠',fullName:'스포츠'},
                {id:486,parentName:'게임_모바일게임',name:'시뮬레이션',fullName:'시뮬레이션'},
                {id:487,parentName:'게임_모바일게임',name:'액션/슈팅',fullName:'액션/슈팅'},
                {id:488,parentName:'게임_모바일게임',name:'전략',fullName:'전략'},
                {id:489,parentName:'음식점',name:'기타',fullName:'기타'},
                {id:490,parentName:'음식점',name:'배달음식',fullName:'배달음식'},
                {id:491,parentName:'음식점',name:'양식',fullName:'양식'},
                {id:492,parentName:'음식점',name:'일식',fullName:'일식'},
                {id:493,parentName:'음식점',name:'출장부페',fullName:'출장부페'},
                {id:494,parentName:'음식점',name:'한식',fullName:'한식'},
                {id:495,parentName:'엔터테인먼트_영화',name:'영화',fullName:'영화'},
                {id:497,parentName:'소매(쇼핑몰)_명품',name:'명품브랜드',fullName:'명품브랜드'},
                {id:502,parentName:'비카카오게임_PC비카카오게임',name:'레이싱',fullName:'레이싱'},
                {id:503,parentName:'비카카오게임_PC비카카오게임',name:'롤플레잉',fullName:'롤플레잉'},
                {id:504,parentName:'비카카오게임_PC비카카오게임',name:'보드/퍼즐',fullName:'보드/퍼즐'},
                {id:505,parentName:'비카카오게임_PC비카카오게임',name:'스포츠',fullName:'스포츠'},
                {id:506,parentName:'비카카오게임_PC비카카오게임',name:'시뮬레이션',fullName:'시뮬레이션'},
                {id:507,parentName:'비카카오게임_PC비카카오게임',name:'액션/슈팅',fullName:'액션/슈팅'},
                {id:508,parentName:'비카카오게임_PC비카카오게임',name:'전략',fullName:'전략'},
                {id:509,parentName:'비카카오게임_PC비카카오게임',name:'기타',fullName:'기타'},
                {id:510,parentName:'비카카오게임_모바일비카카오게임',name:'레이싱/런',fullName:'레이싱/런'},
                {id:511,parentName:'비카카오게임_모바일비카카오게임',name:'롤플레잉',fullName:'롤플레잉'},
                {id:512,parentName:'비카카오게임_모바일비카카오게임',name:'보드/퍼즐',fullName:'보드/퍼즐'},
                {id:513,parentName:'비카카오게임_모바일비카카오게임',name:'스포츠',fullName:'스포츠'},
                {id:514,parentName:'비카카오게임_모바일비카카오게임',name:'시뮬레이션',fullName:'시뮬레이션'},
                {id:515,parentName:'비카카오게임_모바일비카카오게임',name:'액션/슈팅',fullName:'액션/슈팅'},
                {id:516,parentName:'비카카오게임_모바일비카카오게임',name:'전략',fullName:'전략'},
                {id:517,parentName:'비카카오게임_모바일비카카오게임',name:'기타',fullName:'기타'},
                {id:554,parentName:'기타',name:'연령제한',fullName:'연령제한'},
                {id:555,parentName:'소매(쇼핑몰)_식품',name:'주류고도수(17도이상)',fullName:'주류고도수(17도이상)'},
                {id:556,parentName:'금융_대출',name:'금리비교서비스',fullName:'금리비교서비스'},
                {id:558,parentName:'금융_가상화폐',name:'거래소',fullName:'거래소'},
                {id:559,parentName:'금융_가상화폐',name:'솔루션',fullName:'솔루션'},
                {id:560,parentName:'금융_가상화폐',name:'기타서비스',fullName:'기타서비스'},
            ]
        }
    };

    
};

