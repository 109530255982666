declare global{
    interface String{
        /**
         * 문자열이 비어 있는지를 반환합니다.
         */
        isEmpty():boolean;

        /**
         * URL형식의 문자열인지 반환합니다.
         */
        isURL():boolean;

        toNumber():number;
        addComma():string;
        removeComma():string;

        /**
         * 문자열을 분리하여 빈문자열을 제외한 값을 배열로 반환합니다.
         * @param separator 분리할 문자열(기본값 : ',')
         */
        splitNoneBlank(separator?:string):string[];

        /**
         * 값의 범위를 체크합니다.
         * @param min 최소값
         * @param max 최대값
         * @param digits 자릿수 - 예)1000단위 입력시 : 3 
         */
        rangeValid(min:number, max:number, digits?:number):boolean;

        /** 32bit hash값을 반환합니다. */
        hashCode():number;

        /**
         * 날짜 범위 체크
         * @param toDate 
         */
        dateRangeValidate(toDate:string):boolean;

        formatPhone():string;

        formatDatetime():string;

        formatDateToString(format?:string):string;

        /** '*' 매칭 */
        wildcardMatch(str:string):boolean;
    }
}

// eslint-disable-next-line no-extend-native
String.prototype.isEmpty = function(){
    return (this === undefined || this === null || (typeof this === 'string' && this.trim().length === 0)) ? true : false;
};

// eslint-disable-next-line no-extend-native
String.prototype.isURL = function(){
    //eslint-disable-next-line
    const urlExp = /(http(s)?:\/\/)(www\.)?([/a-z0-9-%#?&=\w])+(\.[a-z0-9]{2,4}(\?[/a-z0-9-%#?&=\w]+)*)*/gi;
    return urlExp.test(this.toString()) ? true : false;
}

// eslint-disable-next-line no-extend-native
String.prototype.toNumber = function(){
    //eslint-disable-next-line
    return Number(this.replace(/[a-zA-Zㄱ-ㅎ|ㅏ-ㅣ|가-힣\{\}\[\]\/?,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g, '')); // '.' 제외 소숫점 외곡됨
}

// eslint-disable-next-line no-extend-native
String.prototype.addComma = function(){
    const value = this.toNumber().toString().split('.');
    //eslint-disable-next-line
    value[0] = value[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return value.join('.');
}
// eslint-disable-next-line no-extend-native
String.prototype.removeComma = function(){
    //eslint-disable-next-line
    return this.replace(/,/g, '');
}

// eslint-disable-next-line no-extend-native
String.prototype.splitNoneBlank = function(separator?:string) {
    return this.split(separator || ',').map(v=>v.trim()).filter((v)=>v.length > 0);
}

// eslint-disable-next-line no-extend-native
String.prototype.rangeValid = function(min:number, max:number, digits?:number) {
    const pow = digits && digits > 0 ? Math.pow(10, digits) : 1;
    const num:number = this.toNumber();
    return (num >= min && num <= max) && (pow===undefined || (num % pow ===0) );
}

// eslint-disable-next-line no-extend-native
String.prototype.hashCode = function() {
    let h=0;
    for(let i = 0; i < this.length; i++)
        h = Math.imul(31, h) + this.charCodeAt(i) | 0;
    return h;
}


// eslint-disable-next-line no-extend-native
String.prototype.formatPhone = function(){
    if(this.length === 11){
        //eslint-disable-next-line
        return String(this).replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    }else if(this.length === 8){
        //eslint-disable-next-line
        return String(this).replace(/(\d{4})(\d{4})/, '$1-$2');
    }else if(String(this).indexOf('02') === 0){
        //eslint-disable-next-line
        return String(this).replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
    }else{
        //eslint-disable-next-line
        return String(this).replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
};

// eslint-disable-next-line no-extend-native
String.prototype.formatDatetime = function(){
    if(this.length === 14){
        //eslint-disable-next-line
        return this.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5:$6');
    }
    if(this.length === 12){
        //eslint-disable-next-line
        return this.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5');
    }
    return this.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
}


// eslint-disable-next-line no-extend-native
String.prototype.dateRangeValidate = function(toDate:string) {
    const firstDate = new Date(this.toString()).format('yyyy-MM-dd');
    const secondDate = new Date(toDate).format('yyyy-MM-dd');
    return firstDate <= secondDate;
}

// eslint-disable-next-line no-extend-native
String.prototype.formatDateToString = function(format?:string) {
    if(this.toString() === '') {return '';}
    const strFormat:string = format ? format : 'yyyy-MM-dd HH:mm:ss';
    const date = new Date(this.toString()).format(strFormat);

    return date.toString();
}

// eslint-disable-next-line no-extend-native
String.prototype.wildcardMatch = function(pattern:string) {
    // 패턴에 있는 '*'를 정규식 패턴으로 변환
    const regexPattern = new RegExp('^' + pattern.split('*').map(escapeRegExp).join('.*') + '$');
    return regexPattern.test(this.toString());
}

// 문자열에 있는 특수문자를 이스케이프 처리하는 함수 -wildcardMatch
function escapeRegExp(str:string) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export{}





