import clsx from "clsx";
import React, { CSSProperties } from "react";
import { Table } from "react-bootstrap";
import UserSelect from "../../../common/helpers/UserSelect";
import { AdExtensionType_CALCULATION, AdExtensionType_CATALOG_BRAND_MESSAGE, AdExtensionType_CATALOG_DETAIL, AdExtensionType_CATALOG_IMAGE, AdExtensionType_CATALOG_MOVIE, AdExtensionType_CATALOG_RELEASE_DATE, AdExtensionType_DESCRIPTION, AdExtensionType_DESCRIPTION_EXTRA, AdExtensionType_HEADLINE, AdExtensionType_IMAGE_SUB_LINKS, AdExtensionType_NAVER_BLOG_REVIEW, AdExtensionType_NAVER_TV_VIDEO, AdExtensionType_PRICE_LINKS, AdExtensionType_PROMOTION, AdExtensionType_SUB_LINKS } from "../../../common/services/models/naver/AdExtensionTypes";
import { StatReportInfoType } from "../../../common/services/models/naver/StatReportType";
import NaverCode from "../../../modules/code/NaverCode";
import Formatter from "../../modules/Formatter";
import ExplorerListDetail from "./ExplorerListDetail";
import { convertStatInfoViewData } from "./StatInfoData";
import styles from './explorer.module.scss';

export interface ItemsProps{
    index?:number;
    data: StatReportInfoType;
    media?: string;
    level?: string;
    search?: string;
    show?: boolean;
    isDetail?:boolean;
    setSelect?: (id:string)=>void;
}

export const Items:React.FC<ItemsProps> = (props)=>{
    const Item = getItemTag(props);
    const id:string|undefined = getItemId(props.level, props.data);
    if(!Item){ return <>Unknown {props.level}</> }
    return <>
        {props.show && <DetailWindow level={props.level} search={id} />}
            <div className='pt-5' style={{overflowY:'scroll', maxHeight:'calc(100vh - 276px)', marginRight:'-10px', paddingRight:'10px'}}>
            {!props.isDetail && <>
                {props.index!==undefined && <span className='me-3 fw-bold'>{props.index+1}</span>}
                <Item  {...props}/>
            </>}
            {props.isDetail && props.level===NaverCode.data.options.ExplorerLevel.adExtension.value && <div className='mb-6'><Item {...props}/></div>}
            <Viewer {...props} />
            {props.show && <ExplorerListDetail {...props} id={ getItemId(props.level, props.data)} />}
        </div>
    </>;
}

export const ItemsBizChannel:React.FC<ItemsProps> = (props)=>(<SearchHighlight
    className={styles.link}
    text={props.data.businessChannelName}
    search={props.search}
    onClick={()=>{props.setSelect && props.setSelect(props.data.businessChannelId || '')}} 
/>);


export const ItemsCampaign:React.FC<ItemsProps> = (props)=>(<SearchHighlight
    className={styles.link}
    text={props.data.campaignName}
    search={props.search}
    onClick={()=>{props.setSelect && props.setSelect(props.data.campaignId || '')}} 
/>);

export const ItemsAdGroup:React.FC<ItemsProps> = (props)=>(<SearchHighlight
    className={styles.link}
    text={props.data.adgroupName}
    search={props.search}
    onClick={()=>{props.setSelect && props.setSelect(props.data.adgroupId || '')}} 
/>);

export const ItemsKeyword:React.FC<ItemsProps> = (props)=>(<SearchHighlight
    className={styles.link}
    text={props.data.keyword}
    search={props.search}
    onClick={()=>{props.setSelect && props.setSelect(props.data.keywordId || '')}} 
/>);

export const ItemsAd:React.FC<ItemsProps> = (props)=>(<ItemAdType {...props} />);

export const ItemsAdExtension:React.FC<ItemsProps> = (props)=>(<ItemAdExtensionType {...props} />);

export const ItemsSearchKeyword:React.FC<ItemsProps> = (props)=>(<SearchHighlight
    className={styles.link}
    text={props.data.searchKeyword}
    search={props.search}
    onClick={()=>{props.setSelect && props.setSelect(props.data.searchKeywordId || '')}} 
/>);

export const ItemsCriterion:React.FC<ItemsProps> = (props)=>(<SearchHighlight
    className={styles.link}
    text={NaverCode.getLabel([
        NaverCode.data.assets.age,
        NaverCode.data.assets.audience,
        NaverCode.data.assets.gender,
        NaverCode.data.assets.region,
     ], props.data.criterionDictionaryCode || "")}
    search={props.search}
    onClick={()=>{props.setSelect && props.setSelect(props.data.criterionId || '')}} 
/>);

const Viewer:React.FC<{level?:string, data?:StatReportInfoType, isDetail?:boolean}> = (props) =>{
    const viewData = convertStatInfoViewData(props.level, props.data);
    return <Table className={props.isDetail ? 'table border table-bordered border-gray-300 table-row-bordered table-row-gray-300 mb-0' : ''}>
        <colgroup><col width={props.isDetail ? '200px' : '120px'} /><col /></colgroup>
        <tbody>
            {viewData?.map((v,index)=><tr key={index}>
                <th className={clsx('text-gray-500 me-3 text-right', props.isDetail ? '' : 'p-1')}>{v.title}</th>
                <td className={clsx(props.isDetail ? '' : 'p-1')}>{v.value}</td>
            </tr>)}
        </tbody>
    </Table>;
}


export const getItemId = (level?:string, item?:StatReportInfoType):string|undefined => {
    switch(level){
        case NaverCode.data.options.ExplorerLevel.campaign.value :  return item?.campaignId;
        case NaverCode.data.options.ExplorerLevel.adGroup.value :   return item?.adgroupId;
        case NaverCode.data.options.ExplorerLevel.keyword.value :   return item?.keywordId;
        case NaverCode.data.options.ExplorerLevel.ad.value:         return item?.adId;
        case NaverCode.data.options.ExplorerLevel.bizChannel.value :return item?.businessChannelId;
        case NaverCode.data.options.ExplorerLevel.searchKwd.value : return item?.searchKeywordId;
        case NaverCode.data.options.ExplorerLevel.adExtension.value : return item?.adextensionId;
        case NaverCode.data.options.ExplorerLevel.criterion?.value : return item?.criterionId;
        default: ;
    } 
    return undefined;
}

export const getItemTag = (props:ItemsProps):React.FC<ItemsProps>|undefined =>{
    switch(props.level){
        case NaverCode.data.options.ExplorerLevel.campaign.value :  return ItemsCampaign;   
        case NaverCode.data.options.ExplorerLevel.adGroup.value :   return ItemsAdGroup;    
        case NaverCode.data.options.ExplorerLevel.keyword.value :   return ItemsKeyword;    
        case NaverCode.data.options.ExplorerLevel.ad.value :        return ItemsAd; 
        case NaverCode.data.options.ExplorerLevel.bizChannel.value :return ItemsBizChannel; 
        case NaverCode.data.options.ExplorerLevel.searchKwd.value : return ItemsSearchKeyword; 
        case NaverCode.data.options.ExplorerLevel.adExtension.value : return ItemsAdExtension; 
        case NaverCode.data.options.ExplorerLevel.criterion?.value : return ItemsCriterion; 
    }
    return undefined;
}


interface DetailWindowProps{
    level?:string;
    search?:string;
}
const DetailWindow:React.FC<DetailWindowProps> = (props)=>{
    const userselect = UserSelect.storage.get();
    userselect.explorer = {level:props.level, search:props.search};
    const params:string[][] = [
        ['select', JSON.stringify(userselect || '')],
    ];
    const qry = new URLSearchParams(params).toString();
    return <div className="text-right w-100">
        <a target='_blank' rel="noreferrer" href={`/popup/naver/explorer/?${qry}`}>자세히 보기 <i className="bi bi-box-arrow-up-right" /></a>
    </div>;
}

/**
 * 검색키워드 하일라이트 처리
 * @param props 
 * @returns 
 */
const SearchHighlight:React.FC<{text?:string, search?:string, className?:string, style?:CSSProperties, onClick?:()=>void}> = (props)=>{
    const replaceText = (text: string, needle:string, replace:React.ReactNode): React.ReactNode => {
        return <>{text.split(needle).map((v,index)=><span key={index}>{index>0 && replace}{v}</span>)}</>;
    };
    return <span className={props.className} style={props.style} onClick={props.onClick}>
        {props.search && props.text ? replaceText(props.text, props.search, <span className='text-danger'>{props.search}</span>) : props.text}
    </span>
}


const ItemAdType:React.FC<ItemsProps> = (props)=>{ 
    const onClick = ()=>{props.setSelect && props.setSelect(props.data?.adId || '')};
    switch(props.data?.adTypeCode?.toString()){
        case NaverCode.data.explorer.ad.SHOPPING_PRODUCT_AD.value : {
            return <div className="border mt-1 p-3 ps-6 w-100">
                <SearchHighlight
                    key={1}
                    className={styles.link}
                    text={props.data?.adValue1 || props.data?.adValue2}
                    search={props.search}
                    onClick={onClick} 
                />
                <ul className="m-0">
                    <li className="p-0">쇼핑상품 ID : {props.data?.adValue3}</li>
                    <li className="p-0">몰상품 ID : {props.data?.adValue4}</li>
                </ul>
            </div>;
        }

        case NaverCode.data.explorer.ad.CATALOG_AD.value : {
            return <div className="border mt-1 p-3 ps-6 w-100">
                <SearchHighlight
                    key={1}
                    className={styles.link}
                    text={props.data?.adValue1}
                    search={props.search}
                    onClick={onClick} 
                />
                <ul className="m-0">
                    <li className="p-0">쇼핑상품 ID : {props.data?.adValue2}</li>
                </ul>
            </div>;
        }

        case NaverCode.data.explorer.ad.SHOPPING_BRAND_IMAGE_THUMBNAIL_AD.value : {
            return <div className="border mt-1 p-3 ps-6 w-100">
                <SearchHighlight
                    key={1}
                    className={styles.link}
                    text={props.data?.adValue1}
                    search={props.search}
                    onClick={onClick} 
                />
                {props.data?.adValue2 && <><br/><SearchHighlight
                    key={2}
                    className={styles.link}
                    text={props.data?.adValue2}
                    search={props.search}
                    onClick={onClick}  
                /></>}
                {props.data?.adValue3 && <><br/><SearchHighlight
                    key={3}
                    className={styles.link}
                    text={props.data?.adValue3}
                    search={props.search}
                    onClick={onClick} 
                /></>}
            </div>;
        }

        case NaverCode.data.explorer.ad.SHOPPING_BRAND_IMAGE_BANNER_AD.value : {
            return <div className="border mt-1 w-100">
                <div className='p-3 ps-6'>
                    <SearchHighlight
                        key={1}
                        className={styles.link}
                        text={props.data?.adValue1}
                        search={props.search}
                        onClick={onClick} 
                    /><br/>
                    <SearchHighlight
                        key={2}
                        className={styles.link}
                        text={props.data?.adValue2}
                        search={props.search}
                        onClick={onClick} 
                    />
                </div>
                {/* <hr className='m-0 border-gray-600'/>
                <div className="p-3 ps-6">- 연결 URL : <HiperLinkEllisis href={props.data?.adValue3} style={{maxWidth:'500px'}} /></div> */}
            </div>;
        }

        case NaverCode.data.explorer.ad.SHOPPING_BRAND_AD.value : {
            return <div className="border mt-1 p-3 ps-6 w-100">
                <SearchHighlight
                    key={1}
                    className={styles.link}
                    text={props.data?.adValue1}
                    search={props.search}
                    onClick={onClick} 
                />
            </div>;
        }
    }
    
    return <div className="border mt-1 w-100">
        <div className='p-3 ps-6'>
            <SearchHighlight
                key={1}
                className={styles.link}
                text={props.data?.adValue1}
                search={props.search}
                onClick={onClick} 
            /><br/>
            <SearchHighlight
                key={2}
                className={styles.link}
                text={props.data?.adValue2}
                search={props.search}
                onClick={onClick} 
            />
        </div>
        {/* <hr className='m-0 border-gray-600'/>
        <div className="p-3 ps-6">
            <div className="p-0">- PC URL : <HiperLinkEllisis href={props.data?.adValue3} style={{maxWidth:'500px'}} /></div>
            <div className="p-0">- Mobile URL : <HiperLinkEllisis href={props.data?.adValue4} style={{maxWidth:'500px'}} /></div>
        </div> */}
    </div>;
}


const ItemAdExtensionType:React.FC<ItemsProps> = (props)=>{ 
    const onClick = ()=>{props.setSelect && props.setSelect(props.data?.adextensionId || '')};
    let contents:React.ReactNode|undefined = undefined;

    switch(props.data?.adextensionTypeCode?.toString()){
        case NaverCode.data.explorer.adExtension.POWER_LINK_IMAGE.value : 
        case NaverCode.data.explorer.adExtension.CATALOG_IMAGE.value : {
            const data = JSON.parse(props.data?.adextension || '') as AdExtensionType_CATALOG_IMAGE;
            return <div className="border mt-1 p-3 ps-6 w-100" >
                <img className="adExtensionListIMG cursor-pointer" alt="썸네일" onClick={onClick} src={`https://searchad-phinf.pstatic.net${data?.imagePath}?type=THUMBNAIL`} />
            </div>;
        }

        case NaverCode.data.explorer.adExtension.SUB_LINKS.value : {
            let num=0;
            const data = JSON.parse(props.data?.adextension || '') as AdExtensionType_SUB_LINKS[];
            return  <div className="border mt-1 p-3 ps-6 w-100">
                {Array.isArray(data) && data?.map((v:AdExtensionType_SUB_LINKS, index)=><div className="adextention_list" key={index}>
                    <span>서브링크 {++num}</span>    
                    <ul style={{margin:0}}>
                        <li>링크제목 : 
                            <SearchHighlight
                                key={1}
                                className={styles.link}
                                text={v.name}
                                search={props.search}
                                onClick={onClick} 
                            />
                        </li>
                        <li>연결URL : {Formatter.UrlHiperLinkFormatter(v.final)}</li>
                    </ul>
                </div>)}
            </div>;
        }
                
        case NaverCode.data.explorer.adExtension.HEADLINE.value : {     //추가제목
            const data = JSON.parse(props.data?.adextension || '') as AdExtensionType_HEADLINE;
            return <div className="border mt-1 p-3 ps-6 w-100">
                <SearchHighlight
                    key={1}
                    className={styles.link}
                    text={data?.headline}
                    search={props.search}
                    onClick={onClick} 
                />
                {data?.pin!==undefined && <>
                    <hr/>
                    <div className="adextention_list">  
                        <ul style={{margin:0}}><li>노출 가능 위치 지정 : {data?.pin}</li></ul>
                    </div>
                </>}
            </div>;
        }

        case NaverCode.data.explorer.adExtension.DESCRIPTION.value : {  //홍보문구
            const data = JSON.parse(props.data?.adextension || '') as AdExtensionType_DESCRIPTION;
            return <div className="border mt-1 p-3 ps-6 w-100">
                <SearchHighlight
                    key={1}
                    className={styles.link}
                    text={data?.description}
                    search={props.search}
                    onClick={onClick} 
                />
                <hr/>
                <div className="adextention_list">  
                    <ul style={{margin:0}}>
                        {data?.description && <li>홍보종류 : {data?.heading}</li>}
                    </ul>
                </div>
            </div>;
        }
                
        case NaverCode.data.explorer.adExtension.DESCRIPTION_EXTRA.value : { //추가설명
            const data = JSON.parse(props.data?.adextension || '') as AdExtensionType_DESCRIPTION_EXTRA;
            return <div className="border mt-1 p-3 ps-6 w-100">
                <SearchHighlight
                    key={1}
                    className={styles.link}
                    text={data?.description}
                    search={props.search}
                    onClick={onClick} 
                />
            </div>;
        }

        case NaverCode.data.explorer.adExtension.PHONE.value : {
            // const pc = pcBiz?.businessInfo as BusinessInfoType_PHONE;
            // const mo = moBiz?.businessInfo as BusinessInfoType_PHONE;
            // if(pc?.phone !== mo?.phone){₩
            //     return (
            //         <div className="adextention_list">  
            //             <ul style={{margin:0}}>
            //                 <li>PC : {pc?.phone.formatPhone()}</li>
            //                 <li>MO : {mo?.phone.formatPhone()}</li>
            //             </ul>
            //         </div>);
            // }else{
            //     return (<span>{pc?.phone.formatPhone()}</span>);
            // }
            break;
        }

        case NaverCode.data.explorer.adExtension.LOCATION.value : {
            // const pc = pcBiz?.businessInfo as BusinessInfoType_ADDRESS;
            // const mo = moBiz?.businessInfo as BusinessInfoType_ADDRESS;
            // return (<>
            //     { pcBiz?.channelKey !== moBiz?.channelKey &&
            //         <div className="adextention_list">  
            //             <ul style={{margin:0}}>
            //                 <li>PC : {this.locationFormater(pc)}</li>
            //                 <li>MO : {this.locationFormater(mo)}</li>
            //             </ul>
            //         </div>
            //     }
            //     { pcBiz?.channelKey === moBiz?.channelKey &&
            //         this.locationFormater(pc)
            //     }
            // </>)
            break;
        }

        case NaverCode.data.explorer.adExtension.NAVER_BLOG_REVIEW.value : {
            const data = JSON.parse(props.data?.adextension || '') as AdExtensionType_NAVER_BLOG_REVIEW;
            return <div className="border mt-1 p-3 ps-6 w-100">
                <img 
                    className="adExtensionListIMG" 
                    alt="썸네일" 
                    src={`https://search.pstatic.net/common/?src=${encodeURIComponent(data?.thumbnailUrl)}&type=f80_80`}
                />
                <div className="adextention_list" style={{display:"inline-block"}}> 
                    <ul style={{margin:0}}>
                        <li>포스트 제목 : 
                            <SearchHighlight
                                key={1}
                                className={styles.link}
                                text={data.title}
                                search={props.search}
                                onClick={onClick} 
                            />
                        </li>
                        <li>포스트 URL : {Formatter.UrlHiperLinkFormatter(data?.postUrl)}</li>
                    </ul>
                </div>
            </div>;
        }
        case NaverCode.data.explorer.adExtension.PRICE_LINKS.value : {
            const data = JSON.parse(props.data?.adextension || '') as AdExtensionType_PRICE_LINKS[];
            let num=0;
            return <div className="border mt-1 p-3 ps-6 w-100">
                {Array.isArray(data) && data?.map((v:AdExtensionType_PRICE_LINKS, index:number)=><div className="adextention_list" key={index}>
                    <span>가격링크 {++num}</span>
                    <ul style={{margin:0}}>
                        <li>가격제목 : 
                            <SearchHighlight
                                key={1}
                                className={styles.link}
                                text={v?.name}
                                search={props.search}
                                onClick={onClick} 
                            />
                        </li>
                        {v.price    && <li>가격 :   {v.price.addComma() } 원</li>}
                        {v.modifier && <li>가격수식 : {v.modifier}</li>}
                        {v.final    && <li>연결URL : {Formatter.UrlHiperLinkFormatter(v.final)}</li>}
                    </ul>
                </div>)}
            </div>;
        }
        case NaverCode.data.explorer.adExtension.CALCULATION.value : {
            const data = JSON.parse(props.data?.adextension || '') as AdExtensionType_CALCULATION;
            contents = <a href={data?.final} target='blank' >{data?.final}</a>;
            break;
        }
        //서브 이미지링크
        case NaverCode.data.explorer.adExtension.IMAGE_SUB_LINKS.value : {
            const data = JSON.parse(props.data?.adextension || '') as AdExtensionType_IMAGE_SUB_LINKS[];
            let num=0;
            return <div className="border mt-1 p-3 ps-6 w-100">
                {Array.isArray(data) && data?.map((v:AdExtensionType_IMAGE_SUB_LINKS, index:number)=><div key={index}>
                    <img 
                        alt="서브이미지"
                        className="adExtensionListIMG" style={{verticalAlign:"unset"}} 
                        src={`https://searchad-phinf.pstatic.net${v.imagePath}?type=THUMBNAIL`}
                    />
                    <div className="adextention_list" style={{display:"inline-block"}}>
                        <span>이미지형 서브링크 {++num}</span>
                        <ul style={{margin:0}}>
                            <li>링크이름 : 
                                <SearchHighlight
                                    key={1}
                                    className={styles.link}
                                    text={v.name}
                                    search={props.search}
                                    onClick={onClick} 
                                />
                            </li>
                            <li>연결URL : {Formatter.UrlHiperLinkFormatter(v.final)}</li>
                        </ul>
                    </div>
                </div>)}
            </div>;
        }
        //비디오
        case NaverCode.data.explorer.adExtension.NAVER_TV_VIDEO.value : {
            const data = JSON.parse(props.data?.adextension || '') as AdExtensionType_NAVER_TV_VIDEO;
            return <div className="border mt-1 p-3 ps-6 w-100">
                <SearchHighlight
                    key={1}
                    className={styles.link}
                    text={data.description}
                    search={props.search}
                    onClick={onClick} 
                />
                <img
                    alt="썸네일"
                    className="adExtensionListIMG"
                    style={{verticalAlign:"unset"}} 
                    src={`https://searchad-phinf.pstatic.net${data?.imagePath}?type=THUMBNAIL`}
                />
            </div>;
        }
        
        //// 미검증 포맷
        //PROMOTION, CATALOG_PROMOTION
        case NaverCode.data.explorer.adExtension.PROMOTION.value : //추가홍보문구
        case NaverCode.data.explorer.adExtension.CATALOG_PROMOTION.value : {
            const data = JSON.parse(props.data?.adextension || '') as AdExtensionType_PROMOTION;
            return <div className="adextention_list border mt-1 p-3 ps-6 w-100">
                <ul style={{margin:0}}>
                    <li>문구1 : {data?.basicText}</li>
                    {data?.additionalText && <li>문구2 : ${data?.additionalText}</li>}
                </ul>
            </div>;
            break;
        }
        //CATALOG_RELEASE_DATE
        case NaverCode.data.explorer.adExtension.CATALOG_RELEASE_DATE.value : {
            const data = JSON.parse(props.data?.adextension || '') as AdExtensionType_CATALOG_RELEASE_DATE;
            contents = Formatter.ChangeDateFormat(data?.releaseDate, '출시일 : yyyy.MM.dd');
            break;
        }
        
        //CATALOG_DETAIL
        case NaverCode.data.explorer.adExtension.CATALOG_DETAIL.value : {
            const data = JSON.parse(props.data?.adextension || '') as AdExtensionType_CATALOG_DETAIL;
            contents = data?.detail;
            break;
        }
        
        //CATALOG_BRAND_MESSAGE
        case NaverCode.data.explorer.adExtension.CATALOG_BRAND_MESSAGE.value : {
            const data = JSON.parse(props.data?.adextension || '') as AdExtensionType_CATALOG_BRAND_MESSAGE;
            contents = data?.text;
            break;
        }
        
        //CATALOG_MOVIE, CATALOG_EVENT
        case NaverCode.data.explorer.adExtension.CATALOG_MOVIE.value : 
        case NaverCode.data.explorer.adExtension.CATALOG_EVENT.value : {
            const data = JSON.parse(props.data?.adextension || '') as AdExtensionType_CATALOG_MOVIE;
            contents = <img alt="썸네일" src={data?.thumbnail}>${data?.dittoId}</img>;
            break;
        }
        // default : return undefined;
            // return (<div style={{color:"red"}}>* 해당 확장소재 유형은 준비되지 않았습니다. 관리자에게 문의 바랍니다! *</div>);
    }

    return <div className="border mt-1 p-3 ps-6 w-100">
        <SearchHighlight
            key={1}
            className={styles.link}
            text={props.data?.adextensionId}
            search={props.search}
            onClick={onClick} 
        />
        <div>{contents}</div>
    </div>;
}