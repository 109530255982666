import _ from 'lodash';
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { AdminService } from "../../../common/services";
import { DatshBoardStatResType, DatshBoardStatTableType } from "../../../common/services/models/DashboardTypes";
import { BookmarkPutType, BookmarkResType } from "../../../common/services/models/admin/BookmarkTypes";
import { useUserContext } from "../../../contexts/UserContext";
import DragList from "../../modules/dragDrop/DragList";
import DashboardDateRange from "./DashboardDateRange";
import DashboardSetting from "./DashboardSetting";
import ReportCard from "./ReportCard";
import { loadStatResportKakao, loadStatResportNaver, mergeWeekData } from "./ReportDashboardData";
import ReportNone from "./ReportNone";


interface ReportDashboardProps{
}

const defaultRange = {
    week1 :{
        startDate: new Date().addDays(-7),
        endDate: new Date().addDays(-1)
    },
    week2 :{
        startDate: new Date().addDays(-15),
        endDate: new Date().addDays(-8)
    },
}

const ReportDashboard:React.FC<ReportDashboardProps> = (props)=>{
    const userContext = useUserContext();
    const [useStatData, setUseStatData] = useState<DatshBoardStatTableType[]>([]);
    const [bookmark, setBookmark] = useState<BookmarkResType[]>([]);        //즐겨찾기 목록
    const [deBookmark, setDeBookmark] = useState<BookmarkResType[]>([]);    //즐겨찾기 외 목록
    const [bookmarkAll, setBookmarkAll] = useState<BookmarkResType[]>([]);  //전체 데이터
    const [loading, setLoading] = useState<boolean>(false);
    const [dateRange1, setDateRange1] = useState<{startDate:Date, endDate:Date}>(defaultRange.week1);
    const [dateRange2, setDateRange2] = useState<{startDate:Date, endDate:Date}>(defaultRange.week2);

    const loadStatResport = async (data?:BookmarkResType[], lock?:boolean)=>{
        data = data || bookmark;
        if(!data?.length){ return; }
        
        //새로운 광고주가 있는 경우만 호출합니다.
        const ids:string[] = lock ? bookmark.map(v=>v.magId.toString())
            : bookmark.filter((v1)=>!useStatData.find((v2)=>v2.magId===v1.magId)).map(v=>v.magId.toString());
        if(ids.length===0){ return; }   // 새로운 광고주 없음

        setLoading(true);
        const [naver1,naver2,kakao1,kakao2]:DatshBoardStatResType[][] = await Promise.all([
            loadStatResportNaver(ids, dateRange1.startDate, dateRange1.endDate, userContext),
            loadStatResportNaver(ids, dateRange2.startDate, dateRange2.endDate, userContext),
            loadStatResportKakao(ids, dateRange1.startDate, dateRange1.endDate, userContext),
            loadStatResportKakao(ids, dateRange2.startDate, dateRange2.endDate, userContext),
        ]);

        const naver:DatshBoardStatTableType[] = mergeWeekData((data||bookmark), naver1, naver2);
        const kakao:DatshBoardStatTableType[] = mergeWeekData((data||bookmark), kakao1, kakao2);

        const tableData:DatshBoardStatTableType[] = _.merge(naver, kakao);
        setUseStatData((prev)=>[...(prev.filter(v=>!ids.includes(v.magId.toString()))), ...tableData]);
        setLoading(false);
    }

    useEffect(()=>{
        loadStatResport(undefined, true);
    // eslint-disable-next-line
    }, [dateRange1, dateRange2]);

    useEffect(()=>{
        loadStatResport();
    // eslint-disable-next-line
    }, [bookmark]);

    useEffect(()=>{
        loadBookmark();
        // eslint-disable-next-line
    }, []);

    /** 즐겨찾기 데이터 로딩 */
    const loadBookmark = ()=>{
        userContext.modalLoading.show();
        AdminService.bookmark.getList()
        .then((res)=>{
            if(res.status===200){
                const data:BookmarkResType[] = res.data.result || [];
                const tmp:BookmarkResType[] = data.map((v:BookmarkResType)=>({...v, bookmarkOrder:v.bookmarkYn==='Y' ? v.bookmarkOrder : 1000}))
                    .sort((a,b)=>(a.bookmarkOrder===1000 && b.bookmarkOrder===1000) ? (a.magName<b.magName ? -1 : 1) : (a.bookmarkOrder < b.bookmarkOrder ? -1 : 1));
                setBookmark(tmp.filter((v)=>v.bookmarkYn==='Y'));
                setDeBookmark(tmp.filter((v)=>v.bookmarkYn==='N'));
                setBookmarkAll(tmp);
            }
        })
        .catch((e)=>{ 
            userContext.alertMessage.add({
                variant:"danger",
                title:"즐겨찾기 데이터 조회 오류",
                body:<>즐겨찾기 데이터 조회 중 오류가 발생했습니다.<br />{AdminService.ErrorMessage(e)}</>
            });
        })
        .finally(()=>{ userContext.modalLoading.hide(); });
    }

    /** 즐겨찾기 변경 */
    const onChangeBookmark = async (value:BookmarkResType[]):Promise<any>=>{
        const body:BookmarkPutType = {
            updates: value.map((v,index)=>({magId:v.magId, order:index})),
        }
    
        userContext.modalLoading.show();
        return await AdminService.bookmark.put({...body}).then((res) => {
            const ids:number[] = value.map((v)=>v.magId);
            setBookmark(value);
            setDeBookmark(bookmarkAll.filter((v)=>!ids.includes(v.magId)));
            return res;
        })
        .catch((e)=>{ 
            userContext?.alertMessage.add({
                variant:'danger', 
                title:'즐겨찾기 정보 수정 오류',
                body: <>
                    [즐겨찾기 수정] 요청에 실패하였습니다.<br/>
                    {AdminService.ErrorMessage(e)}
                </>,
            });
            throw new Error(e);
        })
        .finally(()=>{ userContext.modalLoading.hide(); });
    }

    const bookmarkUpdate = (id:number, useYn:string)=>{
        if(useYn==='Y' && bookmark.length >= 5){
            userContext.toastMessage.add({bg:'danger', title:'즐겨찾기 추가 오류', body:<>즐겨찾기는 최대 5개까지 등록하실 수 있습니다.<br/>등록된 즐겨찾기를 제외 후 다시 시도해 주세요.</>})
            return;
        }
        const tmp:BookmarkResType[] = useYn==='Y' ? bookmark : bookmark.filter((v)=>v.magId!==id);
        const item:BookmarkResType|undefined = bookmarkAll.find((v)=>v.magId===id);
        if(item && useYn==='Y'){ tmp.push(item); }
        onChangeBookmark(tmp);
    }

    /** 성과리포트에서 정렬 변경 */
    const onChange = (value:BookmarkResType[])=>{
        // const tmp:BookmarkResType[] = value.map(v1=>bookmark.find(v2=>v1.magId===v2.magId)).filter(v=>v!==undefined) as BookmarkResType[];
        // const ids:number[] = bookmark.map((v)=>v.magId);
        // const tmp:BookmarkResType[] = value.filter((v)=>ids.includes(v.magId));
        // console.log({ids, tmp, value, bookmark});
        onChangeBookmark(value);
    }

    return <>
        <div className='d-flex'>
            <Container className='justify-content-start h-50px p-0'>
                <div className='mb-3'>
                    <div className='fw-bolder fs-3'>성과 대시보드  {bookmark.length}/5</div>
                    <div className='text-gray-600'>조회 기간 : <CdateFormatter {...dateRange1}/> vs 비교 기간 : <CdateFormatter {...dateRange2}/></div>
                </div>
            </Container>
            <Container className='justify-content-end d-flex text-right p-0 pt-2 pb-2 h-50px'>
                <DashboardDateRange defaultValue={{week1:dateRange1, week2:dateRange2}} 
                    onChange={(v1,v2)=>{ if(v1 && v2){ setDateRange1(v1); setDateRange2(v2); }}} />
                <DashboardSetting bookmark={bookmark} deBookmark={deBookmark} onChange={(v)=>onChangeBookmark(v)}/>
            </Container>
        </div>

        {!bookmark.length && <ReportNone />}

        <div className='mb-6'>
            <DragList<BookmarkResType>
                hideIcon={true}
                options={bookmark}
                onChange={(v)=>onChange(v)}
                formatter={(v)=><ReportCard info={v} value={useStatData.find((v2)=>v2.magId===v.magId)} loading={loading} bookmarkRemove={(v)=>bookmarkUpdate(v, 'N')} />}
            />
        </div>
    </>
}
export default ReportDashboard;

/** 날짜 포맷 */
const CdateFormatter:React.FC<{startDate:Date, endDate:Date}> = (props)=><>{props.startDate.format('yyyy.MM.dd')}~{props.endDate.format('yyyy.MM.dd')}</>
