import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import UserSelect, { AccountType } from '../../../common/helpers/UserSelect';
import AdminService from '../../../common/services/AdminService';
import { EstimateAccountClientType, EstimateAccountGroupType } from '../../../common/services/models/admin/EstimateTypes';
import { useUserContext } from '../../../contexts/UserContext';
import CustomSelect, { CustomSelectOption } from '../../modules/select/CustomSelect';
import MediaTypeIcon from '../../modules/symbol/MediaTypeIcon';

// import style from './advertiserList.module.scss';

interface AccountSelectProps{
    onChange?:(accountGroup:EstimateAccountGroupType, accountClient:EstimateAccountClientType)=>void;
}

const AccountSelectOld = (props:AccountSelectProps)=> {
    const userContext = useUserContext();
    const [useSelect, setUseSelect] = useState<AccountType|undefined>({});
    const [useData, setUseData] = useState<EstimateAccountGroupType[]>([]);
    useEffect(()=>{
        const info = UserSelect.account.get();
        setUseSelect(info);

        AdminService.getMyAccountGroups().then((v:EstimateAccountGroupType[])=>{
            setUseData(v);
            userContext.userSelect.accountState?.setList(v);
            //선택항목이 목록에 없는 경우, 선택정보 삭제
            if(info?.accountGroup?.magId && info?.accountClient?.mediaType && info?.accountClient?.clientId){
                userContext.userSelect.accountState?.select(info?.accountGroup?.magId, info?.accountClient?.mediaType, info?.accountClient?.clientId, v);
                const isExist = v.reduce((prev, v1):boolean=>{
                    if(v1.magId!==info.accountGroup?.magId){ return prev; }
                    return prev || v1.clients?.some((v2)=>v2.clientId===info.accountClient?.clientId) || false;
                },false);
                if(!isExist){
                    UserSelect.account.clear();
                    userContext.userSelect.accountState?.select(0,'','');
                }
            }
        });
    // eslint-disable-next-line
    },[]);

    // const getName=(id:string, mediaCode:string, mediaClientId:string)=>{
    //     return useData.find((v)=>v.id===id)?.accessInfos.find((v)=>v.mediaCode===mediaCode && v.mediaClientId===mediaClientId)?.mediaClientName ;
    // }

    // const navigate = useNavigate();
    const onChange=(value:string, label?:string|React.ReactNode)=>{
        // 선택항목 저장
        const tmp:string[] = value.split("|");
        const accountGroup = useData.find((v)=>v.magId.toString()===tmp[0]);
        const accountClient = accountGroup?.clients.find((v)=>v.mediaType===tmp[1] && v.clientId===tmp[2]);
        userContext.userSelect.accountState?.select(tmp[0].toNumber(), tmp[1], tmp[2]);

        if( !_.isEqual(useSelect, {accountGroup, accountClient}) ){
            UserSelect.account.set(accountGroup, accountClient);
            UserSelect.deviceType.set(["P","M"]);   //광고주 변경하면 디바이스 선택정보 초기화
            setUseSelect({accountGroup, accountClient});
            userContext.toastMessage.add({title:"광고주계정 선택", body: (<div>[{label}] 계정을 선택하셨습니다!</div>)});

            // 페이지 초기화 - 매체코드가 동일하면 현재페이지 유지 - Explorer 오작동 이슈 있음 현행 유지 시키세요
            // if(useSelect?.accountClient?.mediaType !== accountClient?.mediaType || window.location.search === "/" || window.location.search === ""){ //대시보드 잦은 재로딩 방지
                userContext.navigate("/dashboard");
            // }else{
            //     userContext.navigate(0);
            // }
        }
    }

    const data:CustomSelectOption[] = [];
    useData.forEach((v1:EstimateAccountGroupType)=>{

        data.push({
            value:v1.magId.toString(),
            label: v1.magName,
            type: "header",
        });
        const tmp:CustomSelectOption[] = v1.clients.map((v2):CustomSelectOption=>{
            return {
                value:[v1.magId, v2.mediaType, v2.clientId].join("|"),
                label:(<MediaTypeIcon code={v2.mediaType} label={v2.name} viewLabel={true} />)
            }
        });
        data.push(...tmp);
    });

    const selectAccount:AccountType|undefined  = userContext.userSelect.accountState?.account || useSelect;
    return (
        <CustomSelect variant='light' menuStyle={{maxHeight:'calc(100vh - 90px)'}}
            style={{width:'100%'}}
            value={[selectAccount?.accountGroup?.magId, selectAccount?.accountClient?.mediaType, selectAccount?.accountClient?.clientId].join('|')}
            options={data} placeholder="광고주 계정 선택" onChange={onChange} />
    );
}

export default AccountSelectOld;
