import { useState } from "react";
import { AdGroupSimpleResType } from "../services/models/kakao/AdGroupTypes";
import { CampaignSimpleResType } from "../services/models/kakao/CampaignTypes";
import { KakaoService } from "../services";
import { MediaResResultType, TreeResDataType } from "../services/models/ResponseTypes";

export type KakaoTreeResType = TreeResDataType<CampaignSimpleResType,AdGroupSimpleResType>;

export interface KakaoTreeStateType{
    set: (data:KakaoTreeResType[])=>void;
    load: ()=> Promise<KakaoTreeResType[]>;
    data: KakaoTreeResType[];
}

const KakaoTreeStorage = class {
    public state = ():KakaoTreeStateType=>{
        const [data, setData] = useState<KakaoTreeResType[]>([]);
        const load = async ()=>{ return this.load().then((data)=>{setData(data); return data;}); }
        const set = (data:KakaoTreeResType[])=>{ setData(data); }
        return {set, load, data};
    }

    /** 데이터 로딩 */
    private async load():Promise<KakaoTreeResType[]>{
        const campaign:CampaignSimpleResType[] = await KakaoService.campaign.getSimpleList<MediaResResultType<CampaignSimpleResType>>()
        .then(((res) => {
            const data:CampaignSimpleResType[] = res.data.result || [];
            return data.sort((a,b)=>a.campaignName < b.campaignName ? -1 : 1 );
        }));
    
        const adgroup:AdGroupSimpleResType[] = await KakaoService.adgroup.getSimpleList<MediaResResultType<AdGroupSimpleResType>>()
        .then(((res) => {
            const data:AdGroupSimpleResType[] = res.data.result || [];
            return data.sort((a,b)=>a.adGroupName < b.adGroupName ? -1 : 1 );
        }));

        const data:KakaoTreeResType[] = campaign
            .map((v)=>({
                data:v,
                children: adgroup.filter((v2)=>v.campaignId===v2.campaignId),
            }));
        return data;
    }
}

export default KakaoTreeStorage;