import { useEffect, useState } from "react";
import AuthConfig from "../../../../app/common/conf/AuthConfig";
import Config from "../../../../app/common/conf/Config";
import AuthService from "../../../../app/common/services/AuthService";


const Timmer = ()=>{
    const [timmer,setTimmer] = useState<string>("00:00")

    useEffect(()=>{
        const tm = setInterval(()=>{
            const info = AuthService.storage.get();
            // const time = new Date().format('HH:mm:ss');
            const time = Math.abs( info?.access_time ? (info.access_time + AuthConfig.expireTime*1000 - new Date().getTime()) : 0) / 1000;
            const mm = Math.floor(time/60).zeroFill(2);
            const ss = Math.floor(time % 60).zeroFill(2);
            setTimmer(`${mm}:${ss}`);

            //타임이 0이 되면 새로고침으로 로그인페이지 이동
            if(!time){
                //페이지 캐싱
                sessionStorage.setItem("pageUrl", document.location.href,)
                document.location.replace(Config.project.path);
            }
        },200);

        return ()=>{ clearInterval(tm); }
    },[]);


    return (<div style={{margin:"0.5em 0em",fontFamily:"helvetica"}} title="세션만료 예정 남은 시간">
        <i className="bi bi-clock" style={{marginRight:"0.5em"}} /> 
        {timmer}
    </div>);
}

export default Timmer; 
