import clsx from "clsx";
import { useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import TextButton from "../../modules/layer/TextButton";
import { Confirm } from "../../modules/message";
import { BizMoneyAccountGroupType, BizMoneyDataType } from "../components/bizmoney/BizMoneyLibs";
import { BizMoneyControlLayer } from "../components/bizmoney/BizMoneySyncButton";
import CustomBaseCard from "../modules/card/CustomBaseCard";
import MediaTypeIcon from "../modules/symbol/MediaTypeIcon";
import { BizMoneyResultValue } from "../settings/profile/bizmoney/AccountGroupRow";
import styles from './style.module.scss';


const BizMoneyList:React.FC = ()=>{
    const [useData, setUseData] = useState<BizMoneyAccountGroupType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [sync, setSync] = useState<boolean>(false);

    return <CustomBaseCard style={{height:'320px'}} title='잔액 현황'>
        <div className="d-inline" style={{float:'right', marginTop:'-3em'}}>
            <BizMoneyControlLayer
                state={[useData, setUseData]}
                loadingStatus={[loading, setLoading]}
                syncStatus={[sync, setSync]}
            />
        </div>
        <div className="scroll" style={{height:'240px'}} >
            <table className={clsx(styles.table, styles.bizmoney)}>
                <thead style={{zIndex:1}}>
                    <tr className="bg-body">
                        <th className='text-left'>광고주</th>
                        <th className='text-left'>자산</th>
                        <th className='text-right'>총 잔액</th>
                        <th className="ps-0 pe-0">상세</th>
                    </tr>
                </thead>
                <tbody>
                    {loading && <tr><td className="text-center h-200px" colSpan={6}><Spinner animation="border" variant={"secondary"} role='status' /></td></tr>}
                    {!loading && !useData?.length && <tr><td className="text-center h-200px" colSpan={4}>자산 목록이 없습니다.</td></tr>}
                    {!loading && useData.map((mag)=>{
                        return mag.clients.map((client)=>{
                            const biz:BizMoneyDataType|undefined = client.result;
                            return <tr key={client.clientId}>
                                <td>{mag.magName}</td>
                                <td><MediaTypeIcon code={client.mediaType} label={client.name} viewLabel={true} /></td>
                                <td className="text-right"><BizMoneyResultValue data={biz} value={biz?.total?.addComma() || 0} client={client}/></td>
                                <td className="text-center p-0"><PopupDetail data={biz} disabled={mag.magUseYn==='N' || client.useYn==='N' || biz?.status!=='CTS0003' || biz?.loaded===false} /></td>
                            </tr>;
                        });
                    })}
                </tbody>
            </table>
        </div>
    </CustomBaseCard>
}
export default BizMoneyList;


const PopupDetail:React.FC<{data?:BizMoneyDataType, disabled?:boolean}> = (props)=>{
    const [show, setShow] = useState<boolean>(false);
    return <>
        <TextButton disabled={!props.data || props.disabled} onClick={()=>{setShow(true)}}><i className="bi bi-list-ul"/></TextButton>

        <Confirm 
            title='잔액 상세' 
            state={[show, setShow]}
            cancel={{onClick:()=>{setShow(false);}, title:'닫기'}}
            size="sm"
        >
            <div className="text-gray-600 float-right text-right mb-2">
                잔액 수집 일시 : {props.data?.successSyncDateTime ? new Date(props.data?.successSyncDateTime).format('yyyy.MM.dd HH:mm') : '0000.00.00 00:00'}
            </div>
            <Table className='table border border-gray-300 mb-0 text-right'>
                <colgroup>
                    <col style={{width: '40%'}}/>
                    <col/>
                </colgroup>
                <tbody>
                    <tr className="border border-gray-300">
                        <th className='fw-bold text-gray-600 bg-light p-3' >유상 잔액</th>
                        <td className='p-3'>{props.data?.cash?.addComma()}</td>
                    </tr>
                    <tr className="border border-gray-300">
                        <th className='fw-bold text-gray-600 bg-light p-3'>무상 잔액</th>
                        <td className='p-3'>{props.data?.freeCash?.addComma()}</td>
                    </tr>
                    <tr className="border border-gray-300">
                        <th className='fw-bold text-gray-600 bg-light p-3'>총 잔액</th>
                        <td className='p-3'>{(props.data?.total || 0).addComma()}</td>
                    </tr>
                </tbody>
            </Table>
        </Confirm>
    </>;
}