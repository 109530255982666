import axios from 'axios';
import { SsoInstrospectResType, SsoTokenResType, SsoUserinfoResType } from '../models/SsoModels';
import {SsoServer} from '../../axios';
import SsoConfig from '../../conf/SsoConfig';
import UserSettings from '../../helpers/UserSettings';
import base64 from 'base-64';

export default class SsoHelper {

    /**
     * 토큰의 유효성을 확인합니다.
     * @returns true-정상, false-만료
     */
    public static async introspect2(token?:string):Promise<boolean>{
        if(!token){ return false; }
        const formData = new FormData();
        formData.append("token", token);
        const credentials = base64.encode(`${SsoConfig.clientId}:${SsoConfig.secretKey}`);

        const status = await SsoServer.post<SsoInstrospectResType>(
            `${SsoConfig.introspect}?token=${token}`,
            formData,
            {
                headers: {
                    // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    // 'Authorization' : `Bearer ${credentials}`,
                    'Content-Type': 'application/json;charset=UTF-8',
                    'X-Authorization' : `Basic ${credentials}`,
                }
            }
        ).then((res)=>res.data);
        return status.active;
    }

    /**
     * token check - 배석진님 소스
     * @param token 
     * @returns 
     */
    public static async introspect(token?:string):Promise<boolean>{
        if(!token){ return false; }
        const credentials = base64.encode(`${SsoConfig.clientId}:${SsoConfig.secretKey}`);
        const status = await fetch(`${SsoConfig.introspect}?token=${token}`, {
            method: 'POST',
            headers: { "X-Authorization": `Basic ${credentials}` }
        })
        .then(res => res.body);
        return !status?.locked;
    }

    /**
     * SSO 서버로 로그인 요청합니다.
     */
    public static authorize(): void {
        const theme = UserSettings.theme.getThemeMode();
        const query = {
            client_id: SsoConfig.clientId,
            scope: SsoConfig.scope,
            response_type: 'code',
            redirect_uri: SsoConfig.redirectUri,
            theme_mode: theme,  //portal theme 처리용
        }
        const url = `${SsoConfig.authorizationUri}?${new URLSearchParams(query)}`;
        window.location.replace(url);
    }

    public static async getTokenFromCode(code: string): Promise<SsoTokenResType> {
        const url = new URL(SsoConfig.tokenUri);
        url.search = new URLSearchParams({
            grant_type: 'authorization_code',
            code: code,
            client_id: SsoConfig.clientId,
            client_secret: SsoConfig.secretKey,
            redirect_uri: SsoConfig.redirectUri,
        }).toString();
        return await axios.post<SsoTokenResType>(url.href).then(res => res.data);
    }

    /**
     * refresh token을 이용하여 토큰을 갱신합니다.
     * @param token : 현재 토큰정보
     * @returns :갱신된 토큰정보 | null
     */
    public static async tokenRefresh(token:SsoTokenResType):Promise<SsoTokenResType|null>{
        // const formData = new FormData();
        // formData.append("grant_type", 'refresh_token');
        // formData.append("refresh_token", token.refresh_token);
        // const bearer = base64.encode(`${SsoConfig.clientId}:${SsoConfig.secretKey}`);

        // const config = {
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        //         // 'Authorization' : `Bearer ${bearer}`,
        //         'X-Authorization' : `Basic ${bearer}`,
        //     }
        // }

        // const res = await axios.post<SsoTokenResType>(SsoConfig.tokenUri, formData, config);
        // return res.status===200 ? res.data : null;

        const url = new URL(SsoConfig.tokenUri);
        url.search = new URLSearchParams({
            grant_type: 'refresh_token',
            refresh_token: token.refresh_token,
            client_id: SsoConfig.clientId,
            client_secret: SsoConfig.secretKey,
            redirect_uri: SsoConfig.redirectUri,
        }).toString();
        const res = await axios.post<SsoTokenResType>(url.href);
        return res.status===200 ? res.data : null;
    }

    //
    public static logout(userinfo:SsoUserinfoResType){
        //http://sso.dbase.co.kr/userLogout?userName=&clientId=<클리이언트아이디>
        const url = new URL(SsoConfig.logoutUri);
        url.search = new URLSearchParams({
            'logoutKey': userinfo.authentication_id,
            'clientId': SsoConfig.clientId,
        }).toString();
        // url.search = new URLSearchParams({
        //     'userName': userinfo.name,
        //     'clientId': SsoConfig.clientId,
        // }).toString();
        document.location.replace(url.href);
    }

    public static async getUserInfo(token:string): Promise<SsoUserinfoResType> {
        const res = await SsoServer.post(SsoConfig.userInfoUri, null, {
            headers: {
                // accept: 'application/json',
                Authorization: `Bearer ${token}`
            },
        });
        return res.status === 200 ? res.data : undefined;
    }


}
