import NaverHelperComponent from './NaverHelperComponent';

/** 네이버 Criteria Helper */
export default class CriteriaHelper extends NaverHelperComponent{
    static path="/media/criteria"; //기본 Path
    static stat='/performance/criteria';   //실적데이터
    static performances='/performances/criteria';   //실적데이터
    static downloadPath='/api/naversa/criteria';

}
