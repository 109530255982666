import clsx from "clsx";
import { CSSProperties, useEffect, useState } from "react";
import ThemeConfig from "../../../common/conf/ThemeConfig";
import { BookmarkResType } from "../../../common/services/models/admin/BookmarkTypes";
import { useUserContext } from "../../../contexts/UserContext";
import DragAndDropList from "../../modules/dragDrop/DragAndDropList";
import { ButtonDropDown } from "../../modules/dropdown/ButtonDropDown";

interface DashboardSettingProps{
    variant?: string;
    className?: string;
    style?: CSSProperties;
    children?: React.ReactNode;
    bookmark?: BookmarkResType[];
    deBookmark?: BookmarkResType[];
    onChange?: (data:BookmarkResType[])=>Promise<any>;
}

const DashboardSetting:React.FC<DashboardSettingProps> = (props)=>{
    const userContext = useUserContext();
    const btnCfg = ThemeConfig.Button.Sub;
    const [bookmark, setBookmark] = useState<BookmarkResType[]>([]);        //즐겨찾기 목록
    const [deBookmark, setDeBookmark] = useState<BookmarkResType[]>([]);    //즐겨찾기 외 목록

    useEffect(()=>{
        setBookmark([...(props.bookmark || [])]);
        setDeBookmark(props.deBookmark || []);
    }, [props.bookmark, props.deBookmark]);

    const onChange = (value:BookmarkResType[])=>{
        setBookmark(value);
        props.onChange && props.onChange(value);
    }

    const bookmarkUpdate = (id:number, useYn:string)=>{
        if(useYn==='Y' && bookmark.length >= 5){
            userContext.toastMessage.add({bg:'danger', title:'즐겨찾기 추가 오류', body:<>즐겨찾기는 최대 5개까지 등록하실 수 있습니다.<br/>등록된 즐겨찾기를 제외 후 다시 시도해 주세요.</>})
            return;
        }
        const tmp1:BookmarkResType[] = useYn==='Y' ? bookmark : bookmark.filter((v)=>v.magId!==id);
        const tmp2:BookmarkResType[] = useYn==='N' ? deBookmark : deBookmark.filter((v)=>v.magId!==id);
        const item:BookmarkResType|undefined = useYn==='Y' ? deBookmark.find((v)=>v.magId===id) : deBookmark.find((v)=>v.magId===id);
        if(item){
            (useYn==='Y') ? tmp1.push(item) : tmp2.push(item);
        }
        props.onChange && props.onChange(tmp1)
        .then((res)=>{
            setBookmark(tmp1);
            setDeBookmark(tmp2);
        });
    }

    return <>
        <ButtonDropDown
            className='d-inline'
            title= {props.children || <i className="bi bi-star fs-4 p-0" />}
            button={{
                className: clsx(props.className, btnCfg.className),
                ...{['data-type']:'icon'},
            }}
            menu={{
                className:'p-4',
            }}
        >
            <div className='scroll-y' style={{maxHeight:'600px', minWidth:'180px'}}>
                <div className='mb-6'>
                    <div className="fw-bold p-2">즐겨찾기</div>
                    <hr className='mt-1 mb-1'/>
                    <DragAndDropList<BookmarkResType>
                        options={bookmark}
                        onChange={(v)=>onChange(v)}
                        formatter={(v)=><div className='d-flex pt-2 pb-2 m-0 text-nowrap'>
                            <div className="ps-3 me-9"><i className="bi bi-grip-vertical" />{v.magName}</div>
                            <div className="cursor-pointer position-absolute" onClick={()=>{bookmarkUpdate(v.magId, 'N')}} style={{right:'0.5em'}}>
                                <i className="bi bi-star-fill text-warning fs-4" />
                            </div>
                        </div>}
                    />
                </div>

                <div className='mb-6'>
                    <div className="fw-bold p-2">광고주 목록</div>
                    <hr className='mt-1 mb-1'/>
                    {deBookmark.map((v,index)=>(
                        <div key={index} className='d-flex pt-2 pb-2 m-0 text-nowrap'>
                            <div className="ps-3 me-9">{v.magName}</div>
                            <div className="cursor-pointer position-absolute" onClick={()=>{bookmarkUpdate(v.magId, 'Y')}} style={{right:'0.5em'}}>
                                <i className="bi bi-star fs-4" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </ButtonDropDown>
    </>
}
export default DashboardSetting;

