

/** 리포트 결과 배열타입 */
export interface StatReportResultArrayType<T=StatReportResType, V=StatReportInfoType> {
    info: V;
    performance: T;
}

/** Naver - 리포트 결과 타입 */
export interface StatReportResultType<T=StatReportResType> {
    info?: StatReportInfoType;
    total?: T[];
    daily?: T[];
    performances?: T[];
}

/** Explorer 상세보기 타입 */
export interface StatReportInfoType{
    customerId: number,
    businessChannelId?: string,
    businessChannelContents?: string,
    businessChannelName?: string,
    businessChannelTypeCode?: number,
    businessChannelTypeName?: string,
    campaignId?: string,
    campaignName?: string,
    campaignTypeCode?: number,
    campaignTypeName?: string,
    adgroupId?: string,
    adgroupName?: string,
    adgroupTypeCode?: number,
    adgroupTypeName?: string,
    adgroupContentType?: string,
    adId?: string,
    adTypeCode?: number,
    adValue1?: string,
    adValue2?: string,
    adValue3?: string,
    adValue4?: string,
    adValue5?: string,
    adValue6?: string,
    keywordId?: string,
    keyword?: string,
    keywordTypeCode?: number,
    keywordTypeName?: string,
    adextensionId?: string,
    adextensionTypeCode?: number,
    adextensionTypeName?: string,
    adextension?: string,
    referenceKey?: string,
    referenceData?: string,
    searchKeyword?: string,
    searchKeywordId?: string,
    mediaCode?: string,
    mediaName?: string,
    ownerId?: string,

    criterionDictionaryCode?: string,
    criterionId?: string,
    criterionTypeCode?: string,
    criterionTypeName?: string,
}

/** 리포트 데이터 타입, key - entry ID or Date */
export interface StatReportResType{
    key:string;
    impressionCount: number;
    clickCount: number;
    adCost: number;
    adRank: number;
    videoCount: number;
    dcPurchaseCount: number;
    dcPurchaseValue: number;
    dcSubscriptionCount: number;
    dcSubscriptionValue: number;
    dcCartCount: number;
    dcCartValue: number;
    dcAdvPurchaseCount: number;
    dcAdvPurchaseValue: number;
    dcOthersCount: number;
    dcOthersValue: number;
    icPurchaseCount: number;
    icPurchaseValue: number;
    icSubscriptionCount: number;
    icSubscriptionValue: number;
    icCartCount: number;
    icCartValue: number;
    icAdvPurchaseCount: number;
    icAdvPurchaseValue: number;
    icOthersCount: number;
    icOthersValue: number;

    purchaseCount?: number;
    purchaseValue?: number;
    subscriptionCount?: number;
    subscriptionValue?: number;
    averageAdRank?: number;
    clickThroughRate?: number;
    costPerClick?: number;
    conversionRate?: number;
    costPerAction?: number;
    costPerSubscription?: number;
    returnOnAdSpend?: number;

    cartCount?: number;
    cartValue?: number;
    advPurchaseCount?: number;
    advPurchaseValue?: number;
    othersCount?: number;
    othersValue?: number;
}


/**
 * 리포트 컬럼 처리용 키
 */
export enum StatReportResKeys{
    impressionCount         = "impressionCount",
    clickCount              = "clickCount",
    adCost                  = "adCost",
    adRank                  = "adRank",
    videoCount              = "videoCount",
    dcPurchaseCount         = "dcPurchaseCount",
    dcPurchaseValue         = "dcPurchaseValue",
    dcSubscriptionCount     = "dcSubscriptionCount",
    dcSubscriptionValue     = "dcSubscriptionValue",
    dcCartCount             = "dcCartCount",
    dcCartValue             = "dcCartValue",
    dcAdvPurchaseCount      = "dcAdvPurchaseCount",
    dcAdvPurchaseValue      = "dcAdvPurchaseValue",
    dcOthersCount           = "dcOthersCount",
    dcOthersValue           = "dcOthersValue",
    icPurchaseCount         = "icPurchaseCount",
    icPurchaseValue         = "icPurchaseValue",
    icSubscriptionCount     = "icSubscriptionCount",
    icSubscriptionValue     = "icSubscriptionValue",
    icCartCount             = "icCartCount",
    icCartValue             = "icCartValue",
    icAdvPurchaseCount      = "icAdvPurchaseCount",
    icAdvPurchaseValue      = "icAdvPurchaseValue",
    icOthersCount           = "icOthersCount",
    icOthersValue           = "icOthersValue",

    //컬럼 구분전용
    key                     = "key",
    purchaseCount           = "purchaseCount",
    purchaseValue           = "purchaseValue",
    subscriptionCount       = "subscriptionCount",
    subscriptionValue       = "subscriptionValue",
    averageAdRank           = "averageAdRank",
    clickThroughRate        = "clickThroughRate",
    costPerClick            = "costPerClick",
    conversionRate          = "conversionRate",
    costPerAction           = "costPerAction",
    costPerSubscription     = "costPerSubscription",
    returnOnAdSpend         = "returnOnAdSpend",

    cartCount             = "cartCount",
    cartValue             = "cartValue",
    advPurchaseCount      = "advPurchaseCount",
    advPurchaseValue      = "advPurchaseValue",
    othersCount           = "othersCount",
    othersValue           = "othersValue",
}

/** 리포트 데이터 타입, key - entry ID or Date */
export interface StatReportResType2{
    key: string;
    impressionCount: number;
    clickCount: number;
    adCost: number;
    adRank: number;
    videoCount: number;
    dcPurchaseCount: number;
    dcPurchaseValue: number;
    dcSubscriptionCount: number;
    dcSubscriptionValue: number;
    dcCartCount: number;
    dcCartValue: number;
    dcAdvPurchaseCount: number;
    dcAdvPurchaseValue: number;
    dcOthersCount: number;
    dcOthersValue: number;
    icPurchaseCount: number;
    icPurchaseValue: number;
    icSubscriptionCount: number;
    icSubscriptionValue: number;
    icCartCount: number;
    icCartValue: number;
    icAdvPurchaseCount: number;
    icAdvPurchaseValue: number;
    icOthersCount: number;
    icOthersValue: number;
}



