import clsx from "clsx";
import { omit } from 'lodash';
import { ReactNode } from "react";
import { Card } from "react-bootstrap";
import styles from './style.module.scss';

export interface CustomCardProps{
    title?:string|React.ReactNode;
    description?: string|ReactNode;
    children?:React.ReactNode;
    style?:React.CSSProperties;
    className?:string;
    titleClassName?:string;
    descriptionClassName?:string;
    innerClassName?:string;
    bodyClassName?:string;
    warning?: string | string[] | React.ReactNode | React.ReactNode[];
    readOnly?:boolean;
    disabled?:boolean;
    show?:boolean;
}

const CustomCard:React.FC<CustomCardProps> = (props:CustomCardProps)=>{  
    const warning_props = omit(props, ['className', 'style']);  
    return (<Card className={clsx('mb-3', props.className)} style={{display:props.show===false ? 'none' : '', ...props.style}}>
        <Card.Body className={clsx('p-5', props.innerClassName)}>
            {props.title && (<div key='title' className={clsx("fw-bolder mb-3", props.innerClassName)}>
                <div className={props.titleClassName}>{props.title}</div>
                {props.description && (<div key='description' className={clsx("fw-normal text-gray-700 lh-sm", props.descriptionClassName)}>{props.description}</div>)}
            </div>)}
            <div key='body' className={props.bodyClassName}>
                {props.children}
            </div>
            <Warning className='pt-5' {...warning_props} />
        </Card.Body>
    </Card>);
}

export const Warning:React.FC<CustomCardProps> = (props:CustomCardProps)=>{
    // undefined가 아니고 배열도 아니면 배열화처리 - undefined는 유지할 수 있도록 한다.
    const warning = (props.warning!==undefined && !Array.isArray(props.warning)) ? [props.warning] : props.warning;
    return (<>
        {warning?.map((v, index)=>(v && <div key={index} className={clsx('fs-7', styles.warning_label, props.className)} style={props.style}>
            <i className={clsx("bi bi-exclamation-triangle", styles.warning_icon)} />
            {v}
        </div>))}
    </>);
}

export const NoneCard:React.FC<CustomCardProps> = (props:CustomCardProps)=>{
    // undefined가 아니고 배열도 아니면 배열화처리 - undefined는 유지할 수 있도록 한다.
    const warning = (props.warning!==undefined && !Array.isArray(props.warning)) ? [props.warning] : props.warning;
    
    return (<div className={clsx('mb-3', props.className)} style={{display:props.show===false ? 'none' : '', ...props.style}}>
        {props.title && (<div key='title' className="fw-bolder mb-3">{props.title}</div>)}
        <div key='body' className={props.bodyClassName}>
            {props.children}
        </div>
        {warning?.map((v, index)=>(v && <div key={index} className={clsx(styles.warning_label, 'de-flex')}>
            <i className={clsx("bi bi-exclamation-triangle", styles.warning_icon)} />
            {v}
        </div>))}
    </div>);
}

export default CustomCard;