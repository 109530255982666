import {Suspense, useEffect} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {I18nProvider} from '../_metronic/i18n/i18nProvider';
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core';
import {MasterInit} from '../_metronic/layout/MasterInit';
import {AuthInit} from './modules/auth';
import { UserContext, UserContextModel } from './contexts/UserContext';
import { ModalMessage, ToastMessage, UseAlertMessage, UseModalMessage, UseToastMessage } from './modules/message';
import AuthService from './common/services/AuthService';
import Config from './common/conf/Config';
import LoadingModal, { UseLoadingModal } from './modules/modal/LoadingModal';
// import { UseExplorer } from './pages/explorer/ExplorerDrawer';
import UserSelect from './common/helpers/UserSelect';
import { sseService } from './common/services/SseService';
import TagManager from 'react-gtm-module';
import LabelStorage from './common/helpers/LabelStorage';
import NaverTreeStorage from './common/helpers/NaverTreeStorage';
import KakaoTreeStorage from './common/helpers/KakaoTreeStorage';

const isDevelMode = ['devel', 'local'].includes(Config.project.mode);
// const isLocalMode = ['local'].includes(Config.project.mode);

const App = () => {
    const alertMessage = UseAlertMessage();
    const toastMessage = UseToastMessage();
    const modalMessage = UseModalMessage();
    const userSelect = UserSelect.state();
    const userContext:UserContextModel = {
        navigate : useNavigate(),
        alertMessage : alertMessage,
        toastMessage : toastMessage,
        modalMessage : modalMessage,
        clearMessage :()=>{
            alertMessage.clear();
            toastMessage.clear();
            modalMessage.clear();
        },
        modalLoading : UseLoadingModal(),
        // explorer: UseExplorer(),
        userSelect: userSelect,
        label:{
            naver: LabelStorage.naver.state(),
            kakao: LabelStorage.kakao.state(),
        },
        tree:{
            naver: new NaverTreeStorage().state(),
            kakao: new KakaoTreeStorage().state(),
        },
    };

    // 마우스 동작이 있을 때, 세션시간 초기화
    useEffect(() => {
        // AccessTime 관리
        const refresh=(e:MouseEvent)=>{ AuthService.accessTimeRefresh(); }
        document.addEventListener('click', refresh);

        // Token만료 관리 및 유효성 체크 - 1초간격
        const tm = setInterval(()=>{AuthService.tokenRefreshCheck();},1000);

        // Token  유효성 체크 - 1분간격
        const tokenCheck = ()=>{ AuthService.tokenCheck(); };
        tokenCheck();   //시작시 한번 체크 - 오래된 토큰이 있는경우, 정상적으로 체크되지 않아 추가됨
        const tmToken = setInterval(tokenCheck,60 * 1000);

        return ()=>{ 
            document.removeEventListener('click', refresh); 
            clearInterval(tm);
            clearInterval(tmToken);
        }
    }, []);


    const userInfo = AuthService.storage.get()?.userInfo;
    useEffect(()=>{
        if(Config.app.SSEM.enable && userInfo?.user_id && userInfo.user_name !== "" ){
            sseService.start(userContext);  // SSE Message
        }
        return ()=>{ sseService.event?.close(); }
    // eslint-disable-next-line
    },[userInfo?.user_name]);

    useEffect(()=>{
        //// console 꾸미기 ////
        console.log(`%c AdPlo %c v${Config.project.version} (${Config.project.mode}) build.${process.env.NODE_ENV}`,
            "font-family:Arial; font-size:2.35em; color:white; border:1px solid #000000; border-radius:8px; background-color:#5014d0;",
            // "color:#ffffff; background:linear-gradient(91.59deg, #107BF9 -0.96%, #E20A65 99.96%); border-radius:3px; padding:0 5px; font-size:10pt; ",
            "color:#cdcdff;"
            ,'\nDBASE& Ahn Hyoung-keun(ahk007@cj.net)' );
    },[]);

    if(!isDevelMode){
        TagManager.initialize({gtmId:"GTM-KHHRKFS"});
        TagManager.dataLayer({dataLayer:{event:"event.page"}});
    }

    return (
        <UserContext.Provider value={userContext}>
            <Suspense fallback={<LayoutSplashScreen />}>
                <I18nProvider>
                    <LayoutProvider>
                        <AuthInit>
                            <Outlet />
                            <MasterInit />
                        </AuthInit>
                    </LayoutProvider>
                </I18nProvider>
            </Suspense>
            <ModalMessage data={userContext.modalMessage.data} onClose={ userContext.modalMessage.close }/>
            <ToastMessage data={userContext.toastMessage.data} onClose={ userContext.toastMessage.close }/>
            <LoadingModal isShow={userContext.modalLoading.count>0} />
        </UserContext.Provider>
    )
    }

    export {App}
