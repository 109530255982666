import { StatReportInfoType, StatReportResType } from "../../../common/services/models/kakao/StatReportType";
import KakaoCode from "../../../modules/code/KakaoCode"
import { HiperLinkEllisis } from "../../../modules/layer/HiperLink";
import { ModifyViewerType } from "../../modules/modify/Modify";

export const convertStatInfoViewData = (level?:string, data?:StatReportInfoType):ModifyViewerType[]=>{
    switch(level){
        case KakaoCode.data.options.ExplorerLevel.bizChannel.value : {
            const rspvUrl = data?.businessChannelRspvUrl?.replaceAll("None","");
            const pcUrl = data?.businessChannelPcUrl?.replaceAll("None","");
            const moUrl = data?.businessChannelMoUrl?.replaceAll("None","");
            const result:ModifyViewerType[] = [
                // {title:'비즈채널명', value:tmp?.bizChannelName},
                {title:'비즈채널ID', value:data?.businessChannelId},
            ];
            rspvUrl && result.push({title:'반응형 URL', value: <HiperLinkEllisis href={rspvUrl} >{rspvUrl}</HiperLinkEllisis>});
            pcUrl && result.push({title:'PC URL', value: <HiperLinkEllisis href={pcUrl} >{pcUrl}</HiperLinkEllisis>});
            moUrl && result.push({title:'Mobile URL', value: <HiperLinkEllisis href={moUrl} >{moUrl}</HiperLinkEllisis>});
            
            return result;
        }
        case KakaoCode.data.options.ExplorerLevel.campaign.value : {
            return [
                // {title:'캠페인명', value:tmp?.campaignName},
                {title:'캠페인ID', value:data?.campaignId},
                // {title:'비즈채널명', value:tmp?.bizChannelName},
                // {title:'비즈채널ID', value:tmp?.bizChannelId},
            ];
        }
        case KakaoCode.data.options.ExplorerLevel.adGroup.value : {
            return [
                // {title:'광고그룹명', value:tmp?.adGroupName},
                {title:'광고그룹ID', value:data?.adgroupId},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인ID', value:data?.campaignId},
            ];
        }
        case KakaoCode.data.options.ExplorerLevel.keyword.value : {
            return [
                // {title:'키워드', value:tmp?.keywordText},
                {title:'키워드ID', value:data?.keywordId},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인ID', value:data?.campaignId},
                {title:'광고그룹명', value:data?.adgroupName},
                {title:'광고그룹ID', value:data?.adgroupId},
            ];
        }
        case KakaoCode.data.options.ExplorerLevel.creative.value : {
            return [
                // {title:'소재명', value:tmp?.creativeName},
                {title:'소재ID', value:data?.adId},
                {title:'확장소재 유형', value: data?.assetTypeValues},
                {title:'비즈채널명', value:data?.businessChannelName},
                {title:'비즈채널ID', value:data?.businessChannelId},
                {title:'소재 제목', value:data?.title},
                {title:'소재 설명', value:data?.description},
            ];
        }
        case KakaoCode.data.options.ExplorerLevel.creativLink.value : {
            return [
                {title:'소재연결ID', value:data?.adLinkId},
                {title:'확장소재 유형', value: data?.assetTypeValues},
                // {title:'확장소재 유형', value: KakaoCode.getLabels(KakaoCode.data.Assets.AssetTypes, data?.assetTypeValues?.split(",") || [])?.join(', ')},
                // {title:'비즈채널명', value:tmp?.bizChannelName},
                // {title:'비즈채널ID', value:tmp?.bizChannelId},
                {title:'광고그룹명', value:data?.adgroupName},
                {title:'광고그룹ID', value:data?.adgroupId},
                {title:'소재명', value:data?.adName},
                {title:'소재ID', value:data?.adId},
                {title:'소재 제목', value:data?.title},
                {title:'소재 설명', value:data?.description},
            ];
        }
    }
    return [];
}


export const ExplorerTitle:React.FC<{level?:string, data?:StatReportInfoType}> = (props)=>{
    switch(props.level){
        case KakaoCode.data.options.ExplorerLevel.bizChannel.value : return <>비즈채널명 : {props.data?.businessChannelName}</>;
        case KakaoCode.data.options.ExplorerLevel.campaign.value : return <>캠페인명 : {props.data?.campaignName}</>;
        case KakaoCode.data.options.ExplorerLevel.adGroup.value : return <>광고그룹명 : {props.data?.adgroupName}</>;
        case KakaoCode.data.options.ExplorerLevel.keyword.value : return <>키워드 : {props.data?.keyword}</>;
        case KakaoCode.data.options.ExplorerLevel.creative.value : return <>소재명 : {props.data?.adName}</>;
        case KakaoCode.data.options.ExplorerLevel.creativLink.value : return <>소재연결 ID : {props.data?.adLinkId}</>;
    }
    return <>Unknown</>;
}



/**
 * 20230622 --> 2023-06-22
 * @param txt 
 * @returns 
 */
export const getStatDateFormatter = (txt:string)=>{
    const tmp:string = txt.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    if(isNaN(new Date(tmp).getTime())){ return txt; }
    return new Date(tmp).format('yyyy-MM-dd');
}



export const getStatReportTotal = (data:StatReportResType[]):StatReportResType => {
    const total:StatReportResType = {
        key:'합계',
        date:'합계',
        impressionCount: 0,
        clickCount: 0,
        adCost: 0,
        adRank: 0,
        rankImpression: 0,
        cvAppInstall1D: 0,
        cvAppInstall7D: 0,
        cvParticipation1D: 0,
        cvParticipation7D: 0,
        cvPurchase1D: 0,
        cvPurchase7D: 0,
        cvPurchasePrice1D: 0,
        cvPurchasePrice7D: 0,
        cvRegistration1D: 0,
        cvRegistration7D: 0,
        cvSignup1D: 0,
        cvSignup7D: 0,
        cvViewCart1D: 0,
        cvViewCart7D: 0,

        averageAdRank: 0,
        clickThroughRate: 0,
        costPerClick: 0,
        conversionRate: 0,
        costPerAction: 0,
        costPerSubscription: 0,
        returnOnAdSpend: 0,
    };

    const entries = Object.entries(total);
    data.forEach((v)=>{
        const tmp:Map<string,number> = new Map(Object.entries(v));
        entries.forEach((v2)=>{
            if(v2[0]==='key'){return; }
            v2[1] += tmp.get(v2[0]) || 0;
        });
    });

    return {...total, ...Object.fromEntries(entries)};
}

export const AppendStatReportTotal = (data:StatReportResType[]):StatReportResType[] => {
    const tmp2:StatReportResType = getStatReportTotal(data);
    data.push(tmp2);
    return data;
}