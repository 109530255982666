
import clsx from "clsx";
import { omit } from 'lodash';
import { FormControl, FormControlProps } from "react-bootstrap";
import { v4 as uuid } from 'uuid';

export interface RadioProps extends Omit<FormControlProps, 'id' | 'type'>{
    checked?:boolean;
    name:string;
    isViewer?:boolean;
}
/**
 * 라디오박스
 * @param props 
 * @returns 
 */
const Radio = (props:RadioProps)=>{
    const uid = uuid();
    const handleOnChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
        if(props.readOnly || props.disabled) return;
        props.onChange && props.onChange(e);
    }
    let size="";
    switch(props.size){
        case "sm" : size="form-check-sm"; break;
        case "lg" : size="form-check-lg"; break;
    }
    const props_inp = omit(props, ['children', 'className', 'style']);
    return (<div className={clsx('form-check form-check-custom form-check-solid', size, props.className)} style={{paddingLeft:'inherit', ...props.style}}>
        <FormControl className={`form-check-input`} type="radio" id={'rdo-'+uid} {...props_inp}
            style={{minHeight:"auto", padding:0, cursor:"pointer", display:props.isViewer ? "none" : ""}}
            onChange={handleOnChange} />
        <label className="me-5 pt-1 ps-2" htmlFor={'rdo-'+uid}>{props.children}</label>
    </div>);
}

export default Radio;