import {Card, Col, Row} from "react-bootstrap";
import AuthService from '../../common/services/AuthService';
import Config from "../../common/conf/Config";
// import { useUserContext } from "../../contexts/UserContext";


function Oauth() {
    // const userContext = useUserContext();
    AuthService.signIn().then((res)=>{
        if(res){ 
            // document.location.replace("http://localhost:3000/");
            //document.location.replace(Config.project.path);
            const url = sessionStorage.getItem("pageUrl");  //SessionTimmer.tsx 에서 기록한 URL 사용
            document.location.replace(url || Config.project.path);
            // console.log(`${document.location.protocol}//${document.location.host}`);
            // userContext.navigate("/dashboard");
            // userContext.alertMessage.add({title:"비밀번호 유효기간 만료", body:"비밀번호 유효기간이 만료되었습니다. 비밀번호를 재설정해 주세요. 개인설정 > 비밀번호 재설정."});
            // alert('로그인 과정에서 오류가 발생하였습니다!\n지속적으로 발생하는 경우, 관리자에게 문의바랍니다'); 
        }
    }).catch((e)=>{
        alert('로그인 과정에서 오류가 발생하였습니다!\n지속적으로 발생하는 경우, 관리자에게 문의바랍니다'); 
    });

    return (
        <div>
            <div className="main d-flex w-100 justify-content-center" style={{minHeight: "calc(100vh - 5em)"}}>
                <div className="d-flex flex-column container">
                    <Row className="h-100">
                        <Col sm={10} md={8} lg={6} className="mx-auto d-table h-100">
                            <div className="d-table-cell align-middle">

                                <div className="text-center mt-4">
                                    <h2>Welcome to, {process.env.REACT_APP_CLIENT_NAME}</h2>
                                    <p className="lead">Sign in to your account to continue</p>
                                </div>

                                <Card>
                                    <div className="card-body m-sm-4">
                                        <div className="text-center mt-3">로그인중...</div>
                                    </div>
                                </Card>

                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}


export default Oauth;