import {KakaoServer} from '../../axios';
import Utils from '../../utils/Utils';
import KakaoHelperComponent from './KakaoHelperComponent';

/** 카카오 키워드 */
export default class KeywordHelper extends KakaoHelperComponent{
    static path="/media/keywords"; //기본 Path
    static onOff="/media/keywords/on-off"; //on-off modify Path
    static bidStrategy="/media/keywords/bid-strategy"; //입찰가 변경 modify Path
    static copy="/media/keywords/copy"; //기본 Path
    static stat='/performance/keywords';    //실적데이터
    static performances='/performances/keywords';    //실적데이터
    static downloadPath='/api/kakaokeywordad/xlsxdnld/keywords';

    public static putBidStrategy<S>(body:S){
        return Utils.apiRequestValidation({}) || KakaoServer.put<S>(this.bidStrategy, {body: body},{headers:{useAccount:true}});
    }

    public static putCopy<S>(body:S){
        return Utils.apiRequestValidation({}) || KakaoServer.put<S>(this.copy, body, {headers:{useAccount:true}});
    }
}